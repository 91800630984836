import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      isBooking:false,
      eventInfo:null,
      scheduleInfo:null,
      values:null
}

const EventDetailSlice = createSlice({
      name:'eventdetail',
      initialState:initialState,
      reducers:{
            setEventsDetailsData(state,action){
                  state.eventInfo = action.payload.eventInfo
                  state.scheduleInfo = action.payload.scheduleInfo
                  state.values = action.payload.values
            },
            startEventBooking(state,action){
                  state.isBooking = true;
            },
            closeEventBooking(state,action){
                  state.isBooking = false;
            },
            clearEventDetailsState(state,action){
                  state.isBooking = false;
                  state.eventInfo = null;
                  state.scheduleInfo = null;
                  state.values = null;
            },
            // setScheduleInfo(state,action){
            //       state.scheduleInfo = action.payload.scheduleInfo
            // },
            setEventNotifyResponse(state,action) {
                  state.values.is_waiting_joined = action.payload.is_waiting_joined
            },
            reset: ()=> initialState
      }
})


export const EventDetailActions = EventDetailSlice.actions;
export default EventDetailSlice.reducer;






//--------------------------------------------------------------------------//
// ---------------------old Slice without reset options----------------------
//--------------------------------------------------------------------------//


// import { createSlice } from "@reduxjs/toolkit";


// const EventDetailSlice = createSlice({
//       name:'eventdetail',
//       initialState:{
//             isBooking:false,
//             eventInfo:null,
//             scheduleInfo:null,
//             values:null
//       },
//       reducers:{
//             setEventsDetailsData(state,action){
//                   state.eventInfo = action.payload.eventInfo
//                   state.scheduleInfo = action.payload.scheduleInfo
//                   state.values = action.payload.values
//             },
//             startEventBooking(state,action){
//                   state.isBooking = true;
//             },
//             closeEventBooking(state,action){
//                   state.isBooking = false;
//             },
//             clearEventDetailsState(state,action){
//                   state.isBooking = false;
//                   state.eventInfo = null;
//                   state.scheduleInfo = null;
//                   state.values = null;
//             },
//             // setScheduleInfo(state,action){
//             //       state.scheduleInfo = action.payload.scheduleInfo
//             // },
//             setEventNotifyResponse(state,action) {
//                   state.values.is_waiting_joined = action.payload.is_waiting_joined
//             }
//       }
// })


// export const EventDetailActions = EventDetailSlice.actions;
// export default EventDetailSlice.reducer;