import React,{useEffect} from "react";
import OwlCarousel from 'react-owl-carousel';

// import stockImage13 from "./../assests/images/stock-image-13.jpg";
// import stockImage13a2x from "./../assests/images/stock-image-13@2x.jpg";
// import stockImage13a2x from "./../assests/images/stock-image-13@2x.jpg 2x";
import stockImage13 from "./../assests/images/iStock-542088116.jpg";    //stock image without watermarks

// import stockImage14 from "./../assests/images/stock-image-14.jpg";
// import stockImage14a2x from "./../assests/images/stock-image-14@2x.jpg";
// import stockImage14a2x from "./../assests/images/stock-image-14@2x.jpg 2x";
import stockImage14 from "./../assests/images/iStock-1212167952.jpg";   //stock image without watermarks

// import stockImage15 from "./../assests/images/stock-image-15.jpg";
// import stockImage15a2x from "./../assests/images/stock-image-15@2x.jpg";
// import stockImage15a2x from "./../assests/images/stock-image-15@2x.jpg 2x";
import stockImage15 from "./../assests/images/iStock-465180779.jpg";    //stock image without watermarks

// import stockImage16 from "./../assests/images/stock-image-16.jpg";
// import stockImage16a2x from "./../assests/images/stock-image-16@2x.jpg";
// import stockImage16a2x from "./../assests/images/stock-image-16@2x.jpg 2x";
import stockImage16 from "./../assests/images/iStock-914628584.jpg"     //stock image without watermarks

// import stockImage17 from "./../assests/images/stock-image-17.jpg";
// import stockImage17a2x from "./../assests/images/stock-image-17@2x.jpg";
// import stockImage17a2x from "./../assests/images/stock-image-17@2x.jpg 2x";
import stockImage17 from "./../assests/images/iStock-911008782.jpg";    //stock image without watermarks

const SocialImpact = () => {

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
      },[])

      const options={
            center: true,
            autoplay: true,
            loop: true,
            dots: false,
            items:5,
            autoplayTimeout:2500,
            responsive:{
                  0:{
                        autoWidth:true,
                        // autoWidth sets a max-width for
                        // .card__testimonial at screen size min 1400px
                        items:1
                  },
                  768:{
                        items:1.5

                  },
                  1200:{
                        autoWidth:true,
                        items:5
                  },
            }
      }

      return (

            <main>
                  <section className="section__social-impact">
        
                        {/* <!--
                        NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        {/* <!-- INTRO --> */}
                        <div className="container-fluid container__block border-bottom">
                              <div className="row row__intro">
                                    <div className="col-md-6 col__left border-right-md border-bottom border-bottom-md-0">
                                          <div className="content content__intro-left">
                                                <div className="content__body">
                                                      <p className="lead fw-normal">
                                                            Tickets for Medics works in support of <span className="blue fw-bold"> <a href="https://nhscharitiestogether.co.uk/" style={{"textDecoration":"none"}} target="_blank" rel="noreferrer">NHS Charities Together</a></span>.&nbsp; Every ticket includes a donation that will help to fund one of their projects.
                                                      </p>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-6 --> */}
                                    <div className="col-md-6 col__right">
                                          <div className="content ">
                                                <div className="content__body">
                                                      <p>
                                                            NHS Charities Together helps our health services to do more: from supporting research and development, to brightening up hospital environments, to donating state-of-the-art technologies and equipment, the charities raise funds and
                                                            mobilise volunteers, touching lives and making a huge difference to millions of people when they are at their most vulnerable.
                                                      </p>
                                                      <p>
                                                            During the Covid-19 pandemic this focus has shifted to supporting immediate practical and emotional needs of staff and patients and helping to take pressure off an overstretched health service.
                                                      </p>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                                <div className="content__cta">
                                                      <a className="btn btn-xl-lg btn-primary fw-bold" href="https://nhscharitiestogether.co.uk/" target="_blank" rel="noreferrer">Visit website</a>
                                                </div>
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-6 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid --> */}
        
                        {/* <!-- CAROUSEL --> */}
                        <div className="container-fluid container__block border-bottom">
                              <div className="row row__carousel">
                                    <div className="col-md-12">
                                          <div className="contentt content__ h-100">
                                                <div className="content__body content__body-image h-100">
                                                      {/* <div className="owl-carousel owl-carousel__social-impact owl-theme h-100"> */}
                                                      <OwlCarousel className='owl-carousel owl-carousel__social-impact owl-theme h-100'  {...options}>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        // srcSet={stockImage13a2x} 
                                                                        src={stockImage13} alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        // srcSet={stockImage14a2x} 
                                                                        src={stockImage14} alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        // srcSet={stockImage15a2x} 
                                                                        src={stockImage15} alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        // srcSet={stockImage16a2x} 
                                                                        src={stockImage16} 
                                                                        alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        // srcSet={stockImage17a2x} 
                                                                        src={stockImage17} alt=""/>
                                                                  </div>
                                                            </div>
                                                      </OwlCarousel>
                                                      {/* </div> */}
                                                      {/* <!-- /.owl-carousel__shows --> */}
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid --> */}
        
                        {/* <!-- ABOUT US --> */}
                        <div className="container-fluid container__block">
                              <div className="row row__about">
                                    <div className="col-md-12">
                                          <div className="content content__about">
                                                <div className="content-box-wrapper mx-md-auto">
                                                      <div className="content__header border-bottom text-md-center">
                                                            <h1 className="header__title fw-bold mb-0">About us</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}
                                                      <div className="content__body">
                                                            <p>
                                                                  Tickets for Medics is a seat filling agency connecting NHS Staff & service providers with seat-filling opportunities for live events throughout the UK, created by the same team behind Central Tickets.
                                                            </p>
                                                            <p>
                                                                  By launching Tickets for Medics, we wanted to create a separate space where our ticketing partners can make their events available to a smaller group of verified NHS personnel and service providers.&nbsp; It is also important to us
                                                                  that Tickets for Medics should give something back to the people it supports in a tangible way, which is why every ticket booked generates a donation for NHS Charities Together.
                                                            </p>
                                                            <p>
                                                                  We recognise that our ticketing partners want to give thanks to the NHS for working hard to keep everyone safe throughout the Covid-19 pandemic and we’re also aware that event organisers face their own unique challenges,
                                                                  welcoming audiences back into event spaces after such difficult times and our appreciative audience of NHS keyworkers are happy to help.
                                                            </p>
                                                            <p>
                                                                  By joining Tickets for Medics, or working with us as a Ticketing Partner you are helping to fund the work of over 230 NHS Charities across the UK, providing extra support needed to care for NHS staff, patients & communities.
                                                            </p>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
                  </section>
                  {/* <!-- /.section__social-impact --> */}
          </main>

      )
}

export default SocialImpact;
