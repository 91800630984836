// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Backdrop_Backdrop__DpgX6 {\n        height: 100%;\n        width: 100%;\n        position: fixed;\n        z-index: 1002;\n        top: 0;\n        left: 0;\n        background-color: rgba(242, 233, 233, 0.5);\n}", "",{"version":3,"sources":["webpack://./src/Ui/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;QACQ,YAAY;QACZ,WAAW;QACX,eAAe;QACf,aAAa;QACb,MAAM;QACN,OAAO;QACP,0CAA0C;AAClD","sourcesContent":[".Backdrop {\n        height: 100%;\n        width: 100%;\n        position: fixed;\n        z-index: 1002;\n        top: 0;\n        left: 0;\n        background-color: rgba(242, 233, 233, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": "Backdrop_Backdrop__DpgX6"
};
export default ___CSS_LOADER_EXPORT___;
