
const checkValidationPayment=(value, type) =>{
      let isValid = true;
      let validatingMessage = "";
      if (!type) {
          return true;
      }

      if(type === "nhs_name_on_card"){
            let nhs_name_on_card = value.trim();
            if(nhs_name_on_card.length === 0){
                  isValid = false;
                  validatingMessage ="Card holder’s name is empty"
            }
            else if(nhs_name_on_card.length > 4){

                  let firstName = nhs_name_on_card.split(' ').slice(0, -1).join(' ');
                  let lastName = nhs_name_on_card.split(' ').slice(-1).join(' ');
                  let nhs_fname_on_card_length=firstName.trim().length;
                  let nhs_lname_on_card_length=lastName.trim().length;
      
                  if(nhs_fname_on_card_length <1 || nhs_lname_on_card_length<2)
                  {
                      isValid = false;
                      validatingMessage ="Please enter your name as printed on the card"
                  }
            }
            else {
                  isValid = false;
                  validatingMessage ="Please enter your name as printed on the card"
            }
      }

      if(type === "nhs_card_number"){
            if(value.trim() ==='') 
            {
                  isValid = false;
                  validatingMessage = "Card number is empty";
            }

            var cardno = /^(?:3[47][0-9]{13})$/;
            if(value.match(cardno))
            {
                  isValid =false;
                  validatingMessage = "Sorry, we don't accept AMEX at this time."
            }

      }

      if(type === "nhs_exp"){
            if(value.trim() ==='') 
            {
                  isValid = false;
                  validatingMessage = "Expiry date is empty";
            }
      }

      if(type === "nhs_cvc"){
            if(value.trim() ==='') 
            {
                  isValid = false;
                  validatingMessage = "CVC is empty";
            }
      }

      return {isValid,validatingMessage};
}


export default checkValidationPayment;
