import { BaseUrl } from "./../../Shared/Constants";
import axios from "axios";

import { UiActions } from "../Ui/Ui";
import { AuthActions } from "./Auth";
import { AllEventsActions } from "../Allevents/AllEvents";
import { BookingActions} from "./../Bookings/Bookings";
import { BookingModuleActions } from "./../BookingModule/BookingModule";


export const sendLogoutRequest = (payload)=> {

        return async (dispatch)=>{

                const sendRequest =async () =>{
                        
                        await axios.post(BaseUrl + '/logout',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              // console.log(response)
                              payload.navigate("/")
                              dispatch(AllEventsActions.reset());
                              dispatch(AuthActions.logout())
                              dispatch(AuthActions.reset());
                              dispatch(BookingActions.reset())
                              dispatch(BookingModuleActions.reset())
                              dispatch(UiActions.reset())
                        })
                        .catch(function (error) {

                              // console.log(error.response)
                              if(error.response){

                                    if(error.response.status === 500)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                                    else if(error.response.status === 400) {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message ||  "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                                    else{
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }

                                
                        });
                }
                await sendRequest();
                
        }

}

export const passwordChangeRequest = (payload)=> {

      return async (dispatch)=>{
              
            const changePasswordRequest =async () =>{

                  await axios.post(BaseUrl + '/settings/changePassword',payload.data,{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response)
                        dispatch(UiActions.setChangePasswordRequest({request:false}))
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        dispatch(AllEventsActions.clearEventsData());
                        dispatch(AuthActions.logout());
                        // payload.history.push("/");
                  })
                  .catch(function (error) {

                        dispatch(UiActions.setChangePasswordRequest({request:false}))
                        if(error.response) {

                              if(error.response.status === 400){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message ||error.response.data.error}))
                              }
                              else if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message ||error.response.data.error}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  });
                     
            }

            await changePasswordRequest();
              
      }

}

export const emailChangeRequest = (payload)=> {

      return async (dispatch)=>{
              
            const changeEmailRequest =async () =>{

                  await axios.post(BaseUrl + '/settings/update_email',payload.data,{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response)
                        dispatch(UiActions.setChangeEmailRequest({request:false}))
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        dispatch(AuthActions.logout())
                        dispatch(AllEventsActions.clearEventsData());
                        // payload.history.push("/");
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setChangeEmailRequest({request:false}))
                        if(error.response) {

                              if(error.response.status === 500){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else if(error.response.status === 404){
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                              }
                              else if(error.response.status === 400){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                  });
                     
            }

            changeEmailRequest();
              
      }

}

export const appVerifyEmail = (payload)=> {

      return async (dispatch)=>{
              
            const changeEmailRequest =async () =>{

                  await axios.post(BaseUrl + '/app_verify_email',{verification_code:payload.verification_code},{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response)
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response) {

                              if(error.response.status === 500){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else if(error.response.status === 401){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}));
                              }
                              else if(error.response.status === 404){
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                              }
                              else if(error.response.status === 400){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                  });
                     
            }
            await changeEmailRequest();
              
      }

}

export const sendDeleteAccountRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{

                  await axios.post(BaseUrl + '/settings/delete_account',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                  })
                  .then(function (response) 
                  {
                        // console.log(response);
                        dispatch(AuthActions.logout());
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response){

                              if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 404){
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                              
                  });
            }
            await sendRequest();
                
        }

}

export const sendContactUsRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                        axios.post(BaseUrl + '/contact_web',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              // console.log(response)
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                              dispatch(UiActions.setContactUsRequest({request:"requested"}))
                                
                        })
                        .catch(function (error) {

                              //   console.log(error.response)
                              dispatch(UiActions.setContactUsRequest({request:false}))
                                
                              if(error.response){

                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                    }
                                    else if(error.response.status === 500){
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || error.response.data.errors}))
                                    }
                                    else if(error.response.status === 404)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                                
                        });
            }

            await sendRequest();
      }
}

export const verifyForgotPasswordToken = (payload)=> {

      return async (dispatch)=>{
              
            const verifyTokenRequest =async () =>{

                  await axios.get(BaseUrl + '/password_token_verify?request_token='+payload.request_token,{
                        headers: {
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        if(!response.data){
                              // dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                  })
                  .catch(function (error) {
                        // console.log(error.response);
                        if(error.response) {

                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || "Invalid link please try again"}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }

                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        payload.navigate("/")
                  });
                     
            }

            await verifyTokenRequest();
              
      }

}

export const forgotPasswordSubmitResetPassword = (payload)=> {

      return async (dispatch)=>{
              
            const SubmitResetPasswordRequest =async () =>{

                  await axios.post(BaseUrl + '/password_change_submit',payload.data,{
                        headers: {
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        dispatch(UiActions.setResetPasswordRequest({request:false}))
                        if(response.data){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                        payload.navigate("/")
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setResetPasswordRequest({request:false}))
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        // payload.navigate("/")
                  });
                     
            }
            await SubmitResetPasswordRequest();
              
      }

}

export const submitJoinDataRequest = (payload)=> {

      return async (dispatch)=>{
              
            
            const SubmitJoinRequest =async () =>{

                  await axios.post(BaseUrl + '/registration_with_nhs_email',payload.data,{
                        headers: {
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        dispatch(UiActions.setJoinRequest({request:false}))
                        // console.log(response.data)
                        if(response.data){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              localStorage.removeItem('nhs_ajtix');
                              localStorage.removeItem('nhs_ragistration_type');
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                        payload.navigate("/currentListing");
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setJoinRequest({request:false}))
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || error.response.data.errors}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        // payload.navigate("/")
                  });
                     
            }
            await SubmitJoinRequest();
              
      }

}

export const submitAltJoinDataRequest = (payload)=> {

      return async (dispatch)=>{
              
            const SubmitJoinRequest =async () =>{

                  await axios.post(BaseUrl + '/registration_without_nhs_email',payload.data,{
                        headers: {
                              // 'Content-Type': 'multipart/form-data',
                              'Content-Type': 'application/json',
                              'reuqest_client': 'web',
                        },
                  })
                  .then(function (response) 
                  {
                        dispatch(UiActions.setAltJoinRequest({request:false}))
                        // console.log(response.data)
                        if(response.data){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              localStorage.removeItem('nhs_ajtix');
                              localStorage.removeItem('nhs_ragistration_type');
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                        payload.navigate("/currentListing");
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setAltJoinRequest({request:false}))
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.errors|| error.response.data.message}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        // payload.navigate("/")
                  });
                     
            }
            await SubmitJoinRequest();
              
      }

}

export const verifyRegistration = (payload)=> {

      return async (dispatch)=>{
            
            const SubmitVerifyRequest =async () =>{

                  await axios.post(BaseUrl + '/register_token_verify',{token:payload.token,is_tfm:payload.is_tfm},{
                        headers: {
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        if(response.data){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                        payload.navigate("/")
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || error.response.data.errors}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        payload.navigate("/")
                  });
                     
            }
            await SubmitVerifyRequest();
              
      }

}

export const accessNeedsSubmitRequest = (payload)=> {

      return async (dispatch)=>{
            
            const SubmitAccessNeedsRequest =async () =>{

                  await axios.post(BaseUrl + '/eventlisting/submit_accessneeds',payload.data,{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        dispatch(UiActions.setAccessNeedsRequest({request:false}))
                        if(response.data){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                        payload.navigate("/")
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || error.response.data.errors}))
                              }
                              else if(error.response.status === 400){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message ||"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        dispatch(UiActions.setAccessNeedsRequest({request:false}))
                        payload.navigate("/")
                  });
                     
            }
            await SubmitAccessNeedsRequest();
              
      }

}

export const sendReactiveRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                  axios.post(BaseUrl +'/settings/submit_restore_access_terms',payload.data,{
                        headers:{
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        }
                  })
                  .then(function (response) 
                  {
                        // console.log(response)
                        if(response.data.status === "1") {
                              dispatch(AuthActions.reactiveAccount({reactive:"0"}))
                              // dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                        }
                        payload.navigate("/event")
                        dispatch(UiActions.setReactiveAccountFormRequest({request:false}))
                  })
                  .catch(function (error) {

                        // console.log(error.response);
                        dispatch(UiActions.setReactiveAccountFormRequest({request:false}))
                        if(error.response) {
                              if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    if(error.response.data.status === "0"){
                                          payload.navigate("/event")
                                    }
                              }
                              else if (error.response.status === 400){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  });
            }
            await sendRequest();
      }


}


export const userVerification = (payload)=> {

      return async (dispatch)=>{
            
            const SubmitVerifyRequest =async () =>{

                  await axios.post(BaseUrl + '/user_verification',{id:payload.id},{
                        headers: {
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        if(response.data.status=== "1"){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        }
                        else if(response.data.status=== "0"){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        }
                        // else {
                        //       dispatch(UiActions.setAlert({model:true,message:"Something went weong, Please try again later"}));
                        // }
                        // payload.navigate("/")
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || error.response.data.errors}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        // payload.navigate("/")
                  });
                     
            }
            await SubmitVerifyRequest();
              
      }

}


export const submitSelectedOptionsRequest = (payload)=> {

      return async (dispatch)=>{
              
            
            const SubmitRequest =async () =>{

                  await axios.post(BaseUrl + '/settings/update_tfm_details_data',payload.data,{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        dispatch(UiActions.setSelectedOptionsRequest({request:false}))
                        if(response.data){
                              if(response.data.status === "1"){
                                    dispatch(AuthActions.setSelectedNHSOptions({nhs_city:payload.data.city,nhs_role:payload.data.role,nhs_trust:payload.data.nhs_trust}))
                                    dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              } else if(response.data.status === "0"){
                                    dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setSelectedOptionsRequest({request:false}))
                        if(error.response) {
                              if(error.response.status === 500){

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || error.response.data.errors}))
                              }
                              else if(error.response.status === 404){

                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        // payload.navigate("/")
                  });
                     
            }
            await SubmitRequest();
              
      }

}

// -------------------------------------------------------------------------------------------//
// ------------------------------API Moved in components--------------------------------------//
// -------------------------------------------------------------------------------------------//

export const sendLoginRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                  await axios.post(BaseUrl +'/auth/login',payload.data,{
                        headers:{
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        }
                  })
                  .then(function (response) 
                  {

                        dispatch(AllEventsActions.reset());
                        dispatch(AuthActions.reset());
                        dispatch(BookingActions.reset())
                        dispatch(BookingModuleActions.reset())
                        dispatch(UiActions.reset())
                        const nhs_redirect = localStorage.getItem('nhs_redirect');
                        // console.log({...response.data.data})
                        if(response.data.status === "1") {
                              if(response.data.data) {
                                    dispatch(AuthActions.login({...response.data.data,"reactive":response.data.reactive || "0"}))
                                    if(nhs_redirect){
                                          payload.navigate(`${nhs_redirect}`);
                                          // localStorage.removeItem('nhs_redirect');
                                    }
                                    else{
                                          payload.navigate("/currentListing");
                                    }
                              }
                        }
                              
                        dispatch(UiActions.setLoginRequest({request:"requested"}))
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setLoginRequest({request:false}))
                        if(error.response) {

                              if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 404){
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  });
            }
            await sendRequest();
      }

}

export const sendForgotPasswordLinkRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                        axios.post(BaseUrl + '/forgotPassword',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              console.log(response,"forgot password")
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              dispatch(UiActions.setForgotPasswordRequest({request:'requested'}))
                              payload.navigate("/")
                                
                        })
                        .catch(function (error) {
          
                              // console.log(error.response)
                              dispatch(UiActions.setForgotPasswordRequest({request:false}))
                              if(error.response) {
      
                                    if(error.response.status === 500)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message}));
                                    }
                                    else
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                              }
                        });
            }

            await sendRequest();
      }
}