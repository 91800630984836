import React from 'react';
import "./DummyComponent.css"
const DummyComponent = (props) => {
      
      return (
            
      <main className="main__event-details">
            <section className="section__event-details">
                  <div className="container">
                        <div className="row">
                              <div className="col-lg-12 fixed-scroll-target">

                                    <div className="content content__event-details-v2">
                                          <div className="content__body p-0">
                                                <div className="event">
                                                      <div className="event__header flex-xl-row">
                                                            <div className="event__header-image event__header-image-dummy">
                                                                  {/* <img className="event-image w-100" src="assets/images/paul-weller.jpg" alt=""/> */}
                                                                  <img className="event-image w-100"/>
                                                            </div>
                                                            <div className="event__header-text event__header-text-dummy">
                                                                  <h2 className="h1 event__name event__name-dummy"></h2>
                                                                  <hr className="event__name-hr"/>
                                                                  <h6 className="event__location event__location-dummmy">
                                                                        <p></p>
                                                                  </h6>
                                                                  {/* <ul className="list__event-tags list-unstyled d-flex flex-wrap">
                                                                        <li><a href="#"><span className="badge badge-outline rounded-pill">Dance</span></a></li>
                                                                  </ul> */}
                                                            </div>
                                                      </div>
                                                      {/* <!-- .event__header --> */}

                                                      <div className="nav-tabs__info-venue-wrapper">
                                                            <div className="sticky__tabTicketInfoVenue">
                                                                  <div className="nav-tabs__info-venue">
                                                                        <ul className="nav nav-tabs" id="bookTicketsTab" role="tablist">
                                                                              <li className="nav-item" role="presentation">
                                                                                    <button className="nav-link active nav-link-dummy" id="bt-information-tab" data-bs-toggle="tab" data-bs-target="#bookTicketInformation" type="button" role="tab" aria-controls="bookTicketInformation" aria-selected="true">
                                                                                    </button>
                                                                              </li>
                                                                              <li className="nav-item" role="presentation">
                                                                                    <button className="nav-link nav-link-dummy" id="bt-venue-tab" data-bs-toggle="tab" data-bs-target="#bookTicketVenue" type="button" role="tab" aria-controls="bookTicketVenue" aria-selected="false">
                                                                                    </button>
                                                                              </li>
                                                                        </ul>
                                                                        <div className="tab-content" id="bookTicketsTabContent">
                                                                              <div className="tab-pane tab-pane__info fade show active" id="bookTicketInformation" role="tabpanel" aria-labelledby="bt-information-tab">
                                                                                    <div className="tab-pane__body">
                                                                                          <div className="list-group list-group__event-details list-group-flat">
                                                                                                <div className="list-group-item list-group__time d-flex">
                                                                                                      <div className="icon-wrapper icon-wrapper-dummy">
                                                                                                            <img className="icon__clock" src="" alt=""/>
                                                                                                      </div>
                                                                                                      <p className="mb-0 icon-wrapper-p-dummy"></p>
                                                                                                </div>
                                                                                                <div className="list-group-item list-group__price d-flex">
                                                                                                      <div className="icon-wrapper icon-wrapper-dummy">
                                                                                                            <img className="icon__payment-card" src="" alt=""/>
                                                                                                      </div>
                                                                                                      <p className="mb-0 icon-wrapper-p-dummy"></p>
                                                                                                </div>
                                                                                          </div>
                                                                                          {/* <!-- /.list-group --> */}
                                                                                          <hr/>
                                                                                          <h4 className="heading heading-dummy"></h4>
                                                                                          <p className='collection-instructions-dummy'></p>
                                                                                    </div>
                                                                                    {/* <!-- /.tab-pane__body --> */}
                                                                                    <div className="tab-pane__cta mt-auto">
                                                                                          <button type="button" className="btn btn-primary btn-book-show btn-book-show-dummy">
                                                                                          </button>
                                                                                    </div>
                                                                                    {/* <!-- /.tab-pane__cta --> */}
                                                                              </div>
                                                                              {/* <!-- /.tab-pane#bookTicketInformation --> */}
                                                                              <div className="tab-pane tab-pane__venue fade" id="bookTicketVenue" role="tabpanel" aria-labelledby="bt-venue-tab">
                                                                                    <div className="tab-pane__body mb-lg-0">
                                                                                          <div className="list-group list-group__event-details list-group-flat">
                                                                                                <div className="list-group-item list-group__location d-flex">
                                                                                                      <div className="icon-wrapper icon-wrapper-venue-dummy">
                                                                                                            <img className="icon__location-pin" src=""  alt=""/>
                                                                                                      </div>
                                                                                                      <p className="mb-0 icon-wrapper-p-venue-dummy"></p>
                                                                                                </div>
                                                                                                <div className="list-group-item list-group__cta d-flex">
                                                                                                      <div className="icon-wrapper"></div>
                                                                                                      <a className="btn-citymapper-image btn-citymapper-image-dummy">
                                                                                                            <img />
                                                                                                      </a>
                                                                                                </div>
                                                                                          </div>
                                                                                          {/* <!-- /.list-group --> */}
                                                                                          <div className="venue-map venue-map-dummy">
                                                                                                <iframe title="myFrame" width="100%" height="289" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src=""></iframe>
                                                                                          </div>
                                                                                    </div>
                                                                                    {/* <!-- /.tab-pane__body --> */}
                                                                                    <div className="tab-pane__cta mt-auto">
                                                                                          <button className="btn btn-primary btn-book-show d-lg-none btn-book-show-dummy" >
                                                                                          </button>
                                                                                    </div>
                                                                                    {/* <!-- /.tab-pane__cta --> */}
                                                                              </div>
                                                                              {/* <!-- /.tab-pane#bookTicketVenue --> */}
                                                                        </div>
                                                                        {/* <!-- /.tab-content --> */}
                                                                  </div>
                                                                  {/* <!-- /.nav-tabs__info-venue --> */}
                                                                  <ul className="list__event-tags list-unstyled d-flex flex-wrap">
                                                                        <li className='badge-dummy1'>
                                                                              <div className="badge badge-outline rounded-pill">
                                                                                    
                                                                              </div>
                                                                        </li>
                                                                        <li className='badge-dummy2'>
                                                                              <div>
                                                                                    <span className="badge badge-outline rounded-pill bg-danger badge-outline-dummy"></span>
                                                                              </div>
                                                                        </li>
                                                                  </ul>
                                                            </div>
                                                            {/* <!-- /.wrapper --> */}
                                                      </div>
                                                      {/* <!-- .nav-tabs__info-venue-wrapper --> */}

                                                      <div className="event__body border-top event__body-dummy">
                                                            <p></p> 
                                                      </div>
                                                      {/* <!-- .event__body --> */}
                                                </div>
                                                {/* <!-- .event --> */}
                                          </div>
                                          {/* <!-- .content__body --> */}
                                    </div>
                                    {/* <!-- .content.content__event-details-v2 --> */}

                              </div>
                              {/* <!-- .col-lg-12 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- /.container-fluid  --> */}

            </section>
            {/* <!-- /.section__event-details --> */}
      </main>
      );
}

export default DummyComponent;
