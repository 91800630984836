import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconLocationPinBlack from "./../../../../assests/images/icon-location-pin-black.svg"
import { UiActions } from "../../../../Store/Ui/Ui";
import { BookingActions } from "./../../../../Store/Bookings/Bookings";
import { downloadTicketRequest, getCollectionInstructions } from "./../../../../Store/Bookings/BookingActions";

const CurrentSingleComponent = (props) => {
     
      const sendDownloadTicketRequest = useSelector(state=>state.ui.sendDownloadTicketRequest)
      const sendCollectionInstruction = useSelector(state=>state.ui.sendCollectionInstruction)
      const token = localStorage.getItem("nhs_access_token");
      const dispatch = useDispatch();
      const navigate =  useNavigate();
      const collectionINstructionHandler = ()=>{

            const data = {
                  "booking_id": props.booking_id,
                  "schedule_id":props.show_id
            }

            dispatch(UiActions.setCollectionInstructiontRequest({request:props.booking_id}))
            dispatch(getCollectionInstructions({data:data,token:token}));

            // dispatch(UiActions.setAlert({model:true,message:props.collection_instruction}))
      }

      const startCancleBookingHandler = ()=>{
            dispatch(BookingActions.setCancleBookings({tickets:props.tickets,booking_id:props.booking_id,eventType:props.eventType,bookingRef:props.BookingRef,event_title:props.event_name,start_date:props.start_date,start_time:props.start_time}))
      }

      const donwloadTicketHandler = ()=>{
            const data= {
                  data:{
                        booking_id:props.booking_id,
                        tickets_type:props.tickets_type,
                        show_id:props.show_id
                  },
                  token:token,
                  // navigate_path:"https://nhs.centraltickets.co/download_booking_confirmation/"+ props.web_booking_id,
                  navigate_path:"https://admin.centraltickets.co.uk/download_booking_confirmation/"+ props.web_booking_id,
                  navigate:navigate
            }

            
            dispatch(UiActions.setDownloadTIcketRequest({request:props.booking_id}))
            dispatch(downloadTicketRequest(data))
      }

      const dateFormathandler = ()=>{
            const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            let day = weekday[props.date.getDay()];
            let date1 = props.date.getDate() + nth(props.date.getDate());
            let month = props.date.toLocaleString('default', { month: 'long' })
            let year = props.date.getFullYear();
            return day+' '+date1+' '+month+' '+year;
      }

      const nth = (d)=> {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
              case 1:  return "st";
              case 2:  return "nd";
              case 3:  return "rd";
              default: return "th";
            }
      }
     
      return (

                  <li className="list-item">
                        <div className="event d-flex flex-column flex-md-row align-items-md-stretch align-items-center-start">
                              <div className="event__poster flex-shrink-0">
                                    <img className="img-fluid" 
                                    // srcSet={eventImage09a2x} 
                                    src={props.event_image} alt=""/>
                              </div>
                              <div className="event__body d-flex flex-column flex-lg-row align-items-lg-center">
                                    <div className="event__details d-flex flex-column flex-xxl-row align-items-xxl-center">
                                          <div className="event__details-name mb-xxl-0">
                                                <h3 className="event__name">{props.event_name}</h3>
                                                <p className="event__time mb-xxl-0">{dateFormathandler()  +' – '+ props.time }</p>
                                                {/* <p className="event__time mb-xxl-0">{props.date.getDate()} {props.date.toLocaleString('default', { month: 'long' })} {props.date.getFullYear()} – {props.time}</p> */}
                                                <p className="event__time mb-xxl-0" style={{"fontWeight":"700"}}>{props.tickets} {props.tickets > 1 ? 'tickets':'ticket'}</p>
                                          </div>
                                          <div className="event__details-location">
                                                <div className="event-location">
                                                      <div className="icon-wrapper flex-shrink-0">
                                                            <img className="icon__location-pin" src={iconLocationPinBlack} alt=""/>
                                                      </div>
                                                      <div className="location">
                                                            {props.location}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.event__details --> */}
                                    <div className="event__cta">
                                          <div className="buttons-wrapper d-flex flex-column align-items-md-start">
                                                <button type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0" onClick={collectionINstructionHandler} disabled={sendCollectionInstruction === props.booking_id}>
                                                      {sendCollectionInstruction !== props.booking_id && "Collection Instructions"}
                                                      {sendCollectionInstruction === props.booking_id && "Please wait.."}
                                                      {sendCollectionInstruction === props.booking_id  && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                </button>
                                                <button type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0" onClick={startCancleBookingHandler}>
                                                      Amend Booking
                                                </button>
                                                <button className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0" onClick={donwloadTicketHandler} disabled={sendDownloadTicketRequest === props.booking_id}>
                                                      {sendDownloadTicketRequest !== props.booking_id && "Download Ticket(s)"}
                                                      {sendDownloadTicketRequest === props.booking_id && "Please wait.."}
                                                      {sendDownloadTicketRequest === props.booking_id  && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                </button>
                                          </div>
                                    </div>
                              </div>
                              {/* <!-- /.event__body --> */}
                        </div>
                        {/* <!-- /.event --> */}
                  </li>

      )
}

export default CurrentSingleComponent;
