import React,{useState,useEffect} from "react";

import { useSelector, useDispatch } from "react-redux";

import { checkValiditContactUs } from "./../../Shared/UtilityContactUs"
import { UiActions } from "../../Store/Ui/Ui";
import { sendContactUsRequest } from "./../../Store/Auth/AuthActions"

const ContactUs = () => {

      const [state, setState] = useState({
            email:{value:"",error:false,message:""},
            name:{value:"",error:false,message:""},
            mobile:{value:"",error:false,message:""},
            message:{value:"",error:false,message:""},
      })
      const auth = useSelector(state => state.auth)
      const contactUsRequest = useSelector(state => state.ui.contactUsRequest)
      
      const dispatch =  useDispatch();

      const token = localStorage.getItem('nhs_access_token');

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
            setState(prevState=>{

                  return {
                        ...prevState,
                        email:{...prevState.email,value:auth.nhs_access_email},
                        name:{...prevState.name,value:auth.nhs_access_name},
                        mobile:{...prevState.mobile,value:auth.nhs_access_mobilenumber}
                  }
            })

      },[auth.auth])

      useEffect(()=>{

            if(contactUsRequest === "requested"){
                 
                  setState({
                        email:{value:auth.nhs_access_email,error:false,message:""},
                        name:{value:auth.nhs_access_name,error:false,message:""},
                        mobile:{value:auth.nhs_access_mobilenumber,error:false,message:""},
                        message:{value:"",error:false,message:""},
                  })
                 
            }

      },[contactUsRequest])

      const changeHandler = (event)=>{

            const name = event.target.name;
            const value = event.target.value;

            setState(prevState=>{

                  return {
                        ...prevState,
                        [name]:{...prevState.message,value:value}
                  }
            })
      }

      const submitContactFormHandler=(event)=>{

            event.preventDefault();
            const errorStore = [];
            const copyObject = {...state};
            for(let i in state){

                  const error = checkValiditContactUs(state[i].value,i);
                  if(!error.isValid){
                        errorStore.push(error.validationMessage)
                  }
                  copyObject[i] = {...copyObject[i],error:!error.isValid,message:error.validationMessage}
            }

            setState(copyObject)
           
            if(errorStore.length > 0){
                  //   dispatch(UiActions.setAlert({model:true,message:errorStore[0]}));
                  return;
            }

            const data = { "email":state.email.value, "name":state.name.value, "mobile":state.mobile.value, "message":state.message.value}
            dispatch(UiActions.setContactUsRequest({request:true}))
            dispatch(sendContactUsRequest({data:data,token:token}))

      }

      const onfocusClearErrorHandler = ()=>{
            setState(prevState=>{
                  return {
                        ...prevState,
                        email:{...prevState.email,error:false,message:""},
                        name:{...prevState.name,error:false,message:""},
                        mobile:{...prevState.mobile,error:false,message:""},
                        message:{...prevState.message,error:false,message:""},
                  }
            })
          
      }

      return (
            <form>
                  {/* <!-- Contact Us --> */}
                  <div className="row row__title border-top">
                        <div className="col-md-12">
                              <div className="heading-wrapper">
                                    <h3 className="heading fw-bold mb-0">
                                          Contact Us
                                    </h3>
                              </div>
                        </div>
                  </div>
                  {/* <!-- /.row --> */}

                  <div className="row">
                        <div className="col__left col-md-6 order-md-2">
                              <div className="form-group-wrapper d-flex flex-column">
                                    <div className="form-group">
                                          <label htmlFor="accountSettingsInputFullName" className="form-label fw-normal">Full Name</label>
                                          <input type="text" className="form-control form-control-xl-lg" id="accountSettingsInputFullName" value={state.name.value} readOnly disabled/>
                                    </div>
                                    {/* <!-- /.form-group --> */}

                                    <div className="form-group">
                                          <label htmlFor="accountSettingsInputEmail" className="form-label fw-normal">Email</label>
                                          <input type="email" className="form-control form-control-xl-lg" id="accountSettingsInputEmail" value={state.email.value} readOnly disabled/>
                                    </div>
                                    {/* <!-- /.form-group --> */}

                                    <div className="form-group">
                                          <label htmlFor="accountSettingsInputMobileNumber" className="form-label fw-normal">Mobile Number</label>
                                          <input type="text" className="form-control form-control-xl-lg" id="accountSettingsInputMobileNumber" name="mobile" value={state.mobile.value} disabled/>
                                          {state.mobile.error &&
                                          <label htmlFor="accountSettingsInputMessage" className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px","marginTop":"15px"}}>
                                                {state.mobile.message}
                                          </label>}
                                    </div>
                                   
                                    {/* <!-- /.form-group --> */}

                                    {/* <!-- NOTE: this button is visible only for screen size 768px and greater --> */}
                                    <div className="form-group form-group__buttons mt-lg-auto d-none d-md-block">
                                          <button type="submit" className="btn btn-xl-lg btn-primary  fw-bold" disabled={contactUsRequest === true ? true :false} onClick={submitContactFormHandler}>Submit</button>
                                    </div>
                                    {/* <!-- /.form-group --> */}

                              </div>
                              {/* <!-- /.form-group-wrapper --> */}
                        </div>

                        <div className="col__right col-md-6 order-md-1">
                              <div className="form-group form-group__textarea">
                                    {/* <label htmlFor="accountSettingsInputMessage" className="form-label fw-normal">
                                          Describe how can we help
                                    </label> */}
                                    <textarea className="form-control form-control-xl-lg" id="accountSettingsInputMessage" name="message" onChange={changeHandler} value={state.message.value} disabled={contactUsRequest === true ? true :false} onFocus={onfocusClearErrorHandler} placeholder="Tell us how we can help"></textarea>
                                    {state.message.error &&
                                    <label htmlFor="accountSettingsInputMessage" className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px","marginTop":"15px"}}>
                                          {state.message.message}
                                    </label>}
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                  </div>
                  {/* <!-- /.row --> */}

                  {/* <!-- NOTE: this button is visible only for screen size less than 768px --> */}
                  <div className="row d-md-none">
                        <div className="col-md-12">
                              <div className="form-group form-group__buttons mt-md-auto">
                                    <button type="submit" className="btn btn-xl-lg btn-primary fw-bold" disabled={contactUsRequest === true ? true :false} onClick={submitContactFormHandler}>
                                          Submit
                                    </button>
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                  </div>
                  {/* <!-- /.row --> */}
            </form>
                                                           
      )
}

export default ContactUs;
