import React,{ useState, useEffect} from "react";

import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import stockImage09 from "./../assests/images/stock-image-09.jpg";
import stockImage09a2x from "./../assests/images/stock-image-09@2x.jpg";
// import stockImage09a2x a2xfrom "./../assests/images/stock-image-09@2x.jpg 2x";

import TomSelect from "tom-select";

import { checkValidityJoin } from "../Shared/UtilityJoin";
import { submitJoinDataRequest } from "../Store/Auth/AuthActions";
import { UiActions } from "../Store/Ui/Ui";

const Join = () => {

      const [state,setState]= useState({

            fname:{value:"",error:false,errorMessage:"Please enter a valid first name"},
            lname:{value:"",error:false,errorMessage:"Please enter a valid last name"},
            password:{value:"",error:false,errorMessage:"Please enter a valid password, a minimum of 6 characters"},
            nhsTrust:{value:"",error:false,errorMessage:"Please select NHS trust you work for."},
            role:{value:"",error:false,errorMessage:"Please enter your role"},
            city:{value:"",error:false,errorMessage:"Please select your nearest city"},
            nhsEmail:{value:"",error:false,errorMessage:"Please enter a valid NHS email address"},
            nhsEmailConfirm:{value:"",error:false,errorMessage:"That’s not a match. Please check the email addresses entered."},
            mobile:{value:"",error:false,errorMessage:"Please enter a valid UK mobile number"},
            checkReceiveAlerts:{value:false,error:false,errorMessage:"Please confirm that you provide your consent to receive a show alerts from Tickets for Medics"},
            checkAgreeTOS:{value:false,error:false,errorMessage:"Please confirm that you agree to the Terms and Conditions of membership"},

      })
      const data = useLocation();
      const joinRequest = useSelector(state => state.ui.joinRequest)
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const { param }   = useParams();

      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});

            if(data.state){

                  setState(prevState=>{
                        return {
                              ...prevState,
                              fname:{...prevState.fname,value:data.state.fname},
                              lname:{...prevState.lname,value:data.state.lname},
                              password:{...prevState.password,value:data.state.password},
                              nhsEmail:{...prevState.email,value:data.state.nhsEmail},
                              role:{...prevState.role,value:data.state.role},
                              city:{...prevState.city,value:data.state.city},
                              mobile:{...prevState.mobile,value:data.state.mobile},
                        }
                  })
            }
            localStorage.setItem('nhs_ragistration_type',param);

      },[])

      useEffect(()=>{

            let selectizeCity = new TomSelect('#joinInputNearestCity',{
                  plugins: ['remove_button'],
                  create: false,
                  maxOptions:150,
                  sortField: {
                        field: "text",
                        direction: "asc"
                  },
                  items:[data.state && data.state.city],
                  openOnFocus:false,
                  onType:(str)=>{
                        if(str.length > 1){
                             document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.display = "block"
                        }
                        else{
                              document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.display = "none"
                        }
                  },	
                  render:{
                        no_results:function(data,escape){
                              document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.border = "0px"
                        }
                  }	
            });
            
            let selectize = new TomSelect('#joinInputNHSTrust',{
                  plugins: ['remove_button'],
                  create: false,
                  maxOptions:360,
                  sortField: {
                        field: "text",
                        direction: "asc"
                  },
                  items:[],
                  openOnFocus:false,
                  onType:(str)=>{
                        if(str.length > 1){
                             document.querySelector("#joinInputNHSTrust + .ts-wrapper .ts-dropdown").style.display = "block"
                        }
                        else{
                              document.querySelector("#joinInputNHSTrust + .ts-wrapper .ts-dropdown").style.display = "none"
                        }
                  },	
                  render:{
                        no_results:function(data,escape){
                              document.querySelector("#joinInputNHSTrust + .ts-wrapper .ts-dropdown").style.border = "0px"
                        }
                  }
            });

            return ()=>{
                  selectize.destroy();
                  selectizeCity.destroy();
            }
            
      },[])
          
      const changeHandler = (event)=>{

           
            const name = event.target.name;
            let value = event.target.value;
            let radioButton = false;

            if(name === "fname" || name === "lname"){
                  value = capitalizeFirstLetter(value);
            }

            if(name === "checkReceiveAlerts" || name==="checkAgreeTOS"){
                  value = event.target.checked;
                  radioButton=true;
            }

            if(name === "mobile"){
                  value = event.target.value.replace(/\D/g, '');
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })

            if(radioButton){
                  onfocusHandler();
            }
      }

      function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        fname:{...prevState.fname,error:false},
                        lname:{...prevState.lname,error:false},
                        password:{...prevState.password,error:false},
                        nhsTrust:{...prevState.nhsTrust,error:false},
                        role:{...prevState.role,error:false},
                        city:{...prevState.city,error:false},
                        nhsEmail:{...prevState.nhsEmail,error:false},
                        nhsEmailConfirm:{...prevState.nhsEmailConfirm,error:false},
                        mobile:{...prevState.mobile,error:false},
                        checkAgreeTOS:{...prevState.checkAgreeTOS,error:false},
                        checkReceiveAlerts:{...prevState.checkReceiveAlerts,error:false},
                  }
            })
      }

      const checkForNhsEmailHandler =()=>{
            const value = state.nhsEmail.value;
            const errorCheck = checkValidityJoin(value,"email");

            if(errorCheck.isValid){
                  if((value.indexOf("@nhs.net") === -1) && (value.indexOf("nhs.uk") === -1) && (value.indexOf("nhs.u") === -1) && (value.indexOf("@nhs.n") === -1) && (value.indexOf("nhs.scot") === -1)){
                        const data = {
                              fname:state.fname.value,
                              lname:state.lname.value,
                              password:state.password.value,
                              mobile:state.mobile.value,
                              email:value,
                              role:state.role.value,
                              city:state.city.value
            
                        }
                        joinAltHandler(data);
                        return;
                  }
            }
      }

      const joinAltHandler = (data)=>{
            // event.preventDefault();
            navigate(
                  '/join-alt',
                  {
                    state: data
                  }
            )
                
            // navigate("/join-alt");
      }
      
      const submitJoinDataHandler = (event)=>{

            event.preventDefault();

            const errorStore = [];
            const copy = {

            }
            
            for(let key in state)
            {
                  const errorCheck = checkValidityJoin(state[key].value,key,state.nhsEmail.value);
                              
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(errorStore.length > 0)
            {
                  copy["errorExists"] = true;
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
            }
            else
            {

                  const ragistration_special = localStorage.getItem('nhs_ragistration_type');
                  const ragistration_type = (ragistration_special !== "undefined" && ragistration_special) ? ragistration_special  : "";
                  const data = {
                        fname:state.fname.value,
                        lname:state.lname.value,
                        password:state.password.value,
                        organization:state.nhsTrust.value,
                        role:state.role.value,
                        city:state.city.value,
                        nhs_email:state.nhsEmail.value,
                        nhs_email_confirm:state.nhsEmailConfirm.value,
                        mobile_number:state.mobile.value,
                        agree_tos:state.checkAgreeTOS.value,
                        receive_alerts:state.checkReceiveAlerts.value,
                        is_aj:localStorage.getItem('nhs_ajtix') || false,
                        ragistration_type  : ragistration_type
                        
                  }

                  dispatch(UiActions.setJoinRequest({request:true}));
                  dispatch(submitJoinDataRequest({data:data,navigate:navigate}));
            }
                
      }

      return (
            <main>
                  <section className="section__join">

                        {/* <!--
                                    NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                              --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-6 col__left order-md-2">
                                          <div className="content content__form">
                                                <div className="content__body">
                                                      <h1 className="fw-bold">Join for free!</h1>
                                                      <form>
                                                            <div className="row">
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputFirstName" className="form-label fw-normal">First Name</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputFirstName" name="fname" value={state.fname.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.fname.error && "visible"}}>{state.fname.errorMessage}</div>
                                                                             
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputLastName" className="form-label fw-normal">Last Name</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputLastName" name="lname" value={state.lname.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.lname.error && "visible"}}>{state.lname.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputPassword" className="form-label fw-normal">Password</label>
                                                                              <input type="password" className="form-control form-control-xl-lg" id="joinInputPassword" name="password" value={state.password.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false} autoComplete="new-password"/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.password.error && "visible"}}>{state.password.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group" onFocus={onfocusHandler}>
                                                                              <label htmlFor="joinInputNHSTrust" className="form-label fw-normal">NHS Trust</label>
                                                                              <select className="form-control tomselect tomselect-xl-lg" id="joinInputNHSTrust" aria-label="NHS Trust" autoComplete="off"  name="nhsTrust" value={state.nhsTrust.value}   onChange={changeHandler}  disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :null} placeholder="Enter an NHS Trust" >
                                                                                    {/* <option value="0" hidden>Select NHS Trust</option> */}
                                                                                    <option value="1">NHS Blood and Transplant</option>
                                                                                    <option value="2">NHS Midlands and Lancashire Commissioning Support Unit</option>
                                                                                    <option value="3">NHS Professionals</option>
                                                                                    <option value="4">2gether NHS Foundation Trust</option>
                                                                                    <option value="5">Aintree University Hospital NHS Foundation Trust</option>
                                                                                    <option value="6">Airedale NHS Foundation Trust</option>
                                                                                    <option value="7">Alder Hey Children&#39;s NHS Foundation Trust</option>
                                                                                    <option value="8">Aneurin Bevan University Health Board</option>
                                                                                    <option value="9">Ashford and St Peter&#39;s Hospitals NHS Foundation Trust</option>
                                                                                    <option value="10">Avon and Wiltshire Mental Health Partnership NHS Trust</option>
                                                                                    <option value="11">Barking, Havering and Redbridge University Hospitals NHS Trust</option>
                                                                                    <option value="12">Barnet, Enfield and Haringey Mental Health NHS Trust</option>
                                                                                    <option value="13">Barnsley Hospital NHS Foundation Trust</option>
                                                                                    <option value="14">Barts Health NHS Trust</option>
                                                                                    <option value="15">Bedfordshire Hospitals NHS Foundation Trust</option>
                                                                                    <option value="16">Berkshire Healthcare NHS Foundation Trust</option>
                                                                                    <option value="17">Betsi Cadwaladr University Health Board</option>
                                                                                    <option value="18">Birmingham Community Healthcare NHS Foundation Trust</option>
                                                                                    <option value="19">Birmingham Women&#39;s and Children&#39;s NHS Foundation Trust</option>
                                                                                    <option value="20">Birmingham and Solihull Mental Health NHS Foundation Trust</option>
                                                                                    <option value="21">Black Country Healthcare NHS Foundation Trust</option>
                                                                                    <option value="22">Blackpool Teaching Hospitals NHS Foundation Trust</option>
                                                                                    <option value="23">Bolton NHS Foundation Trust</option>
                                                                                    <option value="24">Bradford District Care NHS Foundation Trust</option>
                                                                                    <option value="25">Bradford Teaching Hospitals NHS Foundation Trust</option>
                                                                                    <option value="26">Bridgewater Community Healthcare NHS Foundation Trust</option>
                                                                                    <option value="27">Buckinghamshire Healthcare NHS Trust</option>
                                                                                    <option value="28">Calderdale and Huddersfield NHS Foundation Trust</option>
                                                                                    <option value="29">Cambridge University Hospitals NHS Foundation Trust</option>
                                                                                    <option value="30">Cambridgeshire Community Services NHS Trust</option>
                                                                                    <option value="31">Cambridgeshire and Peterborough NHS Foundation Trust</option>
                                                                                    <option value="32">Camden and Islington NHS Foundation Trust</option>
                                                                                    <option value="33">Cardiff and Vale University Health Board</option>
                                                                                    <option value="34">Central London Community Healthcare NHS Trust</option>
                                                                                    <option value="35">Central and North West London NHS Foundation Trust</option>
                                                                                    <option value="36">Chelsea and Westminster Hospital NHS Foundation Trust</option>
                                                                                    <option value="37">Cheshire and Wirral Partnership NHS Foundation Trust</option>
                                                                                    <option value="38">Chesterfield Royal Hospital NHS Foundation Trust</option>
                                                                                    <option value="39">Cornwall Partnership NHS Foundation Trust</option>
                                                                                    <option value="40">Countess of Chester Hospital NHS Foundation Trust</option>
                                                                                    <option value="41">County Durham and Darlington NHS Foundation Trust</option>
                                                                                    <option value="42">Coventry and Warwickshire Partnership NHS Trust</option>
                                                                                    <option value="43">Croydon Health Services NHS Trust</option>
                                                                                    <option value="44">Cumbria Partnership NHS Foundation Trust</option>
                                                                                    <option value="45">Cwm Taf Morgannwg University Health Board</option>
                                                                                    <option value="46">Dartford and Gravesham NHS Trust</option>
                                                                                    <option value="47">Derbyshire Community Health Services NHS Foundation Trust</option>
                                                                                    <option value="48">Derbyshire Healthcare NHS Foundation Trust</option>
                                                                                    <option value="49">Devon Partnership NHS Trust</option>
                                                                                    <option value="50">Digital Health and Care Wales</option>
                                                                                    <option value="51">Doncaster and Bassetlaw Teaching Hospitals NHS Foundation Trust</option>
                                                                                    <option value="52">Dorset County Hospital NHS Foundation Trust</option>
                                                                                    <option value="53">Dorset Healthcare University NHS Foundation Trust</option>
                                                                                    <option value="54">Dudley Integrated Health and Care NHS Trust</option>
                                                                                    <option value="55">East Cheshire NHS Trust</option>
                                                                                    <option value="56">East Kent Hospitals University NHS Foundation Trust</option>
                                                                                    <option value="57">East Lancashire Hospitals NHS Trust</option>
                                                                                    <option value="58">East London NHS Foundation Trust</option>
                                                                                    <option value="59">East Midlands Ambulance Service NHS Trust</option>
                                                                                    <option value="60">East Suffolk and North Essex NHS Foundation Trust</option>
                                                                                    <option value="61">East Sussex Healthcare NHS Trust</option>
                                                                                    <option value="62">East and North Hertfordshire NHS Trust</option>
                                                                                    <option value="63">East of England Ambulance Service NHS Trust</option>
                                                                                    <option value="64">East of England Ambulance Service NHS Trust</option>
                                                                                    <option value="65">Epsom and St Helier University Hospitals NHS Trust</option>
                                                                                    <option value="66">Essex Partnership University NHS Foundation Trust</option>
                                                                                    <option value="67">Frimley Health NHS Foundation Trust</option>
                                                                                    <option value="68">Gateshead Health NHS Foundation Trust</option>
                                                                                    <option value="69">George Eliot Hospital NHS Trust</option>
                                                                                    <option value="70">Gloucestershire Health and Care NHS Foundation Trust</option>
                                                                                    <option value="71">Gloucestershire Hospitals NHS Foundation Trust</option>
                                                                                    <option value="72">Great Ormond Street Hospital for Children NHS Foundation Trust</option>
                                                                                    <option value="73">Great Western Hospitals NHS Foundation Trust</option>
                                                                                    <option value="74">Greater Manchester Mental Health NHS Foundation Trust</option>
                                                                                    <option value="75">Guy&#39;s and St Thomas&#39; NHS Foundation Trust</option>
                                                                                    <option value="76">Hampshire Hospitals NHS Foundation Trust</option>
                                                                                    <option value="77">Harrogate and District NHS Foundation Trust</option>
                                                                                    <option value="78">Health Education England</option>
                                                                                    <option value="79">Health Education and Improvement Wales (HEIW)</option>
                                                                                    <option value="80">Herefordshire and Worcestershire Health and Care NHS Trust</option>
                                                                                    <option value="81">Hertfordshire Community NHS Trust</option>
                                                                                    <option value="82">Hertfordshire Partnership University NHS Foundation Trust</option>
                                                                                    <option value="83">Homerton University Hospital NHS Foundation Trust</option>
                                                                                    <option value="84">Hounslow and Richmond Community Healthcare NHS Trust</option>
                                                                                    <option value="85">Hull University Teaching Hospitals NHS Trust</option>
                                                                                    <option value="86">Humber Teaching NHS Foundation Trust</option>
                                                                                    <option value="87">Hywel Dda University Health Board</option>
                                                                                    <option value="88">Imperial College Healthcare NHS Trust</option>
                                                                                    <option value="89">Isle of Wight NHS Trust</option>
                                                                                    <option value="90">James Paget University Hospitals NHS Foundation Trust</option>
                                                                                    <option value="91">Kent Community Health NHS Foundation Trust</option>
                                                                                    <option value="92">Kent and Medway NHS and Social Care Partnership Trust</option>
                                                                                    <option value="93">Kettering General Hospital NHS Foundation Trust</option>
                                                                                    <option value="94">King&#39;s College Hospital NHS Foundation Trust</option>
                                                                                    <option value="95">Kingston Hospital NHS Foundation Trust</option>
                                                                                    <option value="96">Lancashire Care NHS Foundation Trust</option>
                                                                                    <option value="97">Lancashire Teaching Hospitals NHS Foundation Trust</option>
                                                                                    <option value="98">Leeds Community Healthcare NHS Trust</option>
                                                                                    <option value="99">Leeds Teaching Hospitals NHS Trust</option>
                                                                                    <option value="100">Leeds and York Partnership NHS Foundation Trust</option>
                                                                                    <option value="101">Leicestershire Partnership NHS Trust</option>
                                                                                    <option value="102">Lewisham and Greenwich NHS Trust</option>
                                                                                    <option value="103">Lincolnshire Community Health Services NHS Trust</option>
                                                                                    <option value="104">Lincolnshire Partnership NHS Foundation Trust</option>
                                                                                    <option value="105">Liverpool Heart and Chest Hospital NHS Foundation Trust</option>
                                                                                    <option value="106">Liverpool Women&#39;s NHS Foundation Trust</option>
                                                                                    <option value="107">London Ambulance Service NHS Trust</option>
                                                                                    <option value="108">London North West University Healthcare NHS Trust</option>
                                                                                    <option value="109">Maidstone and Tunbridge Wells NHS Trust</option>
                                                                                    <option value="110">Manchester University NHS Foundation Trust</option>
                                                                                    <option value="111">Medway NHS Foundation Trust</option>
                                                                                    <option value="112">Mersey Care NHS Foundation Trust</option>
                                                                                    <option value="113">Mid Cheshire Hospitals NHS Foundation Trust</option>
                                                                                    <option value="114">Mid Yorkshire Hospitals NHS Trust</option>
                                                                                    <option value="115">Mid and South Essex NHS Foundation Trust</option>
                                                                                    <option value="116">Midlands Partnership NHS Foundation Trust</option>
                                                                                    <option value="117">Milton Keynes University Hospital NHS Foundation Trust</option>
                                                                                    <option value="117">Moorfields Eye Hospital NHS Foundation Trust</option>
                                                                                    <option value="119">NHS Wales Shared Services Partnership</option>
                                                                                    <option value="120">Norfolk Community Health and Care NHS Trust</option>
                                                                                    <option value="121">Norfolk and Norwich University Hospitals NHS Foundation Trust</option>
                                                                                    <option value="122">Norfolk and Suffolk NHS Foundation Trust</option>
                                                                                    <option value="123">North Bristol NHS Trust</option>
                                                                                    <option value="124">North East Ambulance Service NHS Foundation Trust</option>
                                                                                    <option value="125">North East London NHS Foundation Trust</option>
                                                                                    <option value="126">North Middlesex University Hospital NHS Trust</option>
                                                                                    <option value="127">North Staffordshire Combined Healthcare NHS Trust</option>
                                                                                    <option value="128">North Tees and Hartlepool NHS Foundation Trust</option>
                                                                                    <option value="129">North West Ambulance Service NHS Trust</option>
                                                                                    <option value="130">North West Anglia NHS Foundation Trust</option>
                                                                                    <option value="131">North West Boroughs Healthcare NHS Foundation Trust</option>
                                                                                    <option value="132">Northampton General Hospital NHS Trust</option>
                                                                                    <option value="133">Northamptonshire Healthcare NHS Foundation Trust</option>
                                                                                    <option value="134">Northern Devon Healthcare NHS Trust</option>
                                                                                    <option value="135">Northern Lincolnshire and Goole NHS Foundation Trust</option>
                                                                                    <option value="136">Northumberland, Tyne and Wear NHS Foundation Trust</option>
                                                                                    <option value="137">Northumbria Healthcare NHS Foundation Trust</option>
                                                                                    <option value="138">Nottingham University Hospitals NHS Trust</option>
                                                                                    <option value="139">Nottinghamshire Healthcare NHS Foundation Trust</option>
                                                                                    <option value="140">Oxford Health NHS Foundation Trust</option>
                                                                                    <option value="141">Oxford University Hospitals NHS Foundation Trust</option>
                                                                                    <option value="142">Oxleas NHS Foundation Trust</option>
                                                                                    <option value="143">Pennine Acute Hospitals NHS Trust</option>
                                                                                    <option value="144">Pennine Care NHS Foundation Trust</option>
                                                                                    <option value="145">Portsmouth Hospitals NHS Trust</option>
                                                                                    <option value="146">Powys Teaching Health Board</option>
                                                                                    <option value="147">Public Health Wales</option>
                                                                                    <option value="148">Queen Victoria Hospital NHS Foundation Trust</option>
                                                                                    <option value="149">Rotherham Doncaster and South Humber NHS Foundation Trust</option>
                                                                                    <option value="150">Royal Berkshire NHS Foundation Trust</option>
                                                                                    <option value="151">Royal Cornwall Hospitals NHS Trust</option>
                                                                                    <option value="152">Royal Devon and Exeter NHS Foundation Trust</option>
                                                                                    <option value="153">Royal Free London NHS Foundation Trust</option>
                                                                                    <option value="154">Royal National Orthopaedic Hospital NHS Trust</option>
                                                                                    <option value="155">Royal Papworth Hospital NHS Foundation Trust</option>
                                                                                    <option value="156">Royal Surrey County Hospital NHS Foundation Trust</option>
                                                                                    <option value="157">Royal United Hospitals Bath NHS Foundation Trust</option>
                                                                                    <option value="158">Salford Royal NHS Foundation Trust</option>
                                                                                    <option value="159">Salisbury NHS Foundation Trust</option>
                                                                                    <option value="160">Sandwell and West Birmingham Hospitals NHS Trust</option>
                                                                                    <option value="161">Sheffield Children&#39;s NHS Foundation Trust</option>
                                                                                    <option value="162">Sheffield Health and Social Care NHS Foundation Trust</option>
                                                                                    <option value="163">Sheffield Teaching Hospitals NHS Foundation Trust</option>
                                                                                    <option value="164">Sherwood Forest Hospitals NHS Foundation Trust</option>
                                                                                    <option value="165">Shrewsbury and Telford Hospital NHS Trust</option>
                                                                                    <option value="166">Shropshire Community Health NHS Trust</option>
                                                                                    <option value="167">Solent NHS Trust</option>
                                                                                    <option value="168">Somerset NHS Foundation Trust</option>
                                                                                    <option value="169">South Central Ambulance Service NHS Foundation Trust</option>
                                                                                    <option value="170">South East Coast Ambulance Service NHS Foundation Trust</option>
                                                                                    <option value="171">South London and Maudsley NHS Foundation Trust</option>
                                                                                    <option value="172">South Tees Hospitals NHS Foundation Trust</option>
                                                                                    <option value="173">South Tyneside and Sunderland NHS Foundation Trust</option>
                                                                                    <option value="174">South Warwickshire NHS Foundation Trust</option>
                                                                                    <option value="175">South West London and St George&#39;s Mental Health NHS Trust</option>
                                                                                    <option value="176">South West Yorkshire Partnership NHS Foundation Trust</option>
                                                                                    <option value="177">South Western Ambulance Service NHS Foundation Trust</option>
                                                                                    <option value="178">Southern Health NHS Foundation Trust</option>
                                                                                    <option value="179">Southport and Ormskirk Hospital NHS Trust</option>
                                                                                    <option value="180">St George&#39;s University Hospitals NHS Foundation Trust</option>
                                                                                    <option value="181">St Helens and Knowsley Teaching Hospitals NHS Trust</option>
                                                                                    <option value="182">Stockport NHS Foundation Trust</option>
                                                                                    <option value="183">Surrey and Borders Partnership NHS Foundation Trust</option>
                                                                                    <option value="184">Surrey and Sussex Healthcare NHS Trust</option>
                                                                                    <option value="185">Sussex Community NHS Foundation Trust</option>
                                                                                    <option value="186">Sussex Partnership NHS Foundation Trust</option>
                                                                                    <option value="187">Swansea Bay University Health Board</option>
                                                                                    <option value="188">Tameside and Glossop Integrated Care NHS Foundation Trust</option>
                                                                                    <option value="189">Tavistock and Portman NHS Foundation Trust</option>
                                                                                    <option value="190">Tees, Esk and Wear Valleys NHS Foundation Trust</option>
                                                                                    <option value="191">The Christie NHS Foundation Trust</option>
                                                                                    <option value="192">The Clatterbridge Cancer Centre NHS Foundation Trust</option>
                                                                                    <option value="193">The Dudley Group NHS Foundation Trust</option>
                                                                                    <option value="194">The Hillingdon Hospitals NHS Foundation Trust</option>
                                                                                    <option value="195">The Newcastle Upon Tyne Hospitals NHS Foundation Trust</option>
                                                                                    <option value="196">The Princess Alexandra Hospital NHS Trust</option>
                                                                                    <option value="197">The Queen Elizabeth Hospital, King&#39;s Lynn, NHS Foundation Trust</option>
                                                                                    <option value="198">The Robert Jones and Agnes Hunt Orthopaedic Hospital NHS Foundation Trust</option>
                                                                                    <option value="199">The Rotherham NHS Foundation Trust</option>
                                                                                    <option value="200">The Royal Marsden NHS Foundation Trust</option>
                                                                                    <option value="201">The Royal Orthopaedic Hospital NHS Foundation Trust</option>
                                                                                    <option value="202">The Royal Wolverhampton NHS Trust</option>
                                                                                    <option value="203">The Walton Centre NHS Foundation Trust</option>
                                                                                    <option value="204">Torbay and South Devon NHS Foundation Trust</option>
                                                                                    <option value="205">United Lincolnshire Hospitals NHS Trust</option>
                                                                                    <option value="206">University College London Hospitals NHS Foundation Trust</option>
                                                                                    <option value="207">University Hospital Southampton NHS Foundation Trust</option>
                                                                                    <option value="208">University Hospitals Birmingham NHS Foundation Trust</option>
                                                                                    <option value="209">University Hospitals Bristol and Weston NHS Foundation Trust</option>
                                                                                    <option value="210">University Hospitals Coventry and Warwickshire NHS Trust</option>
                                                                                    <option value="211">University Hospitals Dorset NHS Foundation Trust</option>
                                                                                    <option value="212">University Hospitals Plymouth NHS Trust</option>
                                                                                    <option value="213">University Hospitals Sussex NHS Foundation Trust</option>
                                                                                    <option value="214">University Hospitals of Derby and Burton NHS Foundation Trust</option>
                                                                                    <option value="215">University Hospitals of Leicester NHS Trust</option>
                                                                                    <option value="216">University Hospitals of Morecambe Bay NHS Foundation Trust</option>
                                                                                    <option value="217">University Hospitals of North Midlands NHS Trust</option>
                                                                                    <option value="218">Velindre University NHS Trust</option>
                                                                                    <option value="219">Walsall Healthcare NHS Trust</option>
                                                                                    <option value="220">Warrington and Halton Hospitals NHS Foundation Trust</option>
                                                                                    <option value="221">Welsh Ambulances Services NHS Trust</option>
                                                                                    <option value="222">West Hertfordshire Hospitals NHS Trust</option>
                                                                                    <option value="223">West London NHS Trust</option>
                                                                                    <option value="224">West Midlands Ambulance Service University NHS Foundation Trust</option>
                                                                                    <option value="225">West Suffolk NHS Foundation Trust</option>
                                                                                    <option value="226">Whittington Health NHS Trust</option>
                                                                                    <option value="227">Wirral Community NHS Foundation Trust</option>
                                                                                    <option value="228">Wirral University Teaching Hospital NHS Foundation Trust</option>
                                                                                    <option value="229">Worcestershire Acute Hospitals NHS Trust</option>
                                                                                    <option value="230">Wrightington, Wigan and Leigh NHS Foundation Trust</option>
                                                                                    <option value="231">Wye Valley NHS Trust</option>
                                                                                    <option value="232">Yeovil District Hospital NHS Foundation Trust</option>
                                                                                    <option value="233">York Teaching Hospital NHS Foundation Trust</option>
                                                                                    <option value="234">Yorkshire Ambulance Service NHS Trust</option>
                                                                                    <option value="235">NHS Barnsley CCG</option>
                                                                                    <option value="236">NHS Basildon and Brentwood CCG</option>
                                                                                    <option value="237">NHS Bassetlaw CCG</option>
                                                                                    <option value="238">NHS Bath and North East Somerset, Swindon and Wiltshire CCG</option>
                                                                                    <option value="239">NHS Bedfordshire, Luton and Milton Keynes CCG</option>
                                                                                    <option value="240">NHS Berkshire West CCG</option>
                                                                                    <option value="241">NHS Birmingham and Solihull CCG</option>
                                                                                    <option value="242">NHS Black Country and West Birmingham CCG</option>
                                                                                    <option value="243">NHS Blackburn with Darwen CCG</option>
                                                                                    <option value="244">NHS Blackpool CCG</option>
                                                                                    <option value="245">NHS Bolton CCG</option>
                                                                                    <option value="246">NHS Bradford District and Craven CCG</option>
                                                                                    <option value="247">NHS Brighton and Hove CCG</option>
                                                                                    <option value="248">NHS Bristol, North Somerset and South Gloucestershire CCG</option>
                                                                                    <option value="249">NHS Buckinghamshire CCG</option>
                                                                                    <option value="250">NHS Bury CCG</option>
                                                                                    <option value="251">NHS Calderdale CCG</option>
                                                                                    <option value="252">NHS Cambridgeshire and Peterborough CCG</option>
                                                                                    <option value="253">NHS Cannock Chase CCG</option>
                                                                                    <option value="254">NHS Castle Point and Rochford CCG</option>
                                                                                    <option value="255">NHS Cheshire CCG</option>
                                                                                    <option value="256">NHS Chorley and South Ribble CCG</option>
                                                                                    <option value="257">NHS County Durham CCG</option>
                                                                                    <option value="258">NHS Coventry and Warwickshire CCG</option>
                                                                                    <option value="259">NHS Derby and Derbyshire CCG</option>
                                                                                    <option value="260">NHS Devon CCG</option>
                                                                                    <option value="261">NHS Doncaster CCG</option>
                                                                                    <option value="262">NHS Dorset CCG</option>
                                                                                    <option value="263">NHS East Lancashire CCG</option>
                                                                                    <option value="264">NHS East Leicestershire and Rutland CCG</option>
                                                                                    <option value="265">NHS East Riding of Yorkshire CCG</option>
                                                                                    <option value="266">NHS East Staffordshire CCG</option>

                                                                                    <option value="267">NHS East Sussex CCG</option>
                                                                                    <option value="268">NHS East and North Hertfordshire CCG</option>
                                                                                    <option value="269">NHS England</option>
                                                                                    <option value="270">NHS Frimley CCG</option>
                                                                                    <option value="271">NHS Fylde and Wyre CCG</option>
                                                                                    <option value="272">NHS Gloucestershire CCG</option>
                                                                                    <option value="273">NHS Greater Preston CCG</option>
                                                                                    <option value="274">NHS Halton CCG</option>
                                                                                    <option value="275">NHS Hampshire, Southampton and Isle of Wight CCG</option>
                                                                                    <option value="276">NHS Herefordshire and Worcestershire CCG</option>
                                                                                    <option value="277">NHS Herts Valleys CCG</option>
                                                                                    <option value="278">NHS Heywood, Middleton and Rochdale CCG</option>
                                                                                    <option value="279">NHS Hull CCG</option>
                                                                                    <option value="280">NHS Ipswich and East Suffolk CCG</option>
                                                                                    <option value="281">NHS Kent and Medway CCG</option>
                                                                                    <option value="282">NHS Kernow CCG</option>
                                                                                    <option value="283">NHS Kirklees CCG</option>
                                                                                    <option value="284">NHS Knowsley CCG</option>
                                                                                    <option value="285">NHS Leeds CCG</option>
                                                                                    <option value="286">NHS Leicester City CCG</option>
                                                                                    <option value="287">NHS Lincolnshire CCG</option>
                                                                                    <option value="288">NHS Liverpool CCG</option>
                                                                                    <option value="289">NHS Manchester CCG</option>
                                                                                    <option value="290">NHS Mid Essex CCG</option>
                                                                                    <option value="291">NHS Morecambe Bay CCG</option>
                                                                                    <option value="292">NHS Newcastle Gateshead CCG</option>
                                                                                    <option value="293">NHS Norfolk and Waveney CCG</option>
                                                                                    <option value="294">NHS North Central London CCG</option>
                                                                                    <option value="295">NHS North Cumbria CCG</option>
                                                                                    <option value="296">NHS North East Essex CCG</option>
                                                                                    <option value="297">NHS North East Lincolnshire CCG</option>
                                                                                    <option value="298">NHS North East London CCG</option>
                                                                                    <option value="299">NHS North Lincolnshire CCG</option>
                                                                                    <option value="300">NHS North Staffordshire CCG</option>
                                                                                    <option value="301">NHS North Tyneside CCG</option>
                                                                                    <option value="302">NHS North West London CCG</option>
                                                                                    <option value="303">NHS North Yorkshire CCG</option>
                                                                                    <option value="304">NHS Northamptonshire CCG</option>
                                                                                    <option value="305">NHS Northumberland CCG</option>
                                                                                    <option value="306">NHS Nottingham and Nottinghamshire CCG</option>
                                                                                    <option value="307">NHS Oldham CCG</option>
                                                                                    <option value="308">NHS Oxfordshire CCG</option>
                                                                                    <option value="309">NHS Portsmouth CCG</option>
                                                                                    <option value="310">NHS Rotherham CCG</option>
                                                                                    <option value="311">NHS Salford CCG</option>
                                                                                    <option value="312">NHS Sheffield CCG</option>
                                                                                    <option value="313">NHS Shropshire, Telford and Wrekin CCG</option>
                                                                                    <option value="314">NHS Somerset CCG</option>
                                                                                    <option value="315">NHS South East London CCG</option>
                                                                                    <option value="316">NHS South East Staffordshire and Seisdon Peninsula CCG</option>
                                                                                    <option value="317">NHS South Sefton CCG</option>
                                                                                    <option value="318">NHS South Tyneside CCG</option>
                                                                                    <option value="319">NHS South West London CCG</option>
                                                                                    <option value="320">NHS Southend CCG</option>
                                                                                    <option value="321">NHS Southport and Formby CCG</option>
                                                                                    <option value="322">NHS St Helens CCG</option>
                                                                                    <option value="323">NHS Stafford and Surrounds CCG</option>
                                                                                    <option value="324">NHS Stockport CCG</option>
                                                                                    <option value="325">NHS Stoke on Trent CCG</option>
                                                                                    <option value="326">NHS Sunderland CCG</option>
                                                                                    <option value="327">NHS Surrey Heartlands CCG</option>
                                                                                    <option value="328">NHS Tameside and Glossop CCG</option>
                                                                                    <option value="329">NHS Tees Valley CCG</option>
                                                                                    <option value="330">NHS Thurrock CCG</option>
                                                                                    <option value="331">NHS Trafford CCG</option>
                                                                                    <option value="332">NHS Vale of York CCG</option>
                                                                                    <option value="333">NHS Wakefield CCG</option>
                                                                                    <option value="334">NHS Warrington CCG</option>
                                                                                    <option value="335">NHS West Essex CCG</option>
                                                                                    <option value="336">NHS West Lancashire CCG</option>
                                                                                    <option value="337">NHS West Leicestershire CCG</option>
                                                                                    <option value="338">NHS West Suffolk CCG</option>
                                                                                    <option value="339">NHS West Sussex CCG</option>
                                                                                    <option value="340">NHS Wigan Borough CCG</option>
                                                                                    <option value="341">NHS Wirral CCG</option>
                                                                                    <option value="342">NHS Ayrshire &amp; Arran</option>
                                                                                    <option value="343">NHS Borders</option>
                                                                                    <option value="344">NHS Dumfries &amp; Galloway</option>
                                                                                    <option value="345">NHS Eileanan Siar Western Isles</option>
                                                                                    <option value="346">NHS Fife</option>
                                                                                    <option value="347">NHS Forth Valley</option>
                                                                                    <option value="348">NHS Grampian</option>
                                                                                    <option value="349">NHS Greater Glasgow and Clyde</option>
                                                                                    <option value="350">NHS Highland</option>
                                                                                    <option value="351">NHS Lanarkshire</option>
                                                                                    <option value="352">NHS Lothian</option>
                                                                                    <option value="353">NHS Orkney</option>
                                                                                    <option value="354">NHS Shetland</option>
                                                                                    <option value="355">NHS Tayside</option>
                                                                                    <option value="356">NHS Central Surrey Health</option>
                                                                                    <option value="357">Moorfields Eye Hospital NHS Foundation Trust</option>
                                                                                    <option value="358">Liverpool University Hospitals NHS Foundation Trust</option>
                                                                                    
                                                                                    
                                                                              </select>
                                                                              <div className="invalid-feedback" style={{"visibility": state.nhsTrust.error && "visible"}}>{state.nhsTrust.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputRole" className="form-label fw-normal">Role</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputRole" name="role" value={state.role.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.role.error && "visible"}}>{state.role.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group" onFocus={onfocusHandler}>
                                                                              <label htmlFor="joinInputNearestCity" className="form-label fw-normal">Nearest City</label>
                                                                              <select className="form-control tomselect tomselect-xl-lg" id="joinInputNearestCity" aria-label="Nearest City" autoComplete="off"  name="city" value={state.city.value} onChange={changeHandler}  disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :null} placeholder="Enter your nearest City">
                                                                                    {/* <option value="0" hidden>Select City</option> */}
                                                                                    <option value="1">Aberdeen</option>
                                                                                    <option value="2">Armagh</option>
                                                                                    <option value="3">Bangor</option>
                                                                                    <option value="4">Barnsley</option>
                                                                                    <option value="5">Basildon</option>
                                                                                    <option value="6">Basingstoke</option>
                                                                                    <option value="7">Bath</option>
                                                                                    <option value="8">Bedford</option>
                                                                                    <option value="9">Belfast</option>
                                                                                    <option value="10">Birkenhead</option>
                                                                                    <option value="11">Birmingham</option>
                                                                                    <option value="12">Blackburn</option>
                                                                                    <option value="13">Blackpool</option>
                                                                                    <option value="14">Bolton</option>
                                                                                    <option value="15">Bournemouth</option>
                                                                                    <option value="16">Bradford</option>
                                                                                    <option value="17">Brighton</option>
                                                                                    <option value="18">Brighton and Hove</option>
                                                                                    <option value="19">Bristol</option>
                                                                                    <option value="20">Cambridge</option>
                                                                                    <option value="21">Canterbury</option>
                                                                                    <option value="22">Cardiff</option>
                                                                                    <option value="23">Carlisle</option>
                                                                                    <option value="24">Chelmsford</option>
                                                                                    <option value="25">Cheltenham</option>
                                                                                    <option value="26">Chester</option>
                                                                                    <option value="27">Chesterfield</option>
                                                                                    <option value="28">Chichester</option>
                                                                                    <option value="29">Colchester</option>
                                                                                    <option value="30">Coventry</option>
                                                                                    <option value="31">Crawley</option>
                                                                                    <option value="32">Darlington</option>
                                                                                    <option value="33">Derby</option>
                                                                                    <option value="34">Doncaster</option>
                                                                                    <option value="35">Dundee</option>
                                                                                    <option value="36">Durham</option>
                                                                                    <option value="37">Eastbourne</option>
                                                                                    <option value="38">Edinburgh</option>
                                                                                    <option value="39">Ely</option>
                                                                                    <option value="40">Exeter</option>
                                                                                    <option value="41">Gateshead</option>
                                                                                    <option value="42">Gillingham</option>
                                                                                    <option value="43">Glasgow</option>
                                                                                    <option value="44">Gloucester</option>
                                                                                    <option value="45">Grimsby</option>
                                                                                    <option value="46">Halifax</option>
                                                                                    <option value="47">Hartlepool</option>
                                                                                    <option value="48">Hastings</option>
                                                                                    <option value="49">Hemel Hempstead</option>
                                                                                    <option value="50">Hereford</option>
                                                                                    <option value="51">High Wycombe</option>
                                                                                    <option value="52">Huddersfield</option>
                                                                                    <option value="53">Inverness</option>
                                                                                    <option value="54">Ipswich</option>
                                                                                    <option value="55">Kingston upon Hull</option>
                                                                                    <option value="56">Lancaster</option>
                                                                                    <option value="57">Leeds</option>
                                                                                    <option value="58">Leicester</option>
                                                                                    <option value="59">Lichfield</option>
                                                                                    <option value="60">Lincoln</option>
                                                                                    <option value="61">Lisburn</option>
                                                                                    <option value="62">Liverpool</option>
                                                                                    <option value="63">London</option>
                                                                                    <option value="64">Londonderry</option>
                                                                                    <option value="65">Luton</option>
                                                                                    <option value="66">Maidstone</option>
                                                                                    <option value="67">Manchester</option>
                                                                                    <option value="68">Middlesbrough</option>
                                                                                    <option value="69">Milton Keynes</option>
                                                                                    <option value="70">Newcastle upon Tyne</option>
                                                                                    <option value="71">Newport</option>
                                                                                    <option value="72">Newry</option>
                                                                                    <option value="73">Northampton</option>
                                                                                    <option value="74">Norwich</option>
                                                                                    <option value="75">Nottingham</option>
                                                                                    <option value="76">Nuneaton</option>
                                                                                    <option value="77">Oldham</option>
                                                                                    <option value="78">Oxford</option>
                                                                                    <option value="79">Peterborough</option>
                                                                                    <option value="80">Plymouth</option>
                                                                                    <option value="81">Poole</option>
                                                                                    <option value="82">Portsmouth</option>
                                                                                    <option value="83">Preston</option>
                                                                                    <option value="84">Raleigh</option>
                                                                                    <option value="85">Reading</option>
                                                                                    <option value="86">Ripon</option>
                                                                                    <option value="87">Rochdale</option>
                                                                                    <option value="88">Rotherham</option>
                                                                                    <option value="89">Sale</option>
                                                                                    <option value="90">Salford</option>
                                                                                    <option value="91">Salisbury</option>
                                                                                    <option value="92">Sheffield</option>
                                                                                    <option value="93">Slough</option>
                                                                                    <option value="94">Solihull</option>
                                                                                    <option value="95">Southampton</option>
                                                                                    <option value="96">Southend-on-Sea</option>
                                                                                    <option value="97">Southport</option>
                                                                                    <option value="98">St Albans</option>
                                                                                    <option value="99">St Davids</option>
                                                                                    <option value="100">St Helens</option>
                                                                                    <option value="101">Stevenage</option>
                                                                                    <option value="102">Stirling</option>
                                                                                    <option value="103">Stockport</option>
                                                                                    <option value="104">Stoke-on-Trent</option>
                                                                                    <option value="105">Sunderland</option>
                                                                                    <option value="106">Sutton Coldfield</option>
                                                                                    <option value="107">Swansea</option>
                                                                                    <option value="108">Swindon</option>
                                                                                    <option value="109">Telford</option>
                                                                                    <option value="110">Truro</option>
                                                                                    <option value="111">Wakefield</option>
                                                                                    <option value="112">Warrington</option>
                                                                                    <option value="113">Watford</option>
                                                                                    <option value="114">Wells</option>
                                                                                    <option value="115">Westminster</option>
                                                                                    <option value="116">Weston-super-Mare</option>
                                                                                    <option value="117">Wigan</option>
                                                                                    <option value="118">Winchester</option>
                                                                                    <option value="119">Woking</option>
                                                                                    <option value="120">Wolverhampton</option>
                                                                                    <option value="121">Worcester</option>
                                                                                    <option value="122">Worthing</option>
                                                                                    <option value="123">York</option>
                                                                              </select>
                                                                              <div className="invalid-feedback" style={{"visibility": state.city.error && "visible"}}>{state.city.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputNHSEmail" className="form-label fw-normal">Key Worker Email</label>
                                                                              <input type="email" className="form-control form-control-xl-lg" id="joinInputNHSEmail" name="nhsEmail" value={state.nhsEmail.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false} autoComplete="new-password" onBlur={checkForNhsEmailHandler}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.nhsEmail.error ? "visible" : "hidden"}}>{state.nhsEmail.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputNHSEmailConfirmation" className="form-label fw-normal">Key Worker Email Confirmation</label>
                                                                              <input type="email" className="form-control form-control-xl-lg" id="joinInputNHSEmailConfirmation" name="nhsEmailConfirm" value={state.nhsEmailConfirm.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false} autoComplete="new-password"/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.nhsEmailConfirm.error && "visible"}}>{state.nhsEmailConfirm.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputMObile" className="form-label fw-normal">Mobile Number</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputMObile" name="mobile" value={state.mobile.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.mobile.error && "visible"}}>{state.mobile.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group form-group__custom-controls">
                                                                              <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                    <input type="checkbox" className="custom-control-input" id="checkReceiveAlerts" value={state.checkReceiveAlerts.value} onChange={changeHandler} checked={state.checkReceiveAlerts.value} name="checkReceiveAlerts"  disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false}/>
                                                                                    <label className="custom-control-label">
                                                                                          I provide my consent to receive show alerts from Tickets for Medics.
                                                                                    </label>
                                                                                    <div className="invalid-feedback" style={{"visibility": state.checkReceiveAlerts.error && "visible"}}>{state.checkReceiveAlerts.errorMessage}</div>
                                                                              </div>
                                                                              <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                    <input type="checkbox" className="custom-control-input" id="checkAgreeTOS" value={state.checkAgreeTOS.value} onChange={changeHandler} checked={state.checkAgreeTOS.value} name="checkAgreeTOS" disabled={joinRequest === true ? true :false} readOnly={joinRequest === true ? true :false}/>
                                                                                    <label className="custom-control-label">
                                                                                          I confirm that I agree to the <Link className="text-decoration-none" to="/terms" target="_blank">Terms & Conditions</Link> of membership and that I either work for the NHS, or for a company that
                                                                                          provides services to the
                                                                                          NHS.
                                                                                    </label>
                                                                                    <div className="invalid-feedback" style={{"visibility": state.checkAgreeTOS.error && "visible"}}>{state.checkAgreeTOS.errorMessage}</div>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group form-group__buttons d-flex flex-column flex-xl-row">
                                                                              <button type="submit" className="btn btn-primary fw-bold w-xl-50" onClick={submitJoinDataHandler} disabled={joinRequest === true ? true :false}>Create Account</button>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}
                                                      </form>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-6 --> */}
                                    <div className="col-md-6 col__right order-md-1 border-right-sm border-top border-top-md-0">
                                          <div className="content h-100">
                                                <div className="content__body content__body-image h-100">
                                                      <img className="img-hero img-fluid" srcSet={stockImage09a2x} src={stockImage09} alt=""/>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-sm-6 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
        )
}

export default Join;
