import  { configureStore } from "@reduxjs/toolkit"
import AllEventsSlice from "./Allevents/AllEvents";
import AuthSlice, { AuthActions } from "./Auth/Auth";
import UiSlice from "./Ui/Ui"
import EventDetailSlice from "./EventDetail/EventDetail";
import BookingModuleSlice from "./BookingModule/BookingModule";
import BookingSlice from "./Bookings/Bookings";

const Store = configureStore({
      reducer: {
            allevents:AllEventsSlice,
            auth:AuthSlice,
            ui:UiSlice,
            eventdetail:EventDetailSlice,
            bookingmodule:BookingModuleSlice,
            bookings:BookingSlice
      },
      devTools: false,
})

window.addEventListener('storage', e => {
  
      // const verified = localStorage.getItem('nhs_is_verified')
      // if(e.key === 'nhs_access_token' && !e.oldValue && e.newValue && verified === "true"){
      //       window.location = "/currentListing"
      // }

      if(e.key === 'nhs_access_token' && e.oldValue && !e.newValue) {
            
            Store.dispatch(AuthActions.logout());
      }

      if(e.key === 'nhs_access_token' && !e.oldValue && e.newValue){
            window.location = "/currentListing"
      }

      if(e.key === 'nhs_is_verified' && e.oldValue === 'false' && e.newValue === 'true'){
            window.location = "/currentListing"
      }
});

export default Store;

