import React from "react";
import classes from "./Backdrop.module.css";

const backdrop = (props)=> {
        
      let backdropClass = [classes.Backdrop];
      if(props.spinner == "expired")
      {
            backdropClass.push(classes.ExpiredBackdrop);
      }
      return (
            <div className={backdropClass.join(" ")}></div>
      )
}

export default backdrop;