import React,{ useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import iconMinusBlack from "./../../../../assests/images/icon-minus-black.svg";
import iconPlusBlack from "./../../../../assests/images/icon-plus-black.svg";

import { BookingActions } from "../../../../Store/Bookings/Bookings";
import { cancleBookingRequest } from "./../../../../Store/Bookings/BookingActions"

const CancleBooking = (props) => {

      const dispatch =  useDispatch();
      const bookings = useSelector(state => state.bookings.cancleBookings)
      useEffect(()=>{
            document.body.style.overflow  = "hidden";
            document.body.style.paddingRight = "17px";

            return ()=>{
                  document.body.style.overflow = "";
                  document.body.style.paddingRight = "";
            }

      },[])

      const increseTicketCountHandler = ()=>{
            const updateTickets =  bookings.numberOfTickets +1;
            if(bookings.totalTickets >=  updateTickets)
            {
                  dispatch(BookingActions.setTicketsHandler({tickets:updateTickets}))
            }
      }

      const decreseTicketCountHandler = ()=>{
            const updateTickets =  bookings.numberOfTickets - 1;
            if(updateTickets > 0)
            {
                  dispatch(BookingActions.setTicketsHandler({tickets:updateTickets}))
            }
      }

      const cancleChangesHandler = ()=>{

            if(!bookings.isRequesting) {

                  dispatch(BookingActions.closeCancleBookings());
            }

      }

      const handleConfirmChangesHandler = ()=>{
            
            if(bookings.step === "initial") {
                  dispatch(BookingActions.setStepHandler({step:'alert'}));
            } 
            else if(bookings.step === "alert")  
            {
                  const token= localStorage.getItem('nhs_access_token');
                  const data = {
                        booking_id:bookings.booking_id,
                        tickets:bookings.numberOfTickets
                  }

                  dispatch(BookingActions.setRequestingHandler({isRequesting:bookings.booking_id}))
                  dispatch(cancleBookingRequest({token:token,data:data}))
            }   
      }

      return (
            <React.Fragment>
            {bookings.step === "initial" &&
                  <React.Fragment>
                        <div className="faux-table d-flex flex-column flex-md-row justify-content-md-between">
                              <div className="item item__cancel-booking">
                                   
                                    <h3 className="heading fw-bold">Cancel Booking</h3>
                                    {bookings.eventType ===0 &&
                                    <div>Select amount of tickets you want to cancel</div>}
                                    {bookings.eventType ===1 && 
                                    <div style={{"marginBottom":"30px"}}>You have <span id="totalBookings">{bookings.numberOfTickets}</span> ticket(s) booked for this event. Please tell us how many tickets you wish to cancel.</div>}
                              </div>
                              {bookings.eventType ===0 &&
                              <div className="item item__flex">
                                    <h3 className="heading fw-bold">Quantity</h3>
                                    <div className="input-group__increment">
                                          <button className="btn btn__minus" data-field="quantity" onClick={decreseTicketCountHandler}>
                                                <img src={iconMinusBlack} alt=""/>
                                          </button>
                                          <input className="form-control form-control__quantity quantity-field" type="number" step="1" readOnly value={bookings.numberOfTickets} name="quantity"/>
                                          <button className="btn btn__plus" data-field="quantity" onClick={increseTicketCountHandler}>
                                                <img src={iconPlusBlack} alt=""/>
                                          </button>
                                    </div>
                              </div>}
                        </div>
                        <div className="buttons-wrapper">
                              <button type="button" className="btn btn-primary fw-bold" onClick={handleConfirmChangesHandler} >
                                    Cancel Booking
                              </button>
                        </div>
                  </React.Fragment>}  
                  {bookings.step === "alert" &&
                  <React.Fragment>
                                    <h3 className="heading fw-bold">Cancel Booking</h3>
                                    <p className="lead fw-normal" style={{"marginLeft":"auto","marginRight":"auto"}}>
                                          {/* Are you sure you want to cancel this {bookings.numberOfTickets} tickets? Please do not use this function to transfer your ticket to a different date. If you wish to transfer your booking, email us instead. */}
                                          Please confirm that you wish to cancel {bookings.numberOfTickets} ticket(s) and that you understand cancellations are non-refundable.
                                    </p>
                                    <div className="buttons-wrapper" style={{"display":"flex","justifyContent":"space-around","marginTop":"30px"}}>
                                          <button type="button" className="btn btn-primary fw-bold" style={{"backgroundColor":"var(--bs-red)"}} onClick={cancleChangesHandler}>
                                                Cancel Changes
                                          </button>
                                          <button type="button" className="btn btn-primary fw-bold" onClick={handleConfirmChangesHandler} disabled={bookings.isRequesting ? true :false}>
                                                {!bookings.isRequesting && "Confirm Changes"}
                                                {bookings.isRequesting && "Please wait.."}
                                                {bookings.isRequesting && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                          </button>
                                    </div>
                  </React.Fragment>}  
            </React.Fragment>               

      )
}

export default CancleBooking;
