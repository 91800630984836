
export const checkValidityLogin=(value, type) =>{
      let isValid = true;
      let validationMessage = "";

      if (!type) {
            return true;
      }
      
      if(type === "password"){
            if(value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please enter your password";
            }
            else if(value.length < 3)
            {
                  isValid=false;
                  validationMessage = "Invalid password";
            }
            
      }
      
      if(type === "email"){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value || value.trim() == "")
            {
                  isValid = false;
                  validationMessage = "please enter your email address";
            }
            else if(!re.test(String(value).toLowerCase()) || !value)
            {
                  isValid = false;
                  validationMessage = "please enter a valid email address";
            }
      }

      return {isValid,validationMessage};
}

