// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Backdrop_Backdrop__Q5bvx {\n        height: 100%;\n        width: 100%;\n        position: absolute;\n        z-index: 100;\n        top: 0;\n        left: 0;\n        background-color: rgba(242, 233, 233, 0.5);\n        opacity: 0.7;\n}\n\n.Backdrop_ExpiredBackdrop__lXJZZ {\n        opacity: 0.9 !important;\n        background-color: rgba(242, 233, 233, 1.0);\n}", "",{"version":3,"sources":["webpack://./src/Ui/BookingModuleLoader/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;QACQ,YAAY;QACZ,WAAW;QACX,kBAAkB;QAClB,YAAY;QACZ,MAAM;QACN,OAAO;QACP,0CAA0C;QAC1C,YAAY;AACpB;;AAEA;QACQ,uBAAuB;QACvB,0CAA0C;AAClD","sourcesContent":[".Backdrop {\n        height: 100%;\n        width: 100%;\n        position: absolute;\n        z-index: 100;\n        top: 0;\n        left: 0;\n        background-color: rgba(242, 233, 233, 0.5);\n        opacity: 0.7;\n}\n\n.ExpiredBackdrop {\n        opacity: 0.9 !important;\n        background-color: rgba(242, 233, 233, 1.0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": "Backdrop_Backdrop__Q5bvx",
	"ExpiredBackdrop": "Backdrop_ExpiredBackdrop__lXJZZ"
};
export default ___CSS_LOADER_EXPORT___;
