import React,{useState, useEffect} from 'react'

import { useSelector,useDispatch } from "react-redux";

import EventCalender from "./Steps/EventCalender"
import AddingTickets from "./Steps/AddingTickets";
import OrderSummury from "./Steps/OrderSummury";
import PaymentPage from "./Steps/PaymentPage";
import BookingConfirmation from "./Steps/BookingConfirmation";
import Loader from '../../../Ui/BookingModuleLoader/Loader';

import { EventDetailActions } from '../../../Store/EventDetail/EventDetail';
import { BookingModuleActions } from '../../../Store/BookingModule/BookingModule';
import { UiActions } from '../../../Store/Ui/Ui';
import { TicketRelease } from "./../../../Store/BookingModule/BookingModuleActions"

const BookingModule = (props) => {
      
      const state                   =     useSelector(state=>state.bookingmodule);
      const eventdetail             =     useSelector(state=>state.eventdetail);
      const ui                      =     useSelector(state=>state.ui);
      const [seconds,setSeconds]    =     useState(0);
      const [minutes,setMinutes]    =     useState(0);
      let token                     =     localStorage.getItem('nhs_access_token');
      const dispatch                =     useDispatch();

      useEffect(()=>{

            document.body.style.overflow  = "hidden";
            document.body.style.paddingRight = "17px";

            return ()=>{
                  document.body.style.overflow = "";
                  document.body.style.paddingRight = "";
            }
          
      },[])

      useEffect(()=>{

            let myInterval = setInterval(() => {
                  if (seconds > 0) {
                        setSeconds(seconds-1)
                  }
                  if (seconds === 0) {
                        if (minutes === 0) {
                              clearInterval(myInterval)
                              if(ui.timer === "start"){
                                    dispatch(UiActions.setSpinner({status:"expired"}))
                              }

                              const nhs_session_block_id          =     localStorage.getItem("nhs_session_block_id");
                              const group_availablity             =     eventdetail.values.group_availablity;
                              let step                            =     "EvnentsCalenderPage";

                              if(nhs_session_block_id){

                                    dispatch(TicketRelease({token:token,session_id:nhs_session_block_id,group_availablity:group_availablity}));
                                    dispatch(BookingModuleActions.clearBookingModuleState({step:step,group_availablity:group_availablity}));
                                    dispatch(UiActions.clearTimer());
                              }
                        } else {
                              setMinutes(minutes-1)
                              setSeconds(59)
                        }
                  } 
            }, 1000)

            return ()=>{
                  clearInterval(myInterval);
            }
      })

      const startTimerHandler=()=>{
            setMinutes(5);
            setSeconds(0);
            dispatch(UiActions.setTimer())
            // setSeconds(20);
            // setMinutes(0);
      }

      const closeTimerHandler = ()=>{
            setMinutes(0);
            setSeconds(0);
            dispatch(UiActions.clearTimer())
      }

      const closeBookingHandler = ()=>{
            
            const nhs_session_block_id          =     localStorage.getItem("nhs_session_block_id");
            const group_availablity             =     eventdetail.values.group_availablity;
            let step                            =     "EvnentsCalenderPage";

            if(nhs_session_block_id){

                  dispatch(TicketRelease({token:token,session_id:nhs_session_block_id,group_availablity:group_availablity}));
            }

            dispatch(BookingModuleActions.clearBookingModuleState({step:step,group_availablity:group_availablity}));
            dispatch(EventDetailActions.closeEventBooking());
            dispatch(UiActions.clearUiSpinner());
            dispatch(UiActions.clearTimer());
      }

      let timeShow = false;
      if (state.step === 'OrderSummaryPage' || state.step === 'PaymentPage') {
            timeShow = true;
      }

      return (
                  <React.Fragment>
                              <div id="modalBookTickets" className={["modal modal__book-tickets fade show",props.show === "entering" && "modalOpen", props.show === "exiting" && "modalClosed" ].join(" ")}
                              style={{"display": "block"}}
                              >
                                    <div className="modal-dialog">
                                          <div className="modal-content">
                                                <div className="modal-body p-0">
                                                      <button type="button" style={{"zIndex" : state.step === "BookingConfirmationPage" ? "1002":""}} className="btn btn-close btn-close__absolute tfm-modal__toggle p-0 d-none d-md-block" 
                                                      // data-bs-dismiss="modal" aria-label="Close" 
                                                      onClick={closeBookingHandler}>
                                                      </button>
                                                      <div className="content__book-tickets mx-md-auto w-100">
                                                            {state.step !== "BookingConfirmationPage" &&
                                                            <div className="content__header border-bottom" style={{"display":"flex"}}>
                                                                  <h1 className="content__title fw-bold mb-0" style={{"width":timeShow ? "90%" : ""}}>
                                                                        { (state.step === "EvnentsCalenderPage" || state.step === "AddingTicketsPage" || state.step ===  "OrderSummaryPage") &&  props.title}
                                                                        { state.step === "PaymentPage" && `Payment `}
                                                                        {/* { state.step === "PaymentPage" && `Payment £${state.bookingDetails.subTotal.toFixed(2)}`} */}
                                                                        { (state.step === "PaymentPage"|| state.step ===  "OrderSummaryPage") && '('+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2)+')'}
                                                                  </h1>
                                                                  {/* {timeShow &&
                                                                  <span style={{"width":"10%","margin":"auto"}}>
                                                                        {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
                                                                  </span>} */}
                                                            </div>}
                                                            <div className='Booking-module-Loader-wraper'>
                                                                  {state.step === "EvnentsCalenderPage" &&<EventCalender clearTimer={closeTimerHandler}/>}
                                                                  {state.step === "AddingTicketsPage" &&<AddingTickets startTimer={startTimerHandler}/>}
                                                                  {state.step === "OrderSummaryPage" &&<OrderSummury clearTimer={closeTimerHandler}/>}
                                                                  {state.step === "PaymentPage" &&<PaymentPage clearTimer={closeTimerHandler}/>}
                                                                  {state.step === "BookingConfirmationPage" &&<BookingConfirmation/>}
                                                                  {ui.spinner !=="" && <Loader/>}
                                                            </div>
                                                      </div>
                                                      {/* <!-- /.content__book-tickets --> */}
                                                     
                                                </div>
                                                {/* <!-- /.modal-body --> */}
                                                
                                               
                                          </div>
                                          {/* <!-- /.modal-content --> */}
                                    </div>
                                    {/* <!-- /.modal-dialog --> */}
                                    <div className="tfm-modal-overlay tfm-modal__close">
                                    </div>
                                    
                              </div>
                              <div className="modal-backdrop fade show"></div>
                  </React.Fragment>
            )
}

export default BookingModule;
