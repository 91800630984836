import React,{ useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { BookingActions } from "./../../../../Store/Bookings/Bookings";
import { amendTicketsRequest } from "./../../../../Store/Bookings/BookingActions"
import { checkValiditContactUs } from "./../../../../Shared/UtilityContactUs";

const AmendBookings = (props) => {

      const [state, setState] = useState({
            email:{value:"",error:false,message:""},
            name:{value:"",error:false,message:""},
            mobile:{value:"",error:false,message:""},
            message:{value:"",error:false,message:""},
      })
      const dispatch =  useDispatch();
      const bookings = useSelector(state => state.bookings.cancleBookings)
      const auth = useSelector(state => state.auth)

      const token = localStorage.getItem('nhs_access_token');

      useEffect(()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        email:{...prevState.email,value:auth.nhs_access_email},
                        name:{...prevState.name,value:auth.nhs_access_name},
                        mobile:{...prevState.mobile,value:auth.nhs_access_mobilenumber}
                  }
            })
      },[])

      const changeHandler = (event)=>{

            const value = event.target.value;
            const name = event.target.name;
            
            setState(prevState=>{

                  return {
                        ...prevState,
                        [name]:{...prevState.message,value:value}
                  }
            })
      }

      const clearOnFocusHandler = ()=>{

            setState(prevState=>{
                  return {
                        ...prevState,
                        email:{...prevState.email,error:false},
                        name:{...prevState.name,error:false},
                        mobile:{...prevState.mobile,error:false},
                        message:{...prevState.message,error:false},
                  }
            })
      }

      const submitContactFormHandler=(event)=>{
            event.preventDefault();
            const errorStore = [];
            const copyObject = {...state};
            for(let i in state){

                  const error = checkValiditContactUs(state[i].value,i);
                  if(!error.isValid){
                        errorStore.push(error.validationMessage)
                  }
                  copyObject[i] = {...copyObject[i],error:!error.isValid,message:error.validationMessage}
            }

            setState(copyObject)
            if(errorStore.length > 0){
                  //   dispatch(UiActions.setAlert({model:true,message:errorStore[0]}));
                  return;
            }

            const data = { "email":state.email.value, "name":state.name.value, "mobile":state.mobile.value, "message":state.message.value,RefId:bookings.bookingRef,event_title:bookings.event_title,start_date:bookings.start_date,start_time:bookings.start_time }
            dispatch(BookingActions.setAmendTicketsRequestingHandler({isRequestingAmendTickets:bookings.booking_id}))
            dispatch(amendTicketsRequest({data:data,token:token}))

      }
      return (

            <React.Fragment>
                  <h3 className="heading fw-bold">Amend Booking</h3>
                  <p className="lead fw-normal">
                        {/* If you want to change the date of your booking you can check the availability with us submitting the form: */}
                        Please detail the changes you are trying to make to your booking. A member of our team will check whether it's possible.
                  </p>
                  <form>
                        <div className="row">
                              <div className="col-md-6">
                                    <div className="form-group">
                                          <label htmlFor="amendBookingInputFullName" className="form-label fw-normal">
                                                Full Name
                                          </label>
                                          <input type="text" className="form-control form-control-xl-lg" id="amendBookingInputFullName" value={state.name.value} readOnly disabled/>
                                    </div>
                                    {/* <!-- /.form-group --> */}
                              </div>
                              <div className="col-md-6">
                                    <div className="form-group">
                                          <label htmlFor="amendBookingInputMobileNumber" className="form-label fw-normal">
                                                Mobile number
                                          </label>
                                          <input type="text" className="form-control form-control-xl-lg" id="amendBookingInputMobileNumber" name="mobile" value={state.mobile.value} onChange={changeHandler} onFocus={clearOnFocusHandler} disabled/>
                                          {state.mobile.error &&
                                          <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                {state.mobile.message}
                                          </label>}
                                    </div>
                                    {/* <!-- /.form-group --> */} 
                              </div>
                        </div>
                        {/* <!-- /.row --> */}
                        <div className="row">
                              <div className="col-md-12">
                                    <div className="form-group">
                                          <label htmlFor="amendBookingInputEmail" className="form-label fw-normal">Email</label>
                                          <input type="email" className="form-control form-control-xl-lg" id="amendBookingInputEmail" value={state.email.value} readOnly disabled/>
                                    </div>
                                    {/* <!-- /.form-group --> */}
                              </div>
                        </div>
                        {/* <!-- /.row --> */}

                        <div className="row">
                              <div className="col-md-12">
                                    <div className="form-group form-group__textarea">
                                          <label htmlFor="amendBookingInputRequest" className="form-label fw-normal">
                                                Request
                                          </label>
                                          <textarea className="form-control form-control-xl-lg" id="amendBookingInputRequest" value={state.message.value} name="message" onChange={changeHandler} disabled={bookings.isRequestingAmendTickets ? true :false} onFocus={clearOnFocusHandler}></textarea>
                                          {state.message.error &&
                                          <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                {state.message.message}
                                          </label>}
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.row --> */}

                        <div className="row">
                              <div className="col-md-12">
                                    <div className="form-group form-group__buttons">
                                          <button type="submit" className="btn btn-primary fw-bold" disabled={bookings.isRequestingAmendTickets ? true :false} onClick={submitContactFormHandler}>  
                                                {!bookings.isRequestingAmendTickets && "Submit"}
                                                {bookings.isRequestingAmendTickets && "Please wait.."}
                                                {bookings.isRequestingAmendTickets && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                          </button>
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.row --> */}
                  </form>
            </React.Fragment>                   

      )
}

export default AmendBookings;
