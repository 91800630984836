import React from "react";

import { useParams, useLocation } from 'react-router-dom';
import { iframeSite } from "./../../Shared/Constants"

const Uptake = () => {
      const {id}   =     useParams();
      let path = useLocation();

      let route = "";
      if(path.pathname.includes("uptake_request")){
            // route = "uptake_request";
            route = "uptake_request_tfm";
      }

      if(path.pathname.includes("uptake_request_by_venue")){
            // route = "uptake_request_by_venue";
            route = "uptake_request_by_venue_tfm";
      }
      
      return (
            <>
                  <iframe width="560" height="315" src={`${iframeSite}${route}/${id}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" style={{"position": "absolute", "width": "100%", "height": "100%"}}>
                  </iframe>
            </>
            
      );
};
export default Uptake;
