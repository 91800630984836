import React, { useState,useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { checkValidityForgotPasswordChange } from "./../Shared/UtilityForgotPassword";
import { verifyForgotPasswordToken, forgotPasswordSubmitResetPassword } from "./../Store/Auth/AuthActions";
import { UiActions } from '../Store/Ui/Ui';


const ResetPassword =(props)=> {
      const [state,setState] = useState({password:"",confirm_password:""});
      const resetPasswordRequest = useSelector(state=>state.ui.resetPasswordRequest);
      const dispatch          = useDispatch();
      const navigate = useNavigate();
      const {token} = useParams();

      useEffect(() => {
            const data = {request_token:token,navigate:navigate}
            dispatch(verifyForgotPasswordToken(data))
      }, [])

      const changeStateHandler = (event)=>{
            event.preventDefault();
            const key = event.target.name;
            const value = event.target.value;
            setState(prevState=>{
                  return {
                        ...prevState,
                        [key]:value
                  }
            })
      }

      const resetPasswordHandler = (event)=>{
            event.preventDefault();
            const errorStore = [];
            for(let key in state)
            {
                  const errorCheck = checkValidityForgotPasswordChange(state,key);
                  if(!errorCheck.isValid){
                        errorStore.push(errorCheck.validationMessage)
                  }
            }
            if(errorStore.length === 0){
                  const data = {password:state.password,confirm_password:state.confirm_password,request_token:token}
                  // const data = {password:state.password,confirm_password:state.confirm_password,request_token:token}
                  dispatch(UiActions.setResetPasswordRequest({request:true}))
                  dispatch(forgotPasswordSubmitResetPassword({data:data,navigate:navigate}))
                        
            } else {
                  dispatch(UiActions.setAlert({model:true,message:errorStore[0]}))
            }

      }

      return (
            <main>
                  <section className="section__account-settings section__reset-password" style={{"height":"65vh","display":"flex","alignItems":"center"}}>

                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content content__account-settings">
                                                <div className="content-box-wrapper mx-lg-auto" style={{"border":"none"}}>
                                                      <div className="content__header text-center">
                                                            <h1 className="header__title fw-bold mb-0">Change Password</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}

                                                      <div className="content__body">
                                                            <form>
                                                                  <div className="row">
                                                                        <div className="col__left col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                                                                            
                                                                              <div className="form-group">
                                                                                    <label htmlFor="changePasswordInputNewPassword" className="form-label fw-normal">Enter New Password</label>
                                                                                    <input type="password" className="form-control form-control-xl-lg" id="changePasswordInputNewPassword" name="password" value={state.password}   onChange={changeStateHandler} autoComplete="off" disabled={resetPasswordRequest === true ? true : false}/>
                                                                                   
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                              <div className="form-group">
                                                                                    <label htmlFor="changePasswordInputConfirmNewPassword" className="form-label fw-normal">Re-enter New Password</label>
                                                                                    <input type="password" className="form-control form-control-xl-lg" id="changePasswordInputConfirmNewPassword" name="confirm_password" value={state.confirm_password} onChange={changeStateHandler}  autoComplete="off" disabled={resetPasswordRequest === true ? true : false}/>
                                                                              </div>
                                                                              <div className="form-group form-group__buttons mt-lg-auto mt-5">
                                                                                    <button type="submit" className="btn btn-xl-lg btn-primary  fw-bold"  style={{"width":"100%"}} onClick={resetPasswordHandler}disabled={resetPasswordRequest === true ? true : false}>
                                                                                          {!resetPasswordRequest && "Submit"}
                                                                                          {resetPasswordRequest === true && "Please wait.."}
                                                                                          {resetPasswordRequest ===true && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                                    </button>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        
                                                                  </div>
                                                                  {/* <!-- /.row --> */}
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
                                          
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}

                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
      )
}

export default ResetPassword;