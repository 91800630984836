import React, {useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Recaptcha from "react-recaptcha";
import { checkValiditContactUs } from "./../../Shared/UtilityContactUs";
import { sendContactUsRequest } from "./../../Store/Auth/AuthActions";
import { UiActions } from '../../Store/Ui/Ui';

const ContactUs = () => {

      let recaptchaInstance;
      const [role,setRole] = useState("");
      const [verify,setVerify] = useState({value:false,error:false,message:'Please tick the captcha box to send your message to us'});
      const [state, setState] = useState({
            email:{value:"",error:false,message:""},
            name:{value:"",error:false,message:""},
            mobile:{value:"",error:false,message:""},
            message:{value:"",error:false,message:""},
      })
      const auth = useSelector(state => state.auth)
      
      const contactUsRequest = useSelector(state => state.ui.contactUsRequest)

      const dispatch =  useDispatch();
     
      const setRoleHandler = (e)=>{
            e.preventDefault();
            setRole(e.target.name)
      }

      const token = localStorage.getItem('nhs_access_token');

      useEffect(()=>{
           
            if(token && auth.auth && !role){
                  setRole("member");
            }

      })

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
            setState(prevState=>{

                  return {
                        ...prevState,
                        email:{...prevState.email,value:auth.nhs_access_email},
                        name:{...prevState.name,value:auth.nhs_access_name},
                        mobile:{...prevState.mobile,value:auth.nhs_access_mobilenumber}
                  }
            })

      },[auth.auth,auth.nhs_access_email,auth.nhs_access_name,auth.nhs_access_mobilenumber])

      useEffect(()=>{

            if(contactUsRequest === "requested"){

                  setState(prevState=>{

                        return {
                              ...prevState,
                              email:{...prevState.email,value:auth.nhs_access_email},
                              name:{...prevState.name,value:auth.nhs_access_name},
                              mobile:{...prevState.mobile,value:auth.nhs_access_mobilenumber},
                              message:{...prevState.email,value:""},
                        }
                  })

                  setVerify(prevState=>{
                        return {
                              ...prevState,
                              value:false
                        }
                  })
                  resetRecaptcha();
                  // Recaptcha.reset();

            }

            if(contactUsRequest === "requested" && role!== ""){
                  setRole("")
            }

      },[contactUsRequest])

      const changeHandler = (event)=>{

            const name = event.target.name;
            setState(prevState=>{

                  return {
                        ...prevState,
                        [name]:{...prevState.message,value:event.target.value}
                  }
            })
      }

      const resetRecaptcha = () => {
            recaptchaInstance.reset();  
      };

      const submitContactFormHandler=(event)=>{
            
            event.preventDefault();
            const errorStore = [];
            const copyObject = {...state};
            for(let i in state){

                  const error = checkValiditContactUs(state[i].value,i);
                  if(!error.isValid){
                        errorStore.push(error.validationMessage)
                  }
                  copyObject[i] = {...copyObject[i],error:!error.isValid,message:error.validationMessage}
            }

            setState(copyObject)
            if(!verify.value){
                  setVerify(prevState=>{
                        return {
                              ...prevState,
                              error:true
                        }
                  })
            }
            if(errorStore.length > 0 || !verify.value){
                  //   dispatch(UiActions.setAlert({model:true,message:errorStore[0]}));
                  return;
            }

            if(verify.value){
                  
                  const data = { "email":state.email.value, "name":state.name.value, "mobile":state.mobile.value, "message":state.message.value}
                  dispatch(UiActions.setContactUsRequest({request:true}))
                  dispatch(sendContactUsRequest({data:data,token:token}))
            }
      }

      const onfocusClearErrorHandler = ()=>{
            setState(prevState=>{
                  return {
                        ...prevState,
                        email:{...prevState.email,error:false,message:""},
                        name:{...prevState.name,error:false,message:""},
                        mobile:{...prevState.mobile,error:false,message:""},
                        message:{...prevState.message,error:false,message:""},
                  }
            })

            setVerify(prevState=>{
                  return {
                        ...prevState,
                        error:false
                  }
            })
      }

      // const callback =()=>{
      //       console.log("reCAPTCHA loaded successfully")
      // }

      const verifyCallback = ()=>{

            setVerify(prevState=>{
                  return {
                        ...prevState,
                        value:true
                  }
            })
      }

      const expiredCallback = ()=>{
            setVerify(prevState=>{
                  return {
                        ...prevState,
                        value:false
                  }
            })
      }

      return (
            <main className='main__contact-us'>
                  <section className="section__contact-us">
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">

                                          { (!role &&  !token )&&
                                          <div className="content content__contact-category">
                                                <div className="content__lead text-center border-bottom">
                                                      <p className="lead mb-0">Get in touch!</p>
                                                </div>
                                                {/* <!-- /.content__lead --> */}
                                                <div className="d-flex flex-column flex-sm-row align-items-stretch">
                                                      <div className="content__body border-bottom border-bottom-sm-0 border-right-sm">
                                                            <a className="btn__block-square bg-yellow" href="/" name="organiser" onClick={setRoleHandler}>
                                                                  I’m an event <br className="d-xxl-none"/>organiser
                                                            </a>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                      <div className="content__body">
                                                            <a className="btn__block-square bg-teal" name="member" href="/" onClick={setRoleHandler}>
                                                                  I’m a member
                                                            </a>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                
                                          </div>}
                                          {/* <!-- /.content --> */}

                                          { (role ||  (auth.auth || token ) ) &&
                                          <div className="content content__contact-form">
                                                <div className="content__lead text-center border-bottom d-none d-lg-block">
                                                      <p className="lead mb-0">Get in touch!</p>
                                                </div>
                                                {/* <!-- /.content__lead --> */}
                                                <div className="content-box-wrapper mx-lg-auto">
                                                      <div className="content__header border-bottom text-center">
                                                            <h1 className="header__title fw-bold mb-0">Contact us</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}
                                                      <div className="content__body">
                                                            <form>
                                                                  <div className="row">
                                                                        <div className="col__left col-md-6 order-md-2">
                                                                              <div className="form-group-wrapper d-flex flex-column">
                                                                                    <div className="form-group">
                                                                                          <label htmlFor="contactInputFullName" className="form-label fw-normal">
                                                                                                Full Name
                                                                                          </label>
                                                                                          <input type="text" className="form-control form-control-xl-lg" id="contactInputFullName" value={state.name.value} name="name" onChange={changeHandler} onFocus={onfocusClearErrorHandler} disabled={contactUsRequest === true || auth.auth === true}/>
                                                                                          {state.name.error &&
                                                                                          <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                                                                {state.name.message}
                                                                                          </label>}
                                                                                    </div>
                                                                                    {/* <!-- /.form-group --> */}
                                                                                    <div className="form-group">
                                                                                          <label htmlFor="contactInputEmail" className="form-label fw-normal">Email</label>
                                                                                          <input type="email" className="form-control form-control-xl-lg" id="contactInputEmail" value={state.email.value} name="email" onChange={changeHandler} onFocus={onfocusClearErrorHandler} disabled={contactUsRequest === true || auth.auth === true}/>
                                                                                          {state.email.error &&
                                                                                          <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                                                                {state.email.message}
                                                                                          </label>}
                                                                                    </div>
                                                                                    {/* <!-- /.form-group --> */}
                                                                                    <div className="form-group">
                                                                                          <label htmlFor="contactInputMobileNumber" className="form-label fw-normal">Mobile Number</label>
                                                                                          <input type="text" className="form-control form-control-xl-lg" id="contactInputMobileNumber" value={state.mobile.value} name="mobile" onChange={changeHandler} onFocus={onfocusClearErrorHandler} disabled={contactUsRequest === true || auth.auth === true}/>
                                                                                          {state.mobile.error &&
                                                                                          <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                                                                {state.mobile.message}
                                                                                          </label>}
                                                                                    </div>
                                                                                    <div className="form-group" onFocus={onfocusClearErrorHandler}>
                                                                                          <Recaptcha
                                                                                                // sitekey="6Ld58OgcAAAAABKotI608mbOtx0Am6RKs5JOym0i"
                                                                                                sitekey="6LfrPYUgAAAAAJqS45H3hE-II7KmY067zgBc-Vwe"
                                                                                                render="explicit"
                                                                                                verifyCallback={verifyCallback}
                                                                                                // onloadCallback={callback}
                                                                                                expiredCallback={expiredCallback}
                                                                                                ref={e => recaptchaInstance = e}
                                                                                          />
                                                                                          {verify.error &&
                                                                                          <label className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                                                                {verify.message}
                                                                                          </label>}
                                                                                    </div>
                                                                                    {/* <!-- /.form-group --> */}

                                                                                    {/* <!-- NOTE: this button is visible only for screen size 768px and greater --> */}
                                                                                    <div className="form-group form-group__buttons mt-lg-auto d-none d-md-block mb-0">
                                                                                          <button type="submit" className="btn btn-xl-lg btn-primary  fw-bold" onClick={submitContactFormHandler} disabled={contactUsRequest === true}>
                                                                                                {(!contactUsRequest || contactUsRequest === "requested") && "Submit"}
                                                                                                {contactUsRequest === true && "Please wait.."}
                                                                                                {contactUsRequest === true && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                                          </button>
                                                                                    </div>
                                                                                    {/* <!-- /.form-group --> */}

                                                                              </div>
                                                                              {/* <!-- /.form-group-wrapper --> */}
                                                                        </div>
                                                                        <div className="col__right col-md-6 order-md-1">
                                                                              <div className="form-group form-group__textarea mb-md-0">
                                                                                    <label htmlFor="contactInputMessage" className="form-label fw-normal d-lg-none">
                                                                                          Describe how can we help
                                                                                    </label>
                                                                                    <textarea className="form-control form-control-xl-lg" id="contactInputMessage" placeholder="Tell us how we can help" value={state.message.value} name="message" onChange={changeHandler} onFocus={onfocusClearErrorHandler} disabled={contactUsRequest === true}>

                                                                                    </textarea>
                                                                                    {state.message.error &&
                                                                                    <p  className="form-label fw-normal"  style={{"color":"var(--bs-red)","margin":"15px"}}>
                                                                                          {state.message.message}
                                                                                    </p>}
                                                                              </div>

                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                                  {/* <!-- NOTE: this button is visible only for screen size less than 768px --> */}
                                                                  <div className="row d-md-none">
                                                                        <div className="col-md-12">
                                                                              <div className="form-group form-group__buttons mt-md-auto">
                                                                                    <button type="submit" className="btn btn-xl-lg btn-primary fw-bold" disabled={contactUsRequest} onClick={submitContactFormHandler}>
                                                                                          {!contactUsRequest && "Submit"}
                                                                                          {contactUsRequest && "Please wait.."}
                                                                                          {contactUsRequest && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                                    </button>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                            </form>

                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}

                                          </div>
                                          }
                                          {/* <!-- /.content --> */}

                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
      
                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
      )
}

export default ContactUs
