import React,{ useState, useEffect } from 'react'

import { useSelector,useDispatch } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../../../ReactDatepicker.css";
import "./ReactDatepicketForEventDetails.css";

import { UiActions } from '../../../../Store/Ui/Ui';
import { BookingModuleActions } from "./../../../../Store/BookingModule/BookingModule";
import {getPerformanceInfo} from "./../../../../Store/BookingModule/BookingModuleActions";

const EventCalender = (props) => {
      
      const [selectedDate, setSelectedDate]     =     useState(null);
      const [listSchedule, setListSchedule]     =     useState([]);
      const state                               =     useSelector(state=>state.eventdetail);
      const dispatch                            =     useDispatch();

      useEffect(()=>{

            props.clearTimer();
            dispatch(UiActions.clearUiSpinner());

      },[])

      const filterAvailableDates = (date) => {

            const date2Digit = "0"+date.getDate();
            const date1 =date2Digit.slice(-2) +"-" +("0" + (date.getMonth() + 1)).slice(-2) +"-" +date.getFullYear();

            if (state.scheduleInfo) {

                  if(state.scheduleInfo.avalability.hasOwnProperty(date1)){

                        let ctAlertCheck = "";
                        ctAlertCheck = state.scheduleInfo.avalability[date1].color_class;
                        return ctAlertCheck === "ct_alert" ? false : true;
                        // return true;
                  } 
                  else {

                        return false;
                  }

            } else {

                  return false;
            }
      };

      const getClassNamesForAvailableDates = (date)=>{

            const date2Digit = "0"+date.getDate();
            const date1 =date2Digit.slice(-2) +"-" +("0" + (date.getMonth() + 1)).slice(-2) +"-" +date.getFullYear();

            if (state.scheduleInfo) {
                  if(state.scheduleInfo.avalability.hasOwnProperty(date1))
                  {
                        return state.scheduleInfo.avalability[date1].color_class;
                  }
                  else
                  {
                        return undefined;
                  }

            } else {
                  return undefined;
            }
      }

      const onClickDateHandler = (value) => {

            const date1 = getDateInddmmyyyyFormat(value);
         
            if (state.scheduleInfo.avalability.hasOwnProperty(date1)) {
                   
                  const shows = state.scheduleInfo.avalability[date1].shows;
                  setListSchedule(shows);
                  setSelectedDate(value);
            }
      };

      const getDateInddmmyyyyFormat = (value)=>{

            const date2Digit = "0"+value.getDate();
            const date1 =date2Digit.slice(-2) +"-" +("0" + (value.getMonth() + 1)).slice(-2) +"-" +value.getFullYear();
            return date1;

      }

      const setShowIdHandler = (showId,colorClass) => {

            if(colorClass === 'ct_alert'){
                  return;
            }

            const group_availablity = state.values.group_availablity;
            const token= localStorage.getItem('nhs_access_token');

            dispatch(BookingModuleActions.setSecureData({data:null}))
            dispatch(UiActions.clearSecure3D());
            
            dispatch(UiActions.setSpinner({status:"request"}));
            dispatch(getPerformanceInfo({token:token,data:{"show_id":showId},group_availablity:group_availablity}));
            
      };

      const fomatingDateHandler = (str)=>{

            str = str.replace(":",".")
            return str;
            // str = str.replace(/(\d+)/g, function (_, num){
            //       return ' ' + num + ' ';
            // });
            // str = str.trim();
            // return str.toUpperCase();
      }

      const formatOpeningDateHandler = ()=>{

            const date1Array = Object.keys(state.scheduleInfo.avalability)[0].split("-");
            const finalDateFormat = date1Array[2]+"/"+date1Array[1]+"/"+date1Array[0];

            return new Date(finalDateFormat)
      }

      const formatDateInUKFormatHandler = (date)=>{

            const dat = getDateInddmmyyyyFormat(date);
            return dat.replaceAll("-","/");
      }
     
      return (
                  <div id="bookTicketsStep1" className="content content__choose-date">
                        <div className="content__body">
                              <div className="wrapper d-flex flex-column flex-md-row">
                                          <div className="col__left">
                                          {/* <!-- Inline event datepicker --> */}
                                                <div id="chooseShowTime" className="datepicker-inline-xl-lg">
                                                      <DatePicker selected={selectedDate} 
                                                      onChange={(date:Date) => onClickDateHandler(date)} 
                                                      calendarStartDay={1} placeholderText="Event Start Date" inline
                                                      filterDate={(date)=>filterAvailableDates(date)}
                                                      dayClassName={(date) =>getClassNamesForAvailableDates(date)}
                                                      openToDate={formatOpeningDateHandler()}
                                                      />
                                                </div>
                                          </div>
                                          <div className="col__right">
                                                <p className="p-label">Select date</p>
                                                {selectedDate &&
                                                <div id="bookTicketsStep1b" className="">
                                                      <p className="p-label mb-0">Selected date:</p>
                                                      {/* <!-- set #chooseShowTime__value from date picked --> */}
                                                      <p className="p-value">
                                                                  <span id="chooseShowTime__value">
                                                                  {/* 09-03-2022 */}
                                                                  {/* {selectedDate && selectedDate} */}
                                                                  {selectedDate && formatDateInUKFormatHandler(selectedDate)}
                                                                  </span>
                                                      </p>
                                                      <p className="p-label">Select time</p>
                                                      {listSchedule.length > 0 && listSchedule.map(el=>{

                                                                  return (
                                                                        <div className={["btn-check-wrapper",el.color_class].join(" ")} key={el.show_id}>
                                                                              <input className="form-check-input btn-check" type="radio" name="radioSelectEventTime" />
                                                                              <label className="btn btn-outline-primary" htmlFor="radioSelectEventTime1"
                                                                              onClick={()=>setShowIdHandler(el.show_id,el.color_class)}
                                                                              >
                                                                                    {fomatingDateHandler(el.start_time)}
                                                                                    {/* {el.start_time} */}
                                                                              </label>
                                                                        </div>
                                                                  )
                                                            })
                                                      }
                                                      
                                                </div>}
                                                {/* <!-- /# --> */}
                                          </div>
                              </div>
                              {/* <!-- /.wrapper --> */}
                        </div>
                        {/* <!-- /.content__body --> */}
                  </div>

            )
}

export default EventCalender;
