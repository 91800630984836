import { createSlice } from "@reduxjs/toolkit";


const initialState = {
      auth:false,
      nhs_access_token:null,
      nhs_access_name:'',
      nhs_access_email:'',
      nhs_access_mobilenumber:'',
      nhs_is_verified:'false',
      nhs_firebase_key:'',
      nhs_reactive:"",
      nhs_vendor:"",
      nhs_city:"",
      nhs_role:"",
      nhs_trust:""

}

const AuthSlice = createSlice({
      name:'auth',
      initialState:initialState,
      reducers:{
            login(state,action){

                  localStorage.setItem('nhs_is_verified',action.payload.userInfo.is_verified);
                  localStorage.setItem('nhs_access_token',action.payload.token);
                  localStorage.setItem('nhs_access_name',action.payload.userInfo.fname +" "+ action.payload.userInfo.lname);
                  localStorage.setItem('nhs_access_email',action.payload.userInfo.email);
                  localStorage.setItem('nhs_access_mobilenumber',action.payload.userInfo.mobilenumber || "");
                  localStorage.setItem('nhs_firebase_key',action.payload.userInfo.firebase_key);
                  localStorage.setItem('nhs_reactive',action.payload.reactive);
                  localStorage.setItem('nhs_vendor',action.payload.userInfo.vendor);
                  localStorage.setItem('nhs_city',action.payload.userInfo.city);
                  localStorage.setItem('nhs_role',action.payload.userInfo.role);
                  localStorage.setItem('nhs_trust',action.payload.userInfo.nhs_trust);
                  
                  state.auth = true;
                  state.nhs_access_token = action.payload.token;
                  state.nhs_access_name = action.payload.userInfo.fname +" "+ action.payload.userInfo.lname;
                  state.nhs_access_email = action.payload.userInfo.email;
                  state.nhs_access_mobilenumber = action.payload.userInfo.mobilenumber || "";
                  state.nhs_is_verified = action.payload.userInfo.is_verified.toString();
                  state.nhs_firebase_key = action.payload.userInfo.firebase_key;
                  state.nhs_reactive = action.payload.reactive;
                  state.nhs_vendor = action.payload.userInfo.vendor;
                  state.nhs_city = action.payload.userInfo.city;
                  state.nhs_role = action.payload.userInfo.role;
                  state.nhs_trust = action.payload.userInfo.nhs_trust;
                  
            },
            logout(state,action){
                  state.auth = false;
                  state.nhs_access_token = null;
                  state.nhs_access_name = "";
                  state.nhs_access_email = "";
                  state.nhs_access_mobilenumber = "";
                  state.nhs_is_verified = 'false';
                  state.nhs_firebase_key = '';
                  state.nhs_reactive = '';
                  state.nhs_vendor = '';
                  state.nhs_city = '';
                  state.nhs_role = '';
                  state.nhs_trust = '';

                  localStorage.removeItem("nhs_access_token");
                  localStorage.removeItem("nhs_access_name");
                  localStorage.removeItem("nhs_access_email");
                  localStorage.removeItem("nhs_access_mobilenumber");
                  localStorage.removeItem("nhs_is_verified");
                  localStorage.removeItem("nhs_firebase_key");
                  localStorage.removeItem("nhs_reactive");
                  localStorage.removeItem("nhs_vendor");
                  localStorage.removeItem("nhs_city");
                  localStorage.removeItem("nhs_role");
                  localStorage.removeItem("nhs_trust");
            },
            checkLogin(state,action){

                  state.auth = true;
                  state.nhs_access_token = action.payload.token;
                  state.nhs_access_name = action.payload.name;
                  state.nhs_access_email = action.payload.email;
                  state.nhs_access_mobilenumber = action.payload.mobilenumber || "";
                  state.nhs_is_verified = action.payload.is_verified.toString();
                  state.firebase_key = action.payload.firebase_key;
                  state.nhs_reactive = action.payload.reactive;

                  state.nhs_city = action.payload.nhs_city;
                  state.nhs_role = action.payload.nhs_role;
                  state.nhs_trust = action.payload.nhs_trust;

                  
            },
            updateMobileNumber(state,action){
                  state.nhs_access_mobilenumber = action.payload.mobile || "";
                  localStorage.setItem('nhs_access_mobilenumber',action.payload.mobile || "");
            },
            verfiedMobileNumber(state,action){
                  state.nhs_is_verified = 'true';
                  localStorage.setItem('nhs_is_verified','true');
            },
            verfiedMobileNumberDemo(state,action){
                  state.nhs_is_verified = 'true';
                  localStorage.setItem('nhs_is_verified','true');
            },
            setSelectedNHSOptions(state,action){
                  state.nhs_city = action.payload.nhs_city;
                  state.nhs_role = action.payload.nhs_role;
                  state.nhs_trust = action.payload.nhs_trust;

                  localStorage.setItem('nhs_city',action.payload.nhs_city);
                  localStorage.setItem('nhs_role',action.payload.nhs_role);
                  localStorage.setItem('nhs_trust',action.payload.nhs_trust);
            },
            reactiveAccount(state,action){
                  localStorage.removeItem("nhs_reactive");
                  state.nhs_reactive = action.payload.reactive;
            },
            reset: ()=> initialState
      }
})


export const AuthActions = AuthSlice.actions;
export default AuthSlice.reducer;



//--------------------------------------------------------------------------//
// ---------------------old Slice without reset options----------------------
//--------------------------------------------------------------------------//


// import { createSlice } from "@reduxjs/toolkit";

// const AuthSlice = createSlice({
//       name:'auth',
//       initialState:{
//             auth:false,
//             nhs_access_token:null,
//             nhs_access_name:'',
//             nhs_access_email:'',
//             nhs_access_mobilenumber:'',
//             nhs_is_verified:'false',
//             nhs_firebase_key:'',
//             nhs_reactive:"",
//             nhs_vendor:""
//       },
//       reducers:{
//             login(state,action){

//                   localStorage.setItem('nhs_access_token',action.payload.token);
//                   localStorage.setItem('nhs_access_name',action.payload.userInfo.fname +" "+ action.payload.userInfo.lname);
//                   localStorage.setItem('nhs_access_email',action.payload.userInfo.email);
//                   localStorage.setItem('nhs_access_mobilenumber',action.payload.userInfo.mobilenumber || "");
//                   localStorage.setItem('nhs_is_verified',action.payload.userInfo.is_verified);
//                   localStorage.setItem('nhs_firebase_key',action.payload.userInfo.firebase_key);
//                   localStorage.setItem('nhs_reactive',action.payload.reactive);
//                   localStorage.setItem('nhs_vendor',action.payload.userInfo.vendor);
                  
//                   state.auth = true;
//                   state.nhs_access_token = action.payload.token;
//                   state.nhs_access_name = action.payload.userInfo.fname +" "+ action.payload.userInfo.lname;
//                   state.nhs_access_email = action.payload.userInfo.email;
//                   state.nhs_access_mobilenumber = action.payload.userInfo.mobilenumber || "";
//                   state.nhs_is_verified = action.payload.userInfo.is_verified.toString();
//                   state.nhs_firebase_key = action.payload.userInfo.firebase_key;
//                   state.nhs_reactive = action.payload.reactive;
//                   state.nhs_vendor = action.payload.userInfo.vendor;
                  
//             },
//             logout(state,action){
//                   state.auth = false;
//                   state.nhs_access_token = null;
//                   state.nhs_access_name = "";
//                   state.nhs_access_email = "";
//                   state.nhs_access_mobilenumber = "";
//                   state.nhs_is_verified = 'false';
//                   state.nhs_firebase_key = '';
//                   state.nhs_reactive = '';
//                   state.nhs_vendor = '';

//                   localStorage.removeItem("nhs_access_token");
//                   localStorage.removeItem("nhs_access_name");
//                   localStorage.removeItem("nhs_access_email");
//                   localStorage.removeItem("nhs_access_mobilenumber");
//                   localStorage.removeItem("nhs_is_verified");
//                   localStorage.removeItem("nhs_firebase_key");
//                   localStorage.removeItem("nhs_reactive");
//                   localStorage.removeItem("nhs_vendor");
//             },
//             checkLogin(state,action){

//                   state.auth = true;
//                   state.nhs_access_token = action.payload.token;
//                   state.nhs_access_name = action.payload.name;
//                   state.nhs_access_email = action.payload.email;
//                   state.nhs_access_mobilenumber = action.payload.mobilenumber || "";
//                   state.nhs_is_verified = action.payload.is_verified.toString();
//                   state.firebase_key = action.payload.firebase_key;
//                   state.nhs_reactive = action.payload.reactive;
                  
//             },
//             verfiedMobileNumber(state,action){
//                   state.nhs_is_verified = 'true';
//                   localStorage.setItem('nhs_is_verified','true');
//             },
//             verfiedMobileNumberDemo(state,action){
//                   state.nhs_is_verified = 'true';
//                   localStorage.setItem('nhs_is_verified','true');
//             },
//             reactiveAccount(state,action){
//                   localStorage.removeItem("nhs_reactive");
//                   state.nhs_reactive = action.payload.reactive;
//             }
//       }
// })


// export const AuthActions = AuthSlice.actions;
// export default AuthSlice.reducer;


