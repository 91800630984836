
export const checkValidityChangeEmail=(new_email,email) =>{
      let isValid = true;
      let validationMessage = "";
      
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!new_email){
              
            isValid = false;
            validationMessage = "Please enter a new email address";
            return {isValid,validationMessage};
              
      }
      if(!re.test(String(new_email).toLowerCase()) || !new_email){
            isValid = false;
            validationMessage = "Please enter a valid email address";
            return {isValid,validationMessage};
      }

      if(new_email === email){
            isValid = false;
            validationMessage = "Your new email address must be different from your existing account email address.";
            return {isValid,validationMessage};
      }

      return {isValid,validationMessage};
}

