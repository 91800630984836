import React,{useEffect} from "react";
import { Link } from "react-router-dom";

const Terms = () => {

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
      },[])
          
      return (
            <main>
                  <section className="section__faq">
        
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content content__toc">
                                                <div className="content-box-wrapper mx-lg-auto">
                                                      <div className="content__header border-bottom text-center">
                                                            <h1 className="header__title fw-bold mb-0">Terms & Conditions</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}
                                                      <div className="content__body p-0">
                                                            <div className="accordion accordion__plus-to-x" id="accordionFAQ">
        
                                                                  {/* <!-- item 1 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading1">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse1" aria-expanded="true" aria-controls="faqCollapse1">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">INTRODUCTION</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse1" className="accordion-collapse collapse show" aria-labelledby="faqHeading1" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Tickets for Medics provide a Membership Service that is designed to discreetly help provide an audience for event organisers throughout the United Kingdom, to NHS Keyworkers or those who provide direct services to the NHS (Pharmacists, etc).
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          When membership services are provided to you it is in accordance with these Terms and Conditions and you will be contracting with Tickets for Medics, which is a trading name of Central Tickets Limited - a registered Company.&nbsp;&nbsp;(Co Reg No: 11263393) Registered Address 71-75 Shelton Street, London, WC2H 9JQ.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Tickets are provided to Tickets for Medics on a complimentary basis, who in turn charge an administration fee which covers our running costs and allows us to offer you these complimentary events to enjoy.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 2 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading2">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse2" aria-expanded="false" aria-controls="faqCollapse2">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">ACCEPTANCE AND INSTRUCTION</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse2" className="accordion-collapse collapse" aria-labelledby="faqHeading2" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <ul className="list-style-decimal mb-0">
                                                                                          <li>
                                                                                                When you join Tickets for Medics you will be entitled to the opportunity to attend a wide variety of cultural events on a regular basis if you are an NHS Healthcare worker or provide direct services to the NHS.  
                                                                                          </li>
                                                                                          <li>
                                                                                                The applications of those who apply using an NHS email address will be processed automatically.&nbsp;&nbsp;Those who do not have, or do not wish to use their NHS email, a manual verification process is in place to allow you to upload supporting documentation to verify your eligibility. 
                                                                                          </li>
                                                                                          <li>
                                                                                                As a member you understand that we do not sell tickets, you will instead pay an admin fee to be added to guest-lists that we manage for various events in London and other regions.
                                                                                          </li>
                                                                                          <li>
                                                                                                When you make a booking you agree to pay an administration fee to Tickets for Medics and in return, you will be added to our guest-list.&nbsp;&nbsp;You will not be purchasing a ticket.
                                                                                          </li>
                                                                                          <li>
                                                                                                The named member must be in attendance for all events booked.&nbsp;&nbsp;Passing along tickets to friends or family or selling tickets to third parties is strictly forbidden.
                                                                                          </li>
                                                                                    </ul>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 3 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading3">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse3" aria-expanded="false" aria-controls="faqCollapse3">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">MEMBERSHIP OBLIGATIONS</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse3" className="accordion-collapse collapse" aria-labelledby="faqHeading3" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          As a member you are required to direct all enquiries about an event to Tickets for Medics staff.&nbsp;&nbsp;Under no circumstance should any member attempt to make direct contact with an organiser or venue of any up-and-coming event.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          All communications sent from Tickets for Medics are intended for the member only and, should not be forwarded without prior written consent under any circumstances.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          On receipt of any seat at any event you must accept the seating as given without complaint and under no circumstance contact the Box Office or any of our representatives to attempt to secure different seats than the ones you are given.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Members are asked to commit to any booking made and to refrain from making repeated cancellations as permitted at 6.2
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Our service is only to be used by members and their accompanying guests and for private use only.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Members cannot use their account to book tickets for either business use or for any social groups whatsoever, without exception.&nbsp;&nbsp;It is strictly forbidden to book tickets and resell them to groups or individuals.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 4 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading4">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse4" aria-expanded="false" aria-controls="faqCollapse4">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">MEMBER AND GUEST CONDUCT</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse4" className="accordion-collapse collapse" aria-labelledby="faqHeading4" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          As a member you will access complimentary guest-list tickets to events that you may attend with a guest or guests of your choice.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Members and guests must not discuss complimentary tickets or Tickets for Medics in, or around the venue or any of the events or venues we work with, this includes postings on social media, in print, online media or any other public discussion forum and, under any circumstances; including but not limited to online news websites, newspapers, blogs, fan pages or discussion boards or review sites.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Member and guests must be willing to remain for the entire event and contribute with good appreciation to support a good event atmosphere.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Tickets for Medics operate a zero-tolerance policy for any kind of abusive, or unfriendly language towards our team, our representatives and the staff at the venues we work with.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          You and your guests agree as follows:
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          <ul>
                                                                                                <li>
                                                                                                      to be friendly and respectful towards all staff at the hosting venue and to co-operate politely at all times with Tickets for Medics staff
                                                                                                </li>
                                                                                                <li>
                                                                                                      be mindful that you will be attending the event as a brand ambassador for Tickets for Medics
                                                                                                </li>
                                                                                                <li>
                                                                                                      to represent Tickets for Medics and demonstrate appropriate behaviour at all times
                                                                                                </li>
                                                                                                <li>
                                                                                                      you should attend events in a smart-casual style and refrain from wearing vests, shorts or flip-flops.
                                                                                                </li>
                                                                                          </ul>
                                                                                          As a member, you should refrain from brining young children to any event unless the event is described as a family show suitable for children. 
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          If you wish to bring a child or teenage guest to an event you should check that the show is age appropriate before booking.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- item 5 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading5">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse5" aria-expanded="false" aria-controls="faqCollapse5">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">COVID SPECIFIC INFORMATION AND OTHER ILLNESS</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse5" className="accordion-collapse collapse" aria-labelledby="faqHeading5" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          As outlined in provision 9, If you choose to cancel tickets for an event you have booked, administration fees are non-refundable.&nbsp;&nbsp;All bookings are final and cannot be transferred to another date.&nbsp;&nbsp;In light of the recent pandemic, we do operate an exception to this policy for those who test positive for COVID-19.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          From the 1st April 2022, there are no longer restrictions relating to Covid-19 and we no longer accept lateral flow or PCR test results. 
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Provision 8 of these terms and conditions applies in the event of non-attendance however, if an inability to attend relates to genuine COVID-19 infection you should provide supporting documentation for the named member account holder.&nbsp;&nbsp;The evidence needs to meet the requirements of our terms and conditions as follows:
                                                                                          <br></br>
                                                                                          <br></br> 
                                                                                          A completed medical confirmation form, which can be downloaded <a href="https://tfm.centraltickets.co/TFM_COVID_Form.pdf" target="_blank" rel="noreferrer">here</a> which should be signed and stamped by the affected member’s GP.&nbsp;&nbsp;Any costs to obtain the supporting documentation from the GP should be paid by the member or guest and, the costs incurred are non-refundable.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          We may be able to accept other medical documents, provided they confirm the first date that treatment was sought and were medically unfit to attend the event.&nbsp;&nbsp;However, we will not be able to confirm that these are adequate for our needs until they are reviewed alongside your application.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          On receipt of the medical confirmation form and any additional supporting documents, Tickets for Medics will consider providing a credit to the relevant account on a discretionary basis only.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 6 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading6">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse6" aria-expanded="false" aria-controls="faqCollapse6">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">CHARITY DONATIONS</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse6" className="accordion-collapse collapse" aria-labelledby="faqHeading6" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          When you use our service we make a donation for every booking you make to <a href="https://nhscharitiestogether.co.uk/" target="_blank" rel="noreferrer">NHS Charities Together</a> (Registered Charity No 1186569).
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 7 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading7">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse7" aria-expanded="false" aria-controls="faqCollapse7">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">TICKET COLLECTION</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse7" className="accordion-collapse collapse" aria-labelledby="faqHeading7" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          The member account holder is responsible for collecting any event tickets and must not allow any other person to collect tickets either on behalf of the member or instead of the member.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          You are required to follow specific instructions for ticket collection for each listing and booking confirmation and:
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          It is important that the member reads the ticket collection information for each booking carefully and follow the instructions given.&nbsp;&nbsp;Each event has its own collection arrangements.&nbsp;&nbsp;You will be provided with details of both the time and place for collection on the event listing, booking confirmation email and ticket.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Members must collect the allocated tickets at least 30 minutes prior to the event beginning so that last minute sales can be processed, it is essential that you arrive at the time designated in the collection instructions printed on your ticket.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          If you fail to arrive at least 30 minutes prior to the start of the event your tickets may be re-allocated by the box office, and you may lose your seats.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          At the collection point you should preferably show the Box Office staff your PDF confirmation on your smart phone and, you should be prepared to show ID (such as a driver’s license or credit card) if requested to do so. 
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          Print confirmation will be acceptable however, we prefer you to show sight of your ticket allocation on your phone for environmental reasons.
                                                                                          <br></br>
                                                                                          <br></br>
                                                                                          If, for any reason the venue is unable to issue your event tickets for an event please accept the decision and make contact with Tickets for Medics staff who will liaise with the venue on your behalf.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 8 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading8">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse8" aria-expanded="false" aria-controls="faqCollapse8">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">NON-ATTENDANCE AND REFUNDS</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse8" className="accordion-collapse collapse" aria-labelledby="faqHeading8" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Only the account holder can log-in to the site and make bookings.&nbsp;&nbsp;You must not disclose your password or allow others to use your account to log into the Tickets for Medics website and make bookings.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          If for any reason you are unable to attend an event that you have booked you should contact Tickets for Medics at least 3 hours before the event begins to cancel the booking so that seats can be re-allocated to other members.&nbsp;&nbsp;Simply log into My Account, locate the event in Current Bookings and cancel or amend.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          If you choose to cancel tickets for an event you have booked, administration fees are non-refundable.&nbsp;&nbsp;All bookings are final and cannot be transferred to another date.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Save for provision 4 above in the event of illness or injury, if a member fails to attend an event and in doing so also fails to notify Tickets for Medics the member shall have their account withdrawn with immediate effect and without exception. 
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Tickets for Medics are given event tickets to provide an audience and, on rare occasions allocated tickets will be recalled.&nbsp;&nbsp;We do not guarantee any specific performance and cannot be held liable for your loss however, all admin fees will be refunded should a performance be cancelled or withdrawn.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We provide no warranty to provide alternative tickets or cover any costs incurred including travel to the event venue.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 9 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading9">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse9" aria-expanded="false" aria-controls="faqCollapse9">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">MEMBERSHIP SUSPENSION AND CANCELLATION</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse9" className="accordion-collapse collapse" aria-labelledby="faqHeading9" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Any infringements of these conditions may result in a temporary or permanent suspension from Tickets for Medics.&nbsp;&nbsp;Management reserve the right to refuse or suspend membership at any time.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Membership will be withdrawn, and your account permanently closed where a new member cancels any one of their first three bookings or fails to make at least one booking in a 12 month period.&nbsp;&nbsp;Your membership account will be permanently withdrawn with no exceptions.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          You agree not to leave any negative reviews online where suspension or cancellation action is taken as a result of these rules not being followed.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                
                                                                  {/* <!-- item 10 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading10">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse10" aria-expanded="false" aria-controls="faqCollapse10">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">PROHIBITED MEMBERSHIP LIABILITY</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse10" className="accordion-collapse collapse" aria-labelledby="faqHeading10" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Media or Journalist individuals who are found to be a member or who attempt to secure membership of Tickets for Medics whether found to have written any editorial of any kind shall have their membership withdrawn immediately upon discovery and shall be liable for costs shown at 8.4 and 8.5.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          All members, private and professional are prohibited from publishing any editorial, review or any other information included but not limited to editorial and/or reviews to be published on behalf of media outlets, on social media, in newspapers/magazines/blogs/radio this list is not exhaustive.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Any member of any media profession agrees that they are private members and expressly agree not to exploit any media coverage whatsoever, howsoever by becoming a member of Tickets for Medics.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Any member found to be in breach of 8.1, 8.2 and 8.3 shall be liable to refund Tickets for Medics for the full value of any tickets issued to their benefit at the face value of the ticket/s acquired and used.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          In the case of economic loss experienced by either Tickets for Medics or any event production company as a result of any breach of 8.1 to 8.3 Tickets for Medics may bring either a direct action or third-party action against any member found to be in breach.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 11 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading11">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse11" aria-expanded="false" aria-controls="faqCollapse11">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">BREACH OF TERMS AND CONDITIONS</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse11" className="accordion-collapse collapse" aria-labelledby="faqHeading11" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                    Any member found to be in breach of any term herein shall be liable to compensate Tickets for Medics for all damages caused because of the breach, including indirect or consequential damages.
                                                                                    <br/>
                                                                                    <br/>
                                                                                    At Tickets for Medics we believe in treating people fairly.&nbsp;&nbsp;If, for whatever reason, a member is unable to adhere to the terms and conditions by way of a minor breach we will investigate the situation and decide an appropriate action, advise any corrective measures that we decide and extend the member the opportunity to remedy the minor breach.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 12 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading12">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse12" aria-expanded="false" aria-controls="faqCollapse12">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">PRIVACY</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse12" className="accordion-collapse collapse" aria-labelledby="faqHeading12" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Our Privacy Policy explains how we collect, manage, process, secure, and store your private information and is included under the scope of this Agreement.&nbsp;&nbsp;You can access the Privacy Policy  <Link to="/privacy-policy" target="_blank" rel="noreferrer">here</Link>.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 13 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading13">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse13" aria-expanded="false" aria-controls="faqCollapse13">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">LIMITATION OF LIABILITY</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse13" className="accordion-collapse collapse" aria-labelledby="faqHeading13" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          You expressly understand and agree, save for personal injury that any claim against us shall be limited to the amount you paid, if any, for use of the service offered and engaged.&nbsp;&nbsp;Tickets for Medics will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you because of any changes, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Tickets for Medics membership is available for those aged 18 or above and is subject to approval.&nbsp;&nbsp;Tickets for Medics reserves the right to amend its terms and conditions, fee structure and referral scheme at any point, without notice.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
        
                                                                  {/* <!-- item 14 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading14">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse14" aria-expanded="false" aria-controls="faqCollapse14">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">GOVERNING LAW</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse14" className="accordion-collapse collapse" aria-labelledby="faqHeading14" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          You agree that the statutes and laws of England and Wales shall apply to all matters relating to the services provided by Tickets for Medics.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 15 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading15">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse15" aria-expanded="false" aria-controls="faqCollapse15">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">CHANGES TO THIS AGREEMENT</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse15" className="accordion-collapse collapse" aria-labelledby="faqHeading15" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We reserve the right to make changes to our terms and conditions from time to time without notice.&nbsp;&nbsp;You acknowledge and agree that it is your responsibility to review our Terms periodically.&nbsp;&nbsp;Your continued use of our services after such modifications will constitute acknowledgment and agreement of the modified Terms.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.accordion__faq --> */}
        
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
        
                                                      <div className="content__body content__body--notice">
                                                            <p className="p-notice fw-bold">
                                                                  BY ACCEPTING TICKETS FOR MEDICS OFFER OF MEMBERSHIP AND THE BENEFITS CONFERRED THROUGH MEMBERSHIP YOU AGREE TO ABIDE BY THESE TERMS AND CONDITIONS.
                                                            </p>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
        
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
        
                                          </div>
                                          {/* <!-- /.content --> */}
                                          
                                    </div>
                                    {/* <!-- .col-md-12 --> */}

                              </div>
                              {/* <!-- /.row --> */}

                        </div>
                        {/* <!-- /.container-fluid  --> */}

                  </section>
                  {/* <!-- /.section__ --> */}
          </main>
      )
}

export default Terms;
