import React,{useState, useEffect} from "react";
import classes from "./ReactiveForm.module.css";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { UiActions } from "../../Store/Ui/Ui";
import checkValidationReactiveForm from "./../../Shared/UtilityReactiveForm";
import { sendReactiveRequest } from "./../../Store/Auth/AuthActions";

const ReactiveForm = (props) => {

      const reactiveAccountFormRequest   = useSelector(state=>state.ui.reactiveAccountFormRequest)
      const dispatch = useDispatch();
      const [state,setState] = useState({
            fullname:{lable:"Name",value:"",name:"fullname",error:false},
            email:{lable:"Registered Account Email Address",value:"",name:"email",error:false},
            attendance:{lable:"Attendance",value:false,name:"attendance",error:false},
            cancellingbooking:{lable:"Cancelling a Booking",value:false,name:"cancellingbooking",error:false},
            Punctuality:{lable:"Punctuality",value:false,name:"Punctuality",error:false},
            Etiquette:{lable:"Etiquette",value:false,name:"Etiquette",error:false},
            TicketOffers:{lable:"Free Ticket Offers:",value:false,name:"TicketOffers",error:false}
      })

      const navigate = useNavigate();
      const token = localStorage.getItem("nhs_access_token");
      const nhs_reactive = localStorage.getItem("nhs_reactive");
      

      useEffect(()=>{

            const fullname  = localStorage.getItem("nhs_access_name") || "";
            const email     = localStorage.getItem("nhs_access_email") || "";
            // const fullname  = "";
            // const email     = "";

            setState(prevState=>{
                  return {
                        ...prevState,
                        fullname:{
                              ...prevState.fullname,
                              value:fullname
                        },
                        email:{
                              ...prevState.email,
                              value:email
                        }
                  }
            })

      },[])

      const changeValueHandler = (event)=>{
            event.preventDefault();
            const name= event.target.name;
            let value;
            if(name === "fullname" ||  name === "email"){
                  value = event.target.value;
            }
            else {
                  value = !(event.target.value === "true" ? true : false);
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{
                              ...prevState[name],
                              value:value
                        }
                  }
            })

      }

      const submitFormHandler = (event)=>{

            event.preventDefault()

            dispatch(UiActions.setReactiveAccountFormRequest({request:true}))
            const checkedObject     =       {};
            const requestAllow      =       [];

            for(let i in state){
                  
                  const checkValidation   =       checkValidationReactiveForm(state[i].value,i); 

                  checkedObject[i]        =       {...state[i],error:!checkValidation}

                  if(!checkValidation){

                        requestAllow.push(i); 
                  }
            }

            setState(prevState=>{
                  return {
                        ...checkedObject
                  }
            });
                
            if(requestAllow.length > 0) {
                  
                  var element = document.getElementById(requestAllow[0]+"error");
                  var headerOffset = 450;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
                  window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                  });
                  dispatch(UiActions.setReactiveAccountFormRequest({request:false}))
            }
            else {

                  const data = {
                        fullname:state.fullname.value,
                        email:state.email.value,
                        attendance:state.attendance.value,
                        cancellingbooking:state.cancellingbooking.value,
                        Punctuality:state.Punctuality.value,
                        Etiquette:state.Etiquette.value,
                        TicketOffers :state.TicketOffers.value,
                        reactive_token:token
                  }

                  dispatch(sendReactiveRequest({token:token,data:data,navigate:navigate}))
            }

      }

      if(nhs_reactive === "0" ||  !nhs_reactive)
      {
            return <Navigate to="/"/>
      }

      return (
            <>
                 <main>
                        <section className="section__join">

                              {/* <!--
                                    NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                              --> */}
                              <div className="container container-fluid container__block p-5">
                                          <div className="row">
                                                      <div className={["col-xs-12 col-sm-12 col-md-12"].join(" ")} >
                                                            <form  method="post" action="#">

                                                                  <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                        <h3>
                                                                              To unblock your account you'll be asked a short series of questions which will allow you to demonstrate that you have read and understood what is expected of you as one of our members.
                                                                        </h3>
                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} >
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <h3><p>1) &nbsp; Name</p></h3>
                                                                        </label>
                                                                        <input style={{"height": "47px"}} name="fullname" autoComplete="off" type="text" pattern=".{2,}"  className="form-control form-control-xl-lg" value={state.fullname.value} id="fullname" data-error="something doesn't look right" placeholder="" onChange={(event)=>changeValueHandler(event)} disabled={reactiveAccountFormRequest} />
                                                                        
                                                                        <div id="fullnameerror" className={classes.with_errors}>
                                                                              {state.fullname.error && "Please enter your name"}
                                                                        </div>
                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} >
                                                                        <hr/>
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <h3><p>2) &nbsp; Registered Account Email Address</p></h3>
                                                                        </label>
                                                                        <input style={{"height": "47px"}} name="email" className="form-control form-control-xl-lg" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" value={state.email.value} id="emailid" autoComplete="off" data-error="type your full email address in lowercase" placeholder="" onChange={(event)=>changeValueHandler(event)} disabled={reactiveAccountFormRequest}/>

                                                                        <div id="emailerror"  className={classes.with_errors}>
                                                                              {state.email.error && "Please enter your email address"}
                                                                        </div>

                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <hr/>
                                                                              <h3><p>3) &nbsp; Attendance</p></h3>
                                                                              <p className={classes.marginsmallleft}>Central Tickets members must always attend those events that they book, with no exceptions.</p>
                                                                              <p className={classes.marginsmallleft}>You are given access to these seats for a nominal booking fee in return for providing a service - filling a seat. If you do no attend, we fail to provide the service that we offer to event organisers and they may choose to use a different seat filling service in the future.</p>
                                                                              <p className={classes.marginsmallleft}>By clicking 'I accept' you agree to attend all events that you book with Central Tickets.*</p>
                                                                        </label>
                                                                        <div className={["input-group"].join(" ")}>
                                                                              <button className={["col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state.attendance.value ? classes.buttonClicked : classes.buttonNotClicked].join(" ")} onClick={changeValueHandler} name="attendance" value={state.attendance.value}>
                                                                                    click to accept
                                                                              </button>
                                                                             
                                                                        </div>
                                                                        
                                                                        <div className={classes.with_errors} id="attendanceerror">
                                                                              {state.attendance.error && "You must agree to the Terms & Conditions" }
                                                                        </div>
                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <hr/>
                                                                              <h3>
                                                                                    <p>4) &nbsp; Cancelling a Booking</p>
                                                                              </h3>
                                                                              <p className={classes.marginsmallleft}>We understand that you'll need to cancel from time to time, these things happen. Central Tickets members can cancel up to 3 bookings per year and continue to be a member.</p>
                                                                              <p className={classes.marginsmallleft}>The correct process to do this is to visit our website and use the contact form to inform us that you can no longer attend. You must give us at least 3 hours notice so that we can reallocate the tickets to someone else and fill the seat for the event organiser.</p>
                                                                              <p className={classes.marginsmallleft}>By clicking 'I agree' you are confirming that you understand how many cancellations you can make each year and how to make them.*</p>
                                                                        </label>
                                                                        <div className="checkclass">
                                                                              <div className={[classes.inputGroup,classes.marginsmallleft].join(" ")}>
                                                                              
                                                                                    <button className={["col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state.cancellingbooking.value ? classes.buttonClicked : classes.buttonNotClicked].join(" ")} onClick={changeValueHandler} name="cancellingbooking" value={state.cancellingbooking.value}>
                                                                                          click to accept
                                                                                    </button>
                                                                                    
                                                                                    {/* <label style={{"textAlign": "center"}} htmlFor="cancellingbooking1">click to accept</label> */}
                                                                              </div>
                                                                              
                                                                              <div className={classes.with_errors} id="cancellingbookingerror">
                                                                                    {state.cancellingbooking.error && "You must agree to the Terms & Conditions"}
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <hr/>
                                                                              <h3><p>5) &nbsp; Punctuality:</p></h3>
                                                                              <p className={classes.marginsmallleft}>Collecting tickets on time is very important. In the last 30 minutes before the show begins, the Box Office are busy dealing with customers who have purchased full price tickets. That's why we ask you to collect at least 30 minutes in advance.</p>
                                                                              <p className={classes.marginsmallleft}>By clickiing 'I accept' you confirm that you have understood that you must collect tickets at least 30 minutes in advance.*</p>
                                                                        </label>
                                                                        <div className="checkclass">
                                                                              <div className={[classes.inputGroup,classes.marginsmallleft].join(" ")}>
                                                                                    <input value="true" name="Punctuality" id="Punctuality1" type="hidden" checked={state.Punctuality.value} onChange={(event)=>changeValueHandler(event)}/>
                                                                                    <button className={["col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state.Punctuality.value ? classes.buttonClicked : classes.buttonNotClicked].join(" ")} onClick={changeValueHandler} name="Punctuality" value={state.Punctuality.value}>
                                                                                          click to accept
                                                                                    </button>
                                                                                    {/* <label style={{"textAlign": "center"}} htmlFor="Punctuality1"> click to accept</label> */}
                                                                              </div>
                                                                              <div className={classes.with_errors} id="Punctualityerror">
                                                                                    {state.Punctuality.error && "You must agree to the Terms & Conditions"}
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <hr/>
                                                                              <h3>
                                                                                    <p>6) &nbsp; Etiquette:</p>
                                                                              </h3>
                                                                              <p className={classes.marginsmallleft}>Event organisers give you the opportunity to attend their events at a low cost on the condition that you accept the seats you are given without complaint and do not make a fuss or be rude to their staff.</p>
                                                                              <p className={classes.marginsmallleft}>Central Tickets members are not paying guests of the venue. You are a member of special group who gets access to these perks in return for providing a service.</p>
                                                                              <p className={classes.marginsmallleft}>Your role is to be discreet, friendly and grateful for whatever seats you are given and to enjoy the show with your guests.</p>
                                                                              <p className={classes.marginsmallleft}>By clicking "I accept" you are confirming that you will not ask for different seats and that you will be friendly and pleasant to all staff at the venues at all times*</p>
                                                                        </label>
                                                                        <div className="checkclass">

                                                                              <div className={[classes.inputGroup,classes.marginsmallleft].join(" ")}>
                                                                                    <button className={["col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state.Etiquette.value ? classes.buttonClicked : classes.buttonNotClicked].join(" ")} onClick={changeValueHandler} name="Etiquette" value={state.Etiquette.value}>
                                                                                          click to accept
                                                                                    </button>
                                                                                    {/* <label style={{"textAlign": "center"}} htmlFor="Etiquette1">click to accept</label> */}
                                                                              </div>
                                                                              <div className={classes.with_errors} id="Etiquetteerror">
                                                                                    {state.Etiquette.error && "You must agree to the Terms & Conditions"}
                                                                              </div>

                                                                        </div>
                                                                  </div>
                                                                  <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                                        <label htmlFor="inputName" className="control-label">
                                                                              <hr/>
                                                                              <h3><p>7) &nbsp; Free Ticket Offers:</p></h3>
                                                                              <p className={classes.marginsmallleft}>
                                                                                    We regularly waive the admin fee on some events to provide free tickets to our members. The only condition of these free tickets is that you always attend and do not cancel them.
                                                                              </p>
                                                                              <p className={classes.marginsmallleft}>
                                                                                    We are offering these tickets free of admin fee in return for you providing a service. If you cancel or do not attend after booking these tickets after receiving this warning, your account will be permanently closed immediately.
                                                                              </p>
                                                                              <p className={classes.marginsmallleft}>
                                                                                    Please always bear in mind that event organisers are relying upon us and we are relying upon you to provide a service. By clicking "I accept" you agree to always attend and never cancel any tickets that have been made free of charge.
                                                                              </p>
                                                                        </label>
                                                                        <div className="checkclass">
                                                                              <div className={[classes.inputGroup,classes.marginsmallleft].join(" ")}>
                                                                                    
                                                                                    <button className={["col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state.TicketOffers.value ? classes.buttonClicked : classes.buttonNotClicked].join(" ")} onClick={changeValueHandler} name="TicketOffers" value={state.TicketOffers.value}>
                                                                                          click to accept
                                                                                    </button>
                                                                                    {/* <label style={{"textAlign": "center"}} htmlFor="TicketOffers1">click to accept</label> */}
                                                                              </div>

                                                                              
                                                                              <div className={classes.with_errors} id="TicketOfferserror">
                                                                                    {state.TicketOffers.error && "You must agree to the Terms & Conditions"}
                                                                              </div>
                                                                        </div>
                                                                        <div className={["form-group","col-md-8 col-sm-8 col-xs-8"].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                                              <br/><br/>
                                                                              <button type="submit" className={["col-sm-12 col-md-8 btn btn-xl-lg btn-primary fw-bold mt-2"].join(" ")} onClick={submitFormHandler} disabled={reactiveAccountFormRequest}>
                                                                                    Submit {reactiveAccountFormRequest ===true && <i style={{"marginLeft":"10px"}} className="fa fa-spinner fa-spin"></i>}
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </form>
                                                      </div>
                                          </div>
                              </div>
                        </section>
                  </main>
            </>
            
      );
};

export default ReactiveForm;
