import React,{ useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Transition } from 'react-transition-group';

import BookingConfirmation from "../BookingModule/Steps/BookingConfirmation";
import PastSingleComponent from "./PastBookings/PastSingleComponent";
import CurrentSingleComponent from "./CurrentBookings/CurrentSingleComponent";
import DummyComponent from "./DummyComponent/DummyComponent";
import CancleBookingModule from "./CancleBooking/CancleBookingModule"

import { fetchCurrentBookingsData } from "../../../Store/Bookings/BookingActions";


const BookingHistory = () => {

      const [state, setState] = useState(false);
      const token = localStorage.getItem("nhs_access_token");
      const bookings = useSelector(state => state.bookings)
      const dispatch = useDispatch()
      let navigate = useNavigate();
      const {bookingId}   =     useParams();
      useEffect(()=>{
           
            window.scrollTo({top: 0, behavior: 'smooth'});
            if(bookingId){
                  setState(true)
            }
           
            if(token) {
                  dispatch(fetchCurrentBookingsData({data:{},token:token}));
            }
      },[])
      
      const closeBookingHandler =()=>{
            setState(false)
            return navigate("/booking-history");
      }

      return (
            
            <React.Fragment>
                  <Transition in={state} timeout={500} mountOnEnter unmountOnExit>
                        {status => {
                              return (
                                    <React.Fragment>
                                    <div id="modalBookTickets" className={["modal modal__book-tickets fade show",status === "entering" && "modalOpen", status === "exiting" && "modalClosed"].join(" ")} style={{"display": "block"}}>
                                          <div className="modal-dialog">
                                                <div className="modal-content">
                                                      <div className="modal-body p-0">
                                                            <button type="button" style={{"zIndex" : "1002"}} className="btn btn-close btn-close__absolute tfm-modal__toggle p-0 d-none d-md-block" 
                                                                        onClick={closeBookingHandler}>
                                                            </button>
                                                            <div className="content__book-tickets mx-md-auto w-100">
                                                                  <div className='Booking-module-Loader-wraper'>
                                                                        <BookingConfirmation bookingId={bookingId} bookingHistory closeBookingHandler={closeBookingHandler} />
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.content__book-tickets --> */}
                                                                  
                                                      </div>
                                                      {/* <!-- /.modal-body --> */}
                                                </div>
                                                {/* <!-- /.modal-content --> */}
                                          </div>
                                          {/* <!-- /.modal-dialog --> */}
                                          <div className="tfm-modal-overlay tfm-modal__close">
                                          </div>
                                    </div>
                                    <div className="modal-backdrop fade show"></div>
                                    </React.Fragment>
                              )}
                        }
                  </Transition>
                  <main>
                        <section className="section__booking-history">

                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                              <div className="container-fluid container__block">
                                    <div className="row">
                                          <div className="col-md-12">

                                                {/* <!-- CURRENT BOOKINGS --> */}
                                                <div className="content content__booking-history">
                                                      <div className="content__header content__header--current-bookings border-bottom d-xl-flex align-items-xxl-end">
                                                            <h1 className="header__title mb-0">Current Bookings</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}

                                                      <div className="content__body pb-0">
                                                            <ul className="list__booking-history list-unstyled mb-0">
                                                                  {!bookings.reqresponse &&
                                                                  <React.Fragment>
                                                                        <DummyComponent type="current"/>
                                                                        <DummyComponent type="current"/>
                                                                  </React.Fragment>}
                                                                  {(bookings.reqresponse && bookings.currentBookings.length >0) && bookings.currentBookings.map(el=>{
                                                                        const date = new Date(el.start_date);
                                                                        let time = ""
                                                                        if(el.start_time){
                                                                              const timeArr= el.start_time.split(":");
                                                                              time = (timeArr[0] > 12 ? timeArr[0] - 12 :timeArr[0]) +":"+ timeArr[1] +" "+(timeArr[0] > 12 ? "PM" :"AM")
                                                                        }

                                                                        return <CurrentSingleComponent
                                                                              key={el.booking_id}
                                                                              event_image={el.event_image}
                                                                              event_name={el.event_name}
                                                                              location={el.Location}
                                                                              collection_instruction={el.collection_instruction}
                                                                              date={date}
                                                                              time={time}
                                                                              start_date={el.start_date}
                                                                              start_time={el.start_time}
                                                                              eventType ={el.group_availablity}
                                                                              tickets={el.tickets}
                                                                              booking_id={el.booking_id}
                                                                              BookingRef={el.BookingRef}
                                                                              web_booking_id={el.web_booking_id}
                                                                              show_id ={el.show_id}
                                                                              tickets_type={el.tickets_type}
                                                                        />
                                                                  })}
                                                                  {(bookings.reqresponse && bookings.currentBookings.length === 0) &&
                                                                        
                                                                        <li className="list-item">
                                                                              
                                                                              <div className="event d-flex flex-column flex-md-row align-items-md-stretch align-items-center-start">
                                                                                    <h1 className="event__name no__bookings__found" style={{"color":"var(--bs-blue)"}}>No Current Bookings</h1>
                                                                              </div>
                                                                        </li>

                                                                  }
                                                                 
                                                            </ul>
                                                            {/* <!-- /.list__booking-history --> */}

                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                                {/* <!-- END CURRENT BOOKINGS --> */}

                                                {/* <!-- PAST BOOKINGS --> */}
                                                <div className="content content__booking-history border-top">
                                                      <div className="content__header content__header--past-bookings border-bottom d-xl-flex align-items-xxl-end">
                                                            <h1 className="header__title mb-0">Past Bookings</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}
                                                      <div className="content__body pb-0">
                                                            <ul className="list__booking-history list-unstyled mb-0">
                                                                  {(bookings.reqresponse && bookings.pastBookings.length > 0)&& bookings.pastBookings.map(el=>{
                                                                        const date = new Date(el.start_date);
                                                                        let time = ""
                                                                        if(el.start_time){
                                                                              const timeArr= el.start_time.split(":");
                                                                              time = (timeArr[0] > 12 ? timeArr[0] - 12 :timeArr[0]) +":"+ timeArr[1] +" "+(timeArr[0] > 12 ? "PM" :"AM")
                                                                              
                                                                        }

                                                                        return <PastSingleComponent 
                                                                              key={el.booking_id}
                                                                              booking_id={el.booking_id}
                                                                              event_image={el.event_image}
                                                                              blur_event_image={el.blur_event_image}
                                                                              event_name={el.event_name}
                                                                              location={el.Location}
                                                                              date={date}
                                                                              time={time}
                                                                              is_feedback_left={el.is_feedback_left}
                                                                              tickets={el.tickets}
                                                                        />;
                                                                  })}
                                                                  { !bookings.reqresponse &&
                                                                        <React.Fragment>
                                                                              <DummyComponent type="past"/>
                                                                              <DummyComponent type="past"/>
                                                                        </React.Fragment>
                                                                  }
                                                                  {(bookings.reqresponse && bookings.pastBookings.length === 0) &&
                                                                        
                                                                        <li className="list-item">
                                                                              <div className="event d-flex flex-column flex-md-row align-items-md-stretch align-items-center-start">
                                                                                    <h1 className="event__name no__bookings__found" style={{"color":"var(--bs-blue)"}}>No Past Bookings</h1>
                                                                              </div>
                                                                        </li>

                                                                  }

                                                            </ul>
                                                            {/* <!-- /.list__booking-history --> */}

                                                      </div>
                                                      {/* <!-- /.content__body --> */}

                                                </div>
                                                {/* <!-- /.content --> */}
                                                {/* <!-- END PAST BOOKINGS --> */}

                                          </div>
                                          {/* <!-- .col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid  --> */}

                              {/* <!--
                              - Amend Booking modal below
                              --> */}
                              <div className="container-fluid container__block">
                                    <div className="row">
                                          <div className="col-md-12">
                                                
                                                <Transition in={bookings.cancleBookings.isCancling} timeout={500} mountOnEnter unmountOnExit>
                                                      {status => {
                                                            return (
                                                                  <CancleBookingModule show={status}/>
                                                            )}
                                                      }
                                                </Transition>
                                               
                                                {/* <!-- /.tfm-modal --> */}
                                          </div>
                                          {/* <!-- .col-lg-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid  --> */}

                        </section>
                        {/* <!-- /.section__ --> */}
                  </main>
            </React.Fragment>

      )
}

export default BookingHistory;
