import React,{ useState, useEffect } from 'react';

import TfmLogoColour from "./../assests/images/TFM-logo-colour.svg";
import IconMenuBlack from "./../assests/images/icon-menu-black.svg";

import LoginModal from './layoutcomponents/LoginModal';

import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { sendLogoutRequest } from "./../Store/Auth/AuthActions";

const Header = () => {

      const auth = useSelector(state=>state.auth);
      const token = localStorage.getItem("nhs_access_token");

      const [hiddenMenu,setHiddenMenu] = useState(false)
      const dispatch = useDispatch()
      const location = useLocation();
        
      const navigate = useNavigate();
     
      useEffect(()=>{

            if(hiddenMenu){
                  setHiddenMenu(false)
            }
      },[location.pathname])

      const showHiddenMenuHandler = ()=>{
            setHiddenMenu(!hiddenMenu);
      }


      // const navForCurrentListingHandler = ()=>{
      //       navigate("/currentListing");
      // }

      const handleLogoutHandler = (event)=> {
            
            event.preventDefault();
            setHiddenMenu(false);
            const token= localStorage.getItem('nhs_access_token');

            dispatch(sendLogoutRequest({token:token,data:{},navigate:navigate}));
      }

      const hiddenMenuHandler = ()=>{
            setHiddenMenu(false)
      }
      
      const faqHandler = (e)=>{
            e.preventDefault();
            // console.log("faq")
            hiddenMenuHandler();
            window.location = "/faq";
      }

     
      return (

            <header className="main-header d-flex flex-column align-items-center position-fixed w-100">
                  <div className="container-fluid pe-0">
                        <nav className="navbar main-header__navbar navbar-expand-xl align-items-center py-0">
                              <NavLink className="navbar-brand p-0 me-auto" to="/">
                                    <img className="navbar-brand__logo" src={TfmLogoColour} alt="Tickets for Medics logo"/>
                              </NavLink>
                                    {(!auth.auth || !token) && 
                                    <button className="btn navbar-toggler__custom d-xl-none navbar-toggler" type="button" aria-label="Toggle navigation" onClick={showHiddenMenuHandler}>
                                          <span className="btn__icon">
                                                <img className="icon__menu" src={IconMenuBlack} alt=""/>
                                          </span>
                                    </button>}
                                    <div className={["main-header__main-menu",hiddenMenu ? "show":" "].join(" ")} id="navbarMainMenu">
                                          <ul className="navbar-nav mb-0">
                                                {(!auth.auth || !token) && 
                                                <li className="nav-item nav-item__join">
                                                      <NavLink className="nav-link" to="/join" onClick={hiddenMenuHandler}>Join</NavLink>
                                                      {/* <a className="nav-link active" href="#">Join</a>  for active class while on join page*/ }
                                                </li>}
                                                {(auth.auth || token) && 
                                                <li className="nav-item nav-item__contact-us">
                                                      <NavLink className="nav-link" to="/currentListing" onClick={hiddenMenuHandler}>Current Listings</NavLink>
                                                </li>}

                                                <li className="nav-item nav-item__social-impact webOnly">
                                                      <NavLink className="nav-link" to="/socialimpact" onClick={hiddenMenuHandler}>Social Impact</NavLink>
                                                </li>
                                                <li className="nav-item nav-item__faq webOnly">
                                                      <a className={["nav-link",location.pathname.includes("faq") && "active"].join(" ")} href="/" rel='noreferrer' onClick={faqHandler}>FAQ</a>
                                                </li>
                                                <li className="nav-item nav-item__contact-us webOnly">
                                                      <NavLink className="nav-link" to="/contactus" onClick={hiddenMenuHandler}>Contact Us</NavLink>
                                                </li>
                                                
                                                {(!auth.auth || !token) && 
                                                <React.Fragment>
                                                      <li className="nav-item nav-item__login-button d-xl-none">
                                                            <NavLink className="nav-link" to="/login" onClick={hiddenMenuHandler}>Login</NavLink>
                                                      </li>
                                                      <LoginModal hiddenMenu={hiddenMenu}/>
                                                </React.Fragment>
                                                }
                                                
                                          </ul>
                                    </div>
                                    {/* <!-- / #navbarMainMenu --> */}

                                    {(auth.auth && token)&&
                                    <React.Fragment>
                                    {/* <h5 className="page-name fw-bold mb-0 d-none d-xl-block" onClick={navForCurrentListingHandler}>Current Listings</h5> */}
                                    <button className="nav-link btn btn__my-account navbar-toggler__custom slideup__toggler d-flex justify-content-center" type="button" aria-label="Toggle navigation" onClick={showHiddenMenuHandler}>
                                          <span className="btn__text d-none d-xl-block">My Account</span>
                                          <span className="btn__icon d-xl-none">
                                                <img className="icon__menu" src={IconMenuBlack} alt=""/>
                                          </span>
                                    </button>
                                    <div className={["slideup-dropdown slideup-dropdown__my-account",hiddenMenu  ? "show":""].join(" ")}>
                                          <ul className="nav">
                                                <li className="nav-item">
                                                      <NavLink className="nav-link" to="/booking-history" onClick={hiddenMenuHandler}>My Bookings</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                      <NavLink className="nav-link" to="/settings"  onClick={hiddenMenuHandler}>Account Settings</NavLink>
                                                </li>
                                                <li className="nav-item mobileOnly">
                                                      <NavLink className="nav-link" to="/currentListing"  onClick={hiddenMenuHandler}>Current Listings</NavLink>
                                                </li>
                                                <li className="nav-item mobileOnly">
                                                      <NavLink className="nav-link" to="/socialimpact"  onClick={hiddenMenuHandler}>Social Impact</NavLink>
                                                </li>
                                                <li className="nav-item mobileOnly">
                                                      <a className={["nav-link",location.pathname.includes("faq") && "active"].join(" ")} href="/" rel='noreferrer' onClick={faqHandler}>FAQ</a>
                                                </li>
                                                <li className="nav-item mobileOnly">
                                                      <NavLink className="nav-link" to="/contactus" onClick={hiddenMenuHandler}>Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item"> 
                                                      <a className="nav-link nav-link__log-out" href='/' rel='noreferrer' onClick={handleLogoutHandler}>
                                                            <span className="btn__text">Log Out</span>
                                                      </a>
                                                </li>
                                          </ul>
                                    </div>
                                    </React.Fragment>}
                              </nav>
                  </div>
                  {/* <!-- /.container-fluid --> */}
            </header>
      )
}

export default Header;
