import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      alert:{
            model:false,message:'',type:{value:"alert",status:false}
      },
      secure3D:false,
      spinner:"",
      timer:null,
      changePasswordRequest:false,
      resetPasswordRequest:false,
      changeEmailRequest:false,
      contactUsRequest:false,
      loginRequest:false,
      forgotPasswordRequest:false,
      joinRequest:false,
      altJoinRequest:false,
      waitingListRequest:false,
      accessNeedsRequest:false,
      reactiveAccountRequest:false,
      reactiveAccountFormRequest:false,
      otpSubmitRequest:false,
      sendOtpRequest:false,
      sendDownloadTicketRequest:null,
      sendCollectionInstruction : null,
      selectedOptions:false

}
const UiSlice = createSlice({
      name:'ui',
      initialState:initialState,
      reducers:{
            setAlert(state,action) {
                  state.alert.message=action.payload.message;
                  state.alert.model=action.payload.model;
                  state.alert.type= action.payload.type || {value:"alert",status:false};
            },
            setSpinner(state,action){
                  state.spinner = action.payload.status;
            },
            clearUiSpinner(state,action){
                  state.spinner ="";
            },
            setSecure3D(state,action){
                  state.secure3D = true;
            },
            clearSecure3D(state,action){
                  state.secure3D = false;
            },
            setTimer(state,action){
                  state.timer = "start";
            },
            clearTimer(state,action){
                  state.timer =null;
            },
            setChangePasswordRequest(state,action){
                  state.changePasswordRequest = action.payload.request;
            },
            setChangeEmailRequest(state,action){
                  state.changeEmailRequest = action.payload.request;
            },
            setContactUsRequest(state,action){
                  state.contactUsRequest = action.payload.request;
            },
            setLoginRequest(state,action){
                  state.loginRequest = action.payload.request;
            },
            setForgotPasswordRequest(state,action){
                  state.forgotPasswordRequest = action.payload.request;
            },
            setResetPasswordRequest(state,action){
                  state.resetPasswordRequest = action.payload.request;
            },
            setJoinRequest(state,action){
                  state.joinRequest = action.payload.request;
            },
            setAltJoinRequest(state,action){
                  state.altJoinRequest = action.payload.request;
            },
            setWaitingListRequest(state,action){
                  state.waitingListRequest = action.payload.request;
            },
            setAccessNeedsRequest(state,action){
                  state.accessNeedsRequest = action.payload.request;
            },
            setReactiveAccountRequest(state,action){
                  state.reactiveAccountRequest = action.payload.request;
            },
            setReactiveAccountFormRequest(state,action){
                  state.reactiveAccountFormRequest = action.payload.request;
            },
            setOtpSubmitRequest(state,action){
                  state.otpSubmitRequest = action.payload.request;
            },
            setOtpRequest(state,action){
                  state.sendOtpRequest = action.payload.request;
            },
            setDownloadTIcketRequest(state,action){
                  state.sendDownloadTicketRequest = action.payload.request;
            },
            setCollectionInstructiontRequest(state,action){
                  state.sendCollectionInstruction = action.payload.request;
            },
            confirmMessage(state,action){
                  state.alert.type = action.payload.type
            },
            setSelectedOptionsRequest(state,action){
                  state.selectedOptions = action.payload.request;
            },
            reset: ()=> initialState
      }
})

export const UiActions = UiSlice.actions;
export default UiSlice.reducer;










//--------------------------------------------------------------------------//
// ---------------------old Slice without reset options----------------------
//--------------------------------------------------------------------------//

// import { createSlice } from "@reduxjs/toolkit";

// const UiSlice = createSlice({
//       name:'ui',
//       initialState:{
//             alert:{
//                   model:false,message:'',type:{value:"alert",status:false}
//             },
//             secure3D:false,
//             spinner:"",
//             timer:null,
//             changePasswordRequest:false,
//             resetPasswordRequest:false,
//             changeEmailRequest:false,
//             contactUsRequest:false,
//             loginRequest:false,
//             forgotPasswordRequest:false,
//             joinRequest:false,
//             altJoinRequest:false,
//             waitingListRequest:false,
//             accessNeedsRequest:false,
//             reactiveAccountRequest:false,
//             reactiveAccountFormRequest:false,

//       },
//       reducers:{
//             setAlert(state,action) {
//                   state.alert.message=action.payload.message;
//                   state.alert.model=action.payload.model;
//                   state.alert.type= action.payload.type || {value:"alert",status:false};
//             },
//             setSpinner(state,action){
//                   state.spinner = action.payload.status;
//             },
//             clearUiSpinner(state,action){
//                   state.spinner ="";
//             },
//             setSecure3D(state,action){
//                   state.secure3D = true;
//             },
//             setTimer(state,action){
//                   state.timer = "start";
//             },
//             clearTimer(state,action){
//                   state.timer =null;
//             },
//             setChangePasswordRequest(state,action){
//                   state.changePasswordRequest = action.payload.request;
//             },
//             setChangeEmailRequest(state,action){
//                   state.changeEmailRequest = action.payload.request;
//             },
//             setContactUsRequest(state,action){
//                   state.contactUsRequest = action.payload.request;
//             },
//             setLoginRequest(state,action){
//                   state.loginRequest = action.payload.request;
//             },
//             setForgotPasswordRequest(state,action){
//                   state.forgotPasswordRequest = action.payload.request;
//             },
//             setResetPasswordRequest(state,action){
//                   state.resetPasswordRequest = action.payload.request;
//             },
//             setJoinRequest(state,action){
//                   state.joinRequest = action.payload.request;
//             },
//             setAltJoinRequest(state,action){
//                   state.altJoinRequest = action.payload.request;
//             },
//             setWaitingListRequest(state,action){
//                   state.waitingListRequest = action.payload.request;
//             },
//             setAccessNeedsRequest(state,action){
//                   state.accessNeedsRequest = action.payload.request;
//             },
//             setReactiveAccountRequest(state,action){
//                   state.reactiveAccountRequest = action.payload.request;
//             },
//             setReactiveAccountFormRequest(state,action){
//                   state.reactiveAccountFormRequest = action.payload.request;
//             },
//             confirmMessage(state,action){
//                   state.alert.type = action.payload.type
//             },
            
//       }
// })

// export const UiActions = UiSlice.actions;
// export default UiSlice.reducer;