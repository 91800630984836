
export const checkValidityJoin=(value, type,email) =>{
      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }

      if(type === "fname"){

            validationMessage = "Please enter a valid first name";
            if(value.trim() ==="")
            {
                  isValid = false;
                  validationMessage = "Please enter your first name.";
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = "Please enter a valid first name";
            }
      }

      if(type==="lname"){
            validationMessage = "Please enter a valid last name";
            if(value.trim() === "")
            {
                  isValid = false;
                  validationMessage = "Please enter your last name.";
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = "Please enter a valid last name";
            }
      }

      if(type === "password"){

            validationMessage = "Please enter a valid password, a minimum of 6 characters";
            if(value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please choose a password to login to our site";
            }
            else if(value.length < 6)
            {
                  isValid=false;
                  validationMessage = "Please enter a valid password, a minimum of 6 characters";
            }
      }

      if(type==="nhsTrust"){

            validationMessage = "Please select NHS trust you work for.";
            if(value.trim()==='0' || value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please select NHS trust you work for.";
            }
      }
      
      if(type === "organisation"){

            validationMessage = "Please select Organisation";
            if(value.trim()==='0' || value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please select Organisation";
            }
      }

      if(type === "mobile"){

            validationMessage = "Please enter a valid UK mobile number";
            let isnum = /^\d+$/.test(value);
            if(value.trim()=== "07")
            {
                  isValid =false;
                  validationMessage = "Please enter a valid UK mobile number";
                  return {isValid,validationMessage};
            }

            if(value.length !== 11 || !isnum )
            {
                  isValid =false;
                  validationMessage = "Please enter a valid UK mobile number";
                  return {isValid,validationMessage};
            }
             
      }
      
      if(type==="comments"){
            validationMessage = "Please share with us some information regarding your Keyworker status.";
            if(value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please share with us some information regarding your Keyworker status.";
            }
      }

      if(type==="image"){
            validationMessage = "Please upload documentation that verifies your Keyworker status.";
            if(value==='')
            {
                  isValid = false;
                  validationMessage = "Please upload documentation that verifies your Keyworker status.";
            }
      }

      if(type==="role"){

            validationMessage = "Please enter your role";
            if(value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please enter your role";

            } 
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = "Please enter your role";
                  // validationMessage = "This field cannot be left blank";
            }
      }

      if(type==="city"){

            validationMessage = "Please select your nearest city";
            if(value.trim()==='' || value.trim() === "0")
            {
                  isValid = false;
                  validationMessage = "Please select your nearest city";
            }
      }

      if(type === "nhsEmail"){

            validationMessage = "Please enter a valid NHS email address";
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage = "Please enter a valid NHS email address";
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = "Please enter a valid email address";
            }
            else if((value.indexOf("@nhs.net") === -1) && (value.indexOf("nhs.uk") === -1) && (value.indexOf("nhs.scot") === -1)){
                  isValid = false;
                  validationMessage = "Please enter a valid NHS email address";
            }
      }

      if(type === "email"){

            validationMessage = "Please enter a valid email address";
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage = "Please enter your email address.";
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = "Please enter a valid email address";
            }
      }

      if(type === "emailConfirm"){

            validationMessage = "That’s not a match. Please check the email addresses entered.";
            if(!value){
                  isValid = false;
                  validationMessage = "That’s not a match. Please check the email addresses entered.";
            }

            if(value !== email){

                  isValid = false;
                  validationMessage = "That’s not a match. Please check the email addresses entered.";
            }
      }


      if(type === "nhsEmailConfirm"){

            validationMessage = "That’s not a match. Please check the email addresses entered.";
            if(!value){
                  isValid = false;
                  validationMessage = "That’s not a match. Please check the email addresses entered.";
            }

            if(value !== email){

                  isValid = false;
                  validationMessage = "That’s not a match. Please check the email addresses entered.";
            }
      }

      if(type==="checkReceiveAlerts"){
            validationMessage = "Please confirm that you provide your consent to receive a show alerts from Tickets for Medics";
            if(value === false){

                  isValid=false;
                  validationMessage = "Please confirm that you provide your consent to receive a show alerts from Tickets for Medics";
            }
      }

      if(type==="checkAgreeTOS"){
            validationMessage = "Please confirm that you agree to the Terms and Conditions of membership";
            if(value === false){

                  isValid=false;
                  validationMessage = "Please confirm that you agree to the Terms and Conditions of membership";
            }
      }

      return {isValid,validationMessage};
}

