import React,{useEffect} from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {


      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
      },[])
          
      return (
            <main>
                  <section className="section__faq">
        
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content content__privacy-policy">
                                                <div className="content-box-wrapper mx-lg-auto">
                                                      <div className="content__header border-bottom text-center">
                                                            <h1 className="header__title fw-bold mb-0">Privacy Policy</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}
                                                      <div className="content__body p-0">
                                                            <div className="accordion accordion__plus-to-x" id="accordionFAQ">
            
                                                                  {/* <!-- item 1 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading1">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse1" aria-expanded="true" aria-controls="faqCollapse1">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Tickets for Medics – Privacy Policy</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse1" className="accordion-collapse collapse show" aria-labelledby="faqHeading1" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Tickets for Medics (“TFM”, “we” or “us”) are committed to protecting your privacy, including online, and in the transparent use of any information you give us.&nbsp;&nbsp;This Privacy Policy (the “Policy”) (together with our terms of use (the <Link to="/terms">"Terms"</Link>) and any other documents referred to on it) sets out what information we may hold about you if you provide it, and how that data will be used.&nbsp;&nbsp;No information will be shared more widely than is set out below unless it is required by law.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Please read this policy carefully to understand our practices regarding your personal data and how we will treat it.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 2 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading2">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse2" aria-expanded="false" aria-controls="faqCollapse2">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Who are we?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse2" className="accordion-collapse collapse" aria-labelledby="faqHeading2" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                              <p>
                                                                                    Tickets for Medics is the NHS division of Central Tickets, a limited company, will be what is known as the “data controller” of the personal data you provide to us.&nbsp;&nbsp;We are registered with the Information Commissioner’s Office (ICO) under registration number ZA256756.&nbsp;&nbsp;You may contact us at the following address:
                                                                              </p>
                                                                              <p>TFM c/o Central Tickets Limited
                                                                                    <br/>71-75 Shelton Street
                                                                                    <br/> Covent Garden
                                                                                    <br/>London WC2H 9JQ
                                                                                    <br/><a className="text-decoration-none" href="mailto:help@ticketsformedics.co.uk">help@ticketsformedics.co.uk</a>
                                                                                    {/* <a className="nav-link-dark-to-blue text-decoration-none" href="mailto:help@ticketsformedics.co.uk">help@ticketsformedics.co.uk</a> */}
                                                                              </p>

                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 3 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading3">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse3" aria-expanded="false" aria-controls="faqCollapse3">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Information we collect from you</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse3" className="accordion-collapse collapse" aria-labelledby="faqHeading3" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Unless otherwise agreed with you, we will only collect basic personal data about you which does not include any special information about you (often known as “sensitive personal data”).
                                                                                          <br/>
                                                                                          <br/>
                                                                                          This information does, however, include the likes of your full name, contact details (which will be mobile phone number and email address).&nbsp; We also ask you to confirm which NHS Trust you are employed by as well as your Role and your nearest city.&nbsp; If you are not an NHS Employee, we ask that you provide documentation that verifies your links to the NHS or Keyworker status and you are able to decide what to submit to verify your Keyworker status.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          By submitting your personal information to us using this website, you are confirming that the information you are giving is correct and relates to you.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 4 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading4">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse4" aria-expanded="false" aria-controls="faqCollapse4">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">How we use your information</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse4" className="accordion-collapse collapse" aria-labelledby="faqHeading4" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We need to know basic personal data in order to provide you with the services you have engaged us to provide, and to assert our right to be recompensed in return for these services.&nbsp;&nbsp;If you do not provide this information, then we will be unable to provide the services you have requested.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We use your personal data to assess whether you are eligible for membership with TFM, which is only available to NHS Staff, NHS service providers or verified Keyworkers in the Healthcare industry.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We will also use your basic personal data to keep in contact with you and to answer any questions or queries you may have submitted.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We may share your information with third party service providers in connection with providing our services to you where we have your permission or such disclosure is required by law.&nbsp;&nbsp;However, when we use third party service providers, we disclose only the personal data that is necessary to deliver the service and we require them to keep your information secure and not to use it for their own direct marketing purposes.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          In light of the coronavirus pandemic, many venues wish to send you etickets directly, for tickets booked via TFM and require your name, email address or mobile number in order to facilitate delivery.&nbsp; TFM may share with venues who have agreed to our standard data processing agreement protecting your data, when you visit them in person.&nbsp; This is to help the nation’s effort to stop the spread of the coronavirus to protect public health.&nbsp;  Venues can retain this personal data for 21 days from the date you visit them which will thereafter be permanently deleted.&nbsp; You can rest assured that our third-party service provider theatres will not use your personal data for any other purpose than providing the tickets to fulfil your booking and or to contact you or liaise with NHS Track and Trace in the event of a coronavirus outbreak to protect public health.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We will not collect any personal data from you that we do not need in order to provide and oversee the services we have agreed to provide you with.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 5 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading5">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse5" aria-expanded="false" aria-controls="faqCollapse5">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Who has access to your information?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse5" className="accordion-collapse collapse" aria-labelledby="faqHeading5" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We take all reasonable steps to ensure that your information is processed securely.&nbsp;&nbsp;All the personal data that we hold about you will be processed by our staff in the UK.&nbsp;&nbsp;No third parties will have access to your personal data unless there is a legal obligation for us to provide them with this.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          In return for providing Tickets for Medics with ticket allocations, we share anonymous feedback responses with event organisers submitted by members who attend their events, to provide them with constructive feedback and quotes that can be used on social media to promote their event.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We do not share any information that can be used to identify you personally.&nbsp;&nbsp;We only share a summary report of answers, to questions answered by our members.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 6 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading6">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse6" aria-expanded="false" aria-controls="faqCollapse6">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">What we do with your information</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse6" className="accordion-collapse collapse" aria-labelledby="faqHeading6" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          The information we collect from you may be transferred to and stored at a destination outside the European Union (EU).
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We take all reasonable steps to ensure that your information is treated securely.&nbsp;&nbsp;We ensure that third-party service providers who handle or store data outside the UK or EU, for example through data servers or relays, adhere to the <a href="https://www.privacyshield.gov/welcome" target="_blank" rel="noreferrer">EU-US Privacy Shield.</a>
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 7 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading7">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse7" aria-expanded="false" aria-controls="faqCollapse7">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">What we would also like to do with your information</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse7" className="accordion-collapse collapse" aria-labelledby="faqHeading7" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We would like to send you suggestions and recommendations about goods and services that may interest you.&nbsp;&nbsp;However, we will not contact you for marketing purposes unless you have given your prior consent.&nbsp;&nbsp;This information is not shared with third parties and you can change your marketing preferences or unsubscribe at any time by contacting us by email or amending your marketing preferences from within your account.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 8 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading8">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse8" aria-expanded="false" aria-controls="faqCollapse8">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">How long we keep your information</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse8" className="accordion-collapse collapse" aria-labelledby="faqHeading8" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We are required under UK tax law to keep your basic personal data (name, contact details) for a minimum of six (6) years after which time it will be destroyed if it is no longer required for the legitimate business need for which it was obtained or for other legal / regulatory purposes.&nbsp;&nbsp;If you consent to marketing, the information we use for this purpose will be kept with us until you notify us that you no longer wish to receive this information.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 9 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading9">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse9" aria-expanded="false" aria-controls="faqCollapse9">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Links to other websites and partner platforms</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse9" className="accordion-collapse collapse" aria-labelledby="faqHeading9" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Our website may contain links to other websites run by other organisations.&nbsp;&nbsp;This privacy policy applies only to our website‚ so we encourage you to read the privacy statements on the other websites you visit.&nbsp;&nbsp;We cannot be responsible for the privacy policies and practices of other sites even if you access them using links from our website.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          In addition, if you linked to our website from a third-party site, we cannot be responsible for the privacy policies and practices of the owners and operators of that third party site and recommend that you check the policy of that third party site.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Our third-party partner platforms: Sometimes, you will provide your information to our third-party partner platforms.&nbsp;&nbsp;For example, <a href="https://centraltickets.ticketswitch.com/" target="_blank" rel="noreferrer">Ingresso</a> and <a href="https://centraltickets.tixuk.com/" target="_blank" rel="noreferrer">Encore</a> who we’ve teamed up with to sell tickets on our behalf.&nbsp;&nbsp;These platforms are run by other organisations.&nbsp;&nbsp;This privacy policy applies only to our website‚ so we encourage you to read the privacy statements on the other platforms you visit.&nbsp;&nbsp;TFM is not responsible for the privacy policies and practices of other platforms.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 10 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading10">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse10" aria-expanded="false" aria-controls="faqCollapse10">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Social Media Platforms</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse10" className="accordion-collapse collapse" aria-labelledby="faqHeading10" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Communication, engagement and actions taken through external social media platforms that TFM’s website participates on are custom to the terms and conditions as well as the privacy policies of each social media platform respectively.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          As described above, TFM’s website may use social sharing buttons which help share web content directly from web pages to the social media platform in question.&nbsp;&nbsp;You are advised before using such social sharing buttons that you do so at your own discretion and note that the social media platform may track and save your request to share a web page respectively through your social media platform account.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          You are advised to use social media platforms wisely and communicate / engage with them with due care and caution in regard to your own privacy and your personal details.&nbsp;&nbsp;TFM will never ask for personal or sensitive information through social media platforms and encourages you to contact us by email if you wish to discuss sensitive details.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          You are, however, reminded that part of your Terms & Conditions of membership is that you cannot make any reference to complimentary tickets or Tickets for Medics on any social media channels, blogs or public forums.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 11 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading11">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse11" aria-expanded="false" aria-controls="faqCollapse11">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Security precautions in place to protect the loss, misuse or alteration of your information</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse11" className="accordion-collapse collapse" aria-labelledby="faqHeading11" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          When you give us personal information, we take steps to ensure that it’s treated securely.&nbsp;&nbsp;Any sensitive information (such as credit or debit card details) is encrypted and protected with secure server software (SSL) with 128-bit encryption: the industry standard for secure commercial transactions.&nbsp;&nbsp;It encrypts all of your personal information, including your name, payment details and password that you use to enter the site, so that it cannot be read as the information travels over the internet.&nbsp;&nbsp;When you are on a secure page, a lock icon will appear on the bottom of web browsers such as Microsoft Internet Explorer.&nbsp;&nbsp;Additionally, our choice of payment provider allows us to never hold your credit card details on our website.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Non-sensitive details (your email address etc.) are transmitted normally over the Internet, and this can never be guaranteed to be 100% secure.&nbsp;&nbsp;Although we will do our best to protect your personal data, we cannot guarantee the security of any information you transmit to us; any transmission is at your own risk.&nbsp;&nbsp;Once we have received your information, we will use strict procedures and security features to ensure its security on our systems and try to prevent unauthorised access.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 12 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading12">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse12" aria-expanded="false" aria-controls="faqCollapse12">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Visitors to our website</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse12" className="accordion-collapse collapse" aria-labelledby="faqHeading12" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          When you visit <a href="https://www.ticketsformedics.co.uk/" target="_blank" rel="noreferrer">https://www.ticketsformedics.co.uk</a> we use a third party service, Google Analytics, to collect standard internet log information and details of visitor behaviour patterns.&nbsp;&nbsp;We do this to find out things such as the number of visitors to the various pages of the site.&nbsp;&nbsp;This information is only processed in a way which does not identify anyone.&nbsp;&nbsp;We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website.&nbsp;&nbsp;If we do want to collect personally identifiable information through our website, we will be up front about this.&nbsp;&nbsp;We will make it clear when we collect personal information and will explain what we intend to do with it.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 13 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading13">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse13" aria-expanded="false" aria-controls="faqCollapse13">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">What Are Cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse13" className="accordion-collapse collapse" aria-labelledby="faqHeading13" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience.&nbsp;&nbsp;This page describes what information they gather, how we use it and why we sometimes need to store these cookies.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          For more general information on cookies see the Wikipedia article on HTTP Cookies.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 14 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading14">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse14" aria-expanded="false" aria-controls="faqCollapse14">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">How We Use Cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse14" className="accordion-collapse collapse" aria-labelledby="faqHeading14" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We use cookies for a variety of reasons detailed below.&nbsp;&nbsp;Unfortunately, in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site.&nbsp;&nbsp;It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 15 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading15">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse15" aria-expanded="false" aria-controls="faqCollapse15">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Disabling Cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse15" className="accordion-collapse collapse" aria-labelledby="faqHeading15" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this).&nbsp;&nbsp;Be aware that disabling cookies will affect the functionality of this and many other websites that you visit.&nbsp;&nbsp;Disabling cookies will usually result in also disabling certain functionality and features of the this site.&nbsp;&nbsp;Therefore it is recommended that you do not disable cookies.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 16 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading16">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse16" aria-expanded="false" aria-controls="faqCollapse16">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Login related cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse16" className="accordion-collapse collapse" aria-labelledby="faqHeading16" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We use cookies when you are logged in so that we can remember this fact.&nbsp;&nbsp;This prevents you from having to log in every single time you visit a new page.&nbsp;&nbsp;These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 17 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading17">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse17" aria-expanded="false" aria-controls="faqCollapse17">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Email newsletters related cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse17" className="accordion-collapse collapse" aria-labelledby="faqHeading17" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 18 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading18">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse18" aria-expanded="false" aria-controls="faqCollapse18">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Orders processing related cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse18" className="accordion-collapse collapse" aria-labelledby="faqHeading18" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          This site offers e-commerce or payment facilities and some cookies are essential to ensure that your order is remembered between pages so that we can process it properly.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 19 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading19">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse19" aria-expanded="false" aria-controls="faqCollapse19">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Forms related cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse19" className="accordion-collapse collapse" aria-labelledby="faqHeading19" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 20 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading20">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse20" aria-expanded="false" aria-controls="faqCollapse20">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Site preferences cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse20" className="accordion-collapse collapse" aria-labelledby="faqHeading20" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it.&nbsp;&nbsp;In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 21 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading21">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse21" aria-expanded="false" aria-controls="faqCollapse21">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Third Party Cookies</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse21" className="accordion-collapse collapse" aria-labelledby="faqHeading21" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          In some special cases we also use cookies provided by trusted third parties.&nbsp;&nbsp;The following section details which third party cookies you might encounter through this site.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience.&nbsp;&nbsp;These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          For more information on Google Analytics cookies, see the official Google Analytics page.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          As we sell products it's important for us to understand statistics about how many of the visitors to our site actually make a purchase and as such this is the kind of data that these cookies will track.&nbsp;&nbsp;This is important to you as it means that we can accurately make business predictions that allow us to monitor our advertising and product costs to ensure the best possible price.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          We use adverts to offset the costs of running this site and provide funding for further development.&nbsp;&nbsp;The behavioural advertising cookies used by this site are designed to ensure that we provide you with the most relevant adverts where possible by anonymously tracking your interests and presenting similar things that may be of interest.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 22 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading22">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse22" aria-expanded="false" aria-controls="faqCollapse22">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">What are your rights?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse22" className="accordion-collapse collapse" aria-labelledby="faqHeading22" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          If at any point you believe the information we hold and process on you is incorrect, under the <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN" target="_blank" rel="noreferrer">GDPR</a>, you may at any time request a copy of the information we hold on you by contacting us and have it corrected or deleted.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Any information access request may be subject to a fee of £10 to cover our administrative costs in providing you with details of the information we hold about you.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          You can also request the erasure of your personal data in any of the specific circumstances set out in Article 17 of the GDPR.&nbsp;&nbsp;If you wish to raise a complaint on how we have handled your personal data, you can contact us to have the matter investigated.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          To contact us about any of your rights, please write to TFM c/o Central Tickets Limited, 71-75 Shelton Street, Covent Garden, London WC2H 9JQ or email help@ticketsformedics.co.uk.&nbsp;&nbsp;Please provide your full name, email address and mobile number when you contact us so we can identify you.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          If you are not satisfied with our response or you believe we are processing your personal data not in accordance with the law, you can complain to the Information Commissioner’s Office <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">https://ico.org.uk/</a>

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                  
                                                                  {/* <!-- item 23 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading23">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse23" aria-expanded="false" aria-controls="faqCollapse23">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">Changes to our privacy policy</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse23" className="accordion-collapse collapse" aria-labelledby="faqHeading23" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                    We may make changes to this Policy any time.&nbsp;&nbsp;Any changes we may make will be posted on this page.
                                                                                    <br/>
                                                                                    <br/>
                                                                                    Any questions or comments about this Policy can be sent to:
                                                                                    <br/>
                                                                                    <br/>
                                                                                    
                                                                                    TFM c/o Central Tickets Limited
                                                                                    <br/>71-75 Shelton Street
                                                                                    <br/>Covent Garden
                                                                                    <br/>London WC2N 9JQ
                                                                                    <br/><a href="mailto:help@ticketsformedics.co.uk">help@ticketsformedics.co.uk</a>

                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                          
                                                            </div>
                                                            {/* <!-- /.accordion__faq --> */}
            
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
        
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
        
                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
      )
}

export default PrivacyPolicy;
