import { createSlice } from "@reduxjs/toolkit";


const initialState = {
      reqresponse:false,
      currentBookings:[],
      pastBookings:[],
      cancleBookings:{
            isRequesting:false,
            isRequestingAmendTickets:false,
            bookingRef:'',
            booking_id:'',
            isCancling:false,
            eventType:1,
            numberOfTickets:1,
            totalTickets:1,
            step:'initial',
            groups:[],
            start_time:"",
            start_date:"",
            event_title:""
      }
      
}
const BookingSlice = createSlice({
      name:'booking',
      initialState:initialState,
      reducers:{
            setBookingsData(state,action){
                  state.currentBookings = action.payload.currentBookings;
                  state.pastBookings = action.payload.pastBookings;
            },
            setRequestResponse(state,action){
                  state.reqresponse = true;
            },
            setTicketsHandler(state,action){
                  state.cancleBookings.numberOfTickets = action.payload.tickets;
            },
            setStepHandler(state,action){
                  state.cancleBookings.step = action.payload.step;
            },
            setRequestingHandler(state,action){
                  state.cancleBookings.isRequesting = action.payload.isRequesting;
            },
            setAmendTicketsRequestingHandler(state,action){
                  state.cancleBookings.isRequestingAmendTickets = action.payload.isRequestingAmendTickets;
            },
            setCancleBookings(state,action){
                  state.cancleBookings.isCancling = true;
                  state.cancleBookings.eventType =action.payload.eventType
                  state.cancleBookings.numberOfTickets = action.payload.tickets
                  state.cancleBookings.totalTickets = action.payload.tickets
                  state.cancleBookings.step = 'initial';
                  state.cancleBookings.booking_id = action.payload.booking_id;
                  state.cancleBookings.bookingRef = action.payload.bookingRef;
                  state.cancleBookings.start_time = action.payload.start_time;
                  state.cancleBookings.start_date = action.payload.start_date;
                  state.cancleBookings.event_title = action.payload.event_title;
            },
            closeCancleBookings(state,action){
                  state.cancleBookings.isCancling = false;
                  state.cancleBookings.isRequesting =false;
                  state.cancleBookings.isRequestingAmendTickets =false;
                  state.cancleBookings.bookingRef = '';
                  state.cancleBookings.booking_id = '';
                  state.cancleBookings.eventType =0;
                  state.cancleBookings.numberOfTickets = 1;
                  state.cancleBookings.totalTickets = 1;
                  state.cancleBookings.step = 'initial';
                  state.cancleBookings.groups = [];
                  state.cancleBookings.start_time = "";
                  state.cancleBookings.start_date = "";
                  state.cancleBookings.event_title = "";
            },
            reset: ()=> initialState
            

      }
})

export const BookingActions = BookingSlice.actions;
export default BookingSlice.reducer;







//--------------------------------------------------------------------------//
// ---------------------old Slice without reset options----------------------
//--------------------------------------------------------------------------//

// import { createSlice } from "@reduxjs/toolkit";


// const BookingSlice = createSlice({
//       name:'booking',
//       initialState:{
//             reqresponse:false,
//             currentBookings:[],
//             pastBookings:[],
//             cancleBookings:{
//                   isRequesting:false,
//                   isRequestingAmendTickets:false,
//                   bookingRef:'',
//                   booking_id:'',
//                   isCancling:false,
//                   eventType:1,
//                   numberOfTickets:1,
//                   totalTickets:1,
//                   step:'initial',
//                   groups:[]
//             }
            
//       },
//       reducers:{
//             setBookingsData(state,action){
//                   state.currentBookings = action.payload.currentBookings;
//                   state.pastBookings = action.payload.pastBookings;
//             },
//             setRequestResponse(state,action){
//                   state.reqresponse = true;
//             },
//             setTicketsHandler(state,action){
//                   state.cancleBookings.numberOfTickets = action.payload.tickets;
//             },
//             setStepHandler(state,action){
//                   state.cancleBookings.step = action.payload.step;
//             },
//             setRequestingHandler(state,action){
//                   state.cancleBookings.isRequesting = action.payload.isRequesting;
//             },
//             setAmendTicketsRequestingHandler(state,action){
//                   state.cancleBookings.isRequestingAmendTickets = action.payload.isRequestingAmendTickets;
//             },
//             setCancleBookings(state,action){
//                   state.cancleBookings.isCancling = true;
//                   state.cancleBookings.eventType =action.payload.eventType
//                   state.cancleBookings.numberOfTickets = action.payload.tickets
//                   state.cancleBookings.totalTickets = action.payload.tickets
//                   state.cancleBookings.step = 'initial';
//                   state.cancleBookings.booking_id = action.payload.booking_id;
//                   state.cancleBookings.bookingRef = action.payload.bookingRef;
//             },
//             closeCancleBookings(state,action){
//                   state.cancleBookings.isCancling = false;
//                   state.cancleBookings.isRequesting =false;
//                   state.cancleBookings.isRequestingAmendTickets =false;
//                   state.cancleBookings.bookingRef = '';
//                   state.cancleBookings.booking_id = '';
//                   state.cancleBookings.eventType =0;
//                   state.cancleBookings.numberOfTickets = 1;
//                   state.cancleBookings.totalTickets = 1;
//                   state.cancleBookings.step = 'initial';
//                   state.cancleBookings.groups = [];
//             },
//             setBookingsDataOnLogout(state,action){
//                   state.currentBookings = [];
//                   state.pastBookings = [];
//                   state.reqresponse = false;
//             }
            

//       }
// })

// export const BookingActions = BookingSlice.actions;
// export default BookingSlice.reducer;