import React,{useEffect} from "react";
import { Link } from "react-router-dom";
const Faq = () => {

      useEffect(()=>{
      // window.scrollTo({top: 0, behavior: 'smooth'});
      },[])

      const token = localStorage.getItem("nhs_access_token");
      let fogotPasswordLink = "/forgot_password"

      if(token){
            fogotPasswordLink = "/settings"
      }
          
      return (
            <main>
                  <section className="section__faq">
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content content__faq">
                                                <div className="content-box-wrapper mx-lg-auto">
                                                      <div className="content__header border-bottom text-center">
                                                            <h1 className="header__title fw-bold mb-0">FAQ</h1>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}
                                                      <div className="content__body p-0">
                                                            <div className="accordion accordion__plus-to-x" id="accordionFAQ">
      
                                                                  {/* <!-- item 1 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading1">
                                                                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse1" aria-expanded="true" aria-controls="faqCollapse1">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Do you offer telephone support?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse1" className="accordion-collapse collapse show" aria-labelledby="faqHeading1" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          No, we're a very small team and need to be able to prioritise our workload.&nbsp;&nbsp;You can use <Link to="/contactus" style={{"textDecoration":"none"}} target="_blank">contact us form</Link> to reach us and we'll get back to you as quickly as we can. 
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  
                                                                  {/* <!-- item 2 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading2">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse2" aria-expanded="false" aria-controls="faqCollapse2">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I have a query about my booking, should I contact the venue directly?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse2" className="accordion-collapse collapse" aria-labelledby="faqHeading2" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          No, you must always direct any enquiries to Tickets for Medics and never contact the venue directly.&nbsp;&nbsp;This is an important rule of membership.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
      
                                                                  {/* <!-- item 3 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading3">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse3" aria-expanded="false" aria-controls="faqCollapse3">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">If I make a booking for a seat-filling show, will my seats be together?</span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse3" className="accordion-collapse collapse" aria-labelledby="faqHeading3" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Yes, you will be seated together if you buy more than one ticket.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>
      
                                                                  {/* <!-- item 4 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading4">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse4" aria-expanded="false" aria-controls="faqCollapse4">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          How do I find/filter shows that are outside of London?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse4" className="accordion-collapse collapse" aria-labelledby="faqHeading4" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Click on the + icon, above the listings to filter listings.&nbsp;&nbsp;If you don't see any in your area after filtering the results it means that we don't have any.&nbsp;&nbsp;Please stay subscribed - it will help us to offer event organisers seat filling services in their area.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>
      
                                                                  {/* <!-- item 5 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading5">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse5" aria-expanded="false" aria-controls="faqCollapse5">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Is there any minimum booking requirement?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse5" className="accordion-collapse collapse" aria-labelledby="faqHeading5" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Members must now make at least 1 booking per year to remain a member of Tickets for Medics (this rule does not apply to Regional members).&nbsp;&nbsp;Please note that there is no charge if the minimum booking requirement isn’t met.
                                                                                          
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 6 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading6">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse6" aria-expanded="false" aria-controls="faqCollapse6">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I have access needs, can I join Tickets for Medics?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse6" className="accordion-collapse collapse" aria-labelledby="faqHeading6" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Absolutely - Tickets for Medics is totally inclusive.&nbsp;&nbsp;See the notes below to understand how best to use Tickets for Medics.  
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 7 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading7">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse7" aria-expanded="false" aria-controls="faqCollapse7">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Access Bookings for Seat-Filling Opportunities
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse7" className="accordion-collapse collapse" aria-labelledby="faqHeading7" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          For seat-filling bookings, the only thing we ask is that you get in touch with us so that we can reach out to the venue on your behalf to make sure they can accommodate you.&nbsp;&nbsp;Sometimes the venues do not have access and we wouldn't want you to have a wasted journey.  
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Some of the events we work with book quickly.&nbsp;&nbsp;If you would prefer to book tickets to secure your booking and then contact us to check whether the venue can seat you, we're happy for you to do that.&nbsp;&nbsp;Should the venue not be able to accommodate your needs for any reason, we will of course cancel and refund your booking.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Alternatively, you can make enquiries before booking and we can reach out and ask if your needs can be met.&nbsp;&nbsp;Please use our <Link to="/contactus" style={{"textDecoration":"none"}} target="_blank">contact form</Link> to reach us.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 8 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading8">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse8" aria-expanded="false" aria-controls="faqCollapse8">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Is membership free?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse8" className="accordion-collapse collapse" aria-labelledby="faqHeading8" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Yes, membership is completely free so long as you make one booking for a seat-filling show every 12 months.&nbsp;&nbsp;If you do not make a booking every 12-months, your access to seat-filling opportunities will become restricted (but no charge will be applied).
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 9 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading9">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse9" aria-expanded="false" aria-controls="faqCollapse9">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I can’t remember my username
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse9" className="accordion-collapse collapse" aria-labelledby="faqHeading9" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Your username is the email address you used to register with Tickets for Medics.
                                                                                    </p>

                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 10 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading10">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse10" aria-expanded="false" aria-controls="faqCollapse10">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I can't remember my password
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse10" className="accordion-collapse collapse" aria-labelledby="faqHeading10" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We don't store your password on our server.&nbsp;&nbsp;If you have forgotten it, please click <Link to={fogotPasswordLink} style={{"textDecoration":"none"}} target="_blank">here</Link>.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 11 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading11">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse11" aria-expanded="false" aria-controls="faqCollapse11">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I’m having trouble logging into the site
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse11" className="accordion-collapse collapse" aria-labelledby="faqHeading11" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          If you are unable to login to site, you will need to reset your password.&nbsp;&nbsp;If you have already done this and found that you cannot access the site, it means you are still entering the wrong password and the way to correct this is to repeat the process and reset it again.&nbsp;&nbsp;Please take care to correctly type your new password and choose something that you'll remember, as we cannot access your password to resend it.&nbsp;&nbsp;You can do this by clicking  <Link to={fogotPasswordLink} style={{"textDecoration":"none"}} target="_blank">here</Link>.
                                                                                    </p>

                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 12 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading12">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse12" aria-expanded="false" aria-controls="faqCollapse12">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Can I update my details in my account?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse12" className="accordion-collapse collapse" aria-labelledby="faqHeading12" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Yes, you can update your email address or change your password from within your account.&nbsp;&nbsp;Login and click 'Account Settings' to get started. 
                                                                                    </p>

                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 13 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading13">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse13" aria-expanded="false" aria-controls="faqCollapse13">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          How do I cancel, amend or transfer my booking?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse13" className="accordion-collapse collapse" aria-labelledby="faqHeading13" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          If you need to cancel a booking, login in to your account, click 'My Account' and click ‘My Bookings'.&nbsp;&nbsp;From there, you can cancel or your booking, or reduce the number of tickets you have booked.&nbsp;&nbsp;Please note that all bookings are final and cannot be refunded or transferred in the event you cannot attend.
                                                                                          <br/>
                                                                                          <br/>
                                                                                          To book more tickets, you should head back to the listing and order the additional number of tickets you require.&nbsp;&nbsp;We will automatically group your booking when we pass your details to the venue.    
                                                                                          <br/>
                                                                                          <br/>
                                                                                          * The accounts of new members who cancel any of their first three bookings will be closed.
                                                                                    </p>

                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 14 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading14">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse14" aria-expanded="false" aria-controls="faqCollapse14">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I need to reduce my booking, how can I cancel some of the tickets I've booked?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse14" className="accordion-collapse collapse" aria-labelledby="faqHeading14" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          You can manage your bookings, by logging into the members area, navigating to 'My Account' and clicking on 'My Bookings'.&nbsp;&nbsp;From there, you can reduce the number of tickets you've booked.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 15 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading15">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse15" aria-expanded="false" aria-controls="faqCollapse15">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I've lost my booking confirmation, where can I find it?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse15" className="accordion-collapse collapse" aria-labelledby="faqHeading15" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          You can download copies of your booking confirmations from within your account by logging into the members area, navigating to 'My Account’ and clicking on 'My Bookings'.&nbsp;&nbsp;From there, you can download your booking confirmations.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 16 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading16">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse16" aria-expanded="false" aria-controls="faqCollapse16">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          How do I leave feedback? 
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse16" className="accordion-collapse collapse" aria-labelledby="faqHeading16" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          It's great when our members leave feedback for shows they've seen.&nbsp;&nbsp;Simply login, navigate to 'My Account' and click on 'My Bookings' and click on 'Leave Feedback' to tell us what you thought. 
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 17 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading17">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse17" aria-expanded="false" aria-controls="faqCollapse17">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Are admin fees refundable if I cancel my booking?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse17" className="accordion-collapse collapse" aria-labelledby="faqHeading17" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          No, admin fees are not refundable.&nbsp;&nbsp;Please see our <Link to="/terms" style={{"textDecoration":"none"}} target="_blank">Terms & Conditions</Link> for full details.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 18 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading18">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse18" aria-expanded="false" aria-controls="faqCollapse18">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Why do you charge an admin fee per ticket?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse18" className="accordion-collapse collapse" aria-labelledby="faqHeading18" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          You can save hundreds of pounds per year by joining Tickets for Medics.&nbsp;&nbsp;The only thing you’ll pay is a nominal booking fee per ticket that helps cover our running costs and allows us to bring you these opportunities.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 19 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading19">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse19" aria-expanded="false" aria-controls="faqCollapse19">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          How much is the 'nominal booking fee'?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse19" className="accordion-collapse collapse" aria-labelledby="faqHeading19" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Booking fees range from £4 for a smaller show to £6.50 for a West End show, or Music Concert.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 20 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading20">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse20" aria-expanded="false" aria-controls="faqCollapse20">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          What is expected of me in return?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse20" className="accordion-collapse collapse" aria-labelledby="faqHeading20" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We ask that our members read and agree to our <Link to="/terms" target="_blank"  style={{"textDecoration":"none"}}>Terms and Conditions</Link> before membership is granted.&nbsp;&nbsp;You can find the full breakdown of what is required of you as a member there, however the most important conditions of membership are:
                                                                                         
                                                                                    </p>
                                                                                    <br/>
                                                                                    <ul style={{"marginLeft":""}}>
                                                                                          <li>Read the collection instructions for every booking</li>
                                                                                          <li>Be discreet and make no mention of free or complimentary tickets</li>
                                                                                          <li>Be friendly and polite to all staff</li>
                                                                                          <li>Collect at least 30 minutes ahead of the event starting.</li>
                                                                                          <li>Bring a printed copy or a screen grab of your booking confirmation.</li>
                                                                                          <li>Accept the seats you are allocated graciously.</li>
                                                                                          <li>Direct any questions to us, never contact the venue.</li>
                                                                                          <li>Always collect tickets yourself and don’t share your account</li>
                                                                                          <li>Never mention ‘free tickets’ at the venue, or on social media.</li>
                                                                                          <li>Dress nicely – smart casual is perfect.</li>
                                                                                          <li>Stay for the whole event and show your appreciation with hearty applause.</li>
                                                                                    </ul>
                                                                                    <br/>
                                                                                    <p>
                                                                                          Above all, we ask that you appreciate that you are given access to these ticket offers in exchange for providing a service – to be a reliable, discreet audience member and create a good atmosphere for the rest of the audience to enjoy.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 21 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading21">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse21" aria-expanded="false" aria-controls="faqCollapse21">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          How do I book tickets?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse21" className="accordion-collapse collapse" aria-labelledby="faqHeading21" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Once you have become a member of Tickets for Medics you’ll be able to login to your account where you will have access to the listings available to you.&nbsp;&nbsp;From there you’ll be able to book tickets for any event you’d like to attend.&nbsp;&nbsp;Click into any event and click book now and follow the prompts to book tickets.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 22 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading22">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse22" aria-expanded="false" aria-controls="faqCollapse22">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Where can I find the link for my online event?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse22" className="accordion-collapse collapse" aria-labelledby="faqHeading22" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Links for online events will be made available within your account within 3 hours of the event beginning.&nbsp;&nbsp;Please login to your account, navigate to My Account, then My Bookings and then click Collection Instructions - the link you need will be available there.&nbsp;&nbsp;If the link is not there, please double check the date of the event - the majority of queries we receive are from people who have confused the date.
                                                                                    </p>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 23 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading23">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse23" aria-expanded="false" aria-controls="faqCollapse23">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Where can I access a recording of an event?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse23" className="accordion-collapse collapse" aria-labelledby="faqHeading23" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Recordings of online events are generally not made available to our members (even when mentioned during the talks themselves).&nbsp;&nbsp;Please check your confirmation email, booking reminder or PDF ticket under ‘Collection Instructions’ where we will always detail whether a recording will be made available.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 24 --> */}
                                                                  {/* <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading24">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse24" aria-expanded="false" aria-controls="faqCollapse24">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I’ve received the link for an event, but I’m struggling to join
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse24" className="accordion-collapse collapse" aria-labelledby="faqHeading24" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          If we’re made aware of any technical issues experienced by any event organiser, we’ll write to you immediately to let you know.&nbsp;&nbsp;In the meantime, we suggest troubleshooting by trying a different device, or rebooting your connection and trying again. 
                                                                                          <br/>
                                                                                          <br/>
                                                                                          Click here to see a video on how to access a zoom link.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div> */}

                                                                  {/* <!-- item 25 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading25">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse25" aria-expanded="false" aria-controls="faqCollapse25">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          What events do you have at the moment?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse25" className="accordion-collapse collapse" aria-labelledby="faqHeading25" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Our event organisers rely upon us for discretion and only our members are able to see the current event listings.&nbsp;&nbsp;However, we can tell you that we help to provide an audience for West End Theatre, fringe theatre, dance shows, opera, the ballet, classical music concerts, cinema, music concerts and more in and around London.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 26 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading26">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse26" aria-expanded="false" aria-controls="faqCollapse26">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          How many seats can I book for each performance?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse26" className="accordion-collapse collapse" aria-labelledby="faqHeading26" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          Our members can book up to six tickets per event, depending on the event and the availability of tickets. 
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 27 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading27">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse27" aria-expanded="false" aria-controls="faqCollapse27">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Can friends or family use my account?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse27" className="accordion-collapse collapse" aria-labelledby="faqHeading27" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          No, we ask that you do not let anyone else use your account.&nbsp;&nbsp;We trust our members to respect this rule and not allow their friends or family to use their Tickets for Medics account to book tickets.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 28 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading28">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse28" aria-expanded="false" aria-controls="faqCollapse28">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          Can someone else pick up tickets that I’ve booked?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse28" className="accordion-collapse collapse" aria-labelledby="faqHeading28" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          No, we ask that you be present to collect all events that you book to attend.&nbsp;&nbsp;For those who would like their partner to be able to collect tickets, please do encourage your partner to open their own account with Tickets for Medics.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                                  {/* <!-- item 29 --> */}
                                                                  <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqHeading29">
                                                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse29" aria-expanded="false" aria-controls="faqCollapse29">
                                                                                    <span className="btn btn__plus-to-x">
                                                                                          <span className="icon__plus"></span>
                                                                                    </span>
                                                                                    <span className="text-wrapper">
                                                                                          I have a comment or suggestion that I’d like to offer.&nbsp;&nbsp;How may I contact you?
                                                                                    </span>
                                                                              </button>
                                                                        </h2>
                                                                        <div id="faqCollapse29" className="accordion-collapse collapse" aria-labelledby="faqHeading29" data-bs-parent="#accordionFAQ">
                                                                              <div className="accordion-body">
                                                                                    <p>
                                                                                          We welcome and value all feedback from visitors and members.&nbsp;&nbsp;If you think that we could improve something, please do let us know.&nbsp;&nbsp;Visit our <Link to="/contactus" style={{"textDecoration":"none"}} target="_blank">contact page</Link> and get in touch.&nbsp;&nbsp;respond to every message that we receive.
                                                                                    </p>
                                                                                    
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.accordion__faq --> */}
      
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
      
                                          </div>
                                          {/* <!-- /.content --> */}

                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
      
                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
      )
}

export default Faq;
