import React from 'react';

import { Link} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import iconLocationPinBlack from "./../../../assests/images/icon-location-pin-black.svg";
import iconClockBlack from "./../../../assests/images/icon-clock-black.svg";
import iconPaymentCardBlack from "./../../../assests/images/icon-payment-card-black.svg";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { eventNotifymeWeb } from "./../../../Store/EventDetail/EventDetailActions";
import { UiActions } from '../../../Store/Ui/Ui';

const SingleEventComponent = (props) => {

      const waitingListRequest = useSelector(state=>state.ui.waitingListRequest)
      const token = localStorage.getItem("nhs_access_token");
      const dispatch = useDispatch();

      const confirmationHandler = (event)=>{
            event.preventDefault();
            if(props.is_soldout === '1'){
                  const event_id  = props.id;
                  const data      = {event_id:event_id};
                  dispatch(UiActions.setWaitingListRequest({request:event_id}))
                  // .................call API Join Waiting List...............//
                  dispatch(eventNotifymeWeb({token:token,data:data,type:"currentListing"}));
            }

      }
     
      return (
            
            <div className="grid-item">
                  <div className={["card card__event"].join(" ")}>
                  {/* <div className={["card card__event",props.is_soldout === "1" && "card__event-sold-out"].join(" ")}> */}
                        <div className="card__event-image">
                              
                              {props.is_soldout === "1" ? 
                              <React.Fragment>
                                    <div className="badge badge__ticket-left-count badge__ticket-sold-out">
                                          Sold Out!
                                    </div>
                                    <Link to={"/eventdetails/"+props.id}>
                                    {/* <img className={["event-image",props.is_soldout === "1" && "current_listings__soldout__event-image"].join(" ")}
                                    // srcSet={props.blur_event_image} 
                                    src={props.img} 
                                    alt=""/> */}
                                    <LazyLoadImage className={["event-image",props.is_soldout === "1" && "current_listings__soldout__event-image"].join(" ")} src={props.img} afterLoad={props.afterLoad} />
                                    </Link>
                              </React.Fragment> :
                              <React.Fragment>
                                    {props.tickets_left < 4 &&
                                          <div className="badge badge__ticket-left-count">{props.tickets_left} {props.tickets_left > 1 ? "tickets left" : "ticket left"}</div>
                                    }
                                    <Link to={"/eventdetails/"+props.id}>
                                          {/* <img className="event-image"  src={props.img} 
                                          // srcSet={props.blur_event_image} 
                                          alt=""/> */}
                                          <LazyLoadImage  className="event-image" src={props.img} afterLoad={props.afterLoad}/>
                                          {/* <img className="event-image" srcSet="assets/images/event-image-05@2x.jpg 2x" src="assets/images/event-image-01.jpg" alt=""/> */}
                                    </Link>
                              </React.Fragment>}
                        </div>
                        <div className="card-body">
                              <h5 className="card-title fw-bold">
                                    <Link className={props.is_soldout === "1" ? "soldOutEvent" :" "} to={"/eventdetails/"+props.id}>{props.event_name}</Link>
                              </h5>
                              <div className="list-group list-group__event-details list-group-flat">
                                    <div className="list-group-item list-group__location border-0 d-flex p-0">
                                          <div className="icon-wrapper flex-shrink-0">
                                                <img className="icon__location-pin" src={iconLocationPinBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">
                                                <span className="location-name">
                                                      {props.address}
                                                </span>
                                          </p>
                                    </div>
                                    <div className="list-group-item list-group__time border-0 d-flex p-0">
                                          <div className="icon-wrapper flex-shrink-0">
                                                <img className="icon__clock" src={iconClockBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">{props.event_date_time}</p>
                                          {/* <p className="mb-0">19/11 – 02/12 (Various Times)</p> */}
                                    </div>
                                    <div className="list-group-item list-group__price border-0 d-flex p-0">
                                          <div className="icon-wrapper flex-shrink-0">
                                                <img className="icon__payment-card" src={iconPaymentCardBlack} alt=""/>
                                          </div>
                                          {parseFloat(props.admin_fees) <= 6.5 && 
                                          <p className="mb-0">£0 Ticket + £{props.admin_fees} Admin Fee</p>}
                                          {parseFloat(props.admin_fees) >= 6.51 && 
                                          <p className="mb-0">£{props.admin_fees} Ticket </p>}

                                    </div>
                              </div>
                              <ul className="list__event-tags list-unstyled d-flex flex-wrap">
                                    {props.categories.split(",").map((el,index)=>{
                                          return (
                                                
                                                <li key={index}>
                                                      <a href="#">
                                                            <span className="badge badge-outline badge-xl-lg rounded-pill">{el}</span>
                                                      </a>
                                                </li>
                                          );
                                    })}
                                    
                              </ul>
                              {props.is_soldout ==="1" &&
                              <div className="event__cta mt-4">
                                    <button type="button" className="btn btn-xl-lg btn-primary fw-bold w-100" 
                                    onClick={confirmationHandler} disabled={waitingListRequest === props.id}>
                                          JOIN WAITING LIST
                                          {waitingListRequest === props.id && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                    </button>
                              </div>}
                        </div>
                        {/* <!-- /.card-body --> */}
                  </div>
                  {/* <!-- /.card --> */}
            </div>
      );
}

export default SingleEventComponent;
