import React,{useState, useEffect, useRef} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../../ReactDatepicker.css";

import { useSelector,useDispatch } from "react-redux";

import TomSelect from "tom-select"
import { AllEventsActions } from '../../../Store/Allevents/AllEvents';

const FilterBar = () => {

      const [sortState,setSortState]=useState({
            categories:[
                  {name:"Theatre",value:61,selected:false},
                  {name:"Music",value:19,selected:false},
                  {name:"Talks & Walks",value:21,selected:false},
                  {name:"Performance",value:37,selected:false},
                  {name:"Movies",value:26,selected:false},
                  {name:"Comedy",value:60,selected:false},
                  {name:"Classical Music",value:9,selected:false},
                  {name:"Cabaret",value:33,selected:false},
                  {name:"LGBTQ+",value:25,selected:false}

            ],
            region:[
                  {name:"London / Greater London",value:1,selected:false},
                  {name:"South East England",value:2,selected:false},
                  {name:"East Anglia",value:3,selected:false},
                  {name:"South West England",value:4,selected:false},
                  {name:"West Midlands",value:5,selected:false},
                  {name:"East Midlands",value:6,selected:false},
                  {name:"North West England",value:7,selected:false},
                  {name:" Yorkshire & Humberside",value:8,selected:false},
                  {name:"North England",value:9,selected:false},
                  {name:"Wales",value:10,selected:false},
                  {name:"Scotland",value:11,selected:false},
                  {name:"Northern Ireland",value:12,selected:false},
            ],
            sortby:[
                  {name:"Sort By A-Z",value:1,selected:false},
                  {name:"Sort By Z-A",value:2,selected:false},
            ],
      })
      
      const sortbyRef = useRef();
      const regionRef = useRef();
      const categoriesRef = useRef();
      const collapseRef = useRef();

      const [filter,setFilter] = useState(false);
      const [sortby,setSortby] = useState("");
      const [region,setRegion] = useState([]);
      const [categories,setCategories] = useState([]);

      const [startDate, setStartDate] = useState(null);
      const [endDate, setEndDate] = useState(null);
      const [state,setState] = useState({
            filterPoints:{
                  fully_booked:false,
                  hide_online_event:false,
                  online_event:false,
                  standby_events:false,
            }
      })

      const allevents         =  useSelector(state=>state.allevents);

      const dispatch = useDispatch();
     
      useEffect(()=>{
            
            new TomSelect('#selectEventSort',{
                  // plugins: ['remove_button'],
                  create: false,
                  // maxItems: null,
                  valueField: 'value',
	            labelField: 'name',
                  searchField: 'name',
                  placeholder:" Sort by",
                  options : sortState.sortby,
                  items:sortby,
                  onChange:(value)=>onChangeDefaultHandler(value,'sortby')
            });

            new TomSelect('#selectEventLocation',{
                  plugins: ['checkbox_options'],
                  create: false,
                  maxItems: null,
                  valueField: 'value',
	            labelField: 'name',
                  searchField: 'name',
                  placeholder:" Select Location",
                  options : sortState.region,
                  items:region,
                  onChange:(value)=>onChangeDefaultHandler(value,'region')
            });
            new TomSelect('#selectEventCategory',{
                  plugins: ['checkbox_options'],
                  create: false,
                  maxItems: null,
                  valueField: 'value',
	            labelField: 'name',
                  searchField: 'name',
                  placeholder:" Select Category",
                  options : sortState.categories,
                  items:categories,
                  onChange:(value)=>onChangeDefaultHandler(value,'categories'),
                  // createFilter: function(input) {
                        // console.log(input,'hereee')
                        // var match, regex;
                
                        // regex = new RegExp('^' + REGEX + '$', 'i');
                        // match = input.match(regex);
                        // if (match) {
                        // console.log(match[0]);
                        //     return !this.options.hasOwnProperty(match[0]);
                        // }
                
                        // return false;
                  // },
                  // render:{
                  //       item: function(data, escape) {
                  //             console.log(data.$option,escape)
                  //             return `<div data-value="19"> ${escape(data.name)}</div>`;
                  //       }
                  // }
            });

      },[])

      useEffect(()=>{
            checkFilterHandler();
      },[filter])

      useEffect(()=>{
            if(!allevents.isFilteredApplied){
                  clearFilterHandler();
            }

            let childnodesCat = document.querySelector("#selectEventCategory + .ts-wrapper .ts-control").childNodes;
            let childnodesLoc = document.querySelector("#selectEventLocation + .ts-wrapper .ts-control").childNodes;

            if(typeof(childnodesCat[childnodesCat.length-1].data) === "string"){
                  childnodesCat[childnodesCat.length-1].textContent = "";
            }

            if(typeof(childnodesLoc[childnodesLoc.length-1].data) === "string"){
                  childnodesLoc[childnodesLoc.length-1].textContent = "";
            }

      },[allevents.isFilteredApplied])

      const hideMorethan1SelectedOptionsHandler = (type)=>{

            let items;
            if(type === "categories"){
                  items = document.querySelectorAll("#selectEventCategory + .ts-wrapper .ts-control .item")
            }
            else if(type === "region"){
                  items = document.querySelectorAll("#selectEventLocation + .ts-wrapper .ts-control .item")
            }
            else{
                  return;
            }
            const amendItem  = items.length || "0";

            items.forEach((item, index)=>{
                  if(index > 0){
                        item.style.display = "none";
                        let childnode;
                        if(type === "categories"){
                              childnode = document.querySelector("#selectEventCategory + .ts-wrapper .ts-control").childNodes
                        }else if(type === "region"){
                              childnode = document.querySelector("#selectEventLocation + .ts-wrapper .ts-control").childNodes
                        }

                        if(typeof(childnode[childnode.length-1].data) === "string"){
                              childnode[childnode.length-1].textContent = "("+amendItem  +")";
                        }
                        else {
                              if(type === "categories"){
                                    document.querySelector("#selectEventCategory + .ts-wrapper .ts-control").append("("+amendItem  +")")
                              }else if(type === "region"){
                                    document.querySelector("#selectEventLocation + .ts-wrapper .ts-control").append("("+amendItem  +")")
                              }

                        }
                       
                  }
                  else {
                        let childnodes;
                        if(type === "categories"){
                              childnodes = document.querySelector("#selectEventCategory + .ts-wrapper .ts-control").childNodes
                        }
                        else if(type === "region"){
                              childnodes = document.querySelector("#selectEventLocation + .ts-wrapper .ts-control").childNodes
                        }
                        if(typeof(childnodes[childnodes.length-1].data) === "string"){
                              // childnode[childnode.length-1]
                              childnodes[childnodes.length-1].textContent = "";
                        }
                        item.style.display = "inline-flex"
                        item.style.display = "inline-flex"
                  }
            })
      }
      
      const onChangeDefaultHandler = (value,type)=>{
           
            // Hide More than 1 selected options in selection box 
            hideMorethan1SelectedOptionsHandler(type)
            
            if(type === "sortby"){
                  setSortby(value)
            }
            else if(type === "region") {

                  setRegion(prevState=>{
                        return [...value]
                  })
            }
            else if(type === "categories"){

                  setCategories(prevState=>{
                        return [...value]
                  })
            }

            setFilter(true);
      }

      const capitalizeFirstLetter = (string) =>{
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const checkFilterHandler = ()=>{
           
            if(filter){
                        setFilter(false)
                  
                        let filteredEventsData = [];
                        let filtredBefore = false;
                        // let isFilteredSort=false;
                        let isFilteredApplied=false;

                        // Filter By Sorting Listing by A-Z Or Z-A
                        if(sortby){
                             
                              if(sortby === "1"){
                                    
                                    let  sortAzNameArray = [];
                                    sortAzNameArray= [...allevents.eventsData]
            
                                    sortAzNameArray.sort(function(a, b) {
            
                                          if (a.event_name < b.event_name){
                                                return -1;
                                          }
                                          if (a.event_name > b.event_name){
                                                return 1;
                                          }
                                          return 0;
                                    }); 
            
                                    filteredEventsData = sortAzNameArray;
                                    
            
                              }
                              else if(sortby === "2"){
            
                                    // console.log("filter za")
                                    let  sortZaNameArray = [];
                                    
                                    sortZaNameArray= [...allevents.eventsData]
                                    
                                    sortZaNameArray.sort(function(a, b) {
            
                                    
                                                const aeventName1 = capitalizeFirstLetter(a.event_name);
                                                const beventName1 = capitalizeFirstLetter(b.event_name);
            
                                                if (aeventName1 > beventName1){
                                                      return -1;
                                                }
                                                if (aeventName1 < beventName1){
                                                      return 1;
                                                }
                                                return 0;
                                    });
                                    
                                    filteredEventsData = sortZaNameArray;
                              }
                              filtredBefore = true;
                              isFilteredApplied=true;
                  
                        }
      
                        // Filter BY Location OR Region
                        if(region.length > 0){
                              
                              if(filtredBefore) {
                                    const selectedRegionFilter = [];
                                    filteredEventsData.forEach(event=>{
                                          
                                          const regArry = event.locations
                                          // console.log(regArry,region)
                                          let checkEventForFilter = false;
                                    
                                          regArry.forEach(reg=>{
                                                if(region.indexOf(reg) > -1){
                                                      checkEventForFilter = true;
                                                }
                                          })
                                          if(checkEventForFilter){
                                                selectedRegionFilter.push(event);
                                          }
                                    })
                                    filteredEventsData = selectedRegionFilter;
                              }
                              else {
                                    allevents.eventsData.forEach(event=>{
                                          
                                          const regArry = event.locations;
            
                                          let checkEventForFilter = false;
                                          
                                          regArry.forEach(reg=>{
                                                if(region.indexOf(reg) > -1){
                                                      checkEventForFilter = true;
                                                }
                                          })
                                          if(checkEventForFilter){
                                                filteredEventsData.push(event);
                                          }
                                    })
                              }
                              filtredBefore = true;
                              isFilteredApplied=true;
                        }
      
                        // Filter BY Categories 
                        if(categories.length > 0){
            
                              const catNameArray =  [];
                              sortState.categories.forEach(cat=>{
                                    if(categories.indexOf(cat.value.toString()) > -1){
                                          catNameArray.push(cat.name);
                                    }
                              })
            
                              if(filtredBefore) {
                                          
                                    const selectedCatFilter = [];
                                    
                                    filteredEventsData.forEach(event=>{
                                          
                                          const catArry = event.categories.split(",");
            
                                          let checkEventForFilter = false;
            
                                          catArry.forEach(cat=>{
                                                // console.log(cat,catNameArray)
                                                // console.log(catNameArray.indexOf(cat))
                                                if(catNameArray.indexOf(cat) !== -1){
                                                      checkEventForFilter = true;
                                                }
                                          })
            
                                          if(checkEventForFilter){
                                                selectedCatFilter.push(event);
                                          }
            
                                    })
                                    filteredEventsData = selectedCatFilter;
                              }
                              else {
                              
                                    allevents.eventsData.forEach(event=>{
                                          
                                          const catArry = event.categories.split(",");
                                          
                                          let checkEventForFilter = false;
            
                                          catArry.forEach(cat=>{
                                                // console.log(cat,catNameArray)
                                                // console.log(catNameArray.indexOf(cat))
                                                if(catNameArray.indexOf(cat) !== -1){
                                                      checkEventForFilter = true;
                                                }
                                          })
                                          if(checkEventForFilter){
                                                filteredEventsData.push(event);
                                          }
                                    })
                              }
            
                              filtredBefore = true;
                              isFilteredApplied=true;
                        }
                        
                        if(startDate != null || endDate!==null){

                              let finalStartDate = "";
                              let finalEndDate = "";

                              let intialEventsData = filtredBefore ? filteredEventsData : allevents.eventsData;
                              let filtesEventsByDate = [];

                              if(startDate !==null){
                                    const start_dd        = ("0"+ startDate.getDate()).slice(-2);
                                    const start_mm        = ("0"+(startDate.getMonth() + 1)).slice(-2);
                                    const start_yyyy      = startDate.getFullYear();
                                    finalStartDate = start_yyyy+"-"+start_mm+"-"+start_dd;
                              }

                              if(endDate !==null){
                                    const end_dd        = ("0"+ endDate.getDate()).slice(-2);
                                    const end_mm        = ("0"+(endDate.getMonth() + 1)).slice(-2);
                                    const end_yyyy      = endDate.getFullYear();
                                    finalEndDate = end_yyyy+"-"+end_mm+"-"+end_dd;
                              }


                              if(finalStartDate !=="" && finalEndDate !==""){
                                    
                                    intialEventsData.forEach(event=>{
                                          if(event.start_date >= finalStartDate && event.start_date <= finalEndDate) {
                                                filtesEventsByDate.push(event) 
                                          }
                                    })
                                          
                              }
                              else if(finalStartDate !=="" && finalEndDate === "") {

                                    intialEventsData.forEach(event=>{
                                          if(event.start_date >= finalStartDate) {
                                                filtesEventsByDate.push(event) 
                                          }
                                    })
                              }
                              else if(finalStartDate === "" && finalEndDate !=="") {

                                    intialEventsData.forEach(event=>{
                                          if(event.start_date <= finalEndDate) {
                                                filtesEventsByDate.push(event) 
                                          }
                                    })
                              }
                              filteredEventsData = filtesEventsByDate;
                              filtredBefore = true;
                              isFilteredApplied=true;
                        } 

                        // Filter Event Listing by HIDE FULLY BOOKED EVENTS
                        if(state.filterPoints.fully_booked) {
                             
                              if(filtredBefore) {
                                    const hideFullyBookedEvents = [];
                                    filteredEventsData.forEach(event=>{

                                          if(event.is_soldout !== "1") {

                                                hideFullyBookedEvents.push(event);
                                          }
                                    })

                                    filteredEventsData = hideFullyBookedEvents;
                              }
                              else {
                                    
                                    allevents.eventsData.forEach(event=>{

                                          if(event.is_soldout !== "1") {

                                                filteredEventsData.push(event);
                                          }
                                    })
                              }
                              filtredBefore = true;
                              isFilteredApplied=true;
                        }
                        
                        // Filter Event Listing by HIDE ONLINE EVENTS
                        if(state.filterPoints.hide_online_event){

                              if(filtredBefore) {

                                    const hideOnlineEvents = [];
                                    filteredEventsData.forEach(event=>{

                                          if(!["5","6","7"].includes(event.tickets_type)) {
                                                hideOnlineEvents.push(event);
                                          }
                                          
                                    })

                                    filteredEventsData = hideOnlineEvents;
                              }
                              else {
                                    allevents.eventsData.forEach(event=>{

                                          if(!["5","6","7"].includes(event.tickets_type)) {
                                                filteredEventsData.push(event);
                                          }
                                    })
                              }
                              filtredBefore = true;
                              isFilteredApplied=true;
                        }

                        // Filter Event Listing by ONLINE EVENTS
                        if(state.filterPoints.online_event){

                              if(filtredBefore) {
                                    const showOnlineEvents = [];
                                    filteredEventsData.forEach(event=>{

                                          if(["5","6","7"].includes(event.tickets_type)) {
                                                showOnlineEvents.push(event);
                                          }
                                    })

                                    filteredEventsData = showOnlineEvents;
                              }
                              else {

                                    allevents.eventsData.forEach(event=>{
                                          if(["5","6","7"].includes(event.tickets_type)) {
                                                filteredEventsData.push(event);
                                          }
                                    })
                              }
                              filtredBefore = true;
                              isFilteredApplied=true;

                        }
      
                        // Filter Event Listing by HIDE STANDING ONLY EVENTS
                        if(state.filterPoints.standby_events){

                              if(filtredBefore) {
                                    const hideStandingOnlyEvents = [];
                                    filteredEventsData.forEach(event=>{

                                          if(!event.standing_only) {

                                                hideStandingOnlyEvents.push(event);
                                          }
                                    })

                                    filteredEventsData = hideStandingOnlyEvents;
                              }
                              else {
                                    allevents.eventsData.forEach(event=>{
                                          
                                          if(!event.standing_only) {

                                                filteredEventsData.push(event);
                                          }
                                    })
                              }
                              filtredBefore = true;
                              isFilteredApplied=true;
                        }
      
                        if(filtredBefore){
                              
                              dispatch(AllEventsActions.setFilterEventsData({filteredEvents:filteredEventsData,isFilteredApplied:isFilteredApplied}));
                              
                        }else if(!isFilteredApplied) {
                              // for delete all options from selection dropdown need to clear filter in that case
                              dispatch(AllEventsActions.setFilterEventsData({filteredEvents:filteredEventsData,isFilteredApplied:false}));
                        }
            }
      }

      const changeCheckBoxHandler=(event)=>{

                  event.stopPropagation();

                  const key = event.target.name;
                  const value = event.target.checked;

                  if(key === "online_event" || key === "hide_online_event"){

                        setState(prevState=>{
                              return {
                                    ...prevState,
                                    filterPoints:{
                                          ...prevState.filterPoints,
                                          hide_online_event:false,
                                          online_event:false,
                                          [key]:value
                                    }
                              }
                      })

                  }
                  else {

                        setState(prevState=>{
                                return {
                                          ...prevState,
                                          filterPoints:{
                                                ...prevState.filterPoints,
                                                [key]:value
                                          }
                                }
                        })
                  }


                  setFilter(true);
      }

      const clearTomSelectValues =()=>{
            sortbyRef.current.tomselect.setValue("");
            regionRef.current.tomselect.setValue([]);
            categoriesRef.current.tomselect.setValue([]);
      }

      const clearFilterHandler = ()=>{
            setState({
                  filterPoints:{
                        fully_booked:false,
                        hide_online_event:false,
                        online_event:false,
                        standby_events:false,
                  }
            })
            setStartDate(null);
            setEndDate(null);
            setFilter(false)
            setSortby("")
            setRegion([])
            setCategories([])
            clearTomSelectValues();
            dispatch(AllEventsActions.clearFilter());
      }

      const applyFilterHandler = (e)=>{
           
            e.stopPropagation();
            if(allevents.isFilteredApplied){
                  clearFilterHandler();
                  return;
            }
            else {

                  setFilter(true)
                  checkFilterHandler();
            }

      }
      
      const collapseHandler = ()=>{

            if(collapseRef.current.getAttribute('aria-expanded') === "false"){

                  if(allevents.isFilteredApplied || allevents.isFilteredSort){

                        clearFilterHandler();
                  }
            }
      }

      const chnageStartDateHandler = (date)=>{

            if(endDate !==null){

                  if(date > endDate){
                        setStartDate(date)
                        setEndDate(null)
                        setFilter(true)
                  }
                  else if(date <= endDate){
                        setStartDate(date)
                        setFilter(true)
                  }
            }
            else {
                  setStartDate(date)
                  setFilter(true)
            }
            
      }

      const chnageEndDateHandler = (date)=>{

            if(date >= startDate || startDate ===null){
                  setEndDate(date)
            }
            setFilter(true)
      }

      return (

                        <div className="container-fluid container__content-header">
                              <div className="row row__content-header">
                                    <div className="col-xl-12 col-xxl-5 d-xl-flex align-items-xl-end">
                                          <h1 className="content-header__title display-1 fw-normal mx-xxl-0 mb-3 mb-md-2 mb-xl-0">Our current events</h1>
                                    </div>
                                    <div className="col-xl-12 col-xxl-7 d-xl-flex align-items-xl-end justify-content-xl-end">
                                          <div className="grid__listing-filter d-grid d-flex flex-column flex-md-row flex-md-wrap mt-xl-2">
                                                <select id="selectEventSort" className="form-control tomselect tomselect-xl-lg" aria-label="Sort by" ref={sortbyRef} >
                                                </select>

                                                <select id="selectEventLocation" className="form-control tomselect tomselect-xl-lg" aria-label="Select Location" multiple={true} ref={regionRef}>
                                                </select>

                                                <select id="selectEventCategory" className="form-control tomselect tomselect-xl-lg" aria-label="Select Category" multiple={true} ref={categoriesRef}>
                                                      <p>2</p>
                                                </select>

                                                <div className="buttons-wrapper">
                                                      <button className="btn btn-xl-lg btn-outline-primary btn__plus-to-x" type="button" data-bs-toggle="collapse" data-bs-target="#collapseListingFilters" aria-expanded="false" aria-controls="collapseListingFilters" ref={collapseRef} onClick={collapseHandler}>
                                                            <span className="icon__plus"></span>
                                                      </button>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              {/* <!-- /.row --> */}

                              <div className="row row__listing-filters">
                                    <div className="col-xl-12">
                                          <div className="collapse" id="collapseListingFilters">
                                                <div className="collapse__listing-filters">
                                                      <div className="d-flex flex-column flex-md-row flex-md-wrap justify-content-between">
                                                            <div className="col__date-range col__form-group d-flex flex-column pe-md-3 pe-xl-0">
                                                                  <div className="form-datepicker form-datepicker-xl-lg">
                                                                        {/* <input id="EventStartDate" className="form-control__datepicker form-control__datepicker-xl-lg" placeholder="Event Start Date"/> */}
                                                                        <DatePicker selected={startDate}  onChange={(date:Date) => chnageStartDateHandler(date)} dateFormat="dd-MM-yyyy" placeholderText="Event Start Date" minDate={new Date()}/>
                                                                  </div>
                                                                  <div className="form-datepicker form-datepicker-xl-lg">
                                                                        {/* <input id="EventEndDate" className="form-control__datepicker form-control__datepicker-xl-lg" placeholder="Event End Date"/> */}
                                                                        <DatePicker selected={endDate}  onChange={(date:Date) => chnageEndDateHandler(date)} dateFormat="dd-MM-yyyy" placeholderText="Event End Date" minDate={new Date(startDate)}/>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.col__date-range --> */}

                                                            <div className="col__toggles col__form-group d-flex flex-column ps-md-3 ps-xl-0">
                                                                  <div className="toggle-group d-flex flex-column">
                                                                        <div className="form-check form-switch form-switch__right">
                                                                              <input className="form-check-input" type="checkbox" id="flexSwitchOne" name="fully_booked" checked={state.filterPoints.fully_booked} onChange={(event)=>changeCheckBoxHandler(event)}/>
                                                                              <label className="form-check-label" htmlFor="flexSwitchOne">Hide Fully Booked Events</label>
                                                                        </div>
                                                                        <div className="form-check form-switch form-switch__right">
                                                                              <input className="form-check-input" type="checkbox" id="flexSwitchTwo" name="hide_online_event" checked={state.filterPoints.hide_online_event} onChange={(event)=>changeCheckBoxHandler(event)}/>
                                                                              <label className="form-check-label" htmlFor="flexSwitchTwo">Hide Online Events</label>
                                                                        </div>
                                                                       
                                                                  </div>
                                                                  {/* <!-- /.toggle-group --> */}
                                                                  <div className="toggle-group d-flex flex-column">
                                                                        <div className="form-check form-switch form-switch__right">
                                                                              <input className="form-check-input" type="checkbox" id="flexSwitchThree" name="online_event" checked={state.filterPoints.online_event}  onChange={(event)=>changeCheckBoxHandler(event)}/>
                                                                              <label className="form-check-label" htmlFor="flexSwitchThree">Online Events</label>
                                                                        </div>
                                                                        {/* <div className="form-check form-switch form-switch__right">
                                                                              <input className="form-check-input" type="checkbox" id="flexSwitchFour" name="filter_by_fa" checked={state.filterPoints.filter_by_fa} onChange={(event)=>changeCheckBoxHandler(event)}/>
                                                                              <label className="form-check-label" htmlFor="flexSwitchFour">Show Favourites Only</label>
                                                                        </div> */}
                                                                        <div className="form-check form-switch form-switch__right">
                                                                              <input className="form-check-input" type="checkbox" id="flexSwitchFive"  name="standby_events" checked={state.filterPoints.standby_events}  onChange={(event)=>changeCheckBoxHandler(event)}/>
                                                                              <label className="form-check-label" htmlFor="flexSwitchFive">Hide Standing Only Events</label>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.toggle-group --> */}
                                                            </div>
                                                            {/* <!-- /.col__toggles --> */}

                                                            <div className="col__cta col__form-group d-flex flex-column align-items-end">
                                                                  <button className="btn btn-primary btn-xl-lg rounded-pill fw-bold ms-auto" type="submit" style={{"backgroundColor":allevents.isFilteredApplied && "#ff0000"}} onClick={(e)=>applyFilterHandler(e)}>
                                                                        {!allevents.isFilteredApplied ? "Apply Filters": "Clear Filters"}
                                                                  </button>
                                                            </div>
                                                            {/* <!-- /.col__cta --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.collapse__listing-filters --> */}
                                          </div>
                                          {/* <!-- /.collapse#collapseListingFilters --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                              </div>
                              {/* <!-- /.row__listing-filters --> */}
                        </div>
      )
}

export default FilterBar;
