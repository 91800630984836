import React from "react";

import { useParams } from 'react-router-dom';
import { iframeSite } from "./../../Shared/Constants";

const FeedbackReport = () => {


      const {id}   =     useParams();
      // let path = useLocation();

      let route = "get_feedback_report_tfm";
      
      return (
            <>
                  <iframe width="560" height="315" src={`${iframeSite}${route}/${id}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" style={{"position": "absolute", "width": "100%", "height": "100%"}}>
                  </iframe>
                  
            </>

      );
};
export default FeedbackReport;
