import React from 'react';

import { useDispatch, useSelector } from "react-redux";

import { BookingModuleActions } from "./../../../../Store/BookingModule/BookingModule";
import { TicketRelease, addFreeBookingData } from "./../../../../Store/BookingModule/BookingModuleActions";
import { UiActions } from '../../../../Store/Ui/Ui';

const OrderSummury = (props) => {

      const dispatch          =       useDispatch();
      const state             =       useSelector(state=>state.bookingmodule);
      const eventdetail       =       useSelector(state=>state.eventdetail);
      const token             =       localStorage.getItem("nhs_access_token");
      
      const continueOnOrderSummuryPageHandler = ()=>{

            const subTotal = state.bookingDetails.promocode.status === "valid" ? (state.bookingDetails.promocode.totalAmount)  :state.bookingDetails.subTotal
          
            if (subTotal > 0 && state.bookingDetails.quantity > 0) {

                  dispatch(BookingModuleActions.setBookingStep({step:"PaymentPage"}));

            } 
            else if(state.bookingDetails.quantity > 0) {
                   
                  const nhs_session_block_id = localStorage.getItem("nhs_session_block_id");
                  const obj ={

                        total: state.bookingDetails.promocode.status === "valid" ?  (+state.bookingDetails.promocode.totalAmount) : state.bookingDetails.subTotal,
                        token: token,
                        cid: state.bookingDetails.promocode.status === "valid" ? state.bookingDetails.promocode.discount_session : "",
                        promocode:state.bookingDetails.promocode.status === "valid" ? state.bookingDetails.promocode.promocode : "",
                        book: state.bookingDetails.quantity,
                        event:state.event_id,
                        sch:state.bookingDetails.showId,
                        mKey: "",
                        // vat: 5,
                        vat: state.bookingDetails.promocode.status === "valid" ?  (+state.bookingDetails.promocode.vat) :state.bookingDetails.vat,
                        block_id:nhs_session_block_id,
                        card_identifierx: "",
                        securityCode: "",
                        contacts: [],
                        ticket_type:[],
                        group_availablity:state.group_availablity
                  }

                  dispatch(UiActions.setSpinner({status:"request"}))
                  dispatch(addFreeBookingData({booking:obj}))
                  // props.clearTimer();
            }

      }

      const backOnOrderSummuryPageHandler = ()=>{

            const nhs_session_block_id    =     localStorage.getItem("nhs_session_block_id");
            const group_availablity       =     eventdetail.values.group_availablity;
            let step                      =     "EvnentsCalenderPage";

            if(nhs_session_block_id){
                  
                  dispatch(TicketRelease({token:token,session_id:nhs_session_block_id,group_availablity:group_availablity}))
            }

            dispatch(BookingModuleActions.clearBookingModuleState({step:step,group_availablity:group_availablity}));
            // dispatch(UiActions.clearTimer());
            props.clearTimer();
      }

      const dateAndTimeFormatHandler = (dateTime,showTime)=>{
            const date0 = dateTime.split(" ")[0].split("-");
            const date0Final = `${date0[2]}/${date0[1]}/${date0[0]}`;
            const date1Final = showTime.split(" ")[3];

            return `${date0Final} at ${date1Final}`;
      }

      const priceCalculatorHandler = ()=>{

            let price = 0;
            if(state.bookingDetails.promocode.status ==="valid"){

                  if(state.bookingDetails.promocode.totalAmount > 0){
                        price = (parseFloat(state.bookingDetails.promocode.totalAmount) - parseFloat(state.bookingDetails.promocode.vat)).toFixed(2); 
                  }
                  
            }
            else {
                  if(state.bookingDetails.subTotal > 0){

                        price = (state.bookingDetails.subTotal - state.bookingDetails.vat).toFixed(2);
                  }
            }

            return price;
      }

      const vatCalculatorHandler = ()=>{

            let vat = 0;
            if(state.bookingDetails.promocode.status ==="valid"){

                  if(state.bookingDetails.promocode.totalAmount > 0){ 

                        vat = parseFloat(state.bookingDetails.promocode.vat).toFixed(2)
                  }

            }
            else {
                  if(state.bookingDetails.subTotal > 0){

                        vat = state.bookingDetails.vat.toFixed(2);
                  }

            }

            return vat;
      }
      
      return (

            <div id="bookTicketsStep3" className="content content__order-summary">
                  <div className="content__body">
                        <h4 className="h-label">Order Summary</h4>
                        <ul className="list list-unstyled list__order-summary mb-0">
                              <li>{state.bookingDetails.quantity + (state.bookingDetails.quantity > 1 ? " Tickets" : " Ticket")}  ({eventdetail.eventInfo.tickets_type})</li>
                              <li>Date: {dateAndTimeFormatHandler(state.performanceInfo.date_time,state.performanceInfo.show_time)}</li>
                              <li>Price: £{priceCalculatorHandler()} + £{vatCalculatorHandler()} VAT</li>
                        </ul>
                  </div>
                  {/* <!-- /.content__body --> */}
                  <div className="content__footer border-top p-0">
                        <div className="wrapper d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
                              <div className="col__left d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-between justify-content-md-start w-100">
                                          <h4 className="h-label mb-0">Subtotal</h4>
                                          <div>£{state.bookingDetails.promocode.status ==="valid"?  parseFloat(state.bookingDetails.promocode.totalAmount).toFixed(2):state.bookingDetails.subTotal.toFixed(2)}</div>
                                    </div>
                              </div>
                              <div className="col__right border-left-md flex-shrink-md-0">
                                    <div className="buttons-wrapper d-flex flex-wrap gap-0 mb-0">
                                          <button type="button" className="btn__back btn btn-primary btn-parimary__off-white rounded-0 w-50"
                                          onClick={backOnOrderSummuryPageHandler}
                                          >Back</button>
                                          <button type="button" className="btn__continue btn btn-primary rounded-0 w-50"
                                          onClick={continueOnOrderSummuryPageHandler}
                                          >Continue</button>
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.wrapper --> */}
                  </div>
                  {/* <!-- /.content__footer --> */}
            </div>
      )
}

export default OrderSummury;
