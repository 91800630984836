export const passwordChangeValidation=(value, type) =>{
      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }

      if(type === "current_password"){
            if(value[type].value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please enter your old password";
            }
            else if(value[type].value.length < 6)
            {
                  isValid=false;
                  validationMessage = "Password length must be greater than 6 characters or digits.";
            }
             
      }

      if(type === "new_password"){
            if(value[type].value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please enter a new password";
            }
            else if(value[type].value.length < 6)
            {
                  isValid=false;
                  validationMessage = "Password length must be greater than 6 characters or digits.";
            }
             
      }

      if(type === "confirm_new_password"){
            if(value[type].value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please enter your new password again.";
            }
            else if(value[type].value !== value.new_password.value)
            {
                  isValid=false;
                  validationMessage = "That’s not a match.  Please review your entries to ensure they match.";
            }
             
      }
      
      return {isValid,validationMessage};
}

