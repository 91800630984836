
import {AllEventsActions} from "./AllEvents";
import {UiActions} from "./../Ui/Ui";
// import {AuthActions} from "./../Auth/Auth";
import { BaseUrl } from "../../Shared/Constants";
import axios from "axios";

export const fetchEventsData = (payload)=> {

        return async (dispatch)=>{
               
                const sendRequest =async () =>{
                        axios.post(BaseUrl + '/event/upcoming_events_pagination',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client':'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              const result = response.data;
                              dispatch(AllEventsActions.setEventsData(result));
                                
                        })
                        .catch(function (error) {

                              if(error.response){
                                    if(error.response.status === 404){

                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                                    }
                                    else if(error.response.status === 400){
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}));
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                              }
                                
                        });
                }

                await sendRequest();
                
        }
}
