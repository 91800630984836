import React from "react";

import { useNavigate } from "react-router-dom";
import iconLocationPinBlack from "./../../../../assests/images/icon-location-pin-black.svg"

const PastSingleComponent = (props) => {
      
      const navigate = useNavigate();
      const locations = props.location.split(",");

      const onClickFeedbackHandler = ()=>{

            if(props.is_feedback_left !== "1"){

                  const path = "/leaveFeedBack/"+ props.booking_id
                  // console.log(path)
                  navigate(`${path}`);
            }
      }

      const dateFormathandler = ()=>{
            const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

            let day = weekday[props.date.getDay()];
            let date1 = props.date.getDate() + nth(props.date.getDate());
            let month = props.date.toLocaleString('default', { month: 'long' })
            let year = props.date.getFullYear();
            return day+' '+date1+' '+month+' '+year;
      }

      const nth = (d)=> {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                  case 1:  return "st";
                  case 2:  return "nd";
                  case 3:  return "rd";
                  default: return "th";
            }
      }

      return (

                  <li className="list-item">
                        <div className="event d-flex flex-column flex-md-row align-items-md-stretch align-items-center-start">
                              <div className="event__poster flex-shrink-0">
                                    <img className="img-fluid" 
                                    src={props.event_image} 
                                    // srcSet={props.blur_event_image} 
                                    alt=""/>
                              </div>
                              <div className="event__body d-flex flex-column flex-lg-row align-items-lg-center">
                                    <div className="event__details d-flex flex-column flex-xxl-row align-items-xxl-center">
                                          <div className="event__details-name mb-xxl-0">
                                                <h3 className="event__name">{props.event_name}</h3>
                                                <p className="event__time mb-xxl-0">{dateFormathandler()  +' – '+ props.time }</p>
                                                {/* <p className="event__time mb-xxl-0">{props.date.getDate()} {props.date.toLocaleString('default', { month: 'long' })} – {props.time}</p> */}
                                                <p className="event__time mb-xxl-0" style={{"fontWeight":"700"}}>{props.tickets} {props.tickets > 1 ? 'tickets':'ticket'}</p>
                                          </div>
                                          <div className="event__details-location">
                                                <div className="event-location">
                                                      <div className="icon-wrapper flex-shrink-0">
                                                            <img className="icon__location-pin" src={iconLocationPinBlack} alt=""/>
                                                      </div>
                                                      <div className="location">
                                                            {/* {props.location} */}
                                                            <span className="building-name">{locations[0]}{locations[1] && ","}</span>
                                                            <span className="street d-lg-block">{locations[1]} {locations[2] && ","}</span>
                                                            <span className="city d-block">{locations[2]}</span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.event__details --> */}
                                    <div className="event__cta">
                                          <div className="buttons-wrapper d-flex flex-column align-items-md-start">
                                                <button disabled={props.is_feedback_left === "1"} onClick={onClickFeedbackHandler} type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0" >
                                                      {props.is_feedback_left === "1" ? "Feedback Left":"Leave Feedback"}
                                                </button>
                                          </div>
                                    </div>
                              </div>
                              {/* <!-- /.event__body --> */}
                        </div>
                        {/* <!-- /.event --> */}
                  </li>

      )
}

export default PastSingleComponent;
