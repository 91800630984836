import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      eventsData:[],
      filteredEvents:[],
      requested:false,
      // isFilteredSort:false,
      isFilteredApplied:false
}

const AllEventsSlice = createSlice({
      name:'allevent',
      initialState:initialState,
      reducers:{
            setEventsData(state,action){
                  state.eventsData        =       action.payload.data;
                  state.requested         =       true;
            },
            clearEventsData(state,action){
                  state.eventsData        =       [];
                  state.filteredEvents    =       [];
                  state.requested         =       false;
            },
            setFilterEventsData(state,action){
                  state.filteredEvents = action.payload.filteredEvents;
                  // state.isFilteredSort = action.payload.isFilteredSort;
                  state.isFilteredApplied = action.payload.isFilteredApplied;
            },
            clearFilter(state,action){
                  state.filteredEvents = [];
                  // state.isFilteredSort = false;
                  state.isFilteredApplied = false;
            },
            reset: ()=> initialState
            
      }
})



export const AllEventsActions = AllEventsSlice.actions;
export default AllEventsSlice.reducer;

//--------------------------------------------------------------------------//
// ---------------------old Slice without reset options----------------------
//--------------------------------------------------------------------------//



// import { createSlice } from "@reduxjs/toolkit";


// const AllEventsSlice = createSlice({
//       name:'allevent',
//       initialState:{
//             eventsData:[],
//             filteredEvents:[],
//             requested:false,
//             isFilteredSort:false,
//             isFilteredApplied:false
//       },
//       reducers:{
//             setEventsData(state,action){
//                   state.eventsData        =       action.payload.data;
//                   state.requested         =       true;
//             },
//             clearEventsData(state,action){
//                   state.eventsData        =       [];
//                   state.filteredEvents    =       [];
//                   state.requested         =       false;
//             },
//             setFilterEventsData(state,action){
//                   state.filteredEvents = action.payload.filteredEvents;
//                   state.isFilteredSort = action.payload.isFilteredSort;
//                   state.isFilteredApplied = action.payload.isFilteredApplied;
//             },
//             clearFilter(state,action){
//                   state.filteredEvents = [];
//                   state.isFilteredSort = false;
//                   state.isFilteredApplied = false;
//             }
            
//       }
// })



// export const AllEventsActions = AllEventsSlice.actions;
// export default AllEventsSlice.reducer;