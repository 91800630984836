import React,{ useState, useEffect } from "react";

import { checkValidityChangeEmail } from "./../../Shared/UtilityChangeEmail"
import { useDispatch, useSelector } from "react-redux";
import { emailChangeRequest } from "./../../Store/Auth/AuthActions";
import { UiActions } from "../../Store/Ui/Ui";

const ChangeEmail = (props) => {

      const [state,setState] = useState({
            current_email:{value:"",error:false,message:""},
            new_email:{value:"",error:false,messag:""}
      })

      const changeEmailRequest = useSelector(state => state.ui.changeEmailRequest)
      const dispatch = useDispatch();

      useEffect(()=>{

            const currentEmail = localStorage.getItem("nhs_access_email")

            setState(prevState=>{
                  return {
                        ...prevState,
                        current_email:{...prevState.current_email,value:currentEmail}
                  }
            })
            
      },[])

      const channgeMailTypeHandler = (event)=>{

            const type = event.target.name;
            const value = event.target.value;

            setState(prevState=>{
                    
                  return {
                        ...prevState,
                        [type]:{...prevState[type],value:value}
                  }
            })
      }


      const submitEmailChangeHandler = (event)=>{
            event.preventDefault();

            const res = checkValidityChangeEmail(state.new_email.value,state.current_email.value)
            if(res.isValid){
                    
                  const token= localStorage.getItem('nhs_access_token');

                  const fname = localStorage.getItem("nhs_access_name").split(" ")[0];
                  const data = {email:state.new_email.value,fname:fname};
                  dispatch(UiActions.setChangeEmailRequest({request:true}))
                  dispatch(emailChangeRequest({data:data,token:token}))
                    
            }
            else {
                  setState(prevState=>{
                    
                        return {
                              ...prevState,
                              new_email:{...prevState.new_email,error:true,messag:res.validationMessage}
                        }
                  })
            }
      }

      const clearErrorHandler = ()=>{
            setState(prevState=>{
                  return {
                        ...prevState,
                        new_email:{...prevState.new_email,error:false,messag:""}
                  }
            })
      }
          
      return (

            <form>
                  {/* <!-- Change Email --> */}
                  <div className="row row__title border-top-0">
                        <div className="col-md-12">
                              <div className="heading-wrapper">
                                    <h3 className="heading fw-bold mb-0">Change Email</h3>
                                   
                              </div>
                        </div>
                  </div>
                  {/* <!-- /.row --> */}

                  <div className="row">
                        <div className="col__left col-md-6">
                              <div className="form-group">
                                    <label htmlFor="changeEmailInputOldEmail" className="form-label fw-normal">Enter Old Email</label>
                                    <input type="email" className="form-control form-control-xl-lg" id="changeEmailInputOldEmail" value={state.current_email.value} readOnly autoComplete="off"/>
                              </div>
                              {/* <!-- /.form-group --> */}
                              <div className="form-group">
                                    <label htmlFor="changeEmailInputNewEmail" className="form-label fw-normal">Enter New Email</label>
                                    <input type="email" className="form-control form-control-xl-lg" id="changeEmailInputNewEmail" name="new_email" readOnly={changeEmailRequest} value={state.new_email.value} onChange={channgeMailTypeHandler} onFocus={clearErrorHandler} autoComplete="off" disabled={changeEmailRequest || props.nhs_vendor==='2'}/>
                                    {state.new_email.error &&
                                    <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>{state.new_email.messag}</label>}
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                        <div className="col__right col-md-6 d-md-flex align-items-md-end">
                              <div className="form-group form-group__buttons mt-lg-auto">
                                    <button type="submit" className="btn btn-xl-lg btn-primary fw-bold" onClick={submitEmailChangeHandler} disabled={changeEmailRequest || props.nhs_vendor==='2'}>
                                          
                                          {!changeEmailRequest && "Submit"}
                                          {changeEmailRequest && "Please wait.."}
                                          {changeEmailRequest && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                    </button>
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                  </div>
                  {/* <!-- /.row --> */}
            </form>
                                                           
      )
}

export default ChangeEmail;
