import React,{ useEffect } from "react";

import { useDispatch,useSelector } from "react-redux";

import AmendBookings from "./AmendBookings";
import CancleBooking from "./CancleBooking";

import { BookingActions } from "../../../../Store/Bookings/Bookings";


const CancleBookingModule = (props) => {

      const dispatch =  useDispatch();
      const bookings = useSelector(state => state.bookings.cancleBookings)
      useEffect(()=>{
            document.body.style.overflow  = "hidden";
            document.body.style.paddingRight = "17px";

            return ()=>{
                  document.body.style.overflow = "";
                  document.body.style.paddingRight = "";
            }

      },[])

      const closeCancleBookingHandler = ()=>{

            if(!bookings.isRequesting) {
                  dispatch(BookingActions.closeCancleBookings())
            }
      }

      // console.log(bookings.isCancling)
      return (

            <React.Fragment>
            <div className="modal-backdrop fade show"></div>
            <div id="modalAmendBooking" className={["modal modal__amend-booking fade show",props.show === "entering" && "canclemodalOpen", props.show === "exiting" && "canclemodalClosed" ].join(" ")} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"  style={{"display":"block"}} >
            
                  <div className="modal-dialog modal-dialog-scrollable" >
                  
                        <div className="modal-content">
                        
                              <div className="modal-body p-0">

                                    <button type="button" className="btn btn-close btn-close__absolute tfm-modal__toggle p-0" onClick={closeCancleBookingHandler}>

                                    </button>
                                    <div className="content__amend-booking mx-md-auto w-100">

                                          {/* <!--  --> */}
                                          <div className="content">
                                                <div className="content__body">
                                                
                                                      <CancleBooking />
                                                      
                                                      {(bookings.step === "initial" && bookings.isCancling) &&
                                                      <React.Fragment>
                                                            <hr/>
                                                            <AmendBookings /> 
                                                      </React.Fragment>
                                                      }
                                                      
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /#bookTicketsStep2.content --> */}

                                    </div>
                                    {/* <!-- /.content__amend-booking --> */}

                              </div>
                              {/* <!-- /.modal-body --> */}
                              

                        </div>
                        {/* <!-- /.modal-content --> */}
                        

                  </div>
                 
                  {/* <!-- /.modal-dialog --> */}

                  <div className="tfm-modal-overlay tfm-modal__close"></div>
            </div>
           
            </React.Fragment>                   
            
            
      )
}

export default CancleBookingModule;
