
export const checkValidityAccessNeeds=(value, type) =>{
      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }

      if(type === "name"){
            if(value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please enter your name";
                  return {isValid,validationMessage};
            }

            if(value.length <2)
            {
                  isValid = false;
                  validationMessage = "Please enter a valid name";
                  return {isValid,validationMessage};
            }
      }

      if(type === "access_needs"){
            if(value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please provide a description of your access needs (please keep it short and snappy)";
                  return {isValid,validationMessage};
            } 
      }

      if(type === "email"){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value || value.trim()== "")
            {
                  isValid = false;
                  validationMessage = "Please enter your email address";
                  return {isValid,validationMessage};
            }
            if(!re.test(String(value).toLowerCase()) || !value)
            {
                  isValid = false;
                  validationMessage = "Please enter a valid email address";
                  return {isValid,validationMessage};
            }
      }

      return {isValid,validationMessage};
}

