import React,{ useState, useRef } from 'react'

import { useDispatch, useSelector } from "react-redux";

import logoVisa from "./../../../../assests/images/logo-visa.png";
import logoVisaa2x from "./../../../../assests/images/logo-visa@2x.png";
// import logoVisaa2x from "../../../../../assests/images/logo-visa@2x.png 2x";
import logoMasterCard from "./../../../../assests/images/logo-mastercard.png";

import checkValidationPayment from "./../../../../Shared/UtilityPayment";
import { TicketRelease, sendBookingData } from "./../../../../Store/BookingModule/BookingModuleActions";
import { BookingModuleActions } from "./../../../../Store/BookingModule/BookingModule";
import { UiActions } from '../../../../Store/Ui/Ui';
import axios from 'axios';
import { BaseUrl } from '../../../../Shared/Constants';

const PaymentPage = (props) => {

      const [state,setState] = useState({
            nhs_name_on_card:{value:"",error:false,message:"The cardholder name is empty"},
            nhs_card_number:{value:"",error:false,message:"The card number is empty"},
            nhs_exp:{value:"",error:false,message:"The expiry date is empty"},
            expirey_date:{value:"",error:false,message:"cvc is empty"},
            nhs_cvc:{value:"",error:false,message:"cvc is empty"}
      });

      const eventdetail       =     useSelector(state=>state.eventdetail);
      const bookingModule     =     useSelector(state=>state.bookingmodule);
      const secure3D          =     useSelector(state=>state.ui.secure3D);
      const token             =     localStorage.getItem("nhs_access_token");
      const ref               =     useRef();
      
      const dispatch = useDispatch();

      const changeValueHandler = (event)=>{
            const type = event.target.name;
            
            let val  = event.target.value;
            
            if(type === "nhs_exp")
            {
                  var str = val;
                  if(str.length > 5){
                        return;
                  }
                  const actual_val = str.replace(/\//g, '');
                  const display_val = val.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')

                  const checkValidation = checkValidationPayment(val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [type]:{...prevState[type],value:display_val,error:!checkValidation.isValid,message:checkValidation.validatingMessage},
                              expirey_date:{...prevState.expirey_date,value:actual_val}
                        }
                  })
            }
            else{

                  let actual_val = val;
                  if(type === "nhs_cvc"){
                       
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  if(type === "nhs_card_number"){
                       
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  const checkValidation = checkValidationPayment(actual_val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [type]:{...prevState[type],value:actual_val,error:!checkValidation.isValid,message:checkValidation.validatingMessage}
                        }
                  })
            }
            
      }

      const removeErrorMessageHandler = ()=>{

            setState(prevState=>{
                  return {
                        ...prevState,
                        nhs_name_on_card:{...prevState.nhs_name_on_card,error:false},
                        nhs_card_number:{...prevState.nhs_card_number,error:false},
                        nhs_exp:{...prevState.nhs_exp,error:false},
                        expirey_date:{...prevState.expirey_date,error:false},
                        nhs_cvc:{...prevState.nhs_cvc,error:false},
                  }
            })
      }

      const backOnPaymentPageHandler=()=>{

            const nhs_session_block_id = localStorage.getItem("nhs_session_block_id");

            const group_availablity = eventdetail.values.group_availablity;
            let step = "EvnentsCalenderPage";

            if(nhs_session_block_id){
                  dispatch(TicketRelease({token:token,session_id:nhs_session_block_id,group_availablity:group_availablity}))
            }
          
            dispatch(BookingModuleActions.clearBookingModuleState({step:step,group_availablity:group_availablity}));
            dispatch(UiActions.clearUiSpinner());
            // dispatch(UiActions.clearTimer());
            props.clearTimer()
      }

      const SendPaymentRequestHandler = ()=> {
                
            dispatch(UiActions.setSpinner({status:"request"}))
            let request             =     false;
            const checkedObject     =     {};
            const requestAllow      =     [];
            
            for(let i in state){
                  
                  const checkValidation   =       checkValidationPayment(state[i].value,i); 
                  checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,message:checkValidation.validatingMessage}
                  requestAllow.push(!checkValidation.isValid); 
            }

            setState(prevState=>{
                  return {
                        ...checkedObject
                  }
            });
                    
            request   =       requestAllow.includes(true);
            
            if(!request)
            {
                    
                  axios.post(BaseUrl + '/sagepay/WebInitiateSession',{data:null},{
                        headers: {
                              'token':token,
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response)
                        sagePayRequest(response.data.data.merchantSessionKey);
                        localStorage.setItem("nhs_mKey",response.data.data.merchantSessionKey);

                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response){
                              
                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else{
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        dispatch(UiActions.clearUiSpinner())

                    });

            }
            else {
                  dispatch(UiActions.clearUiSpinner())
            }

      }

      const sagePayRequest = (merchantSessionKey)=>{
               
            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .tokeniseCardDetails({
                  cardDetails: {
                        cardholderName: state.nhs_name_on_card.value,
                        cardNumber: state.nhs_card_number.value,
                        expiryDate: state.expirey_date.value,
                        securityCode: state.nhs_cvc.value
                  },
                  onTokenised: function(result) {
                           
                              if (result.success) {
                                    
                                    const card_identifier = result.cardIdentifier;

                                    const booking={

                                          total:bookingModule.bookingDetails.promocode.status === "valid" ?  (+bookingModule.bookingDetails.promocode.totalAmount) : bookingModule.bookingDetails.subTotal,
                                          token:token,
                                          cid:bookingModule.bookingDetails.promocode.status === "valid" ? bookingModule.bookingDetails.promocode.discount_session:'',
                                          promocode:bookingModule.bookingDetails.promocode.status === "valid" ? bookingModule.bookingDetails.promocode.promocode:"",
                                          book:bookingModule.bookingDetails.quantity,
                                          event:bookingModule.event_id,
                                          sch:bookingModule.bookingDetails.showId,
                                          mKey:localStorage.getItem('nhs_mKey'),
                                          vat: bookingModule.bookingDetails.promocode.status === "valid" ?  (+bookingModule.bookingDetails.promocode.vat) : bookingModule.bookingDetails.vat,
                                          block_id:localStorage.getItem('nhs_session_block_id'),
                                          card_identifier:card_identifier,
                                          securityCode: state.nhs_cvc.value,
                                          contacts:[],
                                          ticket_type:[],
                                          is_save:false,
                                          is_reusable:false,
                                          group_availablity:bookingModule.group_availablity

                                    }

                                    dispatch(sendBookingData({booking:booking}))
                                    // props.clearTimer()

                              } else{

                                    dispatch(UiActions.clearUiSpinner())
                                    
                                    const errors = result.errors;
                                    // console.log(errors)

                                    let nhs_card_number = false;
                                    let nhs_exp = false;
                                    let nhs_cvc = false;
                                    let nhs_name_on_card = false;

                                    errors.forEach(error => {
                                            
                                          let strng       = error.message;
                                          let incNumber   = strng.includes("number");
                                          let incExp      = strng.includes("date");
                                          let incHold     = strng.includes("cardholder");
                                          let incCvv      = strng.includes("code");
                                          if (incNumber) { nhs_card_number = true; }
                                          if (incExp) { nhs_exp = true; }
                                          if (incHold) { nhs_name_on_card = true; }
                                          if (incCvv) { nhs_cvc = true; }
                                    }) 

                                    if (nhs_name_on_card) 
                                    {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      nhs_name_on_card:{...prevState.nhs_name_on_card,error:true,message:"Card holder’s name is invalid"}
                                                }
                                          })
                                            
                                    }

                                    if (nhs_card_number) 
                                    {	
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      nhs_card_number:{...prevState.nhs_card_number,error:true,message:"Card number is invalid"}
                                                }
                                          })							
                                    }

                                    if (nhs_exp) 
                                    {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      nhs_exp:{...prevState.nhs_exp,error:true,message:"Expiry date is invalid"}
                                                }
                                          })

                                    }

                                    if(nhs_cvc) 
                                    {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      nhs_cvc:{...prevState.nhs_cvc,error:true,message:"CVC is invalid"}
                                                }
                                          })

                                    }

                                    if(!nhs_name_on_card && !nhs_card_number && !nhs_exp && !nhs_cvc){
                                          
                                          dispatch(UiActions.setAlert({model:true,message:errors[0].message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }

                              }
                    }
            });
      }
      
      if(bookingModule.secureData){

            // console.log(bookingModule,secure3D, "got secure Data")
            if(bookingModule.secureData.status === "3DAuth" && ref.current && secure3D){
                  // console.log("inside here.... payment page");
                  dispatch(BookingModuleActions.setSecureData({data:null}));
                  dispatch(UiActions.clearSecure3D());
                  ref.current.submit();
            }
                    
      }

      return (
            <React.Fragment>
                  {bookingModule.secureData &&
                  <form  ref={ref}  method="POST" style={{"visibility": "hidden","height":"0px"}} action={bookingModule.secureData && bookingModule.secureData.acsUrl}>
                          <input type="hidden" name="creq" value={bookingModule.secureData && bookingModule.secureData.cReq}/>
                          <input type="hidden" name="acsTransID" value={bookingModule.secureData && bookingModule.secureData.acsTransId}/>
                          <input type="hidden" name="threeDSSessionData" value={bookingModule.secureData && bookingModule.secureData.threeDSSessionData}/>
                          <input type="hidden" name="ThreeDSNotificationURL " value={bookingModule.secureData && bookingModule.secureData.ThreeDSNotificationURL} />
                          <button type="submit" style={{"visibility": "hidden"}}></button>
                  </form>
            }
            <div id="bookTicketsStep4" className="content content__checkout">
                  <div className="content__body">
                        <h4 className="h-label">Secure Checkout</h4>
                        <form className="form__card-info">
                              <div className="form-group">
                                    <label htmlFor="inputPaymentCardName" className="form-label">Name on Card</label>
                                    <input type="text" className="form-control" id="inputPaymentCardName" name="nhs_name_on_card" value={state.nhs_name_on_card.value} onChange={(event)=>changeValueHandler(event)} onFocus={removeErrorMessageHandler}/>
                                    {state.nhs_name_on_card.error &&
                                          <label htmlFor="inputPaymentCardName" className="form-label" style={{"color":"red","marginLeft":"10px"}}>
                                                {state.nhs_name_on_card.message}
                                          </label>
                                    }

                              </div>
                              <div className="form-group">
                                    <label htmlFor="inputPaymentCardNumber" className="form-label">Card Number</label>
                                    <input type="text" className="form-control" id="inputPaymentCardNumber" name="nhs_card_number" value={state.nhs_card_number.value} onChange={(event)=>changeValueHandler(event)} onFocus={removeErrorMessageHandler}/>
                                    {state.nhs_card_number.error &&
                                          <label htmlFor="inputPaymentCardNumber" className="form-label" style={{"color":"red","marginLeft":"10px"}}>
                                                {state.nhs_card_number.message}
                                          </label>
                                    }

                              </div>
                              <div className="row g-3 align-items-center">
                                    <div className="col-auto col__card-expiry">
                                          <label htmlFor="inputPaymentCardExpiry" className="form-label">Exp date (mm/yy)</label>
                                          <input type="text" className="form-control" id="inputPaymentCardExpiry" name="nhs_exp" value={state.nhs_exp.value} onChange={(event)=>changeValueHandler(event)} onFocus={removeErrorMessageHandler}/>
                                          {(state.nhs_cvc.error || state.nhs_exp.error) &&
                                                <label htmlFor="inputPaymentCardExpiry" className="form-label" style={{"color":"red","marginLeft":""}}>
                                                      {state.nhs_exp.message}
                                                </label>
                                          }
                                    </div>
                                    <div className="col-auto col__card-cvc">
                                          <label htmlFor="inputPaymentCardCVC" className="form-label">CVC</label>
                                          <input type="text" className="form-control" id="inputPaymentCardCVC" name="nhs_cvc" value={state.nhs_cvc.value} onChange={(event)=>changeValueHandler(event)} onFocus={removeErrorMessageHandler}/>
                                          {(state.nhs_cvc.error || state.nhs_exp.error) &&
                                                <label htmlFor="inputPaymentCardCVC" className="form-label" style={{"color":"red","marginLeft":""}}>
                                                      {state.nhs_cvc.message}
                                                </label>
                                          }
                                    </div>
                                    <div className="col-auto col__payment-cards mt-md-auto ms-md-auto">
                                          <div className="payment-cards-wrapper d-flex flex-wrap align-items-end">
                                                <img className="logo-visa flex-shrink-0"  srcSet={logoVisaa2x}src={logoVisa} alt=""/>
                                                <img className="logo-mastercard flex-shrink-0" src={logoMasterCard} alt=""/>
                                          </div>
                                    </div>
                              </div>
                        </form>
                  </div>
                  {/* <!-- /.content__body --> */}
                  <div className="content__footer border-top p-0">
                        <div className="wrapper d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
                              <div className="col__left d-flex align-items-center">
                                    <div className="d-flex align-items-center justify-content-between justify-content-md-start w-100">
                                          <h4 className="h-label mb-0">Subtotal</h4>
                                          {/* <div>£{bookingModule.bookingDetails.subTotal.toFixed(2)}</div> */}
                                          <div>£{bookingModule.bookingDetails.promocode.status ==="valid"?  parseFloat(bookingModule.bookingDetails.promocode.totalAmount).toFixed(2):bookingModule.bookingDetails.subTotal.toFixed(2)}</div>
                                    </div>
                              </div>
                              <div className="col__right border-left-md flex-shrink-md-0">
                                    <div className="buttons-wrapper d-flex flex-wrap gap-0 mb-0">
                                          <button type="button" className="btn__back btn btn-primary btn-parimary__off-white rounded-0 w-50" 
                                          onClick={backOnPaymentPageHandler}
                                          >Back</button>
                                          <button type="button" className="btn__continue btn btn-primary rounded-0 w-50" 
                                          onClick={SendPaymentRequestHandler}
                                          >Confirm</button>
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.wrapper --> */}
                  </div>
                  {/* <!-- /.content__footer --> */}
            </div>
            
            </React.Fragment>

      )
}

export default PaymentPage;
