import React, { useState,useEffect } from 'react'

import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { checkValidityAccessNeeds } from "./../../Shared/UtilityAccessNeeds";
import { UiActions } from './../../Store/Ui/Ui';
import { accessNeedsSubmitRequest } from './../../Store/Auth/AuthActions';


const AccessNeeds =(props)=> {
      const [state,setState] = useState({name:"",email:"",access_needs:""});
      const accessNeedsRequest = useSelector(state=>state.ui.accessNeedsRequest);
      const auth = useSelector(state=>state.auth);
      const dispatch          = useDispatch();
      const navigate = useNavigate();
      const token = localStorage.getItem("nhs_access_token");

      useEffect(() => {

            setState(prevState=>{
                  return {
                        ...prevState,
                        name:auth.nhs_access_name,
                        email:auth.nhs_access_email
                  }
            })
      }, [auth])


      useEffect(()=>{

            const redirect = localStorage.getItem("nhs_redirect")
            if(redirect){

                  if(redirect.includes("accessneeds")){
                        localStorage.removeItem("nhs_redirect")
                  }
            }
      },[])
      const changeStateHandler = (event)=>{
            event.preventDefault();

            const key = event.target.name;
            const value = event.target.value;
            setState(prevState=>{
                  return {
                        ...prevState,
                        [key]:value
                  }
            })
      }

      const submitAccessNeedsHandler = (event)=>{
            event.preventDefault();
            const errorStore = [];
            for(let key in state)
            {
                  
                  const errorCheck = checkValidityAccessNeeds(state[key],key);
                  if(!errorCheck.isValid){
                        errorStore.push(errorCheck.validationMessage)
                  }
            }

            if(errorStore.length === 0){
                  const data = {access_needs:state.access_needs}
                  dispatch(UiActions.setAccessNeedsRequest({request:true}))
                  dispatch(accessNeedsSubmitRequest({data:data,token:token,navigate:navigate}))
                        
            } else {
                  dispatch(UiActions.setAlert({model:true,message:errorStore[0]}))
            }

      }

      return (
            <main>
                  <section className="section__account-settings" style={{"marginTop":"50px","height":"auto","display":"flex","alignItems":"center"}}>

                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content content__account-settings">
                                                <div className="content-box-wrapper mx-lg-auto" style={{"border":"none"}}>
                                                      <div className="content__header text-center">
                                                            <h1 className="header__title fw-bold mb-0">Your access requirements</h1>
                                                            <p style={{"marginTop":"20px"}}>Please tell us about your access requirements.&nbsp; We'll then store these so that each time you make a booking our admin team are alerted to make the necessary arrangements for you.</p>
                                                      </div>
                                                      {/* <!-- /.content__header --> */}

                                                      <div className="content__body">
                                                            <form>
                                                                  <div className="row">
                                                                        <div className="col__left col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                                                                            
                                                                              <div className="form-group">
                                                                                    <input type="text" className="form-control form-control-xl-lg" id="InputFullName" name="name" value={state.name} onChange={changeStateHandler} autoComplete="off" disabled/>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                              <div className="form-group">
                                                                                    <input type="email" className="form-control form-control-xl-lg" id="InputEmail" name="email" value={state.email} onChange={changeStateHandler}  autoComplete="off" disabled/>
                                                                              </div>
                                                                              <div className="form-group">
                                                                                    <textarea className="form-control form-control-xl-lg" id="contactInputMessage" placeholder="Enter your Access Needs*" value={state.access_needs} name="access_needs" onChange={changeStateHandler} disabled={accessNeedsRequest === true} style={{"maxHeight":"258px"}}>
                                                                                    </textarea>
                                                                              </div>
                                                                              <div className="form-group form-group__buttons mt-lg-auto mt-5">
                                                                                    <button type="submit" className="btn btn-xl-lg btn-primary  fw-bold"  style={{"width":"100%"}} onClick={submitAccessNeedsHandler} disabled={accessNeedsRequest === true ? true : false}>
                                                                                          {!accessNeedsRequest && "Submit"}
                                                                                          {accessNeedsRequest === true && "Please wait.."}
                                                                                          {accessNeedsRequest ===true && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                                    </button>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        
                                                                  </div>
                                                                  {/* <!-- /.row --> */}
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
                                          
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}

                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
      )
}

export default AccessNeeds;