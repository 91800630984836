import React,{useState, useEffect} from 'react'

import { useSelector,useDispatch } from "react-redux";

import iconMinusBlack from "./../../../../assests/images/icon-minus-black.svg";
// import iconMinusBlack from "./../../../../assests/images/icon-minus-black.svg";
import iconPlusBlack from "./../../../../assests/images/icon-plus-black.svg";

import {BookingModuleActions} from "./../../../../Store/BookingModule/BookingModule";
import { UiActions } from "./../../../../Store/Ui/Ui";
import { TicketBlock, applyPrmoCode } from "./../../../../Store/BookingModule/BookingModuleActions"

const AddingTickets = (props) => {

      const [promoCode,setPromoCode]      =     useState("");
      const dispatch                      =     useDispatch();
      const state                         =     useSelector(state=>state.bookingmodule);
      const eventdetail                   =     useSelector(state=>state.eventdetail);
      const token                         =     localStorage.getItem("nhs_access_token");

      useEffect(()=>{

            if(state.performanceInfo.user_remain > 0) {
            
                  const price     = eventdetail.eventInfo.price;
                  const quantity  = state.performanceInfo.groups[0];
                  const vatPer    = state.bookingDetails.vatPer;
                  const subTotal  = price*quantity;
                  const vat       = (subTotal - (100 / (100+vatPer))*subTotal);

                  dispatch(BookingModuleActions.initialPriceQuantity({price:price,quantity:quantity,subTotal:subTotal,max_tickets:eventdetail.eventInfo.max_tickets,vat:vat}))
            }
      },[])

      const decreaseQuantityHandler = ()=>{

            dispatch(BookingModuleActions.clearValidationMessage())
            if(state.group_availablity === 0)
            {
                  const currQuantity = state.bookingDetails.quantity;
                   
                  let newQuantity=0;
                    
                  if(state.performanceInfo.is_single_group ===1){

                        // for controlled multiples of multiple
                        const groups =state.performanceInfo.groups;
                        const index = groups.indexOf(currQuantity);
                        newQuantity = groups[index-1];
                  }
                  else {
                        newQuantity = currQuantity - 1;
                  }
                    
                  if(newQuantity ===0){
                        return;
                  }
                  else if(!newQuantity){
                        dispatch(UiActions.setAlert({
                              model:true,
                              message:"This organiser of this event has requested that we only sell specific multiples of tickets for this show."
                        }))
                        return;
                  }
                  else if(newQuantity > 0)
                  {
                      
                        const subTotal = state.bookingDetails.price * newQuantity;
                        const vatPer = state.bookingDetails.vatPer
                        const vat = (subTotal - (100 / (100+vatPer))*subTotal);

                        // ---------------Code For PromoCode-----------------------//
                        if(state.bookingDetails.promocode.status === "valid"){
                              promoCodeCalculatorHandler(subTotal,vatPer,newQuantity);
                              
                        }

                        dispatch(BookingModuleActions.decreaseTicketQuantity({newQuantity:newQuantity,subTotal:subTotal,vat:vat}));
                  }
            } 
            else if(state.group_availablity === 1)
            {
                  const currQuantity = state.bookingDetails.quantity;
                  const groups =state.performanceInfo.groups;
                  const index = groups.indexOf(currQuantity);

                  const newQuantity = groups[index-1];

                  if(!newQuantity){
                        dispatch(UiActions.setAlert({
                              model:true,
                              message:"This organiser of this event has requested that we only sell specific multiples of tickets for this show."
                        }))
                        return;
                  }
                  else{
                        const subTotal = state.bookingDetails.price * newQuantity;
                        
                        const vatPer = state.bookingDetails.vatPer;
                        const vat = (subTotal - (100 / (100+vatPer))*subTotal);

                        // ---------------Code For PromoCode-----------------------//
                        if(state.bookingDetails.promocode.status === "valid"){
                              promoCodeCalculatorHandler(subTotal,vatPer,newQuantity);
                              
                        }

                        dispatch(BookingModuleActions.decreaseTicketQuantity({newQuantity:newQuantity,subTotal:subTotal,vat:vat}));
                  }
            }
            

      }

      const increaseQuantityHandler = ()=>{

            dispatch(BookingModuleActions.clearValidationMessage())
            if(state.group_availablity === 0)
            {
                  const currQuantity = state.bookingDetails.quantity;
                 
                  let newQuantity=0;

                  if(state.performanceInfo.is_single_group ===1){
                        
                        // for controlled muliples of multiple
                        const groups =state.performanceInfo.groups;
                        const index = groups.indexOf(currQuantity);
                        newQuantity = groups[index+1];
                  }
                  else {
                        newQuantity = currQuantity + 1;
                  }

                  if(!newQuantity){
                        
                        dispatch(UiActions.setAlert({
                              model:true,
                              message:"This organiser of this event has requested that we only sell specific multiples of tickets for this show."
                        }))
                  }
                  else if (newQuantity > state.performanceInfo.user_remain) {
                        dispatch(BookingModuleActions.setValidatingMessage({validatingMessage:state.performanceInfo.user_remain  + " tickets allowed (max)"}));
                  }
                  else if (newQuantity > state.performanceInfo.total_remain) {

                        const message = state.performanceInfo.validation_message ? state.performanceInfo.validation_message : "only "+state.performanceInfo.total_remain+" ticket left";
                        dispatch(BookingModuleActions.setValidatingMessage({validatingMessage:message}));
                  }
                  else {
                           
                        const subTotal = state.bookingDetails.price * newQuantity;
                        const vatPer = state.bookingDetails.vatPer;
                        const vat = (subTotal - (100 / (100+vatPer))*subTotal);

                        // Code For PromoCode-----------------------------------------//

                        if(state.bookingDetails.promocode.status === "valid"){
                              
                              promoCodeCalculatorHandler(subTotal,vatPer,newQuantity);
                              
                        }

                        dispatch(BookingModuleActions.increaseTicketQuantity({newQuantity:newQuantity,subTotal:subTotal,vat:vat}));
                  }

            }
            else  if(state.group_availablity === 1) {

                  const currQuantity = state.bookingDetails.quantity;
                  const groups =state.performanceInfo.groups;
                  const index = groups.indexOf(currQuantity);
                  const newQuantity = groups[index+1];
                  
                  if(!newQuantity){
                        
                        dispatch(UiActions.setAlert({
                              model:true,
                              message:"This organiser of this event has requested that we only sell specific multiples of tickets for this show."
                        }))
                  }
                  else if (newQuantity > state.performanceInfo.user_remain) {

                        dispatch(BookingModuleActions.setValidatingMessage({validatingMessage:state.bookingDetails.max_tickets + " tickets allowed (max)"}));
                  }
                  else if (newQuantity > state.performanceInfo.total_remain) {

                        dispatch(BookingModuleActions.setValidatingMessage({validatingMessage:state.performanceInfo.validation_message || "only "+state.performanceInfo.total_remain+" ticket left"}));
                  }
                  else {

                        const subTotal = state.bookingDetails.price * newQuantity;
                        const vatPer = state.bookingDetails.vatPer;
                        const vat = (subTotal - (100 / (100+vatPer))*subTotal);

                        // Code For PromoCode-----------------------------------------//
                        if(state.bookingDetails.promocode.status === "valid"){
                              
                              promoCodeCalculatorHandler(subTotal,vatPer,newQuantity);
                        }
                        
                        dispatch(BookingModuleActions.increaseTicketQuantity({newQuantity:newQuantity,subTotal:subTotal,vat:vat}));
                  }
            }
      }

      const backOnAddingTicketsHandler = ()=>{

            const group_availablity = state.group_availablity;
            const step = "EvnentsCalenderPage";
            dispatch(BookingModuleActions.clearBookingModuleState({step:step,group_availablity:group_availablity}));
            dispatch(UiActions.clearTimer());
            // dispatch(BookingModuleActions.setBookingStep({step:step}));
      }

      const ContinueOnAddingTicketsHandler = ()=> {
          
            if(state.bookingDetails.quantity > state.performanceInfo.total_remain){

                  dispatch(UiActions.setAlert({model:true,message:"There aren't enough available tickets to fulfil your order. Some tickets are mid-checkout, and may be released back into circulation. Refresh the page and try again"}));
                  // dispatch(BookingModuleActions.setBookingStep({step:"EvnentsCalenderPage"}));
                  dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:state.group_availablity}));
                  return;
            }

            if(state.group_availablity === 0 || state.group_availablity === 1){
                  
                  if (state.bookingDetails.checkBox) {
                       
                        dispatch(UiActions.setSpinner({status:"request"}))
                        const data = {
                              show_id:state.bookingDetails.showId,
                              tickets:state.bookingDetails.quantity,
                              group_availablity:state.group_availablity
                        }

                        dispatch(TicketBlock({token:token,data:data}))
                        props.startTimer();

                  } else {

                        dispatch(UiActions.setAlert({model:true,message:"Please check that you have understood the collection instructions"}))
                  }
            }
            
      }

      const setCollectionInstructionHandler = () => {
            dispatch(UiActions.setAlert({model:true,message:eventdetail.values.collection_instruction}))
      };

      const ChangeCheckBoxHandler = (event)=> {

            dispatch(BookingModuleActions.changeCheckBox({checkBox:event.target.checked}))
      }

      const promocodeChangeHandler = (event)=> {

            const promocodeValue = event.target.value.toUpperCase();
            setPromoCode(promocodeValue);
      }

      const promoCodeApplyHandler = ()=> {

            if(state.bookingDetails.promocode.status !== "valid"){

                  if(promoCode.length > 2){

                        const data = {
                              show_id:state.bookingDetails.showId,
                              event_id:state.event_id,
                              promocode:promoCode,
                              tickets:state.bookingDetails.quantity,
                              tickets_type_array:{},
                              group_availablity:state.group_availablity,
                        }
                        dispatch(UiActions.setSpinner({status:"request"}))
                        dispatch(applyPrmoCode({token:token,data:data}))

                  } else {

                        let message = ""
                        if(promoCode.length === 0){
                              message="Please enter a promocode";
                        } else {
                              message="Promocode length must be greater then 3";
                        }
                        dispatch(UiActions.setAlert({model:true,message:message}))
                  }
            }
            
      }

      const removePromoCodeHandler = ()=>{
            setPromoCode("");
            dispatch(BookingModuleActions.removePromoCode())
      }

      const promoCodeCalculatorHandler = (subTotal,vatPer,newQuantity)=>{

            let discount=0;
            let totalAmount=0;
            let discountVat= 0;
            if(state.bookingDetails.promocode.discount_type === "%"){
                  discount = ((state.bookingDetails.promocode.main_discount/100)*subTotal).toFixed(2);
                  totalAmount = (subTotal - discount);
            }
            else if(state.bookingDetails.promocode.discount_type === "rs" && state.bookingDetails.promocode.amount_type === "O"){

                  discount = state.bookingDetails.promocode.main_discount;
                  totalAmount = (subTotal - discount);
            }
            else if(state.bookingDetails.promocode.discount_type === "rs" && state.bookingDetails.promocode.amount_type === "T"){

                  discount = state.bookingDetails.promocode.main_discount*newQuantity;
                  totalAmount = (subTotal - discount) >0 ? (subTotal - discount) : 0;
            }

            discountVat= (totalAmount - (100 / (100+vatPer))*totalAmount)

            dispatch(BookingModuleActions.setValueForPromoCodes({totalAmount:totalAmount,discount:discount,mainAmount:subTotal,vat:discountVat}))
      }
    
      const checkForInvalidMessageHandler = ()=>{

            if(state.bookingDetails.promocode.status === "invalid"){
                  dispatch(BookingModuleActions.removePromoCode())
            }
      }

      return (

                  <div id="bookTicketsStep2" className="content content__step2">
                        <div className="content__body">
                              <table className="table table-flat table__items">
                                          <thead>
                                                <tr>
                                                      <th scope="col">Type of Ticket</th>
                                                      <th className="th-quantity" scope="col">Quantity</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td className="td-item">{eventdetail.eventInfo.tickets_type}</td>
                                                      <td>
                                                            <div className="input-group__increment mx-auto">
                                                                  <button className="btn btn__minus" data-field="quantity" onClick={decreaseQuantityHandler}>
                                                                        <img src={iconMinusBlack} alt=""/>
                                                                  </button>
                                                                  <input className="form-control form-control__quantity quantity-field" type="number" readOnly step="1" value={state.bookingDetails.quantity} name="quantity"/>
                                                                  <button className="btn btn__plus" data-field="quantity" onClick={increaseQuantityHandler}>
                                                                        <img src={iconPlusBlack} alt=""/>
                                                                  </button>
                                                            </div>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td colSpan="2" className="td-item" style={{"textAlign":"end","color":"var(--bs-red)"}}>
                                                            {state.bookingDetails.ticketValidationMsg && state.bookingDetails.ticketValidationMsg}
                                                      </td>
                                                </tr>
                                          </tbody>
                              </table>
                              <div className="promo-code-block mb-4 mb-xl-5">
                                          <div className="promo-code__form-group d-flex mb-3 mb-xl-4">
                                                <div className="form-group mb-0">
                                                      <label htmlFor="inputPromoCode" className="form-label visually-hidden">
                                                            Apply promo code
                                                      </label>
                                                      <input type="text" className="form-control" id="inputPromoCode" placeholder="Apply promo code" readOnly={state.bookingDetails.promocode.status === "valid"} value={promoCode} onFocus={checkForInvalidMessageHandler}  onChange={(event)=>promocodeChangeHandler(event)}/>
                                                </div>
                                                <button className="btn btn-outline-primary btn__plus-to-x" type="button" aria-label="Apply promo" onClick={promoCodeApplyHandler}>
                                                      <span className="icon__plus"></span>
                                                </button>
                                          </div>
                                          {state.bookingDetails.promocode.status === "invalid" &&
                                          <div className="promo-code__form-group d-flex mb-xl-4">
                                                <p style={{"color":"var(--bs-red)","margin":"0","marginLeft":"10px"}}>{state.bookingDetails.promocode.message}</p>
                                          </div>}
                                          {state.bookingDetails.promocode.status === "valid" &&
                                          <div className="promo-code__item">
                                                <button type="button" className="btn btn-circle-x p-0 me-2" aria-label="Remove promo" onClick={removePromoCodeHandler}>
                                                      <span className="icon__plus"></span>
                                                </button>
                                                <div className="promo-code">
                                                      {state.bookingDetails.promocode.promocode}
                                                </div>
                                                <div className="promo-code-value-wrapper">
                                                      - <span className="promo-code-value">£{parseFloat(state.bookingDetails.promocode.discount).toFixed(2)}</span>
                                                </div>
                                          </div> }
                              </div>
                              <div className="confirm-box">
                                          <p className="p-custom-control-label">
                                                Please confirm that you have read and understood
                                                the <a href='#' style={{"textDecoration":"none"}} onClick={setCollectionInstructionHandler}>collection instructions</a> for this event
                                          </p>
                                          <div className="custom-control custom-checkbox custom-checkbox__circle">
                                                <input type="checkbox" className="custom-control-input" checked={state.bookingDetails.checkBox}  onChange={(event)=>ChangeCheckBoxHandler(event)}  id="checkCollectionInstructions"/> 
                                                <label className="custom-control-label" htmlFor="checkCollectionInstructions">
                                                      I have read the collection instructions
                                                </label>
                                          </div>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}

                        <div className="content__footer border-top p-0">
                              <div className="wrapper d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
                                    <div className="col__left d-flex align-items-center">
                                          <div className="d-flex align-items-center justify-content-between justify-content-lg-start w-100">
                                                <h4 className="h-label mb-0">Subtotal</h4>
                                                <div>£{state.bookingDetails.promocode.status === "valid" ? parseFloat(state.bookingDetails.promocode.totalAmount).toFixed(2): state.bookingDetails.subTotal.toFixed(2)}</div>
                                          </div>
                                    </div>
                                    <div className="col__right border-left-md flex-shrink-md-0">
                                          <div className="buttons-wrapper d-flex flex-wrap gap-0 mb-0">
                                                <button type="button" className="btn__back btn btn-primary btn-parimary__off-white rounded-0 w-50" 
                                                onClick={backOnAddingTicketsHandler}
                                                >
                                                      Back
                                                </button>
                                                <button type="button" className="btn__continue btn btn-primary rounded-0 w-50" 
                                                onClick={ContinueOnAddingTicketsHandler}
                                                >
                                                      Continue
                                                </button>
                                          </div>
                                    </div>
                              </div>
                              {/* <!-- /.wrapper --> */}
                        </div>
                        {/* <!-- /.content__footer --> */}
                  </div>
                                                            
            )
}

export default AddingTickets;
