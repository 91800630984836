import React,{ useEffect } from "react";

import { useSelector,useDispatch } from "react-redux";
import { UiActions } from "../../Store/Ui/Ui";
import { sendDeleteAccountRequest } from "./../../Store/Auth/AuthActions"

const CancelAccount = (props) => {

      const confirm = useSelector(state=>state.ui.alert.type.status);
      const dispatch = useDispatch();
      const token= localStorage.getItem('nhs_access_token');

      const confirmationHandler = (event)=>{
            event.preventDefault();
            dispatch(UiActions.setAlert({model:true,message:'Are you sure you want to delete your account?',type:{value:"confirm",status:false}}))
      }

      useEffect(()=>{

            if(confirm){
                  dispatch(sendDeleteAccountRequest({token:token,data:{}}));
            }

      },[confirm,dispatch,token])
   
      return (

            <div className="row row__title row__cancel-account border-top">
                  <div className="col-md-6">
                        <div className="heading-wrapper">
                              <h3 className="heading fw-bold mb-0">
                                    Cancel Account
                              </h3>
                              <div className="form-group form-group__buttons">
                                    <button type="button" className="btn btn-xl-lg btn-primary fw-bold" disabled={confirm || props.nhs_vendor ==='2'} onClick={confirmationHandler}>
                                          {/* Cancel Account */}
                                          Delete account
                                    </button>
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                  </div>
            </div>
                                                            
      )
}

export default CancelAccount;
