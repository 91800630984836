import React,{useState,useEffect} from "react";

import classes from "./LeaveFeedBack.module.css";

import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import {BaseUrl} from "./../../Shared/Constants";
import {UiActions} from "./../../Store/Ui/Ui";

// import {fetchPastBookingsWebData} from "./../../Store/Bookings/Bookings";

const LeaveFeedBack = (props) => {
     
      const [state,setState] = useState({
            q1:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"A",label:"Read a preview (i.e an article about the upcoming event)"},
                        {id:2,selected:false,value:"B",label:"Read a review by a professional critic"},
                        {id:3,selected:false,value:"C",label:"Read comments from friends or family members on social media"},
                        {id:4,selected:false,value:"D",label:"Hear about the event by word of mouth"},
                        {id:5,selected:false,value:"E",label:"See an advert promoting the event"},
                        {id:6,selected:false,value:"F",label:"I hadn't heard about it, I learned of it through Tickets for Medics"},
                  ]
            },
            q2:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"A",label:"To be entertained"},
                        {id:2,selected:false,value:"B",label:"To spend quality time with family or friends"},
                        {id:3,selected:false,value:"C",label:"To energise your own creativity"},
                        {id:4,selected:false,value:"D",label:"To expose yourself or others to the arts"},
                        {id:5,selected:false,value:"E",label:"enjoy the work of a specific composer or playwright?"},
                        {id:6,selected:false,value:"F",label:"To revisit a familiar story or style of event"},
                        {id:7,selected:false,value:"G",label:"To see the work of a specific performer/event organiser"},
                        {id:8,selected:false,value:"H",label:"To experience a new type of event"},
                        {id:9,selected:false,value:"I",label:"To celebrate or observe your cultural heritage"},
                        {id:10,selected:false,value:"J",label:"To learn about cultures other than your own"},
                        {id:11,selected:false,value:"K",label:"To be emotionally moved or inspired"},
                        {id:12,selected:false,value:"L",label:"For work or educational purposes"},
                        {id:13,selected:false,value:"M",label:"For reasons of personal development"},
                  ]
            },
            q3:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"A",label:"Very strong"},
                        {id:2,selected:false,value:"B",label:"Quite strong"},
                        {id:3,selected:false,value:"C",label:"Neutral"},
                        {id:4,selected:false,value:"D",label:"Somewhat weak"},
                        {id:5,selected:false,value:"E",label:"Very Weak"},
                  ]
            },
            q4:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"C",label:" Yes - actively"},
                        {id:2,selected:false,value:"B",label:" Yes - casually"},
                        {id:3,selected:false,value:"A",label:"No"},
                  ]
            },
            q5:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"A",label:"Very likely"},
                        {id:2,selected:false,value:"B",label:"Quite strongy"},
                        {id:3,selected:false,value:"C",label:"Neutral"},
                        {id:4,selected:false,value:"D",label:"Somewhat unlikely"},
                        {id:5,selected:false,value:"E",label:"Very unlikely"},
                  ]
            },
            q6:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"E",label:"5 stars"},
                        {id:2,selected:false,value:"D",label:"4 stars"},
                        {id:3,selected:false,value:"C",label:"3 stars"},
                        {id:4,selected:false,value:"B",label:"2 stars"},
                        {id:5,selected:false,value:"A",label:"1 star"},
                  ]
            },
            q7:{
                  error:false,
                  value:''
            },
            q8:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"A",label:"Yes"},
                        {id:2,selected:false,value:"B",label:"No"},
                        {id:3,selected:false,value:"C",label:"Not Applicable - Online Event"},
                  ]
            },
            q9:{
                  error:false,
                  lists:[
                        {id:1,selected:false,value:"A",label:"Drinks"},
                        {id:2,selected:false,value:"B",label:"Programme"},
                        {id:3,selected:false,value:"C",label:"Snacks or Food"},
                        {id:4,selected:false,value:"D",label:"Merchandise"},
                        {id:5,selected:false,value:"E",label:"Other"},
                  ]
            },
            q10:{
                  error:false,
                  value:"0.00"
            },

            // q10:{
            //       error:false,
            //       lists:[
            //                   {id:1,selected:false,value:"E",label:"£20+"},
            //                   {id:2,selected:false,value:"D",label:"£16-20"},
            //                   {id:3,selected:false,value:"C",label:"£11-15"},
            //                   {id:4,selected:false,value:"B",label:"£6-10"},
            //                   {id:5,selected:false,value:"A",label:"£1-5"},
            //       ]
            // },
      });

      const navigate = useNavigate()
      const [eventName,setEventName] = useState("");

      const params   =     useParams();
      const dispatch = useDispatch();
      const token = localStorage.getItem("nhs_access_token");
       
      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
            const sendRequest = async () =>{

                  axios.get(BaseUrl + '/check_event_feedback',{
                        params:{
                              'booking_id':params.id
                        },
                        headers: {
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response){       
                        
                        // console.log(response)
                        if(response.data.status === "0"){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                              navigate("/booking-history");
                        }
                        else if(response.data.status === '1'){
                              setEventName(response.data.data.event_title)
                        }

                  })
                  .catch(function (error) {

                        if(error.response){
                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message ||"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error ||"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else{
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        navigate(-1);
                  });
            }
            sendRequest();

      },[])

      const changeCheckBoxHandler = (type,index,value)=>{
               
            const currentArray  = [...state[type].lists];
               
            currentArray[index] = {...currentArray[index],selected:!value};
            const arrayLength = [];
            currentArray.map(el=>{

                  if(el.selected){
                        arrayLength.push(el.selected)  
                  }
            })
                
            setState(currState=>{
                  
                  return {
                              ...currState,
                              [type]:{
                                    ...currState[type],
                                    error:arrayLength.length > 0 ? false : true,
                                    lists:currentArray
                              }
                  }
            })
      }

      const changeRadioHandler = (type,index,value)=>{
            
            const currentArray  = [...state[type].lists];
            
            const checkForRadio = currentArray.map(el=>el.selected)
            
            if(value && !checkForRadio.includes("true")){
                  return;
            }
            else {
                  
                  const updateArray = currentArray.map(el=>{
                        if(el.id === index) {
                              return {
                                    ...el,selected:!value
                              }
                        }
                        else
                        {
                              return {
                                    ...el,selected:false
                              }
                        }
                  })

                  setState(currState=>{
                        return {
                              ...currState,
                              [type]:{
                                    ...currState[type],
                                    error:false,
                                    lists:updateArray
                              }
                        }
                  })
            }
            
      }

      const changeTextHandler = (event)=>{

            // console.log(event.target.name)
            let val = event.target.value;
            const name = event.target.name;

            const error = val.trim().length > 0 ? false : true
            setState(currState=>{
                  return {
                        ...currState,
                        [name]:{error:error,value:val}
                  }
            })
      }

      const changeNumberHandler = (event)=>{
            let val = validate(event.target.value);
            const name = event.target.name;

            if(!val){
                  return;
                  val = 0;
            }
            
            const error = val > 0.00 ? false : true;
            setState(currState=>{
                  return {
                        ...currState,
                        [name]:{error:error,value:val}
                  }
            })
      }

      const validate = (s) => {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
      }

      const backButtonHandler = (event)=>{

            event.preventDefault();
            navigate(-1)
            // props.history.push({
            //       pathname:"/myprofile/"+ props.location.state.path
            // });
            
      }
      
      const submitFeedBackHandler = (event)=>{
            
            // console.log("submit")
            event.preventDefault();
            const updateState = {...state};
            const errorLengthArray = [];
            for(let key in state){

                  const selectedLength = [];
                  if(key !== "q7" && key !== "q10"){

                        if(key === "q9" || key === "q10"){
                              if(!updateState.q8.lists[0].selected){
                                    updateState[key] = {...updateState[key],error:false}
                                    continue;
                              }
                        }
                        
                        updateState[key].lists.map(el=>{
                              if(el.selected){
                                    selectedLength.push(el.selected)
                              }
                        })

                        if(selectedLength.length <= 0){
                              errorLengthArray.push(key)
                              updateState[key] = {...updateState[key],error:true}
                        }
                  }
                  else if(key=== "q7" || key=== "q10"){
                        
                        if(key === "q10"){

                              if(!updateState.q8.lists[0].selected){
                                   
                                    updateState[key] = {...updateState[key],error:false}
                              }
                              else if(updateState[key].value <= 0.00){
                                    errorLengthArray.push(key)
                                    updateState[key] = {...updateState[key],error:true}
                              }
                              continue;
                        }

                        if(updateState[key].value.length <=0){
                              errorLengthArray.push(key)
                              updateState[key] = {...updateState[key],error:true}
                        }
                              
                  }
                  
            }

            setState(updateState)

            if(errorLengthArray.length <= 0) {
                  const booking_id =      params.id;
                  const q1Data    =      [];
                  const q2Data    =       [];
                  let q3Data      =       "";
                  let q4Data      =       "";
                  let q5Data      =       "";
                  let q6Data      =       "";
                  let q7Data      =       "";
                  let q8Data      =       "";
                  const q9Data    =       [];
                  let q10Data     =       "";
                  
                  state.q1.lists.forEach(que=>{
                        if(que.selected){
                              q1Data.push(que.value)
                        }
                  } )

                  state.q2.lists.forEach(que=>{
                        if(que.selected){
                              q2Data.push(que.value)
                        }
                  } )

                  state.q3.lists.forEach(que=>{
                        if(que.selected){
                              q3Data = que.value;
                        }
                  } )

                  state.q4.lists.forEach(que=>{
                        if(que.selected){
                              q4Data = que.value;
                        }
                  } )

                  state.q5.lists.forEach(que=>{
                        if(que.selected){
                              q5Data = que.value;
                        }
                  } )

                  state.q6.lists.forEach(que=>{
                        if(que.selected){
                              q6Data = que.value;
                        }
                  } )

                  q7Data = state.q7.value;

                  state.q8.lists.forEach(que=>{
                        if(que.selected){
                              q8Data = que.value;
                        }
                  } )

                  state.q9.lists.forEach(que=>{
                        if(que.selected){
                              q9Data.push(que.value);
                        }
                  } )

                  q10Data  = state.q10.value;

                  const feedback_data = {
                        "q1":q1Data,"q2":q2Data,"q3":q3Data,"q4":q4Data,"q5":q5Data,"q6":q6Data,"q7":q7Data,"q8":q8Data,
                        "q9":q8Data === "A" ? q9Data : [],
                        "q10":q8Data === "A" ? q10Data : ""
                  };

                  const data = {booking_id:booking_id,feedback_data:feedback_data}
                  // const data = {booking_id:booking_id,feedback_data:feedback_data}
               
                  axios.post(BaseUrl + '/event_feedback',data,{
                        method:'POST',
                        headers: {
                              'token': token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response);
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                        navigate("/booking-history");

                  })
                  .catch(function (error) {

                              // console.log(error.response)
                              if(error.response.status === 400){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else if(error.response.status === 500){
                                    
                                    if(error.response.data.data) {

                                          const errorArray = error.response.data.data.error_array;
                                          const updateStateForError = {...state};
                                          for(let key in errorArray){
                                                updateStateForError[key] = {...updateState[key],error:true}
                                          }
                                          setState(updateStateForError)
                                          window.scrollTo({
                                                top: 150,
                                                behavior: "smooth"
                                          });
                                    }
                                    else{
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              
                  });

            }
            else {
                  window.scrollTo({
                        top: 150,
                        behavior: "smooth"
                  });
            }
            
      }

      const lengthErrorArray = [];
      for(let key in state){
            if(state[key].error)
            {
                  lengthErrorArray.push(key)
            }
      }

      return (
            <div className={["body-background",classes.FeedBack_Body].join(" ")} ng-app="myApp" ng-controller="myCtrl">
                  <form id="feedbackform" autoComplete="off">
                              {/* <input type="hidden" name="ci_csrf_token" id="ci_csrf_token" value="" /> */}
                              <div className="row" style={{"width": "100%","maxWidth": "840px","paddingLeft": "15px","paddingRight": "15px","margin": "141px auto"}}>
                                    <h2 style={{"margin":"7% 6% 4% 2%", "color": "", "textAlign": "center"}}>
                                          {eventName !== "" ? eventName + " – Member Feedback":" – Member Feedback"}
                                    </h2>
                                    <div className="">
                                          {lengthErrorArray.length > 0 &&<div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"background": "white","padding":"15px"}}>
                                                      <span className={classes.top_headericon}>!</span> 
                                                      <h2 className={classes.top_header}> &nbsp; Please correct the following application form errors.</h2>
                                                      {state.q1.error &&
                                                      <div className={classes.top_error} >
                                                            <a href="#q1errorfocus">
                                                                  Please select the relevant choices.
                                                            </a>
                                                      </div>}

                                                      {state.q2.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q2errorfocus">
                                                                  Please select your reasons for choosing to attend this event.
                                                            </a>
                                                      </div>}

                                                      {state.q3.error &&
                                                      <div className={classes.top_error}> 
                                                            <a href="#q3errorfocus">
                                                                  Please describe your response to the subject matter.
                                                            </a>
                                                      </div>}
                                                      
                                                      {state.q4.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q4errorfocus">
                                                                  Please tell us if you discussed the event with others, afterwards.
                                                            </a>
                                                      </div>}

                                                      {state.q5.error &&
                                                      <div className={classes.top_error}> 
                                                            <a href="#q5errorfocus">
                                                                  Please tell us how likely you are to recommend this event to people you know.
                                                            </a>
                                                      </div>}

                                                      {state.q6.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q6errorfocus">
                                                                  Please rate your experience at this event.
                                                            </a>
                                                      </div>}

                                                      {state.q7.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q7errorfocus">
                                                                  Please give us a positive quote that the event organisers can use to promote their event on social media.
                                                            </a>
                                                      </div>}

                                                      {state.q8.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q8errorfocus">
                                                                  Please tell us if you bought anything at the venue.
                                                            </a>
                                                      </div>}

                                                      {state.q9.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q9errorfocus">
                                                                  Please tell us what you bought.
                                                            </a>
                                                      </div>}

                                                      {state.q10.error &&
                                                      <div className={classes.top_error}>
                                                            <a href="#q10errorfocus">
                                                                  Please tell us how much you spent in total as a group.
                                                            </a>
                                                      </div>}

                                          </div>}

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q1errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>1) &nbsp; Prior to attending this event, did you? (choose as many as you like) *</p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>
                                                                  {state.q1.lists.map((label,index)=>{
                                                                              return (
                                                                                    <div className={classes.inputGroup} key={"q1"+label.id}>
                                                                                          <input className="ifchange" value={label.value} name="q1[]" type="checkbox"/>
                                                                                          <label htmlFor={label.label} onClick={()=>changeCheckBoxHandler('q1',index,label.selected)} style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                                      <span className={[classes.minicon,classes.miniconMargin].join(" ")} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span>
                                                                                                      {label.label}
                                                                                          </label>
                                                                                    </div>
                                                                              )
                                                                  })}
                                                      </div>

                                                      {state.q1.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please select the relevant choices.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q2errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>
                                                                  2) &nbsp; Select the reasons you chose to attend this event?
                                                                  (choose as many as you like) *
                                                            </p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>

                                                            {state.q2.lists.map((label,index)=>{

                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q2"+label.id}>
                                                                                    <input value={label.value} className="ifchange" name="q2[]" type="checkbox"/>
                                                                                    <label htmlFor="To be entertained" onClick={()=>changeCheckBoxHandler('q2',index,label.selected)} style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                          <span className={[classes.minicon,classes.miniconMargin2].join(" ")} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span>
                                                                                          {label.label}
                                                                                    </label>
                                                                              </div>

                                                                  )
                                                            })}

                                                      </div>

                                                      {state.q2.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please select your reasons for choosing to attend this event.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q3errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>
                                                                  3) &nbsp; During the event, how strong was your emotional response
                                                                  to the subject matter?*
                                                            </p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>
                                                            {state.q3.lists.map((label,index)=>{
                                                                  
                                                                  
                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q3"+label.id} style={{"width": "225px","display":"inline-block"}}>
                                                                              <input className="ifchange" value={label.value} name="q3"  type="radio"/>
                                                                              <label htmlFor="Very strong" onClick={()=>changeRadioHandler('q3',label.id,label.selected)} style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                    <span className={classes.minicon} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span>
                                                                                    {label.label}
                                                                              </label>
                                                                  </div>
                                                                  )
                                                            })}

                                                      </div>

                                                      {state.q3.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please describe your response to the subject matter.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q4errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>
                                                                  4) &nbsp; Afterwards, did you discuss the event with others who
                                                                  attended?*
                                                            </p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>

                                                            {state.q4.lists.map((label,index)=>{

                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q4"+label.id} style={{"width": "225px","display":"inline-block"}}>
                                                                                    <input value={label.value} className="ifchange" name="q4" type="radio"/>
                                                                                    <label  htmlFor="Yes - actively"  onClick={()=>changeRadioHandler('q4',label.id,label.selected)}  style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                          <span className={classes.minicon} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span> 
                                                                                          {label.label}
                                                                                    </label>
                                                                              </div>
                                                                  )
                                                            })}
                                                      </div>

                                                      {state.q4.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please tell us if you discussed the event with others, afterwards.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div  className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q5errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>
                                                                  5) &nbsp; How likely are you to recommend this event to people you
                                                                  know? *
                                                            </p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>
                                                            {state.q5.lists.map((label,index)=>{

                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q5"+label.id} style={{"width": "225px","display":"inline-block"}}>
                                                                                    <input className="ifchange" value={label.value} name="q5" type="radio" />
                                                                                    <label htmlFor="Very likely"  onClick={()=>changeRadioHandler('q5',label.id,label.selected)}  style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                          <span className={classes.minicon} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span>
                                                                                          {label.label}
                                                                                    </label>
                                                                              </div>
                                                                  )
                                                            })}
                                                      </div>

                                                      {state.q5.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please tell us how likely you are to recommend this event to people you know.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q6errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>
                                                                  6) &nbsp; Overall, how would you rate your experience at this
                                                                  event? *
                                                            </p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>
                                                            {state.q6.lists.map((label,index)=>{

                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q6"+label.id} style={{"width": "225px","display":"inline-block"}}>
                                                                                    <input value={label.value} className="ifchange" name="q6" type="radio"/>
                                                                                    <label htmlFor={label.label}  onClick={()=>changeRadioHandler('q6',label.id,label.selected)}  style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}> 
                                                                                          <span className={classes.minicon} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span> 
                                                                                          {label.label}
                                                                                    </label>
                                                                              </div>
                                                                  )
                                                            })}

                                                      </div>

                                                      {state.q6.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please rate your experience at this event.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q7errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p style={{"margin": "0 0 10px"}}>
                                                                  7) &nbsp; Please use the space below to leave a positive quote that the event organisers can use to promote their event on social media: *
                                                            </p>
                                                            <span style={{"fontWeight": "unset","fontSize": "17px","lineHeight": "28px","color": "var(--bs-body-color)","opacity": "0.7","marginLeft": ""}}>
                                                                  (Please make sure your feedback is constructive and can be used to
                                                                  promote the show. If you had any problems, you can email us at
                                                                  help@ticketsformedics.co.uk)
                                                            </span>
                                                      </label>

                                                      <div className={classes.marginsmallleft}>
                                                            <textarea type="text" style={{"height": "92px", "overflow": "scroll"}}  placeholder="Type your answer here" value={state.q7.value} onChange={(event)=>changeTextHandler(event)} name="q7" className={classes.txtarea} id="lname">
                                                            </textarea>
                                                      </div>

                                                      {state.q7.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12 feedback_error_box" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please give us a positive quote that the event organisers can use to promote their event on social media.
                                                            </div>
                                                      </div>}
                                          </div>

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q8errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p style={{"margin": "0 0 10px"}}>
                                                                  8) &nbsp; Did you buy anything at the venue?
                                                            </p>
                                                      </label>
                                                      <br />


                                                      <div className={classes.marginsmallleft}>
                                                            {state.q8.lists.map((label,index)=>{

                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q7"+label.id} style={{'width':'266px','display':'inline-block'}}>
                                                                                    <input className="ifchange q8change" value={label.value} name="q8"  type="radio"/>
                                                                                    <label htmlFor={label.label} onClick={()=>changeRadioHandler('q8',label.id,label.selected)}  style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                          <span className={classes.minicon} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span>
                                                                                          {label.label}
                                                                                    </label>
                                                                              </div>
                                                                  )
                                                            })}
                                                                  
                                                      </div>

                                                      {state.q8.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please tell us if you bought anything at the venue.
                                                            </div>
                                                      </div>}
                                          </div>

                                          {state.q8.lists[0].selected &&
                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q9errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>9) &nbsp; What did you buy?*</p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>
                                                            {state.q9.lists.map((label,index)=>{

                                                                  return (
                                                                              <div className={classes.inputGroup} key={"q9"+label.id} style={{"width": "225px","display":"inline-block"}}>
                                                                              <input className="ifchange" value={label.value} name="q9[]"  type="checkbox"/>
                                                                              <label htmlFor={label.label} onClick={()=>changeCheckBoxHandler('q9',index,label.selected)}  style={{backgroundColor:label.selected ? "var(--bs-blue)":'',color:label.selected ? 'var(--bs-off-white)':'',"textAlign": "center"}}>
                                                                                    <span className={classes.minicon} style={{color:label.selected ? "var(--bs-off-white)" : ""}}>{label.value}</span>
                                                                                    {label.label}
                                                                              </label>
                                                                              </div>
                                                                  )
                                                            })}
                  
                                                      </div>

                                                      {state.q9.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please tell us what you bought.
                                                            </div>
                                                      </div>}

                                          </div>}

                                          {state.q8.lists[0].selected &&
                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} id="q10errorfocus" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                      <label className={classes.control_label}>
                                                            <p>10) &nbsp;How much did you spend in total as a group? *</p>
                                                      </label>
                                                      <div className={classes.marginsmallleft}>
                                                                  <div className={[classes.inputGroup,classes.inputGroupInputField,classes.currencyWrap].join(" ")} style={{"width": "225px","border":"none"}}>
                                                                        <span className={classes.currencyCode}>£</span>
                                                                        <input value={state.q10.value} className={['form-control',classes.inputField,classes.textCurrency].join(" ")} name="q10"  type="text" onChange={changeNumberHandler} placeholder="0.00"/>
                                                                  </div>
                                                      </div>

                                                      {state.q10.error &&
                                                      <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{"paddingLeft":"15px","paddingRight":"15px"}}>
                                                            <div className={classes.with_errors}>
                                                                  Please tell us how much you spent in total as a group.
                                                            </div>
                                                      </div>}
                                          </div>}

                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12 mt-5",classes.FeedBack_Body__scrollby].join(" ")} style={{"paddingLeft":"15px","paddingRight":"15px"}}> 
                                                      <button  className={classes.submitbtn} onClick={(event)=>backButtonHandler(event)}>
                                                            Back
                                                      </button>
                                                      <input type="submit" onClick={(event)=>submitFeedBackHandler(event)} className={classes.submitbtn} id="btnSubmit" value="Submit"/>
                                          </div>
                                          <div className={["form-group col-md-12 col-sm-12 col-xs-12",classes.FeedBack_Body__scrollby].join(" ")} style={{"height": "200px"}}>
                                          </div>
                                    </div>
                              </div>
                  </form>
            </div>
      );
};

export default LeaveFeedBack;