
import {EventDetailActions} from "./EventDetail";
import { UiActions } from "./../Ui/Ui";
import { BaseUrl } from "./../../Shared/Constants";
import axios from "axios";

export const fetchEventDetailData = (payload)=> {

        return async (dispatch)=>{
                const fetchData =async () =>{
                        await axios.post(BaseUrl +'/event/event_details',payload.data,{
                              method:'POST',
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              // console.log(response.data.data)
                              dispatch(EventDetailActions.setEventsDetailsData(response.data.data));
                                
                        })
                        .catch(function (error) {

                              // console.log(error.response);

                              if(error.response){

                                    if(error.response.status === 404)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                                    else if(error.response.status === 500)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                    }
                                    else if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                                
                        });
                }

                await fetchData();
                
        }
}

// New API For Join Waiting List

export const eventNotifymeWeb = (payload)=> {

      return async (dispatch)=>{
            const sendRequest =async () =>{
                  await axios.post(BaseUrl +'/event/event_notifyme_web',payload.data,{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}))

                        if(payload.type === "eventDetails") {

                              dispatch(EventDetailActions.setEventNotifyResponse({is_waiting_joined:"1"}))
                        }
                        else if(payload.type === "currentListing"){
                              dispatch(UiActions.setWaitingListRequest({request:false}))
                        }
                        
                  })
                  .catch(function (error) {
                        
                        // console.log(error.response)
                        dispatch(UiActions.setWaitingListRequest({request:false}))
                        if(error.response){

                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else 
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        
                  });
            }

            await sendRequest();
              
      }
}

// New API For Remove from Waiting List Provided on 12-01-2022
export const removeEventNotifymeWeb = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                  await axios.post(BaseUrl +'/event/remove_event_notifyme_web',payload.data,{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                        dispatch(EventDetailActions.setEventNotifyResponse({is_waiting_joined:"0"}))
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        if(error.response){

                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else 
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        
                  });
            }

            await sendRequest();
      }
}


