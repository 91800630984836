import React from 'react';
import "./DummyComponent.css"
const DummyComponent = (props) => {
      
      return (
            
            <div className="grid-item DummyComponentCurrentListing">
                  <div className={["card card__event",props.is_soldout === "1" && "card__event-sold-out"].join(" ")}>
                        <div className="card__event-image">
                                    <a href="/">
                                          <img className="event-image"/>
                                    </a>
                        </div>
                        <div className="card-body">
                              <h5 className="card-title fw-bold">
                                    <a className=" " href="#"></a>
                              </h5>
                              <div className="list-group list-group__event-details list-group-flat">
                                    <div className="list-group-item list-group__location border-0 d-flex p-0">
                                          <div className="icon-wrapper flex-shrink-0">
                                                <div className="icon__location-pin image"></div>
                                          </div>
                                          <p className="mb-0 location">
                                                <span className="location-name">
                                                </span>
                                                <span className="location-name">
                                                </span>
                                          </p>
                                    </div>
                                    <div className="list-group-item list-group__time border-0 d-flex p-0">
                                          <div className="icon-wrapper flex-shrink-0">
                                                <div className="icon__clock image"></div>
                                          </div>
                                          <p className="mb-0 timeTag"></p>
                                    </div>
                                    <div className="list-group-item list-group__price border-0 d-flex p-0">
                                          <div className="icon-wrapper flex-shrink-0">
                                                <div className="icon__payment-card image"></div>
                                          </div>
                                          <p className="mb-0 feesTag"></p>
                                    </div>
                              </div>
                              <ul className="list__event-tags list-unstyled d-flex flex-wrap">
                                    <li>
                                          <a href="#">
                                                <span className="badge badge-outline badge-xl-lg rounded-pill" style={{"width":"30%"}}></span>
                                          </a>
                                    </li>
                                    <li>
                                          <a href="#">
                                                <span className="badge badge-outline badge-xl-lg rounded-pill" style={{"width":"50%"}}></span>
                                          </a>
                                    </li>
                              </ul>
                        </div>
                        {/* <!-- /.card-body --> */}
                  </div>
                  {/* <!-- /.card --> */}
            </div>
      );
}

export default DummyComponent;
