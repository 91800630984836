import React,{ useEffect, useRef } from 'react'

import { useSelector,useDispatch } from "react-redux";

import SingleEventComponent from './SingleComponent/SingleEventComponent';
import FilterBar from "./FilterBar/FilterBar";
import DummyComponent from "./DummyComponent/DummyComponent"

import Masonry from 'react-masonry-component';

import { AllEventsActions } from '../../Store/Allevents/AllEvents';
import { fetchEventsData } from '../../Store/Allevents/AlleventActions';


const CurrentListing = () => {

      const allevents         =     useSelector(state=>state.allevents);
      const auth              =     useSelector(state=>state.auth);
      const token             =     localStorage.getItem("nhs_access_token");
      const dispatch          =     useDispatch();

      let masonryRef  =  useRef()

      const masonryOptions = {
            // transitionDuration: 5000,
            // columnWidth: 200,
            percentPosition: true,
            horizontalOrder: true,
      };

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
           
      },[])

      useEffect(()=>{
            
            const data={'reuqest_client':'web'};

            if(!allevents.requested)   {    
                  if(auth.nhs_access_token)
                  {
                        dispatch(fetchEventsData({token:token,data:data}));
                  }
            }

      },[auth])

      const clearFilterHandler = ()=>{
            dispatch(AllEventsActions.clearFilter());
      }

      const afterLoadHandler = ()=>{
            // console.log("image were loaded");
            // console.log(masonryRef.current)
            masonryRef.current.imagesLoaded();
            
      }

      return (
            <main>
                  <section className="section__current-listings">
                        <div className="container-fluid container__content-header">
                              <FilterBar/>
                        </div>
                        {/* <!-- /.container-fluid --> */}

                        <div className="container-fluid container__content px-0">
                              {(allevents.filteredEvents.length === 0 && (allevents.isFilteredSort || allevents.isFilteredApplied)) ? 
                                    <div className="NoEventsFound">
                                          <h1>
                                                No Events Found
                                          </h1>
                                          <button className="btn btn-primary btn-xl-lg rounded-pill fw-bold ms-auto" type="submit" onClick={clearFilterHandler}>
                                                View all events
                                          </button>
                                    </div>  :
                              <div className="masonry__current-listings-wrapper position-relative">
                              {/* <!-- IMPORTANT --> */}
                                    {/* <div className="masonry__current-listings"> */}
                                   
                                    <Masonry className={'masonry__current-listings'} options={masonryOptions} ref={masonryRef} updateOnEachImageLoad={true}>
                                         
                                          {!allevents.requested && <React.Fragment>
                                                <DummyComponent/>
                                                <DummyComponent/>
                                                <DummyComponent/>
                                                <DummyComponent/>
                                          </React.Fragment>}
                                           
                                          {((allevents.requested && allevents.eventsData.length > 0) && (!allevents.isFilteredSort&& !allevents.isFilteredApplied))&& allevents.eventsData.map(el=>{
                                                
                                                return (
                                                      <SingleEventComponent key={el.id} 
                                                            id={el.id} 
                                                            uniqid={el.uniqid} 
                                                            img={el.event_image}
                                                            event_name={el.event_name}
                                                            categories={el.categories}
                                                            admin_fees={el.admin_fees}
                                                            address={el.address}
                                                            is_soldout={el.is_soldout}
                                                            tickets_left={el.leftTickets}
                                                            event_date_time={el.event_date_time}
                                                            afterLoad={afterLoadHandler}
                                                      />
                                                )
                                          })}
                                          {(allevents.requested && (allevents.isFilteredSort ||allevents.isFilteredApplied )) && allevents.filteredEvents.map(el=>{

                                                return (
                                                      <SingleEventComponent key={el.id} 
                                                            id={el.id} 
                                                            uniqid={el.uniqid} 
                                                            img={el.event_image}
                                                            event_name={el.event_name}
                                                            categories={el.categories}
                                                            admin_fees={el.admin_fees}
                                                            address={el.address}
                                                            is_soldout={el.is_soldout}
                                                            tickets_left={el.leftTickets}
                                                            event_date_time={el.event_date_time}
                                                            afterLoad={afterLoadHandler}
                                                      />
                                                )
                                          })}
                                          
                                    </Masonry>
                                    {/* </div> */}
                                    {/* <!-- /.masonry__current-listings --> */}
                              </div>
                               }
                              {/* <!-- /.masonry__current-listings-wrapper --> */}

                        </div>
                        {/* <!-- /.container__content --> */}
                  </section>
                  {/* <!-- /.section__current-listings --> */}
            </main>
      )
}

export default CurrentListing;
