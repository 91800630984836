import React,{useEffect} from "react";

import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ContactUs from "./ContactUs";
import CancelAccount from "./CancelAccount";

const Settings = () => {

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});

      },[])
      const nhs_vendor = localStorage.getItem("nhs_vendor");

     
      return (
            <main>
                  <section className="section__account-settings">
      
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content content__account-settings">
                                                <div className="content-box-wrapper mx-lg-auto">
                                                      <div className="content__header border-bottom text-center">
                                                            <h1 className="header__title fw-bold mb-0">Your Account</h1>
                                                            {nhs_vendor ==='2' && <label  className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>
                                                                  To make changes to your account, please login via Central Tickets.
                                                            </label>}
                                                      </div>
                                                      {/* <!-- /.content__header --> */}

                                                      <div className="content__body custom-scrollbar">
                                                            <ChangeEmail nhs_vendor={nhs_vendor}/>
                                                            <ChangePassword nhs_vendor={nhs_vendor}/>
                                                            <ContactUs/>
                                                            <CancelAccount nhs_vendor={nhs_vendor}/>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content-box-wrapper --> */}
                                          
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
      
                  </section>
                  {/* <!-- /.section__ --> */}
            </main>
      )
}

export default Settings;
