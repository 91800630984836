
import React, { useState, useEffect, useRef } from "react";

import "./OtpVerificationModal.css"
import Backdrop from "../Backdrop/Backdrop"

import { useSelector, useDispatch } from "react-redux";
import { UiActions } from "../../Store/Ui/Ui";
import { AuthActions } from "./../../Store/Auth/Auth"
import { BaseUrl } from "../../Shared/Constants";
import { checkValidityValidOtp } from "./../../Shared/UtilityOtpVerification";

import axios from "axios";

const OtpVerificationModal = (props)=>{

      const [mobile,setMobile] = useState({value:"",error:false,errorMessage:""})

      const [state,setState] = useState({
            otpFirstChar:"",
            otpSecondChar:"",
            otpThirdChar:"",
            otpFourthChar:"",
            otpFifthChar:"",
            otpSixthChar:""
      })

      const [error,setError] = useState({error:false,errorMessage:""});
      const [otpSent,setOtpSent] = useState({isOtpSent:false,otpSentMessage:""});
      const [seconds,setSeconds]    =     useState(0);
      const [minutes,setMinutes]    =     useState(0);
      const [sendOtp,setSendOtp]    =     useState(false);

      const token             = localStorage.getItem("nhs_access_token");
      const auth              = useSelector(state=>state.auth);
      const otpSubmitRequest  = useSelector(state=>state.ui.otpSubmitRequest)
      const sendOtpRequest    = useSelector(state=>state.ui.sendOtpRequest)
      const dispatch          = useDispatch();
      const firstInputRef     = useRef()
      const secondInputRef    = useRef()
      const thirdInputRef     = useRef()
      const fourthInputRef    = useRef()
      const fifthInputRef     = useRef()
      const sixInputRef       = useRef()

      useEffect(() => {
            if(auth.auth){
                  setMobile(prevState=>{
                        return {
                              ...prevState,
                              value:auth.nhs_access_mobilenumber
                        }
                  })

                  // setSendOtp(true);
            }
      }, [])

      useEffect(()=>{
            let myInterval
            if(!sendOtp){

                  myInterval = setInterval(() => {
                        if (seconds > 0) {
                              setSeconds(seconds-1)
                        }
                        if (seconds === 0) {
                              if (minutes === 0) {
                                    clearInterval(myInterval)
                                    setSendOtp(true);
                              } else {
                                    setMinutes(minutes-1)
                                    setSeconds(59)
                              }
                        } 
                  }, 1000)
            }

            return ()=>{
                  clearInterval(myInterval);
            }
      })

      const senOtpHandler = (event)=>{
            event.preventDefault();
            
            const validation = checkValidityValidOtp(mobile.value,'mobile');

            if(!validation.isValid){
                  setMobile(prevState=>{
                        return {
                              ...prevState,
                              error:true,
                              errorMessage:validation.validationMessage
                        }
                  })
            }
            else if(sendOtp){
                  dispatch(UiActions.setOtpRequest({request:true}))
                  axios.post(BaseUrl+'/send_otp',{mobile:mobile.value},{
                        headers: {
                              'token': token,
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response,"verifyContact");
                        dispatch(UiActions.setOtpRequest({request:false}))
                        setOtpSent(prevState=>{
                              return {
                                    isOtpSent:true,otpSentMessage:response.data.message
                              }
                        })

                        setSendOtp(false);
                        setMinutes(1);
                        setSeconds(0);
                  })
                  .catch(function (error) {
                       
                        // console.log(error.response)
                        dispatch(UiActions.setOtpRequest({request:false}));
                        setSendOtp(false);
                        setMinutes(1);
                        setSeconds(0);
                        if(error.response){
      
                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                                    dispatch(AuthActions.logout());
                              }
                              else if(error.response.status === 500) {
      
                                    if(error.response.data.status === "0"){
                                          setOtpSent(prevState=>{
                                                return {
                                                      isOtpSent:true,otpSentMessage:error.response.data.message
                                                }
                                          })
                                          setState({
                                                otpFirstChar:"",
                                                otpSecondChar:"",
                                                otpThirdChar:"",
                                                otpFourthChar:"",
                                                otpFifthChar:"",
                                                otpSixthChar:""
                                          })
                                    }
                              }
                              else if(error.response.status === 404 ||error.response.status === 405)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    dispatch(AuthActions.logout());
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              dispatch(AuthActions.logout());
                        }
                  });
            }
           
      }

      const changeHandler = (event)=> {

            const key = event.target.name;
            const onlyNumbers = event.target.value.replace(/\D/g, '');

            setState(prevState=>{

                  return {
                        ...prevState,
                        [key] :onlyNumbers
                  }
            })

            if(!onlyNumbers){
                  if(key === "otpSixthChar"){
                        fifthInputRef.current.focus();
                  }
                  else if(key === "otpFifthChar"){
                        fourthInputRef.current.focus();
                  }
                  else if(key === "otpFourthChar"){
                        thirdInputRef.current.focus();
                  }
                  else if(key === "otpThirdChar"){
                        secondInputRef.current.focus();
                  }
                  else if (key === "otpSecondChar"){
                        firstInputRef.current.focus();
                  }
                  return;
            }
            else {

                  if(key === "otpFirstChar"){
                        secondInputRef.current.focus();
                  }
                  else if(key === "otpSecondChar"){
                        thirdInputRef.current.focus();
                  }
                  else if(key === "otpThirdChar"){
                        fourthInputRef.current.focus();
                  }
                  else if(key === "otpFourthChar"){
                        fifthInputRef.current.focus();
                  }
                  else if (key === "otpFifthChar"){
                        sixInputRef.current.focus();
                  }
            }

      }

      const changeMobileHandler = (event)=>{
            // const key = event.target.name;
            const onlyNumbers = event.target.value.replace(/\D/g, '');
            setMobile(prevState=>{
                  return {
                        ...prevState,
                        value:onlyNumbers
                  }
            })
      }

      const mobileFocusHandler = ()=>{

            if(mobile.error){
                  setMobile(prevState=>{
                        return {
                              ...prevState,
                              error:false
                        }
                  })
            }
      }


      const focusHandler = ()=>{
            setError(prevState=>{

                  return {
                        ...prevState,
                        error:false
                  }
            })
      }

      // const keyDownHandler = (event)=>{

      //       console.log(event.target.name)
      // }
      
      const submitOtpHandler = (event)=>{
            event.preventDefault();

            let otp = "";

            for(let i in state){
                  if(state[i]){
                        otp+=state[i]
                  }
                  // else
                  // {
                  //       setError({error:true,errorMessage:"Please enter a valid otp"})
                  //       return
                  // }
            }

            const validation = checkValidityValidOtp(otp,'otp');
            if(!validation.isValid){
                  setError({error:true,errorMessage:validation.validationMessage})
                  return;
            }
            if(validation.isValid && otp.length === 6){
                    
                  dispatch(UiActions.setOtpSubmitRequest({request:true}))
                  axios.post(BaseUrl + '/otp_verify',{otp:otp,mobilenumber:mobile.value},{
                        headers: {
                              'token': token,
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {

                        dispatch(UiActions.setOtpSubmitRequest({request:false}))
                        dispatch(AuthActions.updateMobileNumber({mobile:mobile.value}))
                        dispatch(AuthActions.verfiedMobileNumber());
                        dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                  })
                  .catch(function (error) {

                        // console.log(error.response)
                        dispatch(UiActions.setOtpSubmitRequest({request:false}))
                        if(error.response) {
                                    
                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                              }
                              else if(error.response.status === 500) {

                                    if(error.response.data.status === "0"){
                                          setError({
                                                error:true,
                                                errorMessage:error.response.data.message
                                          })
                                    }
                                    
                              }
                              else if(error.response.status === 404 ||error.response.status === 405)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        
                  });
            }
      }

      const closeOtpPopupHandler = ()=>{
            dispatch(AuthActions.logout());
      }

      return (
                  <div className="otpVerification">
                        <Backdrop show={true} />
                        <div className="modal modal__registration-verification fade show" id="modalVerificationCode" tabIndex="-1" style={{"display": "block"}} aria-modal="true" role="dialog">
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closeOtpPopupHandler}></button>
                                                {!otpSent.isOtpSent ? 
                                                <div className="content">
                                                      <div className="content__body">
                                                            <form>
                                                                  <p className="mb-0">One final thing.&nbsp; We need you to confirm your contact number.&nbsp; Please enter your mobile number below and we'll send you an OTP code to confirm your contact details.
                                                                  </p>
                                                                  <div  className="form-group__send-code d-flex justify-content-center text-center mt-4">
                                                                        <input id="inputMobile" type="text" className="form-control" name="mobile" value={mobile.value} onChange={changeMobileHandler} disabled={sendOtpRequest} onFocus={mobileFocusHandler}/>
                                                                  </div>
                                                                  {mobile.error && <p className="mt-2 text-center" style={{"color":"var(--bs-red)"}}>{mobile.errorMessage} </p>}
                                                                  <div className="form-group__cta d-flex justify-content-center mt-4">
                                                                        <button type="submit" className="btn btn-xl-lg btn-primary" onClick={senOtpHandler}>
                                                                              {(!sendOtpRequest) && "Send SMS"}
                                                                              {(sendOtpRequest) && "Please wait.."}
                                                                              {(sendOtpRequest) && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                        </button>
                                                                  </div>
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div> :
                                                <div className="content">
                                                      <div className="content__body">
                                                            <form>
                                                                  {otpSent.otpSentMessage ? 
                                                                  <p className="mb-0" dangerouslySetInnerHTML={{__html: otpSent.otpSentMessage}}></p>:
                                                                  <p className="mb-0">We just sent you a verification code for your number {mobile}. Insert it here:</p>}
                                                                  <div id="otp" className="mt-4 mb-4 form-group__verification-code d-flex justify-content-center text-center">
                                                                        <input id="otpFirstChar" type="text" className="form-control" name="otpFirstChar" maxLength="1" autoFocus="" value={state.otpFirstChar} onChange={changeHandler} disabled={otpSubmitRequest || sendOtpRequest} onFocus={focusHandler} ref={firstInputRef}/>

                                                                        <input id="otpSecondChar" type="text" className="form-control" name="otpSecondChar" maxLength="1" value={state.otpSecondChar} onChange={changeHandler} disabled={otpSubmitRequest || sendOtpRequest} onFocus={focusHandler} ref={secondInputRef}/>

                                                                        <input id="otpThirdChar" type="text" className="form-control" name="otpThirdChar" maxLength="1" value={state.otpThirdChar} onChange={changeHandler} disabled={otpSubmitRequest ||sendOtpRequest} onFocus={focusHandler} ref={thirdInputRef}/>

                                                                        <input id="otpFourthChar" type="text" className="form-control" name="otpFourthChar" maxLength="1" value={state.otpFourthChar} onChange={changeHandler} disabled={otpSubmitRequest || sendOtpRequest} onFocus={focusHandler} ref={fourthInputRef}/>

                                                                        <input id="otpFifthChar" type="text" className="form-control" name="otpFifthChar" maxLength="1" value={state.otpFifthChar}onChange={changeHandler} disabled={otpSubmitRequest || sendOtpRequest} onFocus={focusHandler} ref={fifthInputRef}/>

                                                                        <input id="otpSixthChar" type="text" className="form-control" name="otpSixthChar" maxLength="1" value={state.otpSixthChar}onChange={changeHandler} disabled={otpSubmitRequest || sendOtpRequest} onFocus={focusHandler} ref={sixInputRef}/>
                                                                  </div>
                                                                  {error.error && <p className="mb-4 text-center" style={{"color":"var(--bs-red)"}}>{error.errorMessage} </p>}
                                                                  <div className="form-group__cta d-flex justify-content-center">
                                                                        <button type="submit" className="btn btn-xl-lg btn-primary" onClick={submitOtpHandler}>
                                                                              {(!otpSubmitRequest && !sendOtpRequest) && "Verify"}
                                                                              {(otpSubmitRequest || sendOtpRequest) && "Please wait.."}
                                                                              {(otpSubmitRequest || sendOtpRequest) && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                        </button>
                                                                  </div>
                                                                  
                                                                  {sendOtp && <p className="mt-4 mb-0 text-center">Didn’t receive it? <a className="blue " href="#" onClick={senOtpHandler}>Send it again</a></p>}
                                                                  {!sendOtp && 
                                                                        
                                                                        <p className="mt-4 mb-0 text-center">Didn’t receive it? <a className="blue textDecoration" href="#" >Send it again in  {String(minutes).padStart(2, '0') +":"+ String(seconds).padStart(2, '0')}</a> </p>
                                                                  }
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>}
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                  </div>

      );
        
}

export default React.memo(OtpVerificationModal);