import React,{useState, useEffect} from 'react'

import "./BookingConfirmation.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { BaseUrl } from '../../../../Shared/Constants';
import { UiActions } from '../../../../Store/Ui/Ui';
import { EventDetailActions } from '../../../../Store/EventDetail/EventDetail';
// import { Navigate } from 'react-router-dom';
const BookingConfirmation = (props) => {

      const [state,setState]  =     useState(null);
      const token             =     localStorage.getItem("nhs_access_token");
      const bookingId         =     useSelector(state => state.bookingmodule.bookingId)
      const spinner         =     useSelector(state => state.ui.spinner)
      const dispatch          =     useDispatch();
      const navigate          =     useNavigate();

      useEffect(()=>{

            dispatch(UiActions.setSpinner({status:'request'}));
            const booking_id = props.bookingHistory ? props.bookingId : bookingId;

            if(booking_id){

                  const sendRequest = async () =>{
      
                        // axios.post(BaseUrl + '/event/BookingDetails',{booking_id:booking_id},{
                        axios.post(BaseUrl + '/event/LastBookingDetails',{booking_id:booking_id},{
                              headers: {
                                    'token': token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              }
                        })
                        .then(function (response) 
                        {       

                              const bookingData = response.data.data[0];
                              setState(bookingData)
                              dispatch(UiActions.clearUiSpinner());
                        })
                        .catch(function (error) {
      
                              // console.log(error.response)
                              dispatch(UiActions.clearUiSpinner());
                              if(props.bookingHistory){
                                    props.closeBookingHandler();
                              }
                              else {

                                    if(error.response){
                                          if(error.response.status === 400)
                                          {
                                                dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                          }
                                          else if(error.response.status === 500)
                                          {
                                                dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                                          }
                                          else
                                          {
                                                dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                          }
                                    }
                                    else{
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
      
                        });
                  }
                  sendRequest();
            }

      },[]);

      const dateFormateHandler = ()=>{
            let date = "";

            if(state){
                  const dateArr = state.start_date.split("-");
                  date  = dateArr[2] +"/"+dateArr[1]+"/"+dateArr[0];
            }
            return date
      }

      const timeFormateHandler = ()=>{
            let time = "";
            if(state){
                  time = state.date_format.split(",")[1];
            }

            return time
      }

      let tickets=0;
      let eventImage = "";
      let eventName = "";

      if(state){
            tickets = state.tickets;
            eventImage = state.event_image;
            eventName = state.EventName;
      }
      
      const bookAnotherShowHandler = (e)=>{
            e.preventDefault();

            if(props.bookingHistory){
                  props.closeBookingHandler();
            }
            else {

                  dispatch(EventDetailActions.closeEventBooking());
            }
            dispatch(UiActions.clearTimer())
            navigate("/currentListing");

      }

      return (
                  <React.Fragment>
                  {!state &&
                        <div id="bookTicketsStep5" className="content content__booking-completed content__booking_completed-dummy">
                              <div className="content__body content__body-dummy">
                                    <div className="wrapper text-center wrapper-dummy">
                                          <div className="event__poster mx-auto event__poster-dummy">
                                                {/* <img className="img-fluid object-fit" src="Error.src" onError={{"display":"none"}} alt=""/> */}
                                          </div>
                                          <p className='dummy-message'></p>
                                          <h1 className="event__name blue fw-bold event__name-dummy">
                                          </h1>
                                          <p className='dummy-date'>
                                          </p>
                                          <p className='dummy-donation'> </p>
                                          <p className='dummy-donation-2'></p>
                                          <p className='dummy-donation-3'></p>
                                          <div className="buttons-wrapper gap-0 buttons-wrapper-dummy">
                                                {/* <a href="/" disabled className="btn btn-primary fw-bold"></a> */}
                                          </div>
                                    </div>
                              </div>
                              {/* <!-- /.content__body --> */}
                        </div>
                  }
                  {state &&
                  <div id="bookTicketsStep5" className="content content__booking-completed">
                        <div className="content__body">
                              <div className="wrapper text-center">
                                    <div className="event__poster mx-auto" style={{"borderRadius":"50%"}}>
                                          <img className="img-fluid object-fit" src={eventImage} alt=""/>
                                    </div>
                                    <p>It’s all done! You are going to see</p>
                                    <h1 className="event__name blue fw-bold">
                                          {eventName}
                                    </h1>
                                    <p>
                                          <span className="fw-bold">{tickets}</span> { tickets > 1 ? "Tickets" : "Ticket"} for <span className="fw-bold"> {dateFormateHandler()}</span> at <span className="fw-bold">{timeFormateHandler()}</span>
                                    </p>
                                    <p>With your booking we just donated {state && state.donation}
                                          <br/>to <strong>NHS Charities Together</strong>
                                          <br/>(registered charity no. 1186569)
                                    </p>
                                    <div className="buttons-wrapper gap-0">
                                          <a href="#" className="btn btn-primary fw-bold" onClick={bookAnotherShowHandler}>Book another show</a>
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}
                  </div>}
                  </React.Fragment>
                                                            
            )
}

export default BookingConfirmation;
