import React from 'react'

import 'font-awesome/css/font-awesome.min.css';
import classes from "./Loader.module.css";

import { useSelector,useDispatch } from 'react-redux';

import Backdrop from "./Backdrop/Backdrop";

import { UiActions } from '../../Store/Ui/Ui';
import { BookingModuleActions } from '../../Store/BookingModule/BookingModule';
import { TicketRelease } from '../../Store/BookingModule/BookingModuleActions';

const Loader = (props) => {

      const state              =       useSelector(state=>state.ui);
      const eventdetail        =       useSelector(state=>state.eventdetail);
      const dispatch           =       useDispatch()


      const resetBookingHandler = ()=>{

            const token = localStorage.getItem("nhs_access_token");
            const session_block_id = localStorage.getItem("nhs_session_block_id");

            if(session_block_id){
                  dispatch(TicketRelease({token:token,session_id:session_block_id}))
            }

            const group_availablity = eventdetail.values.group_availablity;
                
            dispatch(UiActions.clearTimer());
            dispatch(UiActions.clearUiSpinner());
            dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:group_availablity}))
      }

      return (
            <>
                  {state.spinner !== "" && <Backdrop spinner={state.spinner}/>}
                  {state.spinner === "expired" && <div className={classes.ct_widget_spinner}>
                              <div className={[classes.calculation_area,classes.no_padding].join(" ")}>
                                    <div className={classes.card_exprire}>
                                          <span className={["fa fa-close close_icon ct_bg_set",classes.refrsh_close].join(" ")}></span>
                                    </div>
                                    <div className={classes.calculation_area} style={{"textAlign": "center"}}>
                                          <ul>
                                                <li className="">Your order has expired.</li>
                                                <li className="">Please try again.</li>
                                                <li></li>
                                                <li> </li>
                                                <li> </li>
                                          </ul>
                                    </div>
                              </div>
                              <div className={classes.action_area}  style={{"textAlign": "center"}}>
                                    <a href="#" id="ct_prev_icon"  className="action backButton" onClick={resetBookingHandler}>
                                          <span className={["fa fa-refresh icon ct_set_color",classes.ct_widget_spinner__spinner].join(" ")}></span>
                                          <p className={classes.restart_boking}>Restart Booking</p>
                                    </a>
                              </div>
                  </div>}
                  {state.spinner === "request" && <span className={["fa fa-refresh ct_widget_spinner fa-spin",classes.ct_widget_spinner__alone].join(" ")}></span>}
            </>
      )
}

export default Loader;