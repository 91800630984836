import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';

import { Navigate, Link } from 'react-router-dom';
import logoNHSCT from "./../assests/images/logo-NHSCT-md.png"
import logoNHSCTa2x from "./../assests/images/logo-NHSCT-md@2x.png";

import ticketsHeroxSide2Yellow from "./../assests/images/ticket-hero-x-side-2-yellow-xl.png";
import ticketsHeroCorndersSawYSideOrangeLg from "./../assests/images/ticket-hero-corners-saw-y-side-orange-lg.png";
import ticketHeroCorners4TealLg from "./../assests/images/ticket-hero-corners-4-teal-lg.png";
import ticketHeroCorners6GreenLg from "./../assests/images/ticket-hero-corners-6-green-lg.png";
import ticketHeroXSide2YellowLg from "./../assests/images/ticket-hero-x-side-2-yellow-lg.png";
import ticketHeroCornersYSide2OrangeLg from "./../assests/images/ticket-hero-corners-y-side-2-orange-lg.png";
import ticketHeroCornersXySide4TealLg from "./../assests/images/ticket-hero-corners-xy-side-4-teal-lg.png";
import ticketHeroCornersXySide4RedLg from "./../assests/images/ticket-hero-corners-xy-side-4-red-lg.png";
import ticketHeroSawXSide2BlueLg from "./../assests/images/ticket-hero-saw-x-side-2-blue-lg.png";
import ticketHeroCorners4YellowXl from "./../assests/images/ticket-hero-corners-4-yellow-xl.png";
import ticketHeroCornersSawYSideRedXl from "./../assests/images/ticket-hero-corners-saw-y-side-red-xl.png";
import ticketHeroCornersYSide2GreenLg from "./../assests/images/ticket-hero-corners-y-side-2-green-lg.png";
import ticketHeroCorners4BlueXl from "./../assests/images/ticket-hero-corners-4-blue-xl.png";
import ticketHeroCornersYSide2RedLg from "./../assests/images/ticket-hero-corners-y-side-2-red-lg.png";
// import ticketHeroCornersYSide2GreenLg from "./../assests/images/ticket-hero-corners-y-side-2-green-lg.png";

import iconArrowDownBlackXxl from "./../assests/images/icon-arrow-down-black-xxl.svg";
import stockImage06 from "./../assests/images/stock-image-06.jpg";
import stockImage06a2x from "./../assests/images/stock-image-06@2x.jpg";
// import stockImage06a2x from "./../assests/images/stock-image-06@2x.jpg 2x";
import stockImage08 from "./../assests/images/stock-image-08.jpg";
import stockImage08a2x from "./../assests/images/stock-image-08@2x.jpg";
// import stockImage08a2x from "./../assests/images/stock-image-08@2x.jpg 2x";

// -------------------------------------owl carousel images--------------------------------------------------
import stockImage05 from "./../assests/images/stock-image-05.jpg";
import stockImage05a2x from "./../assests/images/stock-image-05@2x.jpg";

// import stockImage05a2x from "./../assests/images/stock-image-05@2x.jpg 2x";

// import stockImage01 from "./../assests/images/stock-image-01.jpg";
// import stockImage01a2x from "./../assests/images/stock-image-01@2x.jpg";
// import stockImage01a2x from "./../assests/images/stock-image-01@2x.jpg 2x";
import stockImage01 from "./../assests/images/iStock-1349269543.jpg";  //stock image without watermarks
// import stockImage01 from "./../assests/image/iStock-13.jpg";  //stock image without watermarks

import stockImage02 from "./../assests/images/stock-image-02.jpg";
import stockImage02a2x from "./../assests/images/stock-image-02@2x.jpg";
// import stockImage02a2x from "./../assests/images/stock-image-02@2x.jpg 2x";

import stockImage03 from "./../assests/images/stock-image-03.jpg";
import stockImage03a2x from "./../assests/images/stock-image-03@2x.jpg";
// import stockImage03a2x from "./../assests/images/stock-image-03@2x.jpg 2x";

import stockImage04 from "./../assests/images/stock-image-04.jpg";
import stockImage04a2x from "./../assests/images/stock-image-04@2x.jpg";
// import stockImage04a2x from "./../assests/images/stock-image-04@2x.jpg 2x";

import { useDispatch } from "react-redux";
import {  useLocation } from 'react-router-dom';
import { appVerifyEmail } from "./../Store/Auth/AuthActions";

const Home = () => {

      const { pathname }      =     useLocation();
      const dispatch          =     useDispatch();
      const token             =     localStorage.getItem("nhs_access_token");
      
      const redirect = localStorage.getItem("nhs_redirect");
      const nhs_role = localStorage.getItem("nhs_role") || "0";
      const nhs_city = localStorage.getItem("nhs_city") || "0";
      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});

            if(pathname.includes("verifyEmail")) {
                  
                  const verification_code = pathname.split("/")[2];
                  dispatch(appVerifyEmail({verification_code:verification_code,token:token}))
            }

      },[])
     
      const options={
            responsive:{
                  0:{
                        items:1
                  }
            },
            autoplay: true,
            loop: true
      }

      let path = "/";
      if(redirect && token){

            if(nhs_role !== "0" || nhs_city !== "0"){
                  path = redirect;
            }
            localStorage.removeItem('nhs_redirect');

            return <Navigate to={`${path}`}/>
      }

      return (
            
            <main>
                  <section className="section__home-content">
        
                        {/* <!-- .container__hero --> */}
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        
                        <div className="container-fluid container__block container__hero border-bottom">
                              
                              <div className="row">
                                    <div className="col-md-12">
                                          <div className="content hero__content">
                                                <div className="content__body d-flex flex-column mb-auto">
                                                      <div className="upper-row mb-auto">
                                                            <h1 className="display-1">
                                                                  Music.&nbsp; Theatre.&nbsp; Events.<br/>Special priced tickets for <span className="d-xxl-block"><span className="fw-bold blue">NHS Staff</span>.*</span>
                                                            </h1>
                                                      </div>
                                                      <div className="lower-row d-flex flex-column flex-md-row flex-wrap align-items-md-end justify-content-md-between">
                                                            <div>
                                                                  <a href="https://nhscharitiestogether.co.uk/" target="_blank" rel='noreferrer'>
                                                                        <img className="logo__NHSCT" src={logoNHSCT} srcSet={logoNHSCTa2x} alt=""/>
                                                                  </a>
                                                                  <p className="p-charity-num">Registered charity no. 1186569</p>
                                                            </div>
                                                            <p className="p-asterisk mb-0">*NHS Staff & verified Healthcare Key Workers.</p>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.hero__content --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                              </div>
                              
                              {/* <!-- /.row --> */}
                              
                              {/* <!-- Tickets with bubble aniamtion --> */}
                              <div className="animated-tickets d-none d-lg-block">
                                    <img className="animated-img img-ticket__1"  src={ticketsHeroxSide2Yellow} alt=""/>
                                    <img className="animated-img img-ticket__2"  src={ticketsHeroCorndersSawYSideOrangeLg} alt=""/>
                                    <img className="animated-img img-ticket__3"  src={ticketHeroCorners4TealLg} alt=""/>
                                    <img className="animated-img img-ticket__4"  src={ticketHeroCorners6GreenLg} alt=""/>
                                    <img className="animated-img img-ticket__5"  src={ticketHeroXSide2YellowLg} alt=""/>
                                    <img className="animated-img img-ticket__6"  src={ticketHeroCornersYSide2OrangeLg} alt=""/>
                                    <img className="animated-img img-ticket__7"  src={ticketHeroCornersXySide4TealLg} alt=""/>
                                    <img className="animated-img img-ticket__8"  src={ticketHeroCornersXySide4RedLg} alt=""/>
                                    <img className="animated-img img-ticket__9"  src={ticketHeroSawXSide2BlueLg} alt=""/>
                                    <img className="animated-img img-ticket__10" src={ticketHeroCorners4YellowXl} alt=""/>
                                    <img className="animated-img img-ticket__11" src={ticketHeroCornersSawYSideRedXl} alt=""/>
                                    <img className="animated-img img-ticket__12" src={ticketHeroCornersYSide2GreenLg} alt=""/>
                                    <img className="animated-img img-ticket__13" src={ticketHeroCorners4BlueXl} alt=""/>
                                    <img className="animated-img img-ticket__14" src={ticketHeroCornersYSide2RedLg} alt=""/>
                                    <img className="animated-img img-ticket__15" src={ticketHeroCornersYSide2GreenLg} alt=""/>
                              </div>
                             
            
                        </div>
                        {/* <!-- /.container__content --> */}
            
                        {/* <!-- .container__about --> */}
                        <div className="container-fluid container__block container__about border-bottom">
                             
                              <div className="row align-items-lg-stretch">
                                    
                                    <div className="col-lg-6 border-right-lg">
                                          <div className="content content__1 border-bottom d-flex flex-column">
                                                <div className="content__body mb-auto">
                                                      <p>
                                                            We are a <strong>platform</strong> that allows all <strong>NHS staff</strong> to enjoy live performances, theatre, concerts, events and much more at a reduced price, that include a donation to <a className="fw-bold" href="https://nhscharitiestogether.co.uk/" target="_blank" rel='noreferrer'>NHS
                                                            Charities Together</a>.
                                                      </p>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                                <div className="content__cta">
                                                      <Link className="btn btn-xl-lg btn-primary fw-bold" to="/join">Register now</Link>
                                                </div>
                                          </div>
                                          {/* <!-- /.content --> */}
                                          {/* <!--
                                                NOTE: this block is desktop visible only. Mobile copy below
                                          --> */}
                                          <div className="content content__2">
                                                <div className="content__body d-none d-lg-block">
                                                      <div className="d-flex justify-content-between">
                                                            <div>
                                                                  <p className="mb-0">Discover how we work together to celebrate and support the work of the NHS.</p>
                                                            </div>
                                                            <img className="icon__arrow-down-black-xxl flex-shrink-0" src={iconArrowDownBlackXxl} alt=""/>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    
                                    {/* <!-- .col-lg-6 --> */}
                                    
                                    <div className="col-lg-6">
                                          <div className="content content__image content__3 h-100">
                                                <div className="content__body content__body-image h-100">
                                                      <OwlCarousel className='owl-carousel owl-carousel__shows owl-theme dots-bottom-overlay h-100' loop {...options}>
                                                      {/* <div className="owl-carousel owl-carousel__shows owl-theme dots-bottom-overlay h-100"> */}
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" srcSet={stockImage05a2x} src={stockImage05} alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        // srcSet={stockImage01a2x} 
                                                                        src={stockImage01} alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" srcSet={stockImage02a2x} src={stockImage02} alt=""/>
                                                                  </div>
                                                            </div>
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" srcSet={stockImage03a2x} src={stockImage03} alt=""/>
                                                                  </div>
                                                            </div>      
                                                            <div className="item">
                                                                  <div className="item__image">
                                                                        <img className="carousel-image object-fit" srcSet={stockImage04a2x} src={stockImage04} alt=""/>
                                                                  </div>
                                                            </div>
                                                      {/* </div> */}
                                                      </OwlCarousel>
                                                      {/* <!-- /.owl-carousel__shows --> */}
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    
                                    {/* <!-- .col-lg-6 --> */}
                              </div>
                              
                              {/* <!-- /.row --> */}
            
                              {/* <!--
                                    NOTE: this row is mobile visible only
                              --> */}
                              <div className="row d-xl-none">
                                    <div className="col-lg-12">
                                          <div className="content content__2">
                                                <div className="content__body d-lg-none">
                                                      <div className="d-flex justify-content-between">
                                                            <p className="mb-0">Discover how we work together to celebrate and support the work of the NHS.</p>
                                                            <img className="icon__arrow-down-black-xxl flex-shrink-0" src={iconArrowDownBlackXxl} alt=""/>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                              </div>
                              {/* <!-- .row --> */}
                        </div>
                        {/* <!-- /.container__about --> */}
            
                        <div className="container-fluid container__block container__work-with-us border-bottom">
                              <div className="row align-items-lg-stretch">
                                    <div className="col-lg-6 order-lg-2">
                                          <div className="content content__1 d-flex flex-column h-100">
                                                <div className="content__header border-bottom">
                                                      <h2 className="header__title fw-bold mb-0">Work with us</h2>
                                                </div>
                                                {/* <!-- /.content__header --> */}
                                                <div className="content__body mb-auto">
                                                      <p>Working with <strong>Tickets for Medics</strong> helps event promoters give thanks to the National Health Service (NHS) by providing complimentary tickets for verified NHS staff members and service providers to attend on a
                                                      fast-fill basis.</p>
                                                      <p>Shows are available to book in a private members area and discreetly attended by our exclusive database of verified NHS staff, or NHS service providers (pharmacists, medicine supply etc).</p>
                                                      <p>Tickets are listed with no face-value cost and only a small booking fee is charged to cover our overheads, which include a donation per ticket to NHS Charities Together (registered charity
                                                      no. 1186569).</p>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                                <div className="content__cta">
                                                      <Link className="btn btn-xl-lg btn-primary fw-bold" to="/contactus" 
                                                      // state={{type: 'becomePartner'}}
                                                      >
                                                            Become a partner
                                                      </Link>
                                                </div>
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    <div className="col-lg-6 order-lg-1 border-right-lg">
                                          <div className="content h-100">
                                                <div className="content__body content__body-image h-100">
                                                      <img className="carousel-image img-fluid object-fit small-device-image-fix" srcSet={stockImage06a2x} src={stockImage06} alt=""/>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                              </div>
                              {/* <!-- .row --> */}
                        </div>
                        {/* <!-- /.container__work-with-us  --> */}
            
                        <div className="container-fluid container__block container__ad border-bottom">
                              <div className="row">
                                    <div className="col-lg-10 offset-lg-1">
                                          <div className="content content__1 text-md-center mx-auto">
                                                <div className="content__body">
                                                      <p className="mb-0">Verified NHS Staff and service providers can take time out from their challenging working lives to attend <span className="blue fw-bold">live events</span> recharge and spend quality time with family and friends.</p>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                              </div>
                              {/* <!-- .row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
            
                        <div className="container-fluid container__block container__how-to-join">
                              <div className="row align-items-lg-stretch">
                                    <div className="col-lg-6 border-right-lg">
                                          <div className="content content__1 d-flex flex-column h-100">
                                                <div className="content__header border-bottom ">
                                                      <h2 className="header__title fw-bold mb-0">How to join us</h2>
                                                </div>
                                                {/* <!-- /.content__header --> */}
                                                <div className="content__body mb-auto">
                                                      <div className="mb-auto">
                                                            <p>Tickets for Medics offers membership to NHS staff or anyone who provides services to the NHS (doctors, nurses, midwives, paramedics, social workers, care workers, and other frontline health and social care staff, including
                                                            community pharmacists and their staff).</p>
                                                            <p>You’ll get access to heavily discounted ticket opportunities in return for being discreet and attending any bookings you make.&nbsp;&nbsp;It’s free and it only takes a few minutes to register.
                                                            </p>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                                <div className="content__cta">
                                                      <Link className="btn btn-xl-lg btn-primary fw-bold" to="/join">Register now</Link>
                                                </div>
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    <div className="col-lg-6">
                                          <div className="content h-100">
                                                <div className="content__body content__body-image h-100">
                                                      <img className="carousel-image img-fluid object-fit small-device-image-fix" srcSet={stockImage08a2x} src={stockImage08} alt=""/>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                              </div>
                              {/* <!-- .row --> */}
                        </div>
                        {/* <!-- /.container__how-to-join  --> */}
                  </section>
                  {/* <!-- /.section__ --> */}
          </main>     
      )
}

export default Home;
