export const checkValidityForgotPasswordChange=(value, type) =>{
      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }

      if(type === "password"){
            if(value[type].trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please enter a new password";
            }
            else if(value[type].length < 6)
            {
                  isValid=false;
                  validationMessage = "Password length must be greater than 6 characters or digits";
            }
             
      }

      if(type === "confirm_password"){
            if(value[type].trim()=== "")
            {
                  isValid =false;
                  validationMessage = "Please enter your new password choice again";
            }
            else if(value[type] !== value.password)
            {
                  isValid=false;
                  validationMessage = "That’s not a match.  Please review your entries to ensure they match.";
            }
             
      }
      
      return {isValid,validationMessage};
}

