import React,{ useEffect, useState} from 'react'

import $ from "jquery";
import { useParams } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";

import "./EventDetail.css";
import DummyComponent from './DummyComponent/DummyComponent';
import iconClockBlack from "./../../assests/images/icon-clock-black.svg";
import iconPaymentCardBlack from "./../../assests/images/icon-payment-card-black.svg";
import iconLocationPinBlock from "./../../assests/images/icon-location-pin-black.svg"
import citymaperImage from "./../../assests/images/Citymapper.webp";

import BookingModule from "../Bookings/BookingModule/BookingModule";

import { Transition } from 'react-transition-group';

import { EventDetailActions } from "../../Store/EventDetail/EventDetail"
import { fetchEventDetailData } from '../../Store/EventDetail/EventDetailActions';
import { BookingModuleActions } from "../../Store/BookingModule/BookingModule";
import { UiActions } from "../../Store/Ui/Ui"
import { eventNotifymeWeb, removeEventNotifymeWeb } from "../../Store/EventDetail/EventDetailActions"

const EventDetail = () => {
    
      const [script,setScript] = useState(false)
      const state       =     useSelector(state=>state.eventdetail);
      const confirm     =     useSelector(state=>state.ui.alert.type.status);
      const {eventId}   =     useParams();
      let token         =     localStorage.getItem('nhs_access_token');
      const dispatch    =     useDispatch();

      useEffect(()=>{
            
            window.scrollTo({top: 0, behavior: 'smooth'});

            dispatch(BookingModuleActions.setEventId({event_id:eventId}));
            dispatch(EventDetailActions.clearEventDetailsState());
            dispatch(fetchEventDetailData({data:{event_id:eventId},token:token}));
           
            return (()=>{
                  dispatch(BookingModuleActions.setEventId({event_id:""}));
                  dispatch(EventDetailActions.clearEventDetailsState());
            })
                  
      },[])

      var windowWidth = window.innerWidth;
      useEffect(()=>{

            const redirect = localStorage.getItem("nhs_redirect")
            if(redirect){

                  if(redirect.includes("eventdetails")){
                        localStorage.removeItem("nhs_redirect")
                  }
            }
            
      },[])

      useEffect(()=>{
            
            if(state.eventInfo && state.values && state.scheduleInfo && !script){
                  
                  if (windowWidth > 991) {

                        var resizeTimer;
                        // $(window).resize(function() {
                          clearTimeout(resizeTimer);
                          resizeTimer = setTimeout(function() {
                            //location.reload();
                            fnStickyInfoVenueTabs();
                          }, 100);
                        // });
                  }

                  setScript(true);
                  
                  // handleLoadScript();
            }

      })

      function fnStickyInfoVenueTabs() {
            var offsetVal = 110;
            var contentWidth = $('.fixed-scroll-target').innerWidth();
            var contentHeight = $('.fixed-scroll-target').innerHeight();
            var navTabsHeight = $('.nav-tabs__info-venue-wrapper').height();
            var navTabsBottomPos = contentHeight - navTabsHeight;
        
            $('.fixed-scroll-target').css('min-height', navTabsHeight)
        
            $(window).scroll(function() {
                  var trigger = $(window).scrollTop();
                  
                  if ($(window).scrollTop() >= offsetVal) {
                        $('.nav-tabs__info-venue-wrapper').addClass('fixed');
                  } else {
                        $('.nav-tabs__info-venue-wrapper').removeClass('fixed');
                  }
        
                  if (trigger >= navTabsBottomPos) {
                        $('.nav-tabs__info-venue-wrapper').addClass('fixed-bottom');
                  } else {
                        $('.nav-tabs__info-venue-wrapper').removeClass('fixed-bottom');
                  }
            });

           
      } // END fnStickyInfoVenueTabs()


      useEffect(()=>{

            if(confirm){
                  confirmationForWaitingHandler();
            }

      },[confirm,dispatch,token])


      const startBookingHandler = ()=>{

            if(state.eventInfo && state.values && state.scheduleInfo && state.values.is_soldout !== "1")
            {
                  const group_availablity = state.values.group_availablity;
                  
                  dispatch(BookingModuleActions.setGroupAvailability({group_availablity:group_availablity,step:"EvnentsCalenderPage"}))
                  dispatch(EventDetailActions.startEventBooking());
            }
            else if(state.values.is_soldout === "1"){

                  let message = "This event is fully booked.  Would you like to join the waiting list & be notified if more tickets become available?"
                  if(is_waiting_joined === "1"){
                        message = "Are you sure you would like to remove yourself from the waiting list? You will not be notified if more tickets become available."
                  }
                  dispatch(UiActions.setAlert({model:true,message:message,type:{value:"confirm",status:false}}))
            }
      }

      const confirmationForWaitingHandler= ()=>{
                
            if(state.values.is_soldout === '1'){
                  const event_id  = eventId;
                  const data      = {event_id:event_id};

                  if(state.values.is_waiting_joined === "1") {
                        // ................call API Remove From Waiting List..........//
                        dispatch(removeEventNotifymeWeb({token:token,data:data}));
                  }
                  else {
                        // .................call API Join Waiting List...............//
                        dispatch(eventNotifymeWeb({token:token,data:data,type:"eventDetails"}));
                  }
            }
            else{
                  dispatch(UiActions.setAlert({model:false,message:''}))
            }
            
      }

      let title = "";
      let price = "";
      let event_img = "";
      // let blur_event_image = "";
      let display_date = "";
      let venue_address = "";
      let category = "";
      let min_age = "";
      let description= '';
      let collection_instruction= '';
      let is_soldout = "";
      let map_link = "";
      
      let is_waiting_joined = "";
      let citymaper = ""
      // let videoLink = "";

      if(state.eventInfo && state.values && state.scheduleInfo)
      {
            title=state.eventInfo.title;
            price=state.eventInfo.price;
            event_img=state.values.event_image;
            // blur_event_image=state.values.blur_event_image;
            display_date = state.values.display_date;
            venue_address = state.values.address;
            category = state.values.category;
            min_age = state.values.min_age;
            description = state.values.description;
            collection_instruction = state.values.collection_instruction;
            is_soldout = state.values.is_soldout;
            map_link = state.values.map_link !== "https://centraltickets.co.uk/" ? state.values.map_link : "";
            is_waiting_joined = state.values.is_waiting_joined;        
            citymaper = state.values.citymaper
            // videoLink = state.values.link || "https://www.youtube.com/embed/wtU9cxZ3WCw";
      }

      return (
            <React.Fragment>
                  <div className="scroll-animations position-relative section__event">
                        {/* <!-- RELEVANT CODE --> */}
                              {state.eventInfo && state.values && state.scheduleInfo ?
                              <main className="main__event-details">
                                    <section className="section__event-details">

                                          <div className="container">
                                                <div className="row">
                                                      <div className="col-lg-12 fixed-scroll-target">
                                                            <div className="content content__event-details-v2">
                                                                  <div className="content__body p-0">
                                                                        <div className="event">
                                                                              <div className="event__header flex-xl-row">
                                                                                    <div className="event__header-image">
                                                                                          {/* <img className="event-image w-100" src="assets/images/paul-weller.jpg" alt=""/> */}
                                                                                          <img className="event-image w-100 event-image-customize" src={event_img} alt=""/>
                                                                                    </div>
                                                                                    <div className="event__header-text">
                                                                                          <h2 className="h1 event__name">{title}</h2>
                                                                                          <hr className="event__name-hr"/>
                                                                                          <h6 className="event__location">{venue_address}</h6>
                                                                                          {/* <ul className="list__event-tags list-unstyled d-flex flex-wrap">
                                                                                                <li><a href="#"><span className="badge badge-outline rounded-pill">Dance</span></a></li>
                                                                                          </ul> */}
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- .event__header --> */}

                                                                              <div className="nav-tabs__info-venue-wrapper">
                                                                                    <div className="sticky__tabTicketInfoVenue">
                                                                                          <div className="nav-tabs__info-venue">
                                                                                                <ul className="nav nav-tabs" id="bookTicketsTab" role="tablist">
                                                                                                      <li className="nav-item" role="presentation">
                                                                                                            <button className="nav-link active" id="bt-information-tab" data-bs-toggle="tab" data-bs-target="#bookTicketInformation" type="button" role="tab" aria-controls="bookTicketInformation" aria-selected="true">
                                                                                                                  Information
                                                                                                            </button>
                                                                                                      </li>
                                                                                                      <li className="nav-item" role="presentation">
                                                                                                            <button className="nav-link" id="bt-venue-tab" data-bs-toggle="tab" data-bs-target="#bookTicketVenue" type="button" role="tab" aria-controls="bookTicketVenue" aria-selected="false">
                                                                                                                  Venue
                                                                                                            </button>
                                                                                                      </li>
                                                                                                </ul>
                                                                                                <div className="tab-content" id="bookTicketsTabContent">
                                                                                                      <div className="tab-pane tab-pane__info fade show active" id="bookTicketInformation" role="tabpanel" aria-labelledby="bt-information-tab">
                                                                                                            <div className="tab-pane__body">
                                                                                                                  <div className="list-group list-group__event-details list-group-flat">
                                                                                                                        <div className="list-group-item list-group__time d-flex">
                                                                                                                              <div className="icon-wrapper">
                                                                                                                                    <img className="icon__clock" src={iconClockBlack} alt=""/>
                                                                                                                              </div>
                                                                                                                              <p className="mb-0">{display_date}</p>
                                                                                                                        </div>
                                                                                                                        <div className="list-group-item list-group__price d-flex">
                                                                                                                              <div className="icon-wrapper">
                                                                                                                                    <img className="icon__payment-card" src={iconPaymentCardBlack} alt=""/>
                                                                                                                              </div>
                                                                                                                              {parseFloat(price) <= 6.5 && 
                                                                                                                              <p className="mb-0">£0 Ticket + £{price} Admin Fee </p>}
                                                                                                                              {parseFloat(price) >= 6.51 && 
                                                                                                                              <p className="mb-0">£{price} Ticket</p>}
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                                  {/* <!-- /.list-group --> */}
                                                                                                                  <hr/>
                                                                                                                  <h4 className="heading">Collection instructions:</h4>
                                                                                                                  <p>
                                                                                                                        {collection_instruction}
                                                                                                                  </p>
                                                                                                            </div>
                                                                                                            {/* <!-- /.tab-pane__body --> */}
                                                                                                            <div className="tab-pane__cta mt-auto">
                                                                                                                  <button type="button" className={["btn btn-primary btn-book-show",is_soldout === "1" && "soldOutButton"].join(" ")} onClick={startBookingHandler}>
                                                                                                                        {is_soldout === "1" && is_waiting_joined !=="1" && " JOIN WAITING LIST"}
                                                                                                                        {is_soldout === "1" && is_waiting_joined ==="1" && "  REMOVE FROM WAITING LIST"}
                                                                                                                        {is_soldout !== "1" && "Book now"}
                                                                                                                  </button>
                                                                                                            </div>
                                                                                                            {/* <!-- /.tab-pane__cta --> */}
                                                                                                      </div>
                                                                                                      {/* <!-- /.tab-pane#bookTicketInformation --> */}
                                                                                                      <div className="tab-pane tab-pane__venue fade" id="bookTicketVenue" role="tabpanel" aria-labelledby="bt-venue-tab">
                                                                                                            <div className="tab-pane__body mb-lg-0">
                                                                                                                  <div className="list-group list-group__event-details list-group-flat">
                                                                                                                        <div className="list-group-item list-group__location d-flex">
                                                                                                                              <div className="icon-wrapper">
                                                                                                                                    <img className="icon__location-pin" src={iconLocationPinBlock}  alt=""/>
                                                                                                                              </div>
                                                                                                                              <p className="mb-0">    {venue_address}
                                                                                                                              </p>
                                                                                                                        </div>
                                                                                                                        <div className="list-group-item list-group__cta d-flex">
                                                                                                                              <div className="icon-wrapper"></div>
                                                                                                                              {/* <a className="btn btn-citymapper has-icon" href={citymaper} rel="noreferrer" target="_blank">
                                                                                                                                    <span className="btn__text me-2">Get Me There Citymapper</span>
                                                                                                                                    <span className="icon-bg icon-arrow-right icon-arrow-right-sm"></span>
                                                                                                                                    
                                                                                                                              </a> */}
                                                                                                                              <a className="btn-citymapper-image" href={citymaper} rel="noreferrer" target="_blank">
                                                                                                                              <img src={citymaperImage} alt="Get directions with Citymapper"/>
                                                                                                                              </a>
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                                  {/* <!-- /.list-group --> */}
                                                                                                                  <div className="venue-map">
                                                                                                                        <iframe title="myFrame" width="100%" height="289" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={map_link}></iframe>


                                                                                                                        {/* <iframe style={{"width": "100%", "height": "287px"}}
                                                                                                                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kenneth%20More%20Theatre,%20Oakfield%20Road,%20Ilford,%20IG1%201BT+(Kenneth%20More%20Theatre)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                                                                                                        </iframe> */}
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            {/* <!-- /.tab-pane__body --> */}
                                                                                                            <div className="tab-pane__cta mt-auto">
                                                                                                                  <button className={["btn btn-primary btn-book-show d-lg-none",is_soldout === "1" && "soldOutButton"].join(" ")} href="/" rel='noreferrer' onClick={startBookingHandler}>
                                                                                                                        {is_soldout === "1" && is_waiting_joined !=="1" && " JOIN WAITING LIST"}
                                                                                                                         {is_soldout === "1" && is_waiting_joined ==="1" && "  REMOVE FROM WAITING LIST"}
                                                                                                                        {is_soldout !== "1" && "Book now"}
                                                                                                                  </button>
                                                                                                            </div>
                                                                                                            {/* <!-- /.tab-pane__cta --> */}
                                                                                                      </div>
                                                                                                      {/* <!-- /.tab-pane#bookTicketVenue --> */}
                                                                                                </div>
                                                                                                {/* <!-- /.tab-content --> */}
                                                                                          </div>
                                                                                          {/* <!-- /.nav-tabs__info-venue --> */}
                                                                                          <ul className="list__event-tags list-unstyled d-flex flex-wrap">
                                                                                                {category.split(",").map((el,index)=>{
                                                                        
                                                                                                      return (<li key={index}>
                                                                                                                  <div className="badge badge-outline rounded-pill">
                                                                                                                        {el}
                                                                                                                  </div>
                                                                                                            </li>)
                                                                                                      })
                                                                                                }
                                                                                                <li>
                                                                                                      <div>
                                                                                                            <span className="badge badge-outline rounded-pill bg-danger">{min_age.includes("+") && "Min Age"} {min_age}</span>
                                                                                                      </div>
                                                                                                </li>
                                                                                          </ul>
                                                                                    </div>
                                                                                    {/* <!-- /.wrapper --> */}
                                                                              </div>
                                                                              {/* <!-- .nav-tabs__info-venue-wrapper --> */}

                                                                              <div className="event__body border-top">
                                                                                    {description ? <p dangerouslySetInnerHTML={{__html: description}}></p> : <p></p>}

                                                                                    {/* <div className="video-wrapper">
                                                                                          <iframe style={{"width": "560px", "height": "315px"}} src="https://www.youtube.com/embed/6dNuxw0EOD4" title="YouTube video player"
                                                                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                                    </div> */}

                                                                              </div>
                                                                              {/* <!-- .event__body --> */}
                                                                        </div>
                                                                        {/* <!-- .event --> */}
                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__event-details-v2 --> */}

                                                      </div>
                                                      {/* <!-- .col-lg-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}

                                    </section>
                                    {/* <!-- /.section__event-details --> */}
                              </main>:
                              <DummyComponent />}
                              {/* <!-- END RELEVANT CODE --> */}

                        {/* <!--
                              - Book Tickets modal below
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-12">
                                          <Transition in={state.isBooking} timeout={500} mountOnEnter unmountOnExit>
                                          {status => {
                                                return (
                                                <BookingModule show={status} title={title}/>
                                                )}
                                          }
                                          </Transition>
                                          {/* <BookingModule/> */}
                                    </div>
                                    {/* <!-- .col-lg-12 --> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container-fluid  --> */}
                  </div>
                
            </React.Fragment>)
}

export default EventDetail;
