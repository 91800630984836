
import axios from "axios";

import {UiActions} from "./../../Store/Ui/Ui";
import {BookingModuleActions} from "./BookingModule";
import {EventDetailActions} from "./../EventDetail/EventDetail";
import { AuthActions } from "./../Auth/Auth"
import { BaseUrl } from "./../../Shared/Constants"

export const getPerformanceInfo = (payload)=> {

        return async (dispatch)=>{
               
                const sendRequest =async () =>{
                        await axios.post(BaseUrl + '/booking/getPerformanceInfo',payload.data,{
                              method:'POST',
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {      
                               
                              const data = response.data.data;

                              if(payload.group_availablity === 3 && data.has_booking === "1"){
                                    dispatch(UiActions.setSpinner({status:""}));
                                    dispatch(UiActions.setAlert({model:true,message:"Sorry, you're only able to have one booking per performance of any show where group sizes are controlled by the event organiser."}));
                                    return;
                              }

                              if(payload.group_availablity === 1 && data.has_booking === "1"){
                                    dispatch(UiActions.setSpinner({status:""}));
                                    dispatch(UiActions.setAlert({model:true,message:"Sorry, you're only able to have one booking per performance of any show where group sizes are controlled by the event organiser."}));
                                    return;
                              }

                              if(payload.group_availablity === 0 && data.is_single_group === 1 && data.has_booking === "1"){
                                    dispatch(UiActions.setSpinner({status:""}));
                                    dispatch(UiActions.setAlert({model:true,message:"Sorry, you're only able to have one booking per performance of any show where group sizes are controlled by the event organiser."}));
                                    return;
                              }

                                // This organiser of this event has requested that we only sell specific multiples of tickets for this show.

                              if(data.total_remain <= 0){

                                    dispatch(UiActions.setSpinner({status:''}))
                                    dispatch(UiActions.setAlert({model:true,message:"sorry, this performance is fully booked"}));
                              }
                              else if(data.user_remain > 0) {

                                    dispatch(BookingModuleActions.setPerformanceInfo({data:data,showId:payload.data.show_id}));
                                    dispatch(BookingModuleActions.setBookingStep({step:'AddingTicketsPage'}));
                                    dispatch(UiActions.setSpinner({status:""}))
                              }
                              else {
                                    dispatch(UiActions.setSpinner({status:""}))
                                    dispatch(UiActions.setAlert({model:true,message:"You have reached the maximum number of allowed tickets for this event. Please contact us if you'd like to book more."}))
                              }
                                
                                
                        })
                        .catch(function (error) {

                              if(error.response) {
                                        
                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                    }
                                    else if(error.response.status === 500)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message}))
                                    }
                                    else
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              dispatch(UiActions.setSpinner({status:""}))
                        });
                }

                await sendRequest();
        }
}

export const TicketBlock = (payload)=> {

        return async (dispatch)=>{

                  const sendRequest =async () =>{

                        await axios.post(BaseUrl + '/booking/TicketBlock',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {       
                              // console.log(response)
                              localStorage.setItem('nhs_session_block_id',response.data.data.session_block_id);
                              dispatch(BookingModuleActions.setBookingStep({step:'OrderSummaryPage'}));
                              dispatch(UiActions.setSpinner({status:""}))
                                
                        })
                        .catch(function (error) {

                              dispatch(UiActions.setSpinner({status:""}))
                              dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:payload.data.group_availablity}));
                              dispatch(BookingModuleActions.removePromoCode());

                              if(error.response){

                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                                    }
                                    else if(error.response.status === 500)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message}))
                                    }
                                    else
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        });
                  }

                  await sendRequest();
        }
}

export const TicketRelease = (payload)=> {

      return async (dispatch)=>{
               
            const sendRequest =async () =>{
                  axios.post(BaseUrl + '/booking/ReleaseTickets',{session_id:payload.session_id,group_availablity:payload.group_availablity},{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              }
                  })
                  .then(function (response) 
                  {       
                        // console.log(response)
                        localStorage.removeItem("nhs_session_block_id");
                  })
                  .catch(function (error) {
                              
                        // console.log(error.response)
                        if(error.response){

                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }

                  });
            }

            await sendRequest();
      }
}

// -----------------------------------------------------------------------------------------------------//
// -------------------------------------Apply PromoCode API---------------------------------------------//
// -----------------------------------------------------------------------------------------------------//

export const applyPrmoCode = (payload)=> {

        return async (dispatch)=>{
               
            const sendRequest =async () =>{

                  await axios.post(BaseUrl + '/booking/applyPromocode',{...payload.data},{
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {       
                        let promocode
                        if(response.data.status ==="1"){

                              promocode = {...response.data.data,status:"valid",message:response.data.message}
                        }
                        else if(response.data.status ==="0"){
                              promocode = {
                                          main_discount: 0,
                                          discount_type: "",
                                          discount_session: 0,
                                          off: "",
                                          promocode: "",
                                          totalAmount: 0,
                                          vat: 0,
                                          discount: 0,
                                          mainAmount: 0,
                                          amount_type: 0,
                                          status: "invalid",
                                          message: response.data.message
                                    }
                        }
                        dispatch(BookingModuleActions.promoCodeSet({promocode:promocode}));
                        dispatch(UiActions.setSpinner({status:""}))
                  })
                  .catch(function (error) {

                        // console.log(error.response,)
                        dispatch(UiActions.setSpinner({status:""}))
                        dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:payload.data.group_availablity}));
                        if(error.response){

                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  });
            }

            await sendRequest();
        }
}

export const sendBookingData = (payload)=> {

      return async (dispatch)=>{
               
            const sendRequest =async () =>{
                  axios.post(BaseUrl + '/sagepay/WebIfinalPayment',{booking:payload.booking},{
                        headers: {
                              'token': payload.booking.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        }
                  })
                  .then(function (response) 
                  {       
                        // console.log(response.data, "API response....")
                        // alert(response.data.status)
                        
                        if(response.data.status === "0")
                        {
                              // window.location = "/event";
                              localStorage.removeItem("nhs_session_block_id");
                              localStorage.removeItem("nhs_mKey");
                              dispatch(UiActions.clearUiSpinner());
                              dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:payload.booking.group_availablity}));
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                              dispatch(UiActions.clearTimer());            
                        }
                        else if(response.data.status === "3DAuth"){
                              
                              dispatch(BookingModuleActions.setSecureData({data:response.data}))
                              localStorage.removeItem("nhs_session_block_id");
                              localStorage.removeItem("nhs_mKey");
                              // dispatch(UiActions.clearUiSpinner());
                              // dispatch(UiActions.clearTimer());
                              dispatch(UiActions.setSecure3D());

                        }
                        else if(response.data.status === "1"){

                              localStorage.removeItem("nhs_session_block_id");
                              localStorage.removeItem("nhs_mKey");
                              dispatch(BookingModuleActions.setBookingId({bookingId:response.data.booking_id}))
                              dispatch(UiActions.clearUiSpinner());
                              dispatch(BookingModuleActions.clearBookingModuleState({step:"BookingConfirmationPage",group_availablity:payload.booking.group_availablity}));
                              dispatch(UiActions.clearTimer());

                              // dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                        }
                              
                  })
                  .catch(function (error) {

                        // console.log(error.response,"error")
                        localStorage.removeItem("nhs_mKey");
                        dispatch(UiActions.clearUiSpinner());
                        dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:payload.booking.group_availablity}));
                        dispatch(TicketRelease({token:payload.booking.token,session_id:payload.booking.block_id,group_availablity:payload.booking.group_availablity}))
                        if(error.response){

                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else{
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  });
            }
            await sendRequest();

      }
}

export const addFreeBookingData = (payload)=> {

      return async (dispatch)=>{
               
            const sendRequest =async () =>{
                  axios.post(BaseUrl + '/sagepay/WebFreeBooking',{...payload.booking},{
                        headers: {
                              'token': payload.booking.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        }
                  })
                  .then(function (response) 
                  {       
                        // console.log(response);
                        if(response.data.status === "0")
                        {
                              dispatch(UiActions.clearUiSpinner());
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                              dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:payload.booking.group_availablity}));
                              dispatch(TicketRelease({token:payload.booking.token,session_id:payload.booking.block_id,group_availablity:payload.booking.group_availablity}))
                              dispatch(UiActions.clearTimer());
                        }
                        else if(response.data.status === "1"){
                              dispatch(BookingModuleActions.setBookingId({bookingId:response.data.booking_id}))
                              dispatch(UiActions.clearUiSpinner());
                              dispatch(BookingModuleActions.clearBookingModuleState({step:"BookingConfirmationPage",group_availablity:payload.booking.group_availablity}));
                              dispatch(UiActions.clearTimer());
                        }
                              
                  })
                  .catch(function (error) {

                        // console.log(error.response)

                        dispatch(UiActions.clearUiSpinner());
                        dispatch(UiActions.clearTimer());
                        dispatch(BookingModuleActions.clearBookingModuleState({step:"EvnentsCalenderPage",group_availablity:payload.booking.group_availablity}));
                        dispatch(TicketRelease({token:payload.booking.token,session_id:payload.booking.block_id,group_availablity:payload.booking.group_availablity}))
                        if(error.response){

                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else{
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  });
            }

            await sendRequest();
        }
}

export const paymentBlockAccess = (payload)=> {

      return async (dispatch)=>{
            const sendRequest =async () =>{
                  axios.post(BaseUrl + '/payment_block_access',{mKey:payload.mKey,card_identifier:payload.card_identifier,access_id:payload.access_id},{
                        headers: {
                              // 'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data);
                        if(response.data.status === "0"){
                              dispatch(UiActions.setAlert({model:true,message:response.data.error || response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              localStorage.removeItem("nhs_mKey");
                              dispatch(UiActions.setReactiveAccountRequest({request:false}))
                              dispatch(UiActions.clearUiSpinner());
                        }
                        else if(response.data.status === "3DAuth"){
                                    
                              dispatch(BookingModuleActions.setSecureData({data:response.data}))
                              localStorage.removeItem("nhs_mKey");
                              dispatch(UiActions.setReactiveAccountRequest({request:false}))
                              // dispatch(UiActions.clearUiSpinner())
                              // dispatch(CardDetailActions.setRenderTag({data:true}))

                        }
                        else if(response.data.status === "1"){

                              localStorage.removeItem("nhs_mKey");
                              dispatch(UiActions.setReactiveAccountRequest({request:false}))
                              // dispatch(UiActions.clearUiSpinner()); 
                              dispatch(AuthActions.login({...response.data.data}));
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                              payload.navigate("/currentListing");
                        }
                        
                  })
                  .catch(function (error) {
                        
                        // console.log(error.response,'errors')
                        dispatch(UiActions.clearUiSpinner())
                        if(error.response){
                                    
                              if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 500)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else{

                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        
                  });
            }
            await sendRequest();
      }
}


