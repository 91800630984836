
import React from "react";

import "./Modal.css"
import Backdrop from "./../Backdrop/Backdrop"
import stockImage08 from "./../../assests/images/stock-image-rounded-08.png";
import stockImage08a2x from "./../../assests/images/stock-image-rounded-08@2x.png";

const Modal = (props)=>{

        return (
                <React.Fragment>
                        <Backdrop show={props.show} />
                        <div className="modal modal__book-tickets fade show uiModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalBookTickets" style={{"display": "block", "paddingLeft": "0px"}} aria-modal="true" role="dialog">
                        <div className="modal-dialog uiModal_modalDialog">
                              <div className="modal-content uiModal_modalDialog__modalContent">
                                    <div className="modal-body p-0 uiModal_modalDialog__modalContent_modalBody">
                                          <div className="content__book-tickets mx-md-auto w-100">
                                                <div id="bookTicketsStep5" className="content content__booking-completed d-flex uiModal_modalDialog__modalContent_modalBody__content">
                                                      <div className="content__body" style={{"justifyContent":"space-around","paddingTop":"0px","paddingBottom":"0px"}}>
                                                            <div className="wrapper text-center">
                                                                  <div className="event__poster mx-auto">
                                                                        <img className="img-fluid object-fit" src={stockImage08} srcSet={stockImage08a2x} alt=""/>
                                                                        {/* <img className="img-fluid object-fit" src={eventImage05b} alt=""/> */}
                                                                  </div>
                                                                  <p dangerouslySetInnerHTML={{__html: props.children}}></p>
                                                                  
                                                                  <div className="buttons-wrapper gap-0">
                                                                        {/* <a href="#" className="btn btn-primary fw-bold cancle" onClick={props.closeBackdrop}>Cancle</a> */}
                                                                        <button className="btn btn-primary fw-bold" style={{"backgroundColor":props.type.value === "confirm" && "var(--bs-red)"}} onClick={props.closeBackdrop}>
                                                                              {props.type.value === "confirm" && "Cancel"}
                                                                              {props.type.value === "alert" && "Close"}
                                                                        </button>
                                                                        {props.type.value === "confirm" &&
                                                                        <button className="btn btn-primary fw-bold" onClick={props.confirmMessage}>
                                                                              {!props.type.status && "Confirm"}
                                                                              {props.type.status && "Please wait.."}
                                                                              {props.type.status && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                                                        </button>}
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                          </div>
                                          {/* <!-- /.content__book-tickets --> */}
                                    </div>
                                    {/* <!-- /.modal-body --> */}
                              </div>
                              {/* <!-- /.modal-content --> */}
                        </div>
                        {/* <!-- /.modal-dialog --> */}
                        <div className="tfm-modal-overlay tfm-modal__close"></div>
                  </div>
                </React.Fragment>

        );
        
}


export default React.memo(Modal);