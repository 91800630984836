
export const checkValidityValidOtp=(value, type) =>{

      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }
     
      if(type === "mobile"){
            // var mobileNumberRegex = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g;
            if(!value.trim() || value.trim()=== "")
            {
                  isValid = false;
                  validationMessage = "Please enter a valid UK mobile number";
                  // return {isValid,validationMessage};
            }
            // else if(value.match(/^((\+44\s?|0)7([45789]\d{2}|624)\s?\d{3}\s?\d{3})$/))
            else if(value.trim().length > 11 || value.trim().length < 11)
            {
                  isValid = false;
                  validationMessage = "Please enter a valid UK mobile number";
                  // return {isValid,validationMessage};
            }
      }

      if(type === "otp"){
            if(!value.trim() || value.trim()=== "")
            {
                  isValid = false;
                  validationMessage = "Please enter the 6-digit code we’ve sent to your mobile.";
                  return {isValid,validationMessage};
            }
            else if(value.trim().length > 6 || value.trim().length < 6)
            {
                  isValid = false;
                  validationMessage = "That doesn’t look right.  Please enter the code we’ve sent you.";
                  return {isValid,validationMessage};
            }
      }

      return {isValid,validationMessage};
}

