import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      event_id:'',
      step:"EvnentsCalenderPage",
      group_availablity:0,
      performanceInfo:null,
      bookingDetails:{
            showId:null,
            price: 0,
            subTotal: 5,
            quantity: 1,
            vatPer:20,
            vat:0,
            ticketValidationMsg: "",
            scheduleTimeTitle: "",
            max_tickets:"",
            promocode:{
                  amount_type: "",
                  discount: 0,
                  discount_session: 0,
                  discount_type: "",
                  mainAmount: 0,
                  main_discount: 0,
                  off: "",
                  promocode: "",
                  totalAmount: 0,
                  vat: 0,
                  status:"none",
                  message:""
            },
            checkBox:false
      },
      secureData:null,
      bookingId:""
}

const BookingModuleSlice = createSlice({
      name:'bookingmodule',
      initialState:initialState,
      reducers:{
            setBookingStep(state,action){
                  state.step = action.payload.step;
            },
            changeCheckBox(state,action){
                  state.bookingDetails.checkBox = action.payload.checkBox;
            },
            increaseTicketQuantity(state,action){
                  state.bookingDetails.quantity = action.payload.newQuantity;
                  state.bookingDetails.subTotal = action.payload.subTotal;
                  state.bookingDetails.vat      = action.payload.vat;
            },
            decreaseTicketQuantity(state,action){
                  state.bookingDetails.quantity= action.payload.newQuantity;
                  state.bookingDetails.subTotal = action.payload.subTotal;
                  state.bookingDetails.vat = action.payload.vat;
            },
            setValidatingMessage(state,action){
                  state.bookingDetails.ticketValidationMsg = action.payload.validatingMessage;
            },
            setPerformanceInfo(state,action){
                  state.performanceInfo = action.payload.data;
                  state.bookingDetails.showId = action.payload.showId
            },
            initialPriceQuantity(state,action){
                  
                  state.bookingDetails.price = action.payload.price;
                  state.bookingDetails.quantity = action.payload.quantity;
                  state.bookingDetails.subTotal = action.payload.subTotal;
                  state.bookingDetails.max_tickets = action.payload.max_tickets;
                  state.bookingDetails.vat = action.payload.vat;
                  state.bookingDetails.ticketValidationMsg = "";
            },
            clearValidationMessage(state,action){
                  state.bookingDetails.ticketValidationMsg = "";
            },
            clearBookingModuleState(state,action){
                  state.step = action.payload.step;
                  state.performanceInfo = null;
                  state.group_availablity = action.payload.group_availablity;
                  state.bookingDetails = {
                        showId:null,
                        price: 0,
                        subTotal: 5,
                        quantity: 1,
                        vatPer:20,
                        vat:0,
                        ticketValidationMsg: "",
                        scheduleTimeTitle: "",
                        max_tickets:"",
                        promocode:{
                              amount_type: "",
                              discount: 0,
                              discount_session: 0,
                              discount_type: "",
                              mainAmount: 0,
                              main_discount: 0,
                              off: "",
                              promocode: "",
                              totalAmount: 0,
                              vat: 0,
                              status:"none",
                              message:""
                        },
                        checkBox:false
                  };
            },
            setGroupAvailability(state,action){
                  state.group_availablity = action.payload.group_availablity;
                  state.step = action.payload.step;
            },
            setEventId(state,action){
                  state.event_id = action.payload.event_id;
            },
            setTicketsQuantity(state,action){
                  state.performanceInfo           = action.payload.performanceInfo
                  state.bookingDetails.quantity   = action.payload.finalQuantity;
                  state.bookingDetails.subTotal   = action.payload.finalSubTotal;
                  state.bookingDetails.vat        = action.payload.finalVat;
            },
            promoCodeSet(state,action){
                  state.bookingDetails.promocode = action.payload.promocode;
            },
            removePromoCode(state,action){
                  state.bookingDetails.promocode = {
                        amount_type: "",
                        discount: 0,
                        discount_session: 0,
                        discount_type: "",
                        mainAmount: 0,
                        main_discount: 0,
                        off: "",
                        promocode: "",
                        totalAmount: 0,
                        vat: 0,
                        status:"none",
                        message:""
                  };
            },
            setValueForPromoCodes(state,action){
                  state.bookingDetails.promocode.totalAmount = action.payload.totalAmount;
                  state.bookingDetails.promocode.discount = action.payload.discount;
                  state.bookingDetails.promocode.mainAmount = action.payload.mainAmount;
                  state.bookingDetails.promocode.vat = action.payload.vat;
            },
            setSecureData(state,action){
                  state.secureData = action.payload.data;
            },
            setBookingId(state,action){
                  state.bookingId = action.payload.bookingId
            },
            reset: ()=> initialState
                
      }
})


export const BookingModuleActions = BookingModuleSlice.actions;
export default BookingModuleSlice.reducer;




//--------------------------------------------------------------------------//
// ---------------------old Slice without reset options----------------------
//--------------------------------------------------------------------------//

// import { createSlice } from "@reduxjs/toolkit";


// const BookingModuleSlice = createSlice({
//             name:'bookingmodule',
//             initialState:{
//             event_id:'',
//             step:"EvnentsCalenderPage",
//             group_availablity:0,
//             performanceInfo:null,
//             bookingDetails:{
//                   showId:null,
//                   price: 0,
//                   subTotal: 5,
//                   quantity: 1,
//                   vatPer:12.5,
//                   vat:0,
//                   ticketValidationMsg: "",
//                   scheduleTimeTitle: "",
//                   max_tickets:"",
//                   promocode:{
//                         amount_type: "",
//                         discount: 0,
//                         discount_session: 0,
//                         discount_type: "",
//                         mainAmount: 0,
//                         main_discount: 0,
//                         off: "",
//                         promocode: "",
//                         totalAmount: 0,
//                         vat: 0,
//                         status:"none",
//                         message:""
//                   },
//                   checkBox:false
//             },
//             secureData:null,
//             bookingId:""
//       },
//       reducers:{
//             setBookingStep(state,action){
//                   state.step = action.payload.step;
//             },
//             changeCheckBox(state,action){
//                   state.bookingDetails.checkBox = action.payload.checkBox;
//             },
//             increaseTicketQuantity(state,action){
//                   state.bookingDetails.quantity = action.payload.newQuantity;
//                   state.bookingDetails.subTotal = action.payload.subTotal;
//                   state.bookingDetails.vat      = action.payload.vat;
//             },
//             decreaseTicketQuantity(state,action){
//                   state.bookingDetails.quantity= action.payload.newQuantity;
//                   state.bookingDetails.subTotal = action.payload.subTotal;
//                   state.bookingDetails.vat = action.payload.vat;
//             },
//             setValidatingMessage(state,action){
//                   state.bookingDetails.ticketValidationMsg = action.payload.validatingMessage;
//             },
//             setPerformanceInfo(state,action){
//                   state.performanceInfo = action.payload.data;
//                   state.bookingDetails.showId = action.payload.showId
//             },
//             initialPriceQuantity(state,action){
                  
//                   state.bookingDetails.price = action.payload.price;
//                   state.bookingDetails.quantity = action.payload.quantity;
//                   state.bookingDetails.subTotal = action.payload.subTotal;
//                   state.bookingDetails.max_tickets = action.payload.max_tickets;
//                   state.bookingDetails.vat = action.payload.vat;
//                   state.bookingDetails.ticketValidationMsg = "";
//             },
//             clearValidationMessage(state,action){
//                   state.bookingDetails.ticketValidationMsg = "";
//             },
//             clearBookingModuleState(state,action){
//                   state.step = action.payload.step;
//                   state.performanceInfo = null;
//                   state.group_availablity = action.payload.group_availablity;
//                   state.bookingDetails = {
//                         showId:null,
//                         price: 0,
//                         subTotal: 5,
//                         quantity: 1,
//                         vatPer:12.5,
//                         vat:0,
//                         ticketValidationMsg: "",
//                         scheduleTimeTitle: "",
//                         max_tickets:"",
//                         promocode:{
//                               amount_type: "",
//                               discount: 0,
//                               discount_session: 0,
//                               discount_type: "",
//                               mainAmount: 0,
//                               main_discount: 0,
//                               off: "",
//                               promocode: "",
//                               totalAmount: 0,
//                               vat: 0,
//                               status:"none",
//                               message:""
//                         },
//                         checkBox:false
//                   };
//             },
//             setGroupAvailability(state,action){
//                   state.group_availablity = action.payload.group_availablity;
//                   state.step = action.payload.step;
//             },
//             setEventId(state,action){
//                   state.event_id = action.payload.event_id;
//             },
//             setTicketsQuantity(state,action){
//                   state.performanceInfo           = action.payload.performanceInfo
//                   state.bookingDetails.quantity   = action.payload.finalQuantity;
//                   state.bookingDetails.subTotal   = action.payload.finalSubTotal;
//                   state.bookingDetails.vat        = action.payload.finalVat;
//             },
//             promoCodeSet(state,action){
//                   state.bookingDetails.promocode = action.payload.promocode;
//             },
//             removePromoCode(state,action){
//                   state.bookingDetails.promocode = {
//                         amount_type: "",
//                         discount: 0,
//                         discount_session: 0,
//                         discount_type: "",
//                         mainAmount: 0,
//                         main_discount: 0,
//                         off: "",
//                         promocode: "",
//                         totalAmount: 0,
//                         vat: 0,
//                         status:"none",
//                         message:""
//                   };
//             },
//             setValueForPromoCodes(state,action){
//                   state.bookingDetails.promocode.totalAmount = action.payload.totalAmount;
//                   state.bookingDetails.promocode.discount = action.payload.discount;
//                   state.bookingDetails.promocode.mainAmount = action.payload.mainAmount;
//                   state.bookingDetails.promocode.vat = action.payload.vat;
//             },
//             setSecureData(state,action){
//                   state.secureData = action.payload.data;
//             },
//             setBookingId(state,action){
//                   state.bookingId = action.payload.bookingId
//             }
                
//       }
// })


// export const BookingModuleActions = BookingModuleSlice.actions;
// export default BookingModuleSlice.reducer;