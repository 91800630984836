import React from "react";

import "./DummyComponent.css"

const DummyComponent = (props) => {
     
      return (

                  <li className="list-item CurrentDummyComponent">
                        <div className="event d-flex flex-column flex-md-row align-items-md-stretch align-items-center-start">
                              <div className="event__poster flex-shrink-0">
                                    <div className="img-fluid"></div>
                              </div>
                              <div className="event__body d-flex flex-column flex-lg-row align-items-lg-center">
                                    <div className="event__details d-flex flex-column flex-xxl-row align-items-xxl-center">
                                          <div className="event__details-name mb-xxl-0">
                                                <h3 className="event__name"></h3>
                                                <p className="event__time mb-xxl-0"></p>
                                          </div>
                                          <div className="event__details-location">
                                                <div className="event-location">
                                                      <div className="icon-wrapper flex-shrink-0">
                                                            <div className="icon__location-pin"></div>
                                                      </div>
                                                      <div className="location">
                                                            <span style={{"marginTop":"0"}}></span>
                                                            <span></span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.event__details --> */}
                                    <div className="event__cta">
                                          <div className="buttons-wrapper d-flex flex-column align-items-md-start">
                                                {props.type === "past" ?
                                                <button type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0">
                                                      
                                                </button> : 
                                                <React.Fragment>
                                                      <button type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0">
                                                            
                                                      </button>
                                                      <button type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0">
                                                            
                                                      </button>
                                                      <button type="button" className="btn btn-xl-lg btn-primary fw-bold flex-shrink-0">
                                                            
                                                      </button>
                                                </React.Fragment>
                                                }
                                          </div>
                                    </div>
                              </div>
                              {/* <!-- /.event__body --> */}
                        </div>
                        {/* <!-- /.event --> */}
                  </li>

      )
}

export default DummyComponent;
