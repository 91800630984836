
const checkValidationReactiveForm=(value, type) =>{
      let isValid = true;
      // let validatingMessage = "";
      if (!type) {
          return true;
      }

      if(type === "fullname"){
            if(value ==='') 
            {
                  isValid = false;
            }
      }

      if(type === "email"){

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(value ==='') 
            {
                  isValid = false;
            }
             
            if(!value){
              
                  isValid = false;
                  // validationMessage = "please enter new email address";
              
            }

            if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  // validationMessage = "please enter a valid email address";
                  // return {isValid,validationMessage};
            }

      }

      if(type === "attendance"){
            if(!value) 
            {
                  isValid = false;
            }
      }

      if(type === "cancellingbooking"){
            if(!value) 
            {
                  isValid = false;
            }
      }
      if(type === "Punctuality"){
            if(!value) 
            {
                  isValid = false;
            }
      }
      if(type === "Etiquette"){
            if(!value) 
            {
                  isValid = false;
            }
      }
      if(type === "TicketOffers"){
            if(!value) 
            {
                  isValid = false;
            }
      }
    
      return isValid;
}

export default checkValidationReactiveForm;
