import React, { useState, useEffect } from 'react'

import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { UiActions } from './../../Store/Ui/Ui';

import PaymentModal from './PaymentModal';

import { Transition } from 'react-transition-group';
import axios from 'axios';
import { BaseUrl } from '../../Shared/Constants';

const ReactiveAccount =(props)=> {

      const [state,setState] = useState(false);
      const [data,setData] = useState(null);

      const dispatch  =       useDispatch();
      const params = useParams();

      const navigate = useNavigate();
      let location = useLocation();

      useEffect(() => {
            if(location.pathname.includes("reactive_account_response")){

                  if(params.status === "success"){
                        dispatch(UiActions.setAlert({model:true,message:"Your account has been restored - you can book tickets again."}))
                        navigate("/")
                  }
                  else if(params.status === "failed"){
                        dispatch(UiActions.setAlert({model:true,message:"Sorry, our payment merchant was unable to process this order.  Please email us at enquiries@centraltickets.co.uk and a member of the team willl assist you"}))
                        navigate("/")
                  }
            }
            else {
                  const sendRequest = async () =>{
            
                        axios.get(BaseUrl + '/restore_block_access',{
                              params:{
                                    'access_id':params.id
                              },
                              headers: {
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response){       
                              
                              // console.log(response)
                              if(response.data.status === "0"){

                                    dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                                    navigate("/")
                              }
                              else if(response.data.status === '1'){
                                    setData(response.data.data)
                              }

                        })
                        .catch(function (error) {

                              if(error.response){
                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message ||"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                                    else if(error.response.status === 500)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error ||"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                                    else
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else{
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              navigate("/");
                        });
                  }
                  sendRequest();
            }

      }, [])

      const restoreAccessHandler = ()=>{
            setState(true)
      }

      const closePaymentModalHandler = ()=>{
            dispatch(UiActions.clearUiSpinner());
            setState(false)
      }

      return (
            <React.Fragment>
                  <Transition in={state} timeout={500} mountOnEnter unmountOnExit>
                        {status => {
                              return (
                                    <PaymentModal show={status} closePaymentModal={closePaymentModalHandler} page="reactive" amount={parseInt(data.upgrade_fees)} access_id={params.id}/>
                              )}
                        }
                  </Transition>
                  <main>
                        <section className="section__account-settings section__reactive-account" style={{"marginTop":"","height":"65vh","display":"flex","alignItems":"center"}}>
                              {/* <!--
                                    NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                              --> */}
                              <div className="container-fluid container__block">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__account-settings">
                                                      <div className="content-box-wrapper mx-lg-auto" style={{"border":"none"}}>
                                                            <div className="content__header text-center">
                                                                  <h1 className="header__title fw-bold mb-0">Account Paused - Unlock Listings</h1>
                                                                  <p style={{"marginTop":"20px"}}>Your account is currently paused for a period of {data && data.count} weeks. You can choose to wait until your account unlocks or you can pay to restore access to our seat-filling listings immediately by paying £{data ? data.upgrade_fees : "0.00"}.
                                                                  </p>
                                                            </div>
                                                            {/* <!-- /.content__header --> */}
                                                            <div className="content__body">
                                                                        <div className="row">
                                                                                    <div className='row' style={{"display":"flex","justifyContent":"space-around"}}>
                                                                                          <button type="submit" className="col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2" onClick={restoreAccessHandler}>
                                                                                                Restore Access
                                                                                          </button>
                                                                                          <Link to="/" className="col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2">
                                                                                                No thanks, I’ll wait
                                                                                          </Link>
                                                                                    </div>
                                                                              
                                                                        </div>
                                                                        {/* <!-- /.row --> */}
                                                            </div>
                                                            {/* <!-- /.content__body --> */}
                                                      </div>
                                                      {/* <!-- /.content-box-wrapper --> */}
                                                
                                                </div>
                                                {/* <!-- /.content --> */}
                                          </div>
                                          {/* <!-- .col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid  --> */}

                        </section>
                        {/* <!-- /.section__ --> */}
                  </main>
            </React.Fragment>
      )
}

export default ReactiveAccount;