import { BookingActions } from "./Bookings";
import { UiActions } from "../Ui/Ui";
import {BaseUrl} from "../../Shared/Constants";
import axios from "axios";

// -------------------------------------------------------------------------------------------------------------------------------//
// ------------------------------  API For Fetch  Current Bookings & Past Bookings Data For user ---------------------------------//
// -------------------------------------------------------------------------------------------------------------------------------//

export const fetchCurrentBookingsData = (payload)=> {

      return async (dispatch)=>{
            const fetchData =async () =>{
                  axios.post(BaseUrl + '/booking/currentBookings',payload.data,{
                        method:'POST',
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response.data)
                        if(response.data.status === "1"){
                              dispatch(BookingActions.setBookingsData({currentBookings:response.data.current_bookings,pastBookings:response.data.past_bookings}));
                              // dispatch(BookingActions.setRequestResponse())
                        }
                        else if(response.data.status === "0"){
                              dispatch(BookingActions.setBookingsData({currentBookings:[],pastBookings:[]}));
                              // dispatch(BookingActions.setRequestResponse())
                        }
                        dispatch(BookingActions.setRequestResponse());
                  })
                  .catch(function (error) {
                        // console.log(error.response)
                        dispatch(BookingActions.setRequestResponse())
                        if(error.response){

                              if(error.response.status === 500){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error ||  "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 400)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error ||  "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                              else if(error.response.status === 404)
                              {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                              
                  });
            }

            await fetchData();
                
      }
}

export const cancleBookingRequest = (payload)=> {

      return async (dispatch)=>{
            const cancleBooking =async () =>{
                     
                  axios.post(BaseUrl + 'booking/reduce_booking',payload.data,{
                        method:'POST',
                        headers: {
                              'token': payload.token,
                              'reuqest_client': 'web',
                              'Content-Type': 'application/json'
                        },
                  })
                  .then(function (response) 
                  {
                        // console.log(response);
                        dispatch(BookingActions.closeCancleBookings());
                        const pauseAccount = localStorage.getItem("pauseAccount");
                        // console.log(pauseAccount)
                        if(!pauseAccount){
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                        }
                        else {
                              localStorage.removeItem("pauseAccount");
                        }
                        dispatch(fetchCurrentBookingsData({data:{},token:payload.token}));

                  })
                  .catch(function (error) {

                        // console.log(error.response);
                        dispatch(BookingActions.closeCancleBookings());
                        if(error.response){

                              if(error.response.status === 400){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message||error.response.data.error}))
                              }
                              else if(error.response.status === 500){
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message||error.response.data.error}))
                              }
                              else{
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else{
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                        
                  });
            }

            await cancleBooking();
      }
}

export const amendTicketsRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                  axios.post(BaseUrl + '/booking/contact_web_booking_amendment',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              // console.log(response)
                              dispatch(BookingActions.closeCancleBookings());
                              dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                                
                        })
                        .catch(function (error) {

                              //   console.log(error.response)
                              dispatch(BookingActions.setAmendTicketsRequestingHandler({isRequestingAmendTickets:false}))
                              if(error.response){

                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                    }
                                    else if(error.response.status === 500){
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || error.response.data.errors}))
                                    }
                                    else if(error.response.status === 404)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                                
                        });
            }

            await sendRequest();
                
        }
}

export const downloadTicketRequest = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                  axios.post(BaseUrl + '/tfm_download_tickets',payload.data,{
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              console.log(response)
                              if(response.data.status === "1"){
                                    window.location = response.data.download_link;
                                    // payload.navigate(`${payload.navigate_path}`);
                              }
                              else if(response.data.status === "0"){

                                    dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                              }
                              dispatch(UiActions.setDownloadTIcketRequest({request:null}))
                                
                        })
                        .catch(function (error) {

                              // console.log(error.response)
                              dispatch(UiActions.setDownloadTIcketRequest({request:null}))
                              if(error.response){

                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                    }
                                    else if(error.response.status === 500){
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || error.response.data.errors}))
                                    }
                                    else if(error.response.status === 404)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                                
                        });
            }

            await sendRequest();
                
        }
}



export const getCollectionInstructions = (payload)=> {

      return async (dispatch)=>{

            const sendRequest =async () =>{
                  axios.get(BaseUrl + '/booking/getcollection_instruction',{
                              params : payload.data,
                              headers: {
                                    'token': payload.token,
                                    'reuqest_client': 'web',
                                    'Content-Type': 'application/json'
                              },
                        })
                        .then(function (response) 
                        {
                              if(response.data.status === "1"){
                                    dispatch(UiActions.setAlert({model:true,message:response.data.collection_instruction}))
                              }
                              else {
                                    console.log(response.data)
                                    dispatch(UiActions.setAlert({model:true,message:response.data.message}))
                              }
                              dispatch(UiActions.setCollectionInstructiontRequest({request:null}))
                                
                        })
                        .catch(function (error) {
                              dispatch(UiActions.setCollectionInstructiontRequest({request:null}))
                              if(error.response){

                                    if(error.response.status === 400)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.error || error.response.data.message}))
                                    }
                                    else if(error.response.status === 500){
                                          dispatch(UiActions.setAlert({model:true,message:error.response.data.message || error.response.data.error || error.response.data.errors}))
                                    }
                                    else if(error.response.status === 404)
                                    {
                                          dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                                    }
                              }
                              else {
                                    dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                                
                        });
            }

            await sendRequest();
                
        }
}







